/* eslint-disable no-unused-vars */
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography
} from '@mui/material';

// All Icones used in this component
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { StyledMenu } from './Styled-components';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { WHITES_CASTLE_IMAGE } from '../../Shared/shared';
import TvIcon from '@mui/icons-material/Tv';

export default function ProfileManagment() {
  // Select Box starts
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Select Box ends

  // get user data from redux State
  const userDetails = useSelector(
    (state) => state.authorize?.loginResponse?.user
  );

  // logOut the user
  const navigate = useNavigate();
  const clearStorage = () => {
    localStorage.clear();
    navigate(0);
  };
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box textAlign="center">
          <div>
            <Button
              style={{
                background: 'transparent',
                fontSize: '11px',
                fontWeight: '700'
              }}
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}>
              <Box>
                <Avatar sx={{ backgroundColor: '#037DFF' }}>
                  <PersonOutlinedIcon />
                  {/* <img
                    src={WHITES_CASTLE_IMAGE}
                    height={30}
                    width={30}
                    alt=""
                  /> */}
                </Avatar>
              </Box>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <Box backgroundColor="#F5F6FA">
                <MenuItem onClick={() => {}}>
                  <Avatar sx={{ backgroundColor: '#037DFF' }}>
                    {userDetails?.name?.toUpperCase()?.charAt(0)}
                  </Avatar>
                  <Box ml={2} display="flex" flexDirection="column">
                    <Box fontSize="13px" fontWeight="800">
                      {userDetails?.name}
                    </Box>
                    <Box fontSize="12px" fontWeight="500" color="#8094ae">
                      {userDetails?.email}
                    </Box>
                  </Box>
                </MenuItem>
              </Box>

              {/* <MenuItem onClick={handleClose} disableRipple>
                <Box color="#037DFF">
                  <ListItemText>
                    <span style={{ fontSize: '13px', fontWeight: '700' }}>
                      Deposit Funds
                    </span>
                  </ListItemText>
                </Box>
              </MenuItem> */}

              <Divider sx={{ my: 0.5 }} />
              <Box color="#75849D">
                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/user-information"
                  disableRipple>
                  <List>
                    <ListItem sx={{ '&:hover': { color: '#037DFF' } }}>
                      <ListItemIcon>
                        <PermIdentityOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography fontSize="13px" fontWeight="700">
                          View Profile
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </MenuItem>

                {(userDetails?.role?.name === 'manager' ||
                  userDetails?.role?.name === 'admin' ||
                  userDetails?.role?.name === 'super') && (
                  <MenuItem
                    onClick={handleClose}
                    component={Link}
                    to="/change-key"
                    disableRipple>
                    <List>
                      <ListItem sx={{ '&:hover': { color: '#037DFF' } }}>
                        <ListItemIcon>
                          <VpnKeyIcon />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography fontSize="13px" fontWeight="700">
                            Change Key
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={handleClose}
                  component={Link}
                  to="/change-password"
                  disableRipple>
                  <List>
                    <ListItem sx={{ '&:hover': { color: '#037DFF' } }}>
                      <ListItemIcon>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography fontSize="13px" fontWeight="700">
                          Change Password
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </MenuItem>

                {userDetails?.role?.name === 'super' && (
                  <>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/role-management"
                      disableRipple>
                      <List>
                        <ListItem sx={{ '&:hover': { color: '#037DFF' } }}>
                          <ListItemIcon>
                            <SettingsOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography fontSize="13px" fontWeight="700">
                              Role Managment
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </MenuItem>

                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/display-screen-management"
                      disableRipple>
                      <List>
                        <ListItem sx={{ '&:hover': { color: '#037DFF' } }}>
                          <ListItemIcon>
                            <TvIcon />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography fontSize="13px" fontWeight="700">
                              Display Screen Management
                            </Typography>
                          </ListItemText>
                        </ListItem>
                      </List>
                    </MenuItem>
                  </>
                )}
              </Box>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                onClick={() => {
                  clearStorage();
                  handleClose();
                }}
                disableRipple>
                <List>
                  <ListItem
                    sx={{
                      '&:hover': { color: '#037DFF' },
                      color: '#75849D'
                    }}>
                    <ListItemIcon>
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography fontSize="13px" fontWeight="700">
                        Log Out
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </MenuItem>
            </StyledMenu>
          </div>
        </Box>
      </Box>
    </>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import { permisssions } from '../../Shared/shared';
import { getOutletsApi } from '../../../api/outlet/outletApi';
import { transferCallCenterOrderApi } from '../../../api/callCenter/callCenterApi';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

export default function TransferOrder({
  transferPopup,
  setTransferPopup,
  callFunc
}) {
  const dispatch = useDispatch();
  // getinng all outlets
  const [outlets, setOutlets] = useState([]);
  const getOutlets = async () => {
    const response = await getOutletsApi();
    setOutlets(response.data);
  };
  useEffect(() => {
    getOutlets();
  }, []);

  // handling form with useForm hook
  const { register, handleSubmit, setValue, watch, reset } = useForm();

  const handleChangeOutlet = (event) => {
    setValue('outlet', event.target.value);
  };

  const onSubmit = async (data) => {
    // transfer order from one outlet to other
    const res = await transferCallCenterOrderApi(transferPopup.orderId, data);
    if (res.status == 200) {
      handleClose();
      callFunc();
      dispatch(addSnackbar({ message: `Order Transfered`, type: 'warning' }));
    }
  };

  const handleClose = () => {
    reset();
    setTransferPopup('isOpen', false);
  };
  return (
    <div>
      <Dialog
        className="dialog"
        open={transferPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Transfer Order</DialogTitle>

        <Box
          sx={{
            width: '500px',
            height: '300px'
          }}>
          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <FormControl sx={{ width: '100%' }} size="small">
                  <InputLabel>Outlet</InputLabel>
                  <Select
                    defaultValue=""
                    {...register('outlet')}
                    label="Outlet"
                    onChange={handleChangeOutlet}>
                    {outlets?.map((outlet, index) => (
                      <MenuItem key={index} value={outlet._id}>
                        {outlet.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  sx={{ width: '50%' }}
                  label="Forced"
                  control={<Checkbox {...register('forced')} />}
                />
              </Box>

              <Button type="submit" variant="contained">
                Transfer Order
              </Button>
            </form>
          </Box>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionGrid,
  HeaderCell,
  ServicesGrid,
  StyledAccordion,
  StyledTableRow,
  StyledTypography,
  TopBox
} from './Styled-components';
import { getWalkSheetShiftWiseApi } from '../../api/walkSheet/walkSheetApi';
import { kitchen, parking, washroom } from './shared';
import { timestamp } from '../Shared/shared';

export default function WalkSheetHistory() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*'];
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1];
  const saleDate = obj.date;
  const shift = obj.shift;

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [isLoading, setLoading] = useState(false);

  const [section, setSection] = useState();

  const getSections = async () => {
    isLoading == true;
    const res = await getWalkSheetShiftWiseApi(outlet._id, saleDate, shift);
    if (res.status == 200) {
      setLoading(false);
    }
    setSection(res?.data);
  };

  useEffect(() => {
    getSections();
  }, []);

  return (
    <Container>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TopBox my={2}>
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: 700
              }}>
              Walk Sheet History
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 600
              }}>
              {/* {section?.saleDate} */}
              {timestamp(section?.createdAt)}
            </Typography>

            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 600
              }}>
              {section?.shift.toUpperCase()}
            </Typography>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 600
              }}>
              {section?.user?.name}
            </Typography>
          </TopBox>

          <ServicesGrid container spacing={3}>
            <AccordionGrid item xs={12} lg={12} pr="24px">
              {section?.sections?.map((item, index) => (
                <StyledAccordion checkid={index} key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        width={50}
                        height={50}
                        src={
                          item.title.toLowerCase() == 'wash room'
                            ? washroom
                            : item.title.toLowerCase() == 'dine in'
                            ? kitchen
                            : item.title.toLowerCase() == 'parking lot'
                            ? parking
                            : 'https://www.iconpacks.net/icons/2/free-click-icon-2384-thumb.png'
                        }
                      />
                      <StyledTypography>{item.title}</StyledTypography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table border={1}>
                        <TableHead>
                          <TableRow>
                            <HeaderCell align="left">Sr.</HeaderCell>
                            <HeaderCell align="left">Question</HeaderCell>
                            <HeaderCell align="left">Answer</HeaderCell>
                            <HeaderCell align="left">Description</HeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {item?.questions?.map((row, index) => (
                            <StyledTableRow key={index} checkid={index}>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{row.detail}</TableCell>
                              <TableCell align="left">
                                {typeof row.yes === 'undefined'
                                  ? ''
                                  : row.yes
                                  ? 'YES'
                                  : 'NO'}
                              </TableCell>
                              <TableCell align="left">{row.reason}</TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </AccordionGrid>
          </ServicesGrid>
        </>
      )}
    </Container>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider
} from '@mui/material';
import { SingleOrderTypography } from './Styled-components';
import { getSingleOrderApi } from '../../../api/orders/OrdersApi';
import { extractTime } from '../shared';
import { theme } from '../../../theme';

export default function OrderDetails({ details, setDetails }) {
  // get single Order Api calling
  const [singleOrder, setSingleOrder] = useState();

  const getSingleOrder = async () => {
    const res = await getSingleOrderApi(details.id);
    setSingleOrder(res?.data);
  };

  const handleClose = () => {
    setDetails({ isOpen: false });
  };

  useEffect(() => {
    if (details.isOpen) {
      getSingleOrder();
    }
  }, [details.isOpen]);

  const totalPayable = Math.round(
    singleOrder?.total +
      singleOrder?.tax +
      singleOrder?.totalCharges +
      (singleOrder?.chargesTax || 0)
  );

  return (
    <div>
      <Dialog open={details.isOpen} onClose={handleClose}>
        <Box
          sx={{
            textAlign: 'center',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#6d1b7b" fontWeight="700">
              Order Details
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: '10px',
                gap: '10px'
              }}>
              <Box width="50%" borderRight="1px solid gray">
                <SingleOrderTypography>
                  <b>Invoice: </b>
                  {singleOrder?.invoiceId}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Order Type: </b>
                  {singleOrder?.orderType}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Order Time: </b>
                  {extractTime(singleOrder?.orderTime)}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Accepted Time: </b>
                  {extractTime(singleOrder?.accepted)}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Accepted By: </b>
                  {singleOrder?.acceptedBy?.name}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Order From: </b>
                  {singleOrder?.callCenter ? 'Call Center' : 'Branch End'}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Branch: </b>
                  {singleOrder?.outlet.name}
                </SingleOrderTypography>
              </Box>
              <Box width="50%" borderRight="1px solid gray">
                {singleOrder?.assembled && (
                  <SingleOrderTypography>
                    <b>Assemble Time: </b>
                    {extractTime(singleOrder?.assembled)}
                  </SingleOrderTypography>
                )}

                {singleOrder?.assembledBy && (
                  <SingleOrderTypography>
                    <b>Assemble By: </b>
                    {singleOrder?.assembledBy?.name}
                  </SingleOrderTypography>
                )}
                {singleOrder?.carrier && (
                  <SingleOrderTypography>
                    <b>Delivered By: </b>
                    {singleOrder?.carrier?.name}
                  </SingleOrderTypography>
                )}
                {singleOrder?.cashedOut && (
                  <>
                    <SingleOrderTypography>
                      <b>CashOut Time: </b>
                      {extractTime(singleOrder?.cashedOut)}
                    </SingleOrderTypography>
                    <SingleOrderTypography>
                      <b>Cashed Out By: </b>
                      {singleOrder?.cashier?.name}
                    </SingleOrderTypography>
                  </>
                )}
              </Box>
            </Box>

            <Divider />
            <Typography color="#6d1b7b" fontWeight="700">
              Customer Info
            </Typography>
            <Box textAlign="left">
              {singleOrder?.customerName &&
              singleOrder?.customerContact &&
              singleOrder?.deliveryAddress ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '10px',
                    gap: '10px'
                  }}>
                  <Box width="50%" borderRight="1px solid gray">
                    <SingleOrderTypography>
                      <b>Customer: </b>
                      {singleOrder?.customerName || 'walk-in-customer'}
                    </SingleOrderTypography>
                    <SingleOrderTypography>
                      <b>Contact: </b>
                      {singleOrder?.customerContact}
                    </SingleOrderTypography>
                  </Box>
                  <Box width="50%">
                    <SingleOrderTypography>
                      <b>Delivery Address: </b>
                      {singleOrder?.deliveryAddress}
                    </SingleOrderTypography>
                    {singleOrder?.deliveryNote ? (
                      <SingleOrderTypography>
                        <b>Delivery Note: </b>
                        {singleOrder?.deliveryNote}
                      </SingleOrderTypography>
                    ) : null}
                  </Box>
                </Box>
              ) : (
                <b>Walk-In-Customer</b>
              )}

              <SingleOrderTypography>
                <b>
                  {singleOrder?.orderType.toLowerCase() === 'dinein'
                    ? 'Waiter: '
                    : 'Rider: '}
                </b>
                {singleOrder?.carrier?.name}
              </SingleOrderTypography>

              {singleOrder?.source && (
                <SingleOrderTypography>
                  <b>Order Source: </b>
                  {singleOrder?.source?.name}
                </SingleOrderTypography>
              )}

              <TableContainer
                component={Paper}
                sx={{ minHeight: '220px', height: '200px', overflowY: 'auto' }}>
                <Table size="small">
                  <TableHead sx={{ background: theme.palette.primary.main }}>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {singleOrder?.items
                      // ?.filter((activeItem) => activeItem.active === true)
                      ?.map((item, index) => {
                        const modified =
                          item.createdAt.substring(0, 18) !==
                          singleOrder.createdAt.substring(0, 18);
                        const deleted = item.active ? false : true;
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell rowSpan={2}>
                                {/* <p style={{ margin: 0, fontWeight: 700 }}>
                                  {item.name}
                                </p> */}

                                {deleted ? (
                                  <>
                                    <del
                                      style={{
                                        fontSize: '16px',
                                        color: '#E3242B'
                                      }}>
                                      {item?.name}
                                    </del>
                                  </>
                                ) : modified ? (
                                  <>
                                    <b
                                      style={{
                                        color: 'green',
                                        fontSize: '16px'
                                      }}>
                                      {item?.name}
                                    </b>
                                  </>
                                ) : (
                                  <span>
                                    <p
                                      style={{
                                        fontSize: '16px'
                                      }}>
                                      {item?.name}
                                    </p>
                                  </span>
                                )}
                                <div>
                                  <strong>Choices: </strong>
                                  <p
                                    style={{
                                      fontSize: '12px',
                                      margin: 0,
                                      display: 'inline'
                                    }}>
                                    (
                                    {item?.choices
                                      ?.reduce(
                                        (prev, current) =>
                                          (prev += current.optionName
                                            ? current.optionName +
                                              ` ${
                                                current.price
                                                  ? ` Rs. ${current.price}`
                                                  : ''
                                              }` +
                                              ','
                                            : ''),
                                        ''
                                      )
                                      .slice(0, -2)}
                                    )
                                  </p>
                                  {!item.note == '' && <b>Note: {item.note}</b>}
                                </div>
                              </TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>{Math.round(item.discount)}</TableCell>
                              <TableCell>
                                {Math.round(item.discountedPrice + item.tax)}
                              </TableCell>
                            </TableRow>
                            <TableRow>{/* <TableCell></TableCell> */}</TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {!singleOrder?.deliveryNote == '' && (
                <b>Delivery Note:{singleOrder?.deliveryNote} </b>
              )}

              <Box>
                <SingleOrderTypography>
                  <b>Sub-Total: </b>{' '}
                  {Math.round(singleOrder?.subTotal + singleOrder?.subTax)}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Discount: </b> {Math.round(singleOrder?.discount)}
                </SingleOrderTypography>
                {singleOrder?.discountRate && (
                  <SingleOrderTypography>
                    <b>Discount Rate: </b>
                    {Math.round(singleOrder?.discountRate * 100)}%
                  </SingleOrderTypography>
                )}
                {singleOrder?.discountReason && (
                  <SingleOrderTypography>
                    <b>Discount Reason: </b> {singleOrder?.discountReason}
                  </SingleOrderTypography>
                )}
                <SingleOrderTypography>
                  <b>Charges: </b> {singleOrder?.totalCharges}
                </SingleOrderTypography>
              </Box>
              <Box>
                <SingleOrderTypography>
                  <b>Total Amount: </b>
                  {totalPayable}
                </SingleOrderTypography>
                {!singleOrder?.tax == 0 && (
                  <SingleOrderTypography>
                    <b>Tax: </b>
                    {Math.round(singleOrder?.tax)}
                  </SingleOrderTypography>
                )}
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

import { styled } from '@mui/system';
import { Box } from '@mui/material';

// export const ImageBox = styled(Box)(() => ({
//   objectFit: 'cover',
//   height: '100%',
//   width: '90%',
//   borderRadius: '10px'
// }));
export const ImageBox = styled(Box)(() => ({
  objectFit: 'cover',
  height: '100%',
  width: '95%',
  borderRadius: '10px'
}));

export const PlaceBox = styled(Box)`
  background-color: #614b64;
  color: #fff;
  width: fit-content;
  border-radius: 10px;
  padding: 0 5px;
  text-align: center;
  margin: 0 3px;
  padding: 0 5px;
`;

export const FieldBox = styled(Box)`
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 7px;
  padding: 10px 5px;
  gap: 1rem;
  margin: 0.5rem 0;
`;
export const ItemBox = styled(Box)`

display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  width: 95%;
  height: 250px;
  text-align: center;
  justify-content: space-evenly,
  padding: 5px 5px 30px 5px;
  box-shadow: 0px 0px 2px 1px #cbd5e0;
  border-radius: 10px;
  border: 1px solid #cbd5e0;
  &:hover {
    box-shadow: 0px 0px 10px 4px #cbd5e0;
  }
`;

export const MenuCard = styled('Box')({
  maxWidth: 345,
  margin: (theme) => theme.spacing(2)
});

//   const Media = styled('Box')({
//     height: 0,
//     paddingTop: '56.25%' // 16:9
//   });

import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from '../../api/refreshTokenApi';
import { getApi, HeaderTypography } from '../Shared/shared';
import { CircularProgress } from '@mui/material';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { HeaderTableCell } from '../Reports/Styled-components';
import DatePickerComponent from '../Shared/DatePickerComponent';
import FeedbackDialog from './feedbackDialog/FeedbackDialog';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function OutLetFeedbackData() {
  const [feedbackData, setFeedbackData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [details, setDetails] = useState({ isOpen: false, id: '', data: null });

  const { code } = useParams();
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // get all feedback data
  const getFeedbackDate = async (query) => {
    setIsLoading(true);
    const response = await fetchWithAuth(
      getApi() + '/feedbacks/outlet/' + outlet?._id,
      { query }
    );
    setIsLoading(false);
    if (Array.isArray(response) && response.length > 0) {
      setFeedbackData(response);
    }
  };

  // filter outlet data based on date range
  const filteredArray = feedbackData?.filter((row) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          row?.customerName?.toLowerCase().includes(term) ||
          row?.customerContact?.toLowerCase().includes(term) ||
          row?.invoiceId?.toLowerCase().includes(term) ||
          row?.visitDate?.toLowerCase().includes(term)
        );
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  useEffect(() => {
    if (datePopup.isSelected == true) {
      getFeedbackDate(datePopup);
    }
  }, [datePopup, outlet]);

  const calculateAverageRating = (ratings) => {
    const {
      foodQuality,
      foodTaste,
      serviceQuality,
      serviceSpeed,
      valueOfHotness,
      valueOfMoney,
      valueOfPastExperience
    } = ratings;

    const totalRatings = [
      foodQuality,
      foodTaste,
      serviceQuality,
      serviceSpeed,
      valueOfHotness,
      valueOfMoney,
      valueOfPastExperience
    ];

    const sum = totalRatings.reduce((acc, curr) => acc + curr, 0);
    return sum / totalRatings.length;
  };

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <HeaderTypography
          sx={{
            fontWeight: 600,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
          }}>
          {outlet?.tag} Feedbacks
        </HeaderTypography>
        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>

      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#008670',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>InvoiceId</HeaderTableCell>
                <HeaderTableCell>Date of Visit</HeaderTableCell>
                <HeaderTableCell>Cust. Name</HeaderTableCell>
                <HeaderTableCell>Contact</HeaderTableCell>
                <HeaderTableCell>Average</HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {filteredArray
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    const averageRating = calculateAverageRating(row);
                    return (
                      <TableRow key={index}>
                        <TableCell>{row?.invoiceId}</TableCell>
                        <TableCell>
                          {new Date(row?.visitDate).toISOString().split('T')[0]}
                        </TableCell>
                        <TableCell>{row?.customerName || 'N/A'}</TableCell>
                        <TableCell>{row?.customerContact || 'N/A'}</TableCell>
                        <TableCell>
                          {Math.round(averageRating).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({
                                isOpen: true,
                                id: row?._id,
                                data: row
                              });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredArray?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <FeedbackDialog details={details} setDetails={setDetails} />
    </>
  );
}


import { fetchWithAuth } from '../refreshTokenApi';
import supplierUrls from './supplierUrls';

// get All Suppliers API
export const getsuppliersApi = async () => {
  const result = await fetchWithAuth(supplierUrls.suppliers);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// Add Suppliers API

export const addSupplierApi = async (supplierData) => {
  const result = await fetchWithAuth(supplierUrls.suppliers, {
    body: { supplierData },
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// delete Supplier API
export const deleteSupplierApi = async (supplierId) => {
  const result = await fetchWithAuth(supplierUrls.suppliers, {
    body: { supplierId },
    method: 'DELETE'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

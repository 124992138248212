/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/IngredietSetDuck';
import { getIngredientSetApi } from '../../../api/ingredientSet/ingredientSetApi';

export function* ingredientSet({ payload }) {
  try {
    const ingredientSet = yield call(getIngredientSetApi);
    yield put(actions.updateIngredientSetSuccessStatus(true));
    yield put(
      actions.ingredientSetResponse({
        response: ingredientSet
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchIngredientSetSagas() {
  yield takeLatest(actions.ingredientSetRequest.type, ingredientSet);
}

import { Box, Button, FormControl, styled } from '@mui/material';

export const MaskBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  position: 'relative',
  //   background: theme.palette.primary.main,
  background: 'gray',
  boxShadow: '1px 1px 2px rgb(165, 165, 165) inset'
}));

export const Mask = styled(Box)(({ theme }) => ({
  width: '100px',
  height: '40px',
  borderRadius: '20px',
  background: theme.palette.primary.main,
  position: 'absolute',
  boxShadow: '1px 0 2px #b473ff',
  transition: 'all 0.5s ease'
}));

export const MaskButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  width: '100px',
  height: '40px',
  fontWeight: 'bold',
  transition: 'all 0.2s 0.1s ease'
}));


/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  getUsersApi,
  getUsersWithPermissionApi
} from '../../../api/users/usersApi';
import { permisssions } from '../../Shared/shared';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { complimentaryOrderApi } from '../../../api/orders/OrdersApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

export default function Discount({
  complimentryOrder,
  setComplimentryOrder,
  handleClose
  // setChildDiscountData
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const { code } = params;

  // getting outlet from Redux state on the basis of params
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );
  //
  const [careOfUsers, setCareOfUsers] = useState([]);

  const getUsers = async () => {
    const response = await getUsersWithPermissionApi('', permisssions.careOf);
    if (response.status == 200) {
      setCareOfUsers(response.data);
    } else {
      return;
    }
  };

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outlet?._id,
      permisssions.addDiscount
    );
    setUsers(response?.data);
  };
  useEffect(() => {
    getUsers();
    getUsersWithPermission();
  }, []);

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, control, reset } = useForm();

  const handleChangeUserName = (event) => {
    setValue('userName', event.target.value);
  };

  const onSubmit = async (data) => {
    const formData = {
      user: data.userName,
      key: data.key,
      reason: data.reason,
      careOf: data.careOf
    };

    const response = await complimentaryOrderApi(
      formData,
      complimentryOrder?.id
    );
    if (response.status == 200) {
      handleClose();
      handleCloseComplimentroy();
      dispatch(
        addSnackbar({
          message: `Order Cashed Out as Complimentry`,
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: response?.response?.error?.message,
          type: 'error'
        })
      );
    }
  };
  const handleCloseComplimentroy = () => {
    reset();
    setComplimentryOrder('isOpen', false);
  };
  return (
    <div>
      <Dialog
        className="dialog"
        maxWidth="auto"
        open={complimentryOrder?.isOpen ?? false}
        onClose={handleCloseComplimentroy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Complimentary Order</DialogTitle>

        <Box
          sx={{
            height: '300px'
          }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'center'
            }}></Box>

          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <FormControl sx={{ width: '50%' }} size="small">
                  <InputLabel>Name</InputLabel>
                  <Select
                    defaultValue=""
                    // {...register('user')}
                    label="Name"
                    onChange={handleChangeUserName}>
                    {users?.map((user, index) => (
                      <MenuItem key={index} value={user._id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  size="small"
                  label="Manager Key"
                  type="password"
                  {...register('key')}
                />
              </Box>

              <Box>
                <TextField
                  required
                  size="small"
                  fullWidth
                  label="Reason for Compliment"
                  type="text"
                  {...register('reason')}
                />

                <Controller
                  name="careOf"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      defaultValue=""
                      label="Care Of"
                      fullWidth
                      margin="normal"
                      select>
                      <MenuItem value="Branch End">Branch End</MenuItem>
                      {careOfUsers?.map((user) => (
                        <MenuItem value={user._id} key={user._id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Box>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </form>
          </Box>
        </Box>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleCloseComplimentroy}
            autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import crop from '../../../assets/shuf.png';
// import { FacebookRounded, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          right: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '15px'
        }}
        bgcolor="#101924">
        <NavLink to="/">
          <img
            style={{
              maxWidth: '100%',
              width: '40px',
              height: '40px'
            }}
            src={crop}
            alt="SHUFCO.org"
          />
        </NavLink>
        <Box
          sx={{
            textAlign: 'center',
            color: '#ffffff'
          }}>
          <Typography fontSize="12px">
            Powered By: <b style={{ fontSize: '16px' }}>SHUFCO.org</b>
          </Typography>
          <Typography fontSize="12px">
            &copy;
            {new Date().getFullYear()} all Rights Reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;

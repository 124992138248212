/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setCharges } from '../../../redux/reducers/ducks/CartDuck';

export default function Charges({
  chargesPopup,
  setChargesPopup,
  setchildChargesData
}) {
  const dispatch = useDispatch();
  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, reset } = useForm();

  const handleChangeUserName = (event) => {
    setValue('type', event.target.value);
  };

  const onSubmit = (data) => {
    const charges = [];
    charges.push(data);

    dispatch(setCharges(charges));
    // setchildChargesData(charges);
    handleClose();
    // Handle form submission logic here
  };
  const handleClose = () => {
    reset();
    setChargesPopup('isOpen', false);
  };

  const chargesType = [
    { name: 'VAT', value: 'VAT' },
    { name: 'delivery', value: 'delivery' },
    { name: 'services', value: 'services' }
  ];
  return (
    <div>
      <Dialog
        className="dialog"
        maxWidth="auto"
        open={chargesPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Add Charges</DialogTitle>

        <Box
          sx={{
            width: '500px',
            height: '300px'
          }}>
          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <FormControl sx={{ width: '50%' }} size="small">
                  <InputLabel>Charges Type</InputLabel>
                  <Select
                    defaultValue=""
                    {...register('type')}
                    label="Charges Type"
                    onChange={handleChangeUserName}>
                    {chargesType?.map((charges, index) => (
                      <MenuItem key={index} value={charges.value}>
                        {charges.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  size="small"
                  label="Charges"
                  type="text"
                  {...register('amount')}
                />
              </Box>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </form>
          </Box>
        </Box>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const reportUrls = {
  registerReport: `${baseURL}/reports/register-sale`,
  closingReport: `${baseURL}/reports/closing-sale`,
  saleReport: `${baseURL}/reports/sales`,
  foodSaleReport: `${baseURL}/reports/food-sales`,
  menuSaleReport: `${baseURL}/reports/menu-sales`,
  hourlySaleReport: `${baseURL}/reports/hourly-sales`,
  consumptionsReport: `${baseURL}/reports/consumptions`,
  orderStatus: `${baseURL}/reports/orders-status`,
  inventoryReport: `${baseURL}/inventory-controllers`,
  categorySale: `${baseURL}/reports/category-sales`,
  dispatchSheet: `${baseURL}/reports/dispatch-sheet`,
  kitchenSheet: `${baseURL}/reports/kitchen-sheet`,
  riderSheet: `${baseURL}/reports/rider-sheet`,
  deliveryMonitor: `${baseURL}/reports/delivery-monitor`,
  outletSales: `${baseURL}/reports/outlet-sales`,
  deletedItemsReport: `${baseURL}/reports/deleted-items`,
  discountReport: `${baseURL}/reports/discounted`,
  cancelledReport: `${baseURL}/reports/cancelled`,
  praTax: `${baseURL}/reports/pra-tax`,
  overallSales: `${baseURL}/overall-sales`,
  daySheet: `${baseURL}/reports/day-sheet`
};

export default reportUrls;

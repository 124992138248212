/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  CartBox,
  CartCard,
  CartItemBox,
  CustomerInfoBox,
  DineInBox,
  PosButton,
  ExpandMore,
  ButtonsBox,
  IncrDecrBox,
  IncrDecrButton,
  DineInButton,
  TakeAwayButton,
  OrdersActionButton,
  CatEndButton,
  DiscountParentBox,
  DiscountBox,
  DiscountGrandBox,
  SourceBox,
  DiscountChargeBox
} from './Styled-components';

import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import { Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlatwareIcon from '@mui/icons-material/Flatware';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeItem,
  setCustomerContact,
  setCustomerName,
  setDeliveryAddress,
  setItemChoice,
  setItemNote,
  setOrderType,
  setTableName,
  resetCart,
  setDeliveryNote,
  setOrderTime,
  setPreOrder,
  setSource,
  setDiscount
} from '../../redux/reducers/ducks/CartDuck';
import { addOrdersApi } from '../../api/orders/OrdersApi';
import Receipt from './components/Receipt';
import { getPrice } from './Shared';
import Discount from './components/Discount';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import CustomerSearch from './components/CustomerSearch';
import Charges from './components/Charges';
import { CartEndButton, GeneralTypography } from '../POS/Styled-components';
import { currentDate } from '../POS/Shared';
import BusinessIcon from '@mui/icons-material/Business';
import {
  getCallCenterSourcesApi,
  getOutletSourcesApi
} from '../../api/sources/sourcesApi';

export default function Cart({
  outlets,
  outlet,
  outletMenuItems,
  updateTriggerState,
  handleChangeOutlet
}) {
  const dispatch = useDispatch();

  console.log(outletMenuItems, 'outletMenuItems');

  // getting cart from store
  const cart = useSelector((state) => state.cart);
  const {
    items,
    customerName,
    customerContact,
    deliveryAddress,
    deliveryNote,
    orderType,
    tableId,
    source,
    charges,
    preOrder,
    orderTime
  } = cart;

  // handling popup for discount
  const [discountPopup, setDiscountPopup] = useState({
    isOpen: false
  });

  // charges pop up
  const [chargesPopup, setChargesPopup] = useState({
    isOpen: false
  });

  let discountAmount = 0;
  let discountPercentage = 0;
  // get total payable amount
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;

    items.forEach((item) => {
      const menuItem = outletMenuItems?.find(
        (menuItem) => menuItem.menuItem._id === item.menuItem
      );
      let itemPrice = getPrice(menuItem, item);
      totalQuantity += item.quantity;
      totalPrice += itemPrice * item.quantity;
    });

    if (cart && cart.fixedDiscount === true) {
      discountAmount = cart.discount;
      totalPrice -= discountAmount;
    } else if (cart && cart.fixedDiscount === false) {
      discountPercentage = (cart.discount / 100) * totalPrice;
      totalPrice -= discountPercentage;
    }

    return { totalPrice, totalQuantity };
  };
  const total = getTotal();
  // Managing Cart Item Expand
  const [expanded, setExpanded] = useState([]);
  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // handling pop Up for print
  const [print, setPrint] = useState({ isOpen: false, id: '' });
  const running = true;

  // selecting preOrder Time
  const handleTimeChange = (event) => {
    const date = new Date();
    const [hours, minutes] = event.target.value.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);

    if (new Date() > date) date.setDate(date.getDate() + 1);
    if (currentDate() !== currentDate(date))
      return dispatch(
        addSnackbar({
          message: 'At this time order can not be placed',
          type: 'error'
        })
      );
    dispatch(setOrderTime(date));
  };

  const [sources, setSources] = useState([]);

  const getUsersWithPermission = async () => {
    const res = await getCallCenterSourcesApi();
    setSources(res.data);
  };
  useEffect(() => {
    getUsersWithPermission();
  }, []);

  // setting up payload to place order

  const handleFormSubmit = async () => {
    const formData = {
      ...cart,
      deliveryAddress: orderType === 'delivery' ? deliveryAddress : '',
      tableId: orderType === 'dineIn' ? tableId : '',
      outlet: outlet,
      discountRef: cart.discountRef,
      discount: cart?.discount,
      fixedDiscount: cart?.fixedDiscount,
      discountReason: cart.discountReason,
      callCenter: true
      // promoCode: applyVoucher && 'defense10'
    };

    const errorMessage =
      cart.orderType === ''
        ? 'Select Order Type'
        : cart.items.length === 0
        ? 'Add Items to place order'
        : cart.customerName === ''
        ? 'Enter Customer Name'
        : cart.customerContact === ''
        ? 'Enter Customer Phone'
        : cart.source === undefined
        ? 'Select Order Source'
        : cart.outlet === ''
        ? 'Select Outlet'
        : '';

    try {
      if (errorMessage) {
        dispatch(
          addSnackbar({
            message: errorMessage,
            type: 'error'
          })
        );
      } else {
        setIsDisabled(true);
        const res = await addOrdersApi(formData);
        if (res.status === 200) {
          setPrint({ isOpen: true, id: res?.data?._id });
          updateTriggerState(true);
          dispatch(resetCart());
          setapplyVoucher(false);
        } else {
          dispatch(
            addSnackbar({
              message: `${res?.response?.data?.message}`,
              type: 'error'
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          message: 'An error occurred while processing the order.',
          type: 'error'
        })
      );
    } finally {
      setIsDisabled(false);
    }
  };

  // handling switch to show customer search or manual customer data
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  // getting charges
  const totalCharges = charges.reduce(
    (prev, current) => prev + Number(current.amount),
    0
  );

  const tax = 0;
  // const chargesTax = 0;
  const grandTotal = total.totalPrice + (tax || 0) + totalCharges;

  // disable button after order punch till API Recall
  const [isDisabled, setIsDisabled] = useState(false);

  function checkDealsInCart(cartItems, outletMenu) {
    for (const cartItem of cartItems) {
      const foundmenuItem = outletMenu.find(
        (item) => item.menuItem._id === cartItem.menuItem
      );

      if (
        foundmenuItem &&
        foundmenuItem?.menuItem?.category?.name?.toLowerCase() === 'deals'
      ) {
        return true;
      }
    }

    return null;
  }

  // handling switch to show customer search or manual customer data
  const [applyVoucher, setapplyVoucher] = useState(false);

  const handleToggleApplyVoucher = () => {
    let isDeal = checkDealsInCart(cart.items, outletMenuItems);
    console.log(isDeal, 'isDeal');
    if (isDeal) {
      dispatch(
        addSnackbar({
          message: 'Discount Can not be applied on Deals.',
          type: 'error'
        })
      );
    } else {
      setapplyVoucher(!applyVoucher);
    }
  };

  useEffect(() => {
    if (applyVoucher) {
      let data = {
        ...cart,
        discount: 10,
        fixedDiscount: false,
        reason: ''
      };

      dispatch(setDiscount(data));
    }
  }, [applyVoucher]);

  return (
    <CartBox>
      <DineInBox>
        <TakeAwayButton
          ordertype={orderType}
          onClick={() => dispatch(setOrderType('takeAway'))}
          variant="outlined"
          startIcon={<TakeoutDiningIcon />}>
          Take Away
        </TakeAwayButton>
        <PosButton
          ordertype={orderType}
          onClick={() => dispatch(setOrderType('delivery'))}
          variant="outlined"
          startIcon={<DeliveryDiningIcon />}>
          Delivery
        </PosButton>
        <FormControl sx={{ width: '40%' }} size="small">
          <InputLabel>Source</InputLabel>
          <Select
            // style={{ height: 30 }}
            defaultValue=""
            value={source || ''}
            inputProps={{
              style: { padding: 4 }
            }}
            sx={{
              borderRadius: '10px'
            }}
            label="Source"
            onChange={(event) => {
              dispatch(setSource(event.target.value));
            }}>
            {sources?.map((sourc, index) => (
              <MenuItem key={index} value={sourc._id}>
                {sourc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <FormControlLabel
          label={<GeneralTypography>Add Address Manually</GeneralTypography>}
          control={<Switch checked={checked} onChange={handleToggle} />}
        /> */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 10px',
            cursor: 'pointer',
            borderRadius: '10px',
            border: '1px solid gray'
          }}
          onClick={handleToggle}>
          <BusinessIcon
            style={{ color: checked ? 'blue' : 'gray', cursor: 'pointer' }}
          />
        </Box>
      </DineInBox>
      {/* sourceBox */}
      {/* <SourceBox>
        <FormControl sx={{ width: '50%' }} size="small">
          <InputLabel>Source</InputLabel>
          <Select
            style={{ height: 30 }}
            defaultValue=""
            value={source || ''}
            inputProps={{
              style: { padding: 4 }
            }}
            sx={{
              borderRadius: '10px'
            }}
            label="Source"
            onChange={(event) => {
              dispatch(setSource(event.target.value));
            }}>
            {sources?.map((sourc, index) => (
              <MenuItem key={index} value={sourc._id}>
                {sourc.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControlLabel
          label={<GeneralTypography>Add Address Manually</GeneralTypography>}
          control={<Switch checked={checked} onChange={handleToggle} />}
        />
      </SourceBox> */}
      {checked ? (
        <>
          <CustomerInfoBox>
            <TextField
              inputProps={{
                style: { padding: 4 }
              }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              label="Customer Name"
              variant="outlined"
              value={customerName} // use value instead of defaultValue
              onChange={(e) => dispatch(setCustomerName(e.target.value))}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
            />

            <TextField
              inputProps={{
                style: { padding: 4 }
              }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              label="Customer Contact"
              value={customerContact}
              onChange={(e) => dispatch(setCustomerContact(e.target.value))}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
            />
            {orderType === 'delivery' ? (
              <TextField
                inputProps={{
                  style: { padding: 4 }
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                label="Delivery Address"
                value={deliveryAddress}
                onChange={(e) => dispatch(setDeliveryAddress(e.target.value))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
              />
            ) : (
              ''
            )}
            {orderType === 'dineIn' ? (
              <TextField
                inputProps={{
                  style: {
                    padding: 4
                  }
                }}
                InputLabelProps={{ style: { fontSize: 12 } }}
                label="Table Id"
                value={tableName}
                onChange={(e) => dispatch(setTableName(e.target.value))}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
              />
            ) : (
              ''
            )}
          </CustomerInfoBox>
        </>
      ) : (
        <CustomerSearch
          handleChangeOutlet={handleChangeOutlet}
          outlets={outlets}
        />
      )}

      <CartItemBox>
        {items?.map((cartItem, index) => {
          const outletItem = outletMenuItems?.find(
            (item) => cartItem.menuItem === item.menuItem._id
          );
          // if (!outletItem) return '';
          const menuItem = outletItem.menuItem;
          let choices = menuItem.choices;

          return (
            <CartCard key={index}>
              <CardActions sx={{ padding: '0px', height: '35px' }}>
                <ExpandMore
                  onClick={() => handleExpandClick(index)}
                  aria-expanded={expanded[index]}
                  aria-label="show more">
                  <ExpandMoreIcon />
                </ExpandMore>
                <IncrDecrBox>
                  <Box sx={{ width: '190px', textAlign: 'start' }}>
                    <p>
                      {menuItem?.shortName?.length > 20
                        ? `${menuItem.name.slice(0, 20)}...`
                        : menuItem?.name}
                    </p>
                  </Box>
                  {/* <p>{menuItem?.shortName}</p> */}
                  <p>
                    Rs.
                    <b>
                      {Math.round(
                        cartItem?.quantity * getPrice(outletItem, cartItem)
                      )}
                    </b>
                  </p>
                  <ButtonsBox>
                    <IncrDecrButton
                      onClick={() => dispatch(decrementQuantity(index))}>
                      -
                    </IncrDecrButton>
                    <p> {cartItem?.quantity} </p>
                    <IncrDecrButton
                      onClick={() => dispatch(incrementQuantity(index))}>
                      +
                    </IncrDecrButton>
                  </ButtonsBox>
                  <IconButton
                    sx={{ color: 'red' }}
                    onClick={() => dispatch(removeItem(index))}>
                    <Delete />
                  </IconButton>
                </IncrDecrBox>
              </CardActions>
              <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                <CardContent>
                  {choices?.map((choice, choiceIndex) => {
                    const optionId = cartItem.choices.find(
                      (choiceObj) => choice._id === choiceObj.choice
                    ).option;
                    const option = choice?.choice?.options?.find(
                      (option) => option.item === optionId
                    );
                    return choice.choice.optional &&
                      choice.choice.options.length === 1 ? (
                      <FormControlLabel
                        key={choiceIndex}
                        label={`${choice.name}(${choice?.choice.options[0].price}) `}
                        control={
                          <Checkbox
                            checked={
                              cartItem.choices.find(
                                (choiceObj) => choice._id === choiceObj.choice
                              ).option
                                ? true
                                : false
                            }
                            onChange={(event) => {
                              dispatch(
                                setItemChoice({
                                  index,
                                  choice: choice._id,
                                  option: event.target.checked
                                    ? choice.choice.options[0].item
                                    : null
                                })
                              );
                            }}
                          />
                        }
                      />
                    ) : (
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px'
                          }
                        }}
                        size="small"
                        key={choiceIndex}
                        defaultValue=""
                        value={option?.item || ''}
                        label={choice?.name}
                        fullWidth
                        margin="normal"
                        select
                        onChange={(event) =>
                          dispatch(
                            setItemChoice({
                              index,
                              choice: choice._id,
                              option: event.target.value
                            })
                          )
                        }>
                        {choice.choice.optional ? (
                          <MenuItem value="">None</MenuItem>
                        ) : (
                          ''
                        )}
                        {choice.choice.options?.map((option, index) => (
                          <MenuItem value={option.item} key={index}>
                            <b style={{ margin: '0 7px 0 0' }}>{option.name}</b>
                            Rs:<b> {option.price}</b>
                          </MenuItem>
                        ))}
                      </TextField>
                    );
                  })}

                  <TextField
                    fullWidth
                    type="text"
                    label="Additional Info"
                    variant="outlined"
                    defaultValue={cartItem.note}
                    onBlur={(e) =>
                      dispatch(setItemNote({ index, note: e.target.value }))
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px'
                      }
                    }}
                  />
                </CardContent>
              </Collapse>
            </CartCard>
          );
        })}
      </CartItemBox>

      <Box sx={{ borderTop: '1px solid gray' }}>
        <Box padding="10px 5px">
          <TextField
            size="small"
            fullWidth
            label="Delivery Note"
            variant="outlined"
            value={deliveryNote} // use value instead of defaultValue
            onChange={(e) => dispatch(setDeliveryNote(e.target.value))}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
              }
            }}
          />
        </Box>
        <DiscountParentBox>
          <DiscountChargeBox>
            <DiscountBox>
              <CatEndButton
                variant="contained"
                onClick={() => {
                  setDiscountPopup({
                    isOpen: true
                  });
                }}>
                Discount
              </CatEndButton>
              <Box>
                {cart.fixedDiscount ? (
                  <strong>{cart?.discount} </strong>
                ) : (
                  <strong>{cart?.discount}%</strong>
                )}
              </Box>
            </DiscountBox>

            <DiscountGrandBox>
              <GeneralTypography>
                Discount:
                {cart.fixedDiscount ? (
                  <strong> Rs {discountAmount}</strong>
                ) : (
                  <strong> Rs {discountPercentage}</strong>
                )}
              </GeneralTypography>
            </DiscountGrandBox>

            {/* <Tooltip title="Apply Defense10">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={applyVoucher}
                    onChange={handleToggleApplyVoucher}
                  />
                }
                label=""
              />
            </Tooltip> */}
          </DiscountChargeBox>
          {/* charges */}
          <DiscountChargeBox>
            <CatEndButton
              variant="contained"
              onClick={() => {
                setChargesPopup({ isOpen: true });
              }}>
              Charges
            </CatEndButton>
            <DiscountGrandBox>
              <GeneralTypography>Charges: {totalCharges}</GeneralTypography>
            </DiscountGrandBox>
          </DiscountChargeBox>
        </DiscountParentBox>

        <Box
          sx={{
            padding: '3px 0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px'
            }}>
            {/* <p style={{ fontSize: '16px' }}>
              (<b>{total.totalQuantity}</b> items)
              <strong>Rs {Math.round(grandTotal)}</strong>
            </p> */}
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
              ({total.totalQuantity}) items
            </Typography>
            <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Rs:{Math.round(grandTotal)}
            </Typography>
          </Box>
          <Box>
            <FormControlLabel
              label={<Typography fontSize="12px">PreOrder</Typography>}
              control={
                <Checkbox
                  size="extra-small"
                  checked={preOrder}
                  // onChange={handlePreOrder}
                  onChange={(e) => {
                    dispatch(setPreOrder(e.target.checked));
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
            {preOrder ? (
              <>
                <input
                  type="time"
                  // value={selectedTime}
                  onChange={handleTimeChange}
                />
              </>
            ) : null}
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: '10px'
            }}>
            <CartEndButton
              sx={{ backgroundColor: '#19a619', borderRadius: '10px' }}
              disabled={isDisabled}
              variant="contained"
              onClick={() => {
                handleFormSubmit();
              }}>
              {isDisabled ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                `Place Order`
              )}
            </CartEndButton>

            <CartEndButton
              sx={{
                backgroundColor: '#e12e2e',
                borderRadius: '10px'
              }}
              variant="contained"
              onClick={() => {
                setapplyVoucher(false);
                dispatch(resetCart());
              }}>
              Clear
            </CartEndButton>
          </Box>
        </Box>
      </Box>
      <Discount
        discountPopup={discountPopup}
        setDiscountPopup={setDiscountPopup}
        outletId={outlet}
        applyVoucher={applyVoucher}
      />
      <Charges chargesPopup={chargesPopup} setChargesPopup={setChargesPopup} />
    </CartBox>
  );
}

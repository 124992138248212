import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TableComponent from './TableComponent';
import { getDaySheetApi } from '../../../api/report/reportApi';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { getCurrentDate } from '../../Shared/shared';
import SearchComponent from './SearchComponent';
import useSearch from './useSearch';

export default function DaySheet() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*'];
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1];

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  const [sheetData, setSheetData] = useState(null);

  const getData = async () => {
    setLoading(true);
    const resp = await getDaySheetApi(outlet?._id, startDate);
    setSheetData(resp?.data);
    setLoading(false);
  };
  const typeSaleColumns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Sale', accessor: 'sale' },
    { Header: 'Discount', accessor: 'discount' },
    { Header: 'Pre Tax', accessor: 'praTax' }
  ];

  const methodSaleColumns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Sale', accessor: 'sale' }
  ];

  const expenseColumns = [
    { Header: 'Sale Date', accessor: 'saleDate' },
    { Header: 'Description', accessor: 'desc' },
    { Header: 'Total', accessor: 'total' }
  ];

  const discountedColumns = [
    { Header: 'Order Time', accessor: 'orderTime' },
    { Header: 'Discount', accessor: 'discount' },
    { Header: 'Discount (%)', accessor: 'discountRate' },
    { Header: 'Grand Total', accessor: 'grandTotal' },
    { Header: 'Discount Reason', accessor: 'discountReason' }
  ];

  // search modules
  const {
    searchQuery: typeSaleSearchQuery,
    handleSearchChange: handleTypeSaleSearchChange,
    filteredData: filteredTypeSale
  } = useSearch(sheetData?.typeSale, ['name', 'sale', 'discount', 'praTax']);

  const {
    searchQuery: methodSaleSearchQuery,
    handleSearchChange: handleMethodSaleSearchChange,
    filteredData: filteredMethodSale
  } = useSearch(sheetData?.methodSale, ['name', 'sale']);

  const {
    searchQuery: expenseSearchQuery,
    handleSearchChange: handleExpenseSearchChange,
    filteredData: filteredExpenses
  } = useSearch(sheetData?.expenses, ['saleDate', 'desc', 'total']);

  const {
    searchQuery: discountedSearchQuery,
    handleSearchChange: handleDiscountedSearchChange,
    filteredData: filteredDiscounted
  } = useSearch(sheetData?.discounted, [
    'orderTime',
    'discount',
    'discountRate',
    'grandTotal',
    'discountReason'
  ]);

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Day Sheet
        </Typography>
        <div>
          <label htmlFor="date">Select Date to see Report</label>
          <input
            className="date-input"
            type="date"
            onChange={handleDate}
            name=""
            id="date"
            value={startDate || ''}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              getData();
            }}>
            Get Report
          </Button>
        </div>
      </Box>
      <div
        style={{
          display: loading ? 'flex' : '',
          alignItems: loading ? 'center' : '',
          justifyContent: loading ? 'center' : '',
          flexDirection: loading ? 'column' : ''
        }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Box my={2} style={boxStyle}>
              <Typography style={typoStyle}> Type Sale</Typography>
              <SearchComponent
                searchQuery={typeSaleSearchQuery}
                handleSearchChange={handleTypeSaleSearchChange}
              />
            </Box>
            <TableComponent columns={typeSaleColumns} data={filteredTypeSale} />
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Box my={2} style={boxStyle}>
              <Typography style={typoStyle}>Method Sale</Typography>
              <SearchComponent
                searchQuery={methodSaleSearchQuery}
                handleSearchChange={handleMethodSaleSearchChange}
              />
            </Box>
            <TableComponent
              columns={methodSaleColumns}
              data={filteredMethodSale}
            />
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Box my={2} style={boxStyle}>
              <Typography style={typoStyle}>Expenses</Typography>
              <SearchComponent
                searchQuery={expenseSearchQuery}
                handleSearchChange={handleExpenseSearchChange}
              />
            </Box>

            <TableComponent columns={expenseColumns} data={filteredExpenses} />
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <>
            <Box my={2} style={boxStyle}>
              <Typography style={typoStyle}>Discounts</Typography>
              <SearchComponent
                searchQuery={discountedSearchQuery}
                handleSearchChange={handleDiscountedSearchChange}
              />
            </Box>
            <TableComponent
              columns={discountedColumns}
              data={filteredDiscounted}
            />
          </>
        )}

        {/* Add more tables for expenses and discounted as needed */}
      </div>
    </>
  );
}

const boxStyle = {
  display: 'flex',
  gap: '10px',
  alignItems: 'baseline',
  justifyContent: 'start',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px'
};

const typoStyle = {
  fontSize: '25px',
  fontWeight: 700
};


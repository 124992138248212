import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import rolesUrls from './rolesUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get all Roles API
export const getRolesApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(rolesUrls.roles, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(rolesUrls.roles, newConfig);
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get all Accesses API
export const getAccessesApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(rolesUrls.access, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(rolesUrls.access, newConfig);
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Add New Role API
export const addRoleApi = async (roleData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(rolesUrls.roles, roleData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(rolesUrls.roles, roleData, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};


// Edit Role API
export const editRoleApi = async (roleId, roleData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${rolesUrls.roles}/${roleId}`,
      roleData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${rolesUrls.roles}/${roleId}`,
        roleData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// delete Role API

export const deleteRoleApi = async (roleId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(`${rolesUrls.roles}/${roleId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${rolesUrls.roles}/${roleId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// {{API}}/api/roles/

import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  menuCategoryResponse: null,
  isSuccess: false
};

const menuCategorySlice = createSlice({
  name: 'menuCategory',
  initialState: INITIAL_STATE,
  reducers: {
    menuCategoryResponse(state, { payload }) {
      return {
        ...state,
        menuCategoryResponse: payload.response
      };
    },
    menuCategoryRequest: (state) => state,
    updateMenuCategorySuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  menuCategoryRequest,
  menuCategoryResponse,
  updateMenuCategorySuccessStatus
} = menuCategorySlice.actions;

export default menuCategorySlice.reducer;

/* eslint-disable no-unused-vars */
import { Box, Button, Chip, Grid, Typography, styled } from '@mui/material';
import { theme } from '../../../theme';

export const ItemBox = styled(Box)(({ itemBoxStates, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: itemBoxStates === true ? '#42ba96' : '',
  color: itemBoxStates ? '#fff' : '',
  transition: 'all 0.4s ease-in-out',
  minHeight: '200px',
  textAlign: 'left',
  justifyContent: 'space-between',
  boxShadow: theme.palette.customShadows.light,
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {}
}));

// ****************************************** Kitchen Display New Card*************************

export const FullGrid = styled(Grid)(({ states }) => ({
  position: states ? 'absolute' : '',
  opacity: states ? 0 : '',
  top: states ? 0 : '',
  left: states ? 0 : '',
  transform: states ? 'translateY(-300px)' : '',
  transition: states ? 'all 0.6s ease-out' : '',
  '&:hover': {},
  display: 'flex',
  flexWrap: 'wrap'
}));

export const ParentSection = styled(Box)(({ theme, length }) => ({
  border: '1px solid gray',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '300px',
  height: length > 5 ? 'fit-content' : '450px',
  // backgroundColor: 'red',
  width: '250px',
  boxShadow: theme.palette.customShadows.light,
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {}
}));

export const UpperSection = styled(Box)(({ theme }) => ({
  height: '20%',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '10px 10px 0 0px',
  textAlign: 'left',
  // display: 'flex',
  // flexDirection: 'row',
  // justifyContent: 'space-between',
  // alignItems: 'center',
  color: '#fff',
  padding: '10px',
  fontSize: '14px',
  fontWeight: '600',
  gap: '20px'
}));

export const UpperTypography = styled(Typography)(({ type }) => ({
  fontSize: '16px',
  fontWeight: 600,
  padding: '2px 0',
  color: type == true ? 'green' : ''
}));

export const AddressTypo = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  padding: '2px 0'
}));

export const TimerRing = styled(Box)(() => ({
  border: '3px solid #EB6440',
  borderRadius: '50%',
  width: '65px',
  height: '65px',
  padding: '3px',
  boxSizing: 'content-box'
}));

export const Timer = styled(Box)(() => ({
  backgroundColor: '#fff',
  textAlign: 'center',
  width: '65px',
  height: '65px',
  border: '2px solid #bee3dd',
  borderRadius: '50%',
  color: '#394867',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: '700'
}));

export const MiddleSection = styled(Box)(({ states, length }) => ({
  backgroundColor: states === true ? '#42ba96' : '',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  minHeight: '200px',
  height: 'fit-content'
  // height: length ? 'fit-content' : ''
}));

export const Items = styled(Box)(({ length }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'start',
  padding: '0 10px',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  fontSize: '17px',
  fontWeight: '600',
  lineHeight: 0,
  height: length ? 'fit-content' : ''
}));

export const LowerSection = styled(Box)(() => ({
  // height: '15%',
  borderTop: '3px solid #EB6440',
  fontSize: '14px',
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px 0 10px'
}));

export const DoneButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '30px',
  fontSize: '15px',
  fontWeight: '700',
  height: '40px',
  width: '100px',
  color: theme.palette.white.main,
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    backgroundColor: theme.palette.primary.hover
  }
}));

export const ReportChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.hover
  }
}));

// orders component section
export const OrdersActionButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  // minWidth: '60px',
  // width: '60px',
  padding: '2px 4px',
  height: '25px',
  textTransform: 'none',
  fontSize: '11px'
}));

export const OrdersBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.customShadows.light,
  padding: '5px 5px',
  textAlign: 'left',
  maxHeight: '490px',
  height: '100%',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  }
}));

export const SingleOrderBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.palette.customShadows.light,
  background: '#f1f1f1',
  borderRadius: '7px',
  marginTop: '10px',
  padding: '10px 5px'
}));
export const UpperBox = styled(Box)(() => ({}));

export const UserSectionBox = styled(Box)(() => ({}));

export const OutletSectionBox = styled(Box)(() => ({
  borderTop: '1px solid #d3cfcf'
}));
export const OrderTypography = styled(Typography)(({}) => ({
  fontSize: '12px'
  // fontSize: theme.typography.fontSize
  // color: 'theme.secondary.dark'
}));

export const OrderStatusBox = styled(Box)(() => ({
  borderTop: '1px solid #d3cfcf'
}));

export const OrdersActionBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  borderTop: '1px solid #d3cfcf',
  paddingTop: '3px'
}));

export const SearchBox = styled(Box)(() => ({
  display: 'flex',
  margin: '5px 0 5px 5px'
}));

export const GeneralTypography = styled(Typography)(() => ({
  fontSize: '12px',
  letterSpacing: 0,
  display: 'flex',
  justifyContent: 'center'
}));

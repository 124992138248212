import React from 'react';
import MenuItemsList from './MenuItemsList';
import { useDrawerContext } from '../contexts/drawer-contexts';
import Drawer from '@mui/material/Drawer';
import { useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isOpened'
})(({ isOpened, theme }) => ({
  width: isOpened ? 240 : theme.spacing(7),
  overflow: 'auto',
  height: '100%',

  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: isOpened
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen
  }),
  '& .MuiDrawer-paper': {
    background: '#101924',
    position: 'static',
    overflowX: 'hidden',
    height: '100%'
  }
}));

const CustomDrawer = () => {
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <StyledDrawer
      variant={isLargeScreen ? 'permanent' : 'temporary'}
      open={!isLargeScreen && isOpened}
      onClose={() => toggleIsOpened(!isOpened)}
      isOpened={isOpened}>
      <MenuItemsList isOpened={isOpened} />
    </StyledDrawer>
  );
};

export default CustomDrawer;

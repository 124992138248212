import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  ingredientSetResponse: null,
  isSuccess: false
};

const ingredientSetSlice = createSlice({
  name: 'ingredientSet',
  initialState: INITIAL_STATE,
  reducers: {
    ingredientSetResponse(state, { payload }) {
      return {
        ...state,
        ingredientSetResponse: payload.response
      };
    },
    ingredientSetRequest: (state) => state,
    updateIngredientSetSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  ingredientSetRequest,
  ingredientSetResponse,
  updateIngredientSetSuccessStatus
} = ingredientSetSlice.actions;

export default ingredientSetSlice.reducer;

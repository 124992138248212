import axios from 'axios';
import { refreshAccessToken } from '../../refreshTokenApi';
import purchasesUrls from './purchasesUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// Add Purchase API

export const addPurchasesApi = async (purchaseData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${purchasesUrls.purchases}`,
      purchaseData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${purchasesUrls.purchases}`,
        purchaseData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Purchases API
export const editPurchaseApi = async (purchaseId, purchaseData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${purchasesUrls.purchases}/${purchaseId}`,
      purchaseData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${purchasesUrls.purchases}/${purchaseId}`,
        purchaseData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get All Areas API
export const getPurchaseApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${purchasesUrls.outletPurchases}/${outletId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${purchasesUrls.outletPurchases}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get single purchase
export const getSinglePurchaseApi = async (purchaseId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${purchasesUrls.purchases}/${purchaseId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${purchasesUrls.purchases}/${purchaseId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

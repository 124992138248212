/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tooltip
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import {
  editIngredientSetApi,
  getSingleIngredientSetApi
} from '../../api/ingredientSet/ingredientSetApi';
import { ingredientRequest } from '../../redux/reducers/ducks/IngredientDuck';
import { FieldBox } from './Styled-components';
import {
  editMenuItemApi,
  getSingleMenuItemApi
} from '../../api/menuItems/menuItemsApi';
import { choicesRequest } from '../../redux/reducers/ducks/ChoicesDuck';
import { menuCategoryRequest } from '../../redux/reducers/ducks/MenuCategoryDuck';

const getById = (collection, id) => {
  for (let item of collection) if (item._id == id) return item;
  return null;
};

const EditMenuItem = ({
  open,
  setOpen,
  callFunc
  //  displayScreen
}) => {
  const dispatch = useDispatch();
  const [choices, setChoices] = useState([]);

  // fetching data from store and calling API through Saga
  const [choicesData, setChoicesData] = useState([]);
  const choicesResponse = useSelector((state) => state.choices);

  // getting menu categories from store
  const [categories, setCategories] = useState([]);
  const menuCategoryResponse = useSelector((state) => state.menuCategory);

  // getting Single Menu Item API
  const [singleMenuItem, setSingleMenuItem] = useState({});

  const getSingleMenuItemFunc = async () => {
    const res = await getSingleMenuItemApi(open.id);
    setSingleMenuItem(res?.data);
    reset(res?.data);
  };

  useEffect(() => {
    // statement for choices
    if (!choicesResponse || !choicesResponse.choicesResponse) {
      dispatch(choicesRequest());
    } else {
      setChoicesData(choicesResponse);
      setChoices(choicesData?.choicesResponse?.data);
    }

    // statement for menu Category

    if (!menuCategoryResponse || !menuCategoryResponse.menuCategoryResponse) {
      dispatch(menuCategoryRequest());
    } else {
      setCategories(menuCategoryResponse?.menuCategoryResponse?.data);
    }
    getSingleMenuItemFunc();
  }, [dispatch, choicesResponse, callFunc]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: singleMenuItem
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'choices'
  });

  const handleFormSubmit = async (values, onSubmitProps) => {
    let response = await editMenuItemApi(open.id, values);
    if (response.status === 200) {
      handleClose();
      callFunc();
    }
  };

  const handleClose = () => {
    setOpen({ isOpen: false });
    reset();
  };

  return (
    <Box>
      <Container>
        <Dialog open={open.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Typography color="#FFA500  " fontWeight="700">
                Edit Menu Item
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <TextField
                  fullWidth
                  label="Name"
                  margin="normal"
                  variant="outlined"
                  {...register('name', { required: true })}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
                <TextField
                  fullWidth
                  label="Description"
                  margin="normal"
                  variant="outlined"
                  {...register('desc')}
                  error={!!errors.desc}
                  helperText={errors?.desc?.message}
                />

                <TextField
                  fullWidth
                  label="Image URL"
                  margin="normal"
                  variant="outlined"
                  {...register('img')}
                  error={!!errors.img}
                  helperText={errors?.img?.message}
                />
                <Controller
                  name="category"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      defaultValue=""
                      label="Select Category"
                      fullWidth
                      margin="normal"
                      error={!!errors.size}
                      helperText={errors?.size?.message}
                      select
                    >
                      {categories?.map((category) => (
                        <MenuItem value={category._id} key={category._id}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Box display="flex" justifyContent="center" gap="1rem">
                  <TextField
                    fullWidth
                    label="Price"
                    margin="normal"
                    variant="outlined"
                    {...register('price', { required: true })}
                    error={!!errors.price}
                    helperText={errors?.price?.message}
                  />

                  <TextField
                    fullWidth
                    label="Discount"
                    margin="normal"
                    variant="outlined"
                    {...register('discount', { required: true })}
                    error={!!errors.discount}
                    helperText={errors?.discount?.message}
                  />

                  <FormControlLabel
                    label="Fixed"
                    control={
                      <Controller
                        name="fixedDiscount"
                        control={control}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="center" gap="1rem">
                  <TextField
                    fullWidth
                    label="Tax"
                    margin="normal"
                    variant="outlined"
                    {...register('tax', { required: true })}
                    error={!!errors.tax}
                    helperText={errors?.tax?.message}
                  />

                  <FormControlLabel
                    label="Inclusive"
                    control={
                      <Controller
                        name="inclusiveTax"
                        control={control}
                        render={({ field: props }) => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={(e) => props.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                  />
                </Box>

                <Button
                  onClick={() =>
                    append({
                      name: '',
                      group: '',
                      parentChoice: '',
                      choice: ''
                    })
                  }
                >
                  Add Choice
                </Button>
                <Box>
                  {fields.map((choice, index) => (
                    <Box
                      sx={{
                        border: '1px solid gray',
                        margin: '10px 0',
                        borderRadius: '7px',
                        padding: '0 5px'
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Tooltip title="Delete Choice">
                          <IconButton
                            sx={{ color: 'red' }}
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Controller
                        control={control}
                        name={`choices.${index}.choice`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Select Size"
                            fullWidth
                            margin="normal"
                            select
                          >
                            {choices
                              ?.filter(
                                (item) =>
                                  !fields.map((v) => v.item).includes(item._id)
                              )
                              ?.map((item) => (
                                <MenuItem key={item._id} value={item._id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                      <FieldBox>
                        <TextField
                          {...register(`choices.${index}.name`, {
                            required: true
                          })}
                          error={errors.choices && errors.choices[index]?.name}
                          helperText={
                            errors.choices &&
                            errors.choices[index]?.name?.message
                          }
                          variant="outlined"
                          label="Name"
                          fullWidth
                        />
                        <TextField
                          {...register(`choices.${index}.parentChoice`)}
                          error={
                            errors.choices &&
                            errors.choices[index]?.parentChoice
                          }
                          helperText={
                            errors.choices &&
                            errors.choices[index]?.parentChoice?.message
                          }
                          variant="outlined"
                          label="Parent Choice"
                          fullWidth
                        />
                      </FieldBox>
                    </Box>
                  ))}
                </Box>

                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default EditMenuItem;

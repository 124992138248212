/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getConsumptionReportApi } from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import { getCurrentDate } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';

export default function ConsumptionsReport() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [consumptionReport, setConsumptionReport] = useState([]);

  // handlng date

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;
  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getConsumptionReportApi(
        outlet?._id,
        startDate,
        endDate
      );
      setConsumptionReport(response?.data);
      setLoading(false);
      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = consumptionReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.name?.toLowerCase().includes(term) ||
          report?.category?.toLowerCase().includes(term) ||
          report?.unit?.toLowerCase().includes(term)
        );
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Report"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Consumption Report
        </Typography>
        {/* <div>
          <label htmlFor="start-date">Start Date:</label>
          <input
            className="date-input"
            id="start-date"
            type="date"
            value={startDate || ''}
            onChange={(event) => handleDateChange(event, true)}
          />

          <label htmlFor="end-date">End Date:</label>
          <input
            className="date-input"
            id="end-date"
            type="date"
            value={endDate || ''}
            onChange={(event) => handleDateChange(event, false)}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </div> */}

        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small" aria-label="collapsible table">
            <TableHead sx={{ background: '#FFA500', color: 'white' }}>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>DineIn</TableCell>
                <TableCell>TakeAway</TableCell>
                <TableCell>Delivery</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FFA500a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell> {report?.name}</TableCell>
                        <TableCell> {report?.category}</TableCell>
                        <TableCell> {report?.unit}</TableCell>
                        <TableCell>{Math.round(report?.dineIn)}</TableCell>
                        <TableCell>
                          {Math.round(report?.takeAway) || 0}
                        </TableCell>
                        <TableCell>
                          {Math.round(report?.delivery) || 0}
                        </TableCell>
                        <TableCell>{Math.round(report?.total) || 0}</TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

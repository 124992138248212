import { Box, styled, TableCell } from '@mui/material';

export const HeaderTableCell = styled(TableCell)(() => ({
  color: '#ffffff'
}));

export const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  // backgroundColor: '#1F1817',
  height: '200px',
  minHeight: '250px',
  textAlign: 'left',
  justifyContent: 'space-evenly',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {
    // boxShadow: '0px 0px 10px 4px #cbd5e0'
  }
}));

export const ImageBox = styled(Box)(() => ({
  objectFit: 'cover',
  height: '80%',
  width: '80%',
  borderRadius: '10px'
}));

export const ColumnTable = styled(TableCell)(() => ({
  textAlign: 'center',
  fontSize: '18px',
  fontWeight: 600
}));

/* eslint-disable no-unused-vars */
import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { StyledTableCell } from './Styled-components';
import {
  getIngredientCategoriesApi,
  getIngredientUnitApi
} from '../../api/ingredients/ingredientsApi';
import AddEditCategory from './AddEditCategory';
import AddEditUnit from './AddEditUnit';
import { getPaymentMethodApi } from '../../api/paymentMethod/paymentMethodApi';
import { useDispatch, useSelector } from 'react-redux';
import AddEditPaymentMethod from './AddEditPaymentMethod';
import { menuCategoryRequest } from '../../redux/reducers/ducks/MenuCategoryDuck';
import AddEditMenuCategories from './AddEditMenuCategories';
import { theme } from '../../theme';
import AddEditSupplier from './AddEditSupplier';
import { getsuppliersApi } from '../../api/suppliers/supplierApi';
import AddEditAreas from './AddEditAreas';
import { getAreasApi } from '../../api/areas/areaAPi';

export default function IngredientManagment() {
  const dispatch = useDispatch();
  // handling state and handleChange for tab component
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const [addPopup, setAddPopup] = useState({ isOpen: false, id: '' });
  const [addCategoryPopup, setAddCategoryPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [addPaymentMethodPopup, setAddPaymentMethodPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [addMenuCategoryPopup, setAddMenuCategoryPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [addSupplierPopup, setAddSupplierPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [addAreasPopup, setAddAreasPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [ingredientCategories, setIngredientCategories] = useState();
  const [ingredientUnits, setIngredientUnits] = useState();
  const [method, setMethod] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [areas, setAreas] = useState([]);

  // calling categories and unit APIs
  async function ingredientCategoryId() {
    const categories = await getIngredientCategoriesApi();
    const unitsId = await getIngredientUnitApi();

    setIngredientCategories(categories.data);
    setIngredientUnits(unitsId.data);

    const res = await getPaymentMethodApi();
    setMethod(res?.data);

    const response = await getsuppliersApi();
    setSuppliers(response?.data);

    const resAreas = await getAreasApi();
    setAreas(resAreas?.data);
  }

  // getting menu categories from store
  const [categories, setCategories] = useState([]);
  const menuCategoryResponse = useSelector((state) => state.menuCategory);

  useEffect(() => {
    // statement for menu Category
    if (!menuCategoryResponse || !menuCategoryResponse.menuCategoryResponse) {
      dispatch(menuCategoryRequest());
    } else {
      setCategories(menuCategoryResponse?.menuCategoryResponse?.data);
    }
  }, [callFunc]);
  async function callFunc() {
    ingredientCategoryId();
  }
  useEffect(() => {
    ingredientCategoryId();
  }, []);

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const arrayToBeSearched =
    tabValue == 0
      ? ingredientUnits
      : tabValue == 1
      ? ingredientCategories
      : tabValue == 2
      ? method
      : tabValue == 3
      ? categories
      : tabValue == 4
      ? suppliers
      : tabValue == 5
      ? areas
      : [];

  const filteredIngredients = arrayToBeSearched?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.name === 'none' ||
      report?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Unit Management" />
          <Tab label="Ingredient Categories" />
          <Tab label="Payment Method" />
          <Tab label="Menu Categories" />
          <Tab label="Suppliers" />
          <Tab label="Areas" />
        </Tabs>
      </Box>

      {tabValue === 0 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Unit"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add New Unit">
                <Button
                  sx={{ margin: '10px' }}
                  variant="contained"
                  onClick={() => {
                    setAddPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add New Unit
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Code</StyledTableCell>
                    <StyledTableCell>Base Multiplier</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row?.code}</TableCell>
                          <TableCell align="center">
                            {row?.baseMultiplier}
                          </TableCell>

                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddPopup({ isOpen: true, id: row._id });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={ingredientUnits?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : tabValue === 1 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Category"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add New Category">
                <Button
                  variant="contained"
                  sx={{ margin: '10px' }}
                  onClick={() => {
                    setAddCategoryPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add New Category
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.desc}</TableCell>
                          <TableCell align="center">{row?.code}</TableCell>

                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddCategoryPopup({
                                  isOpen: true,
                                  id: row._id
                                });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={ingredientCategories?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : tabValue === 2 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Payment Method"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add Payment Method">
                <Button
                  sx={{ margin: '10px' }}
                  variant="contained"
                  onClick={() => {
                    setAddPaymentMethodPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add Payment Method
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Tag</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Tax Rate</StyledTableCell>
                    <StyledTableCell>Inclusive Tax</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row?.tag}</TableCell>
                          <TableCell align="center">
                            {row?.active ? 'Active' : 'In-active'}
                          </TableCell>
                          <TableCell align="center">
                            {row?.overrideTax.rate}
                          </TableCell>
                          <TableCell align="center">
                            {row?.overrideTax?.inclusiveTax ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell align="center">{row?.desc}</TableCell>

                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddPaymentMethodPopup({
                                  isOpen: true,
                                  id: row._id
                                });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={method?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : tabValue === 3 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Menu Category"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add Menu Category">
                <Button
                  variant="contained"
                  sx={{ margin: '10px' }}
                  onClick={() => {
                    setAddMenuCategoryPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add Menu Category
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>

                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddMenuCategoryPopup({
                                  isOpen: true,
                                  id: row._id
                                });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={categories?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : tabValue === 4 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Menu Category"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add Supplier">
                <Button
                  variant="contained"
                  sx={{ margin: '10px' }}
                  onClick={() => {
                    setAddSupplierPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add Supplier
                  </Typography>
                </Button>
              </Tooltip>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Contact</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.name}</TableCell>
                          <TableCell align="center">{row.contact}</TableCell>
                          <TableCell align="center">{row.email}</TableCell>
                          <TableCell align="center">{row.address}</TableCell>
                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddSupplierPopup({
                                  isOpen: true,
                                  id: row._id
                                });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={categories?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : tabValue === 5 ? (
        <Box>
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Search Area"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <Tooltip title="Add New Area">
                <Button
                  variant="contained"
                  sx={{ margin: '10px' }}
                  onClick={() => {
                    setAddAreasPopup({ isOpen: true });
                  }}>
                  <AddIcon />
                  <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                    Add New Area
                  </Typography>
                </Button>
              </Tooltip>

              <Button variant="contained">
                Upload
                <input hidden accept="image/*" multiple type="file" />
              </Button>
            </Box>

            <TableContainer size="small" aria-label="collapsible table">
              <Table>
                <TableHead sx={{ background: theme.palette.primary.main }}>
                  <TableRow>
                    <StyledTableCell>Sr#</StyledTableCell>
                    <StyledTableCell>City</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell colSpan={2}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredIngredients
                    ?.slice(pg * rpg, pg * rpg + rpg)
                    .map((row, index) => {
                      return (
                        <TableRow key={row._id}>
                          <TableCell align="center" component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="center">{row.city}</TableCell>
                          <TableCell align="center">{row.name}</TableCell>

                          <TableCell
                            colSpan={2}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}>
                            <Button
                              onClick={() => {
                                setAddAreasPopup({
                                  isOpen: true,
                                  id: row._id
                                });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 50, 100]}
                      count={areas?.length || 0}
                      rowsPerPage={rpg}
                      page={pg}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Box>
      ) : (
        ''
      )}

      <AddEditCategory
        addCategoryPopup={addCategoryPopup}
        setAddCategoryPopup={setAddCategoryPopup}
        callFunc={callFunc}
      />
      <AddEditUnit
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
      />

      <AddEditPaymentMethod
        addPaymentMethodPopup={addPaymentMethodPopup}
        setAddPaymentMethodPopup={setAddPaymentMethodPopup}
        callFunc={callFunc}
      />

      <AddEditMenuCategories
        addMenuCategoryPopup={addMenuCategoryPopup}
        setAddMenuCategoryPopup={setAddMenuCategoryPopup}
        callFunc={callFunc}
      />

      <AddEditSupplier
        addSupplierPopup={addSupplierPopup}
        setAddSupplierPopup={setAddSupplierPopup}
        callFunc={callFunc}
      />

      <AddEditAreas
        addAreasPopup={addAreasPopup}
        setAddAreasPopup={setAddAreasPopup}
        callFunc={callFunc}
      />
    </>
  );
}

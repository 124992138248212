import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  menuItemsResponse: null,
  isSuccess: false
};

const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState: INITIAL_STATE,
  reducers: {
    menuItemsResponse(state, { payload }) {
      return {
        ...state,
        menuItemsResponse: payload.response
      };
    },
    menuItemsRequest: (state) => state,
    updateMenuItemsSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  menuItemsRequest,
  menuItemsResponse,
  updateMenuItemsSuccessStatus
} = menuItemsSlice.actions;

export default menuItemsSlice.reducer;

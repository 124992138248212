/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from '../../components/MenuDrawer/Layout';
import { ROUTES } from '../../components/constants/routes';
import { useSelector } from 'react-redux';
import IngredientPage from '../../components/pages/IngredientPage';
import MenuPage from '../../components/pages/MenuPage';
import IngredientSets from '../../components/ingredientSets/IngredientSets';
import Choices from '../../components/choices/Choices';
import Outlet from '../Outlet/Outlet';
import POS from '../../components/POS/POS';
import OutletDetails from '../../components/OutletDetails/OutletDetails';
import Login from '../../pages/Login/LogIn';
import Signup from '../../pages/Signup/Signup';
import KitchenDisplay from '../DisplayScreens/KitchenDisplay/KitchenDisplay';
import SalesReport from '../Reports/SalesReport';
import FoodSaleReport from '../Reports/FoodSaleReport';
import ConsumptionsReport from '../Reports/ConsumptionsReport';
import CallCenter from '../CallCenter/CallCenter';
import OrdersStatus from '../Reports/CallCenterOrders/OrdersStatus';
import Purchase from '../Stocks/Purchase/Purchase';
import Transfer from '../Stocks/Transfer/Transfer';
import InventoryController from '../Stocks/InventoryController/InventoryController';
import Waste from '../Stocks/Waste/Waste';
import InventoryHistory from '../Stocks/InventoryHistory/InventoryHistory';
import InventoryHistoryIngredients from '../Stocks/InventoryHistory/InventoryHistoryIngredients';
import InventoryReport from '../Reports/InventoryReport';
import MenuSaleReport from '../Reports/MenuSaleReport';
import HourlySaleReport from '../Reports/HourlySaleReport';
import DispatchDisplay from '../DisplayScreens/DispatchDisplay/DispatchDisplay';
import CategoryWiseSaleReport from '../Reports/CategoryWiseSaleReport';
import DispatchSheet from '../Reports/DispatchSheet';
import CutScreenDisplay from '../DisplayScreens/CutScreenDisplay/CutScreenDisplay';
import KitchenSheet from '../Reports/KitchenSheet';
import RiderSheet from '../Reports/RiderSheet';
import UserManagment from '../UserManagment/UserManagment';
import DeliveryMonitor from '../Reports/DeliveryMonitor/DeliveryMonitor';
import ChangeManagerKey from '../UserManagment/ChangeManagerKey';
import BMDetails from '../BusinessManagment/BMDetails';
import RoleManagement from '../UserManagment/RoleManagement';
import OutletSaleReport from '../BusinessManagment/OutletSaleReport';
import OverAllReport from '../BusinessManagment/OverAllReport';
import DeletedItemReport from '../BusinessManagment/DeletedItemsReport';
import DiscountReport from '../BusinessManagment/DiscountReport';
import CanceledReport from '../BusinessManagment/CanceledReport';
import PRAReport from '../BusinessManagment/PRAReport';
import ChangePassword from '../UserManagment/changePassword/ChangePassword';
import UserInformation from '../UserManagment/viewProfile/ViewProfile';
import DeliveryDisplay from '../DisplayScreens/Delivery/Delivery';
import IngredientManagment from '../ingredientManagment/IngredientManagment';
import DisplayScreenManagment from '../DisplayScreenMngmt/DisplayScreenManagment';
import OutletDeliveryMonitor from '../Outlet/OutletDeliveryMonitor/OutletDeliveryMonitor';
import WalkSheet from '../walkSheet/WalkSheet';
import AddWalkSheet from '../walkSheet/AddWalkSheet';
import WalkSheetHistory from '../walkSheet/WalkSheetHistory';
import BlockUser from '../BlockUser/BlockUser';
import DashBoard from '../DashBoard/DashBoard';
import Expense from '../Stocks/Expenses/Expense';
import DeletedItemsReport from '../Reports/DeletdItemsReport';
import DineInDisplay from '../DisplayScreens/DineInScreen/DineInDisplay';
import DaySheet from '../Reports/DaySheet/DaySheet';
import OutletsDetailedReport from '../BusinessManagment/OutletsDetailedReport';
import FeedbackReports from '../BusinessManagment/FeedbackReports';
import FeedbackData from '../BusinessManagment/FeedbackData';
import FeedBackSummaryReport from '../BusinessManagment/FeedBack/FeedBackSummaryReport';
import Feedback from '../Feedback/Feedback';
import SubmitPage from '../Feedback/SubmitPage';
import FeedbackDashbord from '../Feedback/FeedbackDashbord';
import CreateComplaint from '../Complain/CreateComplaint';
import AllComplaint from '../Complain/AllComplaint';
import TakeFeedback from '../Feedback/TakeFeedback';
import OutLetFeedbackData from '../BusinessManagment/OutLetFeedbackData';

function Main() {
  const user = useSelector((state) => state.authorize?.loginResponse?.user);

  const roles = [
    {
      roleName: 'rider',
      route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/delivery-display`
    },
    // {
    //   roleName: 'waiter',
    //   route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/dinin-display`
    // },
    {
      roleName: 'ufoneCSR',
      route: `callCenter`
    },
    {
      roleName: 'callAgent',
      route: `callCenter`
    },
    {
      roleName: 'dispatcher',
      route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/dispatch`
    },
    {
      roleName: 'assembler',
      route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/cut-table`
    },
    {
      roleName: 'cashier',
      route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/pos`
    },
    {
      roleName: 'kitchen',
      route: `outlet/${user?.outlets[0]?.code?.toLowerCase()}/display/${user?.displays[0]?.name?.toLowerCase()}`
    }
  ];

  const userRoleName = user?.role?.name;
  const matchingRole = roles.find((role) => role.roleName === userRoleName);
  const defaultRoute = 'outlet';
  return (
    <>
      <Layout>
        <Routes>
          <Route path="*" element={<div>Page not found!</div>} />

          <Route path="/">
            <Route
              index
              element={
                <Navigate
                  to={matchingRole ? matchingRole.route : defaultRoute}
                />
              }
            />
          </Route>

          <Route path={ROUTES.main} element={<></>} />
          <Route path={ROUTES.ingredients} element={<IngredientPage />} />
          <Route path={ROUTES.feedbackSubmission} element={<SubmitPage />} />
          <Route
            path={ROUTES.ingredientManagment}
            element={<IngredientManagment />}
          />
          <Route path={ROUTES.ingredientSet} element={<IngredientSets />} />
          <Route path={ROUTES.choices} element={<Choices />} />
          <Route path={ROUTES.menu} element={<MenuPage />} />
          <Route path={ROUTES.callCenter} element={<CallCenter />} />
          <Route path={ROUTES.OrdersStatus} element={<OrdersStatus />} />

          <Route path={ROUTES.changeKey} element={<ChangeManagerKey />} />
          <Route path={ROUTES.roleManagement} element={<RoleManagement />} />
          <Route
            path={ROUTES.displayScreenManagement}
            element={<DisplayScreenManagment />}
          />
          <Route path={ROUTES.changePassword} element={<ChangePassword />} />
          <Route path={ROUTES.userInformation} element={<UserInformation />} />
          <Route path={ROUTES.blockUser} element={<BlockUser />} />

          <Route path={ROUTES.users} element={<UserManagment />} />
          <Route path={ROUTES.deliveryMonitor} element={<DeliveryMonitor />} />
          <Route path={ROUTES.dashBoard} element={<DashBoard />} />
          <Route path={ROUTES.feedback} element={<Feedback />} />
          {/* <Route path={ROUTES.complaint} element={<CreateComplaint />} /> */}

          <Route path="reporting">
            <Route index element={<BMDetails />} />
            <Route path="test" element={<>Testing</>} />
            <Route path="outlet-sale-report" element={<OutletSaleReport />} />
            <Route
              path="outlets-detail-report"
              element={<OutletsDetailedReport />}
            />
            <Route path="feedbacks-reports" element={<FeedbackReports />} />
            <Route path="feedbacks" element={<FeedbackData />} />
            <Route path="over-all-report" element={<OverAllReport />} />
            <Route
              path="feedbacks-summary"
              element={<FeedBackSummaryReport />}
            />
            <Route path="feedback-dashbord" element={<FeedbackDashbord />} />
            {/* <Route path="complaint" element={<AllComplaint />} /> */}
            <Route
              path="deleted-items-report"
              element={<DeletedItemReport />}
            />
            <Route path="discount-report" element={<DiscountReport />} />
            <Route path="canceled-report" element={<CanceledReport />} />
            <Route path="pra-report" element={<PRAReport />} />
          </Route>

          <Route path="/outlet">
            <Route index element={<Outlet />} />
            <Route path=":code">
              <Route index element={<OutletDetails />} />
              <Route path="display/:operation" element={<KitchenDisplay />} />

              <Route path="dispatch">
                <Route index element={<DispatchDisplay />} />
              </Route>

              <Route path="dispatch-sheet" element={<DispatchSheet />} />
              <Route path="rider-sheet" element={<RiderSheet />} />
              <Route
                path="outlet-delivery-monitor"
                element={<OutletDeliveryMonitor />}
              />

              <Route path="day-sheet" element={<DaySheet />} />
              <Route path="take-feedbacks" element={<TakeFeedback />} />
              <Route path="feedbacks" element={<OutLetFeedbackData />} />

              <Route path="walk-sheet">
                <Route index element={<WalkSheet />} />
                <Route path="add-walk-sheet" element={<AddWalkSheet />} />
                <Route
                  path="walk-sheet-history"
                  element={<WalkSheetHistory />}
                />
                <Route
                  path=":date/:shift/sections"
                  element={<WalkSheetHistory />}
                />
              </Route>

              <Route path="cut-table" element={<CutScreenDisplay />} />
              <Route path="delivery-display" element={<DeliveryDisplay />} />
              <Route path="dinein-display" element={<DineInDisplay />} />

              <Route path="kitchen-sheet" element={<KitchenSheet />} />

              <Route path="sales-report" element={<SalesReport />} />
              <Route path="food-sales-report" element={<FoodSaleReport />} />
              <Route path="menu-sales-report" element={<MenuSaleReport />} />
              <Route
                path="hourly-sales-report"
                element={<HourlySaleReport />}
              />
              <Route
                path="category-wise-report"
                element={<CategoryWiseSaleReport />}
              />
              <Route
                path="deleted-items-report"
                element={<DeletedItemsReport />}
              />

              <Route
                path="consumption-report"
                element={<ConsumptionsReport />}
              />
              <Route path="inventory-report" element={<InventoryReport />} />
              <Route path="pos" element={<POS />} />
              <Route path="pos/:pos" element={<POS />} />
              <Route path="purchase" element={<Purchase />} />
              <Route path="transfer" element={<Transfer />} />
              <Route
                path="inventory-controller"
                element={<InventoryController />}
              />

              <Route path="inventory-history">
                <Route index element={<InventoryHistory />} />
                <Route
                  path=":date/ingredients"
                  element={<InventoryHistoryIngredients />}
                />
              </Route>

              <Route path="waste" element={<Waste />} />
              <Route path="expense" element={<Expense />} />
            </Route>
          </Route>
        </Routes>
      </Layout>
    </>
  );
}

export default function RoutesComponent() {
  const accessToken = localStorage.getItem('accessToken');
  return (
    <Routes>
      <Route
        path="/*"
        element={accessToken ? <Main /> : <Navigate replace to="/login" />}
      />

      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<div>Page not found!</div>} />
    </Routes>
  );
}

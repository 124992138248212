import React from 'react';
import {
  CheckIcon,
  Confirmation,
  Container,
  HeadingTage,
  SubmitContainer
} from './Styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';

export default function SubmitPage() {
  const location = useLocation();
  const complaintData = location.state?.complaintData;
  return (
    <>
      <SubmitContainer>
        <Container>
          <HeadingTage>
            {complaintData ? 'Submit Your Complain' : 'Submit Your Feedback'}
          </HeadingTage>
          <Confirmation>
            <CheckIcon>✔</CheckIcon>
            <span>
              {complaintData
                ? 'Your Complain has been submitted!'
                : 'Your feedback has been submitted!'}
            </span>
          </Confirmation>
          <NavLink to="/feedbacks">
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon size={20} />}
              style={{
                marginTop: '10px',
                minWidth: '150px',
                borderRadius: '50px',
                padding: '10px',
                fontSize: 'bold'
              }}>
              Go Back
            </Button>
          </NavLink>
        </Container>
      </SubmitContainer>
    </>
  );
}


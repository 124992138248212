/* eslint-disable prefer-const, no-var,no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Button,
  useMediaQuery,
  Tooltip,
  Typography,
  Skeleton,
  TablePagination,
  TextField
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { StyledTableCell, TableButton } from './Styled-components';
import AddIngredients from './AddIngredients';
import DeleteIngredient from './DeleteIngredient';
import EditIngredient from './EditIngredient';
import {
  getIngredientCategoriesApi,
  getIngredientUnitApi
} from '../../api/ingredients/ingredientsApi';

import { useSelector, useDispatch } from 'react-redux';
import { ingredientRequest } from '../../redux/reducers/ducks/IngredientDuck';
import AddIcon from '@mui/icons-material/Add';
import { theme } from '../../theme';

export default function Ingredients() {
  const dispatch = useDispatch();
  const ingredientResponse = useSelector((state) => state.ingredient);

  useEffect(() => {
    if (!ingredientResponse || !ingredientResponse.ingredientResponse) {
      dispatch(ingredientRequest());
    } else {
      setIngredients(ingredientResponse?.ingredientResponse);
    }
  }, [dispatch, ingredientResponse, callFunc]);

  const [value, setValue] = useState({ isOpen: false, id: '' });
  const [open, setOpen] = useState({ isOpen: false, id: '' });
  const [addPopup, setAddPopup] = useState({ isOpen: false });

  const [ingredients, setIngredients] = useState([]);
  const [ingredientCategories, setIngredientCategories] = useState();
  const [ingredientUnits, setIngredientUnits] = useState();

  // calling categories and unit APIs
  async function ingredientCategoryId() {
    let categories = await getIngredientCategoriesApi();
    const unitsId = await getIngredientUnitApi();

    setIngredientCategories(categories);
    setIngredientUnits(unitsId.data);
  }

  async function callFunc() {
    dispatch(ingredientRequest());
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredIngredients = ingredients?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.name === 'none' ||
      report?.category?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      report?.category === 'none'
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <Box>
      <>
        <Box
          my={2}
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-around',
            boxShadow: '0px 0px 2px 1px #cbd5e0',
            margin: '10px'
          }}>
          <TextField
            label="Search Ingredient"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Typography
            sx={{
              fontSize: '30px',
              fontWeight: 700
            }}>
            Ingredients
          </Typography>
          <div>
            <Tooltip title="Add Ingredient">
              <Button
                variant="contained"
                style={{
                  padding: ' 10px 30px'
                }}
                onClick={() => {
                  setAddPopup({ isOpen: true });
                  ingredientCategoryId();
                }}>
                <AddIcon />
                <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                  Add Ingredient
                </Typography>
              </Button>
            </Tooltip>
          </div>
        </Box>

        {!ingredientResponse.isSuccess ? (
          <Box
            sx={{
              height: 'max-content'
            }}>
            {[...Array(10)].map((arr, i) => (
              <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
            ))}
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="collapsible table">
              <TableHead sx={{ background: theme.palette.primary.main }}>
                <TableRow>
                  <StyledTableCell>Sr#</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>Unit</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Alert Quantity</StyledTableCell>
                  <StyledTableCell colSpan={2}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredIngredients
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    return (
                      <TableRow key={row._id}>
                        <TableCell align="center" component="th" scope="row">
                          {index + 1}
                        </TableCell>

                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">
                          {row.category.name}
                        </TableCell>
                        <TableCell align="center">{row.unit.code}</TableCell>
                        <TableCell align="center">
                          {Math.round(row.value)}
                        </TableCell>
                        <TableCell align="center">
                          {row.alertQuantity}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            onClick={() => {
                              setOpen({ isOpen: true, id: row._id });
                              ingredientCategoryId();
                            }}>
                            <Edit />
                          </Button>
                          <Button
                            onClick={() => {
                              setValue({ isOpen: true, id: row._id });
                            }}>
                            <Delete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TablePagination
                  rowsPerPageOptions={[10, 50, 100]}
                  count={ingredients?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
      <EditIngredient
        open={open}
        setOpen={setOpen}
        callFunc={callFunc}
        ingredientCategories={ingredientCategories}
        ingredientUnits={ingredientUnits}
      />
      <DeleteIngredient value={value} setValue={setValue} callFunc={callFunc} />
      <AddIngredients
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
        ingredientCategories={ingredientCategories}
        ingredientUnits={ingredientUnits}
      />
    </Box>
  );
}

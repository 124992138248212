import axios from 'axios';
import { fetchWithAuth, refreshAccessToken } from '../refreshTokenApi';
import reportUrls from './reportUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// // Function to refresh the access token
// const refreshAccessToken = async () => {
//   // Your logic to refresh the access token goes here
//   // For example, you can make a request to the server to get a new token
//   // and return the new token.
//   const newAccessToken = await fetchNewAccessToken();
//   return newAccessToken;
// };

// // Shared function to call APIs
// export const callApi = async (url, config) => {
//   try {
//     const data = await axios.get(url, config);
//     return data;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const newAccessToken = await refreshAccessToken();
//       config.headers.Authorization = `Bearer ${newAccessToken}`;
//       const data = await axios.get(url, config);
//       return data;
//     } else {
//       console.error(error);
//       return null;
//     }
//   }
// };

// // Example API calling function using the shared function
// export const getPRAReportApi = async (outletId, startDate, endDate) => {
//   const outletParams = `outlet=${outletId}`;
//   const startDateParams = `startDate=${startDate}`;
//   const endDateParams = `endDate=${endDate}`;
//   const config = { headers: { Authorization: `Bearer ${accessToken}` } };
//   const apiUrl = `${reportUrls.praTax}?${startDateParams}&${endDateParams}&${outletParams}`;

//   return callApi(apiUrl, config);
// };

// get Register Report API
export const getRegisterReportApi = async (outletId, date) => {
  const outletParams = `outlet=${outletId}`;
  const dateParams = `saleDate=${date}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.registerReport}?${outletParams}&${dateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.registerReport}?${outletParams}&${dateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Closing Report API
export const getClosingReportApi = async (outletId, date) => {
  const outletParams = `outlet=${outletId}`;
  const dateParams = `saleDate=${date}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.closingReport}?${outletParams}&${dateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.closingReport}?${outletParams}&${dateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Sales Report API
export const getSaleReportApi = async (outletId, date) => {
  const outletParams = `outlet=${outletId}`;
  const dateParams = `saleDate=${date}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.saleReport}?${outletParams}&${dateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.saleReport}?${outletParams}&${dateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Food Sales Report API
export const getFoodSaleReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.foodSaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.foodSaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Food Sales Report API
export const getMenuSaleReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.menuSaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.foodSaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Food Sales Report API
export const getHourlySaleReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.hourlySaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.hourlySaleReport}?${outletParams}&${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Consumption Report API
export const getConsumptionReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.consumptionsReport}?${outletParams}&${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.consumptionsReport}?${outletParams}&${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Order Status Report API
export const getOrderStatusReportApi = async (startDate, endDate, except) => {
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const arrayParams = `except=${except.join(',')}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.orderStatus}?${startDateParams}&${endDateParams}&${arrayParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.orderStatus}?${startDateParams}&${endDateParams}&${arrayParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Consumption Report API
export const getInventoryReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet/${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.inventoryReport}/${outletParams}/report?${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.inventoryReport}/${outletParams}/report?${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Category wsie sale Report API
export const getCategoryWiseSaleReportApi = async (
  outletId,
  startDate,
  endDate
) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.categorySale}?${outletParams}&${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.categorySale}?${outletParams}&${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Daily Dispatch Sheet Report API
export const getDispatchSheetApi = async (outletId, saleDate) => {
  const outletParams = `outlet=${outletId}`;
  const saleDateParams = `saleDate=${saleDate}`;
  const accessToken = localStorage.getItem('accessToken');

  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.dispatchSheet}?${saleDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.dispatchSheet}?${saleDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Daily Kitchen Sheet Report API
export const getKitchenSheetApi = async (outletId, saleDate) => {
  const outletParams = `outlet=${outletId}`;
  const saleDateParams = `saleDate=${saleDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.kitchenSheet}?${saleDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.kitchenSheet}?${saleDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Daily Rider Sheet Report API
export const getRiderSheetApi = async (outletId, saleDate) => {
  const outletParams = `outlet=${outletId}`;
  const saleDateParams = `saleDate=${saleDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.riderSheet}?${saleDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.riderSheet}?${saleDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// to monitor live orders API
export const deliveryMonitorApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(reportUrls.deliveryMonitor, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(reportUrls.deliveryMonitor, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// OutletWise monitor live orders API

export const outletDeliveryMonitorApi = async (outlet) => {
  const result = await fetchWithAuth(`${reportUrls.deliveryMonitor}/${outlet}`);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// get Branch Wise Report Report API
export const getOutletWiseReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.outletSales}?${endDateParams}&${startDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.outletSales}?${endDateParams}&${startDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Branch Wise deleted-items-report API
export const getdeletedItemsReportApi = async (
  outletId,
  startDate,
  endDate
) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.deletedItemsReport}?${startDateParams}&${endDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.deletedItemsReport}?${startDateParams}&${endDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Branch Wise deleted-items-report API
export const getDiscountReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.discountReport}?${startDateParams}&${endDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.discountReport}?${startDateParams}&${endDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Branch Wise canceled-orders-report API
export const getCanceledOrdersReportApi = async (
  outletId,
  startDate,
  endDate
) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.cancelledReport}?${startDateParams}&${endDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.cancelledReport}?${startDateParams}&${endDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Branch Wise PRA-report API
export const getPRAReportApi = async (outletId, startDate, endDate) => {
  const outletParams = `outlet=${outletId}`;
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${reportUrls.praTax}?${startDateParams}&${endDateParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${reportUrls.praTax}?${startDateParams}&${endDateParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get over-all report API

export const getOverAllSalesApi = async (endDate, startDate) => {
  const result = await fetchWithAuth(reportUrls.overallSales, {
    query: {
      endDate,
      startDate
    }
  });
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

export const getDaySheetApi = async (outletId, saleDate) => {
  const outletParams = `outlet=${outletId}`;
  const dateParams = `saleDate=${saleDate}`;
  const result = await fetchWithAuth(
    `${reportUrls.daySheet}?${outletParams}&${dateParams}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

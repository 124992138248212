import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const ingredientUrls = {
  ingredient: `${baseURL}/ingredients`,

  ingredientCategories: {
    category: `${baseURL}/ingredient-categories`
  },
  ingredientUnit: {
    unit: `${baseURL}/units`
  }
};

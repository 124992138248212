import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { extractTime, HeaderTypography } from '../Shared/shared';
import { FullGrid } from '../DisplayScreens/DispatchDisplay/Styled-components';
import { NavLink, useParams } from 'react-router-dom';
import {
  getDineInToFeedbackOrdersApi,
  getRunningOrdersApi
} from '../../api/orders/OrdersApi';
import { useSelector } from 'react-redux';
import Details from '../Reports/DeliveryMonitor/Details';
import {
  ButtonBox,
  CardParentBox,
  CustomerInfo,
  HeaderBox,
  NoOrderFoundBox,
  RotatingIcon,
  TakeFeedbackParentSection,
  TakeLowerSection,
  TakeMiddleSection,
  TakeTimerRing,
  TakeTimerRingChild,
  TakeUpperSection,
  TakeUpperTypography
} from './Styled-components';
import { theme } from '../../theme';

export default function TakeFeedback() {
  const smallScreen = useMediaQuery('(max-width: 900px)');

  const params = useParams();
  const { code } = params;
  // getting outlet from Redux state on the basis of params
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );
  // set all states
  const [runningOrdersApi, setRunningOrdersApi] = useState([]);
  const [isRotating, setIsRotating] = useState(false);
  const [details, setDetails] = useState({ isOpen: false });
  const [selectedTableId, setSelectedTableId] = useState('all');
  const [checked, setChecked] = useState(true);
  const [toggleOrders, setToggleOrders] = useState(false);

  // call API to get getRunningOrdersApiFunc orders
  const getDineInToFeedbackFun = async () => {
    setIsRotating(true);
    try {
      const res = await getDineInToFeedbackOrdersApi(outlet._id);
      setRunningOrdersApi(res?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsRotating(false);
    }
  };
  // getting dineIn Orders
  // const dineInOrders = runningOrdersApi.filter(
  //   (order) => order.orderType == 'dineIn'
  // );
  // Getting cashed out Orders
  const handleToggle = () => {
    setChecked(!checked);
  };

  const cashedOutFilter = (order) => order.cashedOut;
  const runningFilter = (order) => !order.cashedOut;

  const displayOrders = runningOrdersApi.filter(
    toggleOrders ? cashedOutFilter : runningFilter
  );

  // Filter orders based on the selected table ID
  const filteredOrders =
    selectedTableId === 'all'
      ? displayOrders
      : displayOrders.filter((order) => order.tableName === selectedTableId);

  // handling switch to set true or false auto refresh Running orders

  const runningClick = () => setToggleOrders(false);
  const cashedOutClick = () => setToggleOrders(true);

  // get the the value of selectedTableId
  const handleTableIdChange = (event) => {
    setSelectedTableId(event.target.value);
  };
  useEffect(() => {
    getDineInToFeedbackFun();
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        getDineInToFeedbackFun();
      }, 15000);
    }
    // return () => clearInterval(interval);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checked]);

  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
        <HeaderBox my={2} smallScreen={smallScreen}>
          <HeaderTypography
            sx={{
              fontWeight: 600,
              fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
            }}>
            TakeFeedback
          </HeaderTypography>
          <ButtonBox theme={theme}>
            <Button
              variant="outlined"
              color={toggleOrders === false ? 'secondary' : 'primary'}
              sx={{
                borderRadius: '30px',
                backgroundColor:
                  toggleOrders === false ? '#f0f0f0' : 'transparent'
              }}
              onClick={runningClick}>
              DineIn
            </Button>
            <Button
              variant="outlined"
              color={toggleOrders === true ? 'secondary' : 'primary'}
              sx={{
                borderRadius: '30px',
                backgroundColor:
                  toggleOrders === true ? '#f0f0f0' : 'transparent'
              }}
              onClick={cashedOutClick}>
              cashed out
            </Button>
          </ButtonBox>
          <Box
            style={{
              width: smallScreen ? '50%' : '20%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px'
            }}>
            <FormControl fullWidth size="small">
              <InputLabel id="table-id-select-label">
                Select Table Id
              </InputLabel>
              <Select
                labelId="table-id-select-label"
                id="table-id-select"
                label="Select Table Id"
                value={selectedTableId}
                onChange={handleTableIdChange}
                inputProps={{
                  style: { padding: 4 }
                }}
                sx={{
                  borderRadius: '10px'
                }}>
                <MenuItem value="all">All</MenuItem>
                {displayOrders?.map((tableId) => (
                  <MenuItem key={tableId._id} value={tableId.tableName}>
                    {tableId.tableName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              onClick={handleToggle}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '6px 10px',
                cursor: 'pointer',
                borderRadius: '10px',
                border: '1px solid gray'
              }}>
              <RotatingIcon isRotating={isRotating} checked={checked} />
            </Box>
          </Box>
        </HeaderBox>

        <CardParentBox my={2}>
          <Grid position="relative" container height="100%" spacing={2}>
            {filteredOrders.length > 0 ? (
              filteredOrders &&
              filteredOrders?.map((order, index) => {
                const {
                  customerName,
                  invoiceId,
                  customerContact,
                  tableName,
                  total,
                  carrier
                } = order;

                return (
                  <FullGrid
                    key={order._id}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2.4}
                    xl={2.4}>
                    <TakeFeedbackParentSection theme={theme}>
                      <TakeUpperSection theme={theme}>
                        <TakeTimerRing theme={theme}>
                          <TakeTimerRingChild>
                            {extractTime(order.createdAt)}
                          </TakeTimerRingChild>
                          <TakeUpperTypography>
                            {invoiceId || order._id.slice(-5).toUpperCase()}
                          </TakeUpperTypography>
                        </TakeTimerRing>
                      </TakeUpperSection>

                      <TakeMiddleSection>
                        {tableName ? (
                          <CustomerInfo>
                            <Typography>Table No.</Typography>
                            <Typography>{tableName}</Typography>
                          </CustomerInfo>
                        ) : null}
                        {customerName ? (
                          <CustomerInfo background={true}>
                            <Typography>Name.</Typography>
                            <Typography>{customerName}</Typography>
                          </CustomerInfo>
                        ) : null}
                        {customerContact ? (
                          <CustomerInfo>
                            <Typography>Contact.</Typography>
                            <Typography>{customerContact}</Typography>
                          </CustomerInfo>
                        ) : null}
                        {carrier?.name ? (
                          <CustomerInfo background={true}>
                            <Typography>Waiter.</Typography>
                            <Typography>{carrier?.name || ''}</Typography>
                          </CustomerInfo>
                        ) : null}
                      </TakeMiddleSection>

                      <TakeLowerSection>
                        <TakeTimerRing theme={theme} lower={true}>
                          <Button
                            onClick={() => {
                              setDetails({
                                isOpen: true,
                                id: order?._id
                              });
                            }}
                            sx={{
                              borderRadius: '30px',
                              background: theme?.palette?.primary?.dark
                            }}
                            variant="contained"
                            color="primary">
                            Detail
                          </Button>
                          <NavLink
                            to="/feedbacks"
                            state={{
                              liveOrderId: invoiceId,
                              orderDetail: order,
                              outlet: outlet?._id
                            }}
                            style={{ textDecoration: 'none' }}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              sx={{
                                borderRadius: '30px'
                              }}>
                              Feedback
                            </Button>
                          </NavLink>
                        </TakeTimerRing>
                      </TakeLowerSection>
                    </TakeFeedbackParentSection>
                  </FullGrid>
                );
              })
            ) : (
              <NoOrderFoundBox>
                <Typography>No orders found.</Typography>
              </NoOrderFoundBox>
            )}
          </Grid>
        </CardParentBox>
      </Box>
      <Details details={details} setDetails={setDetails} />
    </>
  );
}


import React, { useEffect, useState } from 'react';
import Meeting from './Meeting';
import ReviewSection from './Review';
import PublicReviews from './publicReview';
import { fetchWithAuth } from '../../api/refreshTokenApi';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { getApi, HeaderTypography } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';
import { Box, LinearProgress, Rating, Typography } from '@mui/material';
import {
  getFeedbackSummaryApi,
  getOutletsRatingApi
} from '../../api/feedback/feedbackApi';
import {
  BranchOfMonthBox,
  MonthYearlyDayBox,
  ProgressBarBox,
  TitleBox
} from './Styled-components';
import styled from 'styled-components';
import ProgressBar from '../DashBoard/ProgressBar/ProgressBar';
export default function FeedbackDashbord() {
  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  const [dashBoardData, setDashBoardData] = useState([]);
  const [allFeedbacks, setAllFeedbacks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalMonthFeedbacksSum, setTotalMonthFeedbacksSum] = useState(0);
  const [totalYearlyFeedbacksSum, setTotalYearlyFeedbacksSum] = useState(0);
  const [yearlyAvg, setYearlyAvg] = useState(null);
  const [monthlyAvg, setMonthlyAvg] = useState(null);

  console.log(allFeedbacks, 'allFeedbacks');

  // get getSummaryApi
  const getSummaryApi = async () => {
    try {
      setIsLoading(true);
      const response = await getFeedbackSummaryApi(
        datePopup?.startDate,
        datePopup?.endDate
      );
      setDashBoardData(response?.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const calculateOverallAverageRating = (data) => {
    const totalRatingSum = data.reduce(
      (sum, outlet) => sum + outlet.averageRating,
      0
    );
    const overallAverageRating = totalRatingSum / 9;
    return overallAverageRating;
  };

  // get getMonthAndYearRatingApi
  const getMonthAndYearRatingApi = async () => {
    try {
      setLoading(true);

      // Fetch monthly ratings and calculate the sum
      const monthResponse = await getOutletsRatingApi(30);

      console.log(monthResponse, 'monthResponse');

      setYearlyAvg(calculateOverallAverageRating(monthResponse?.data));

      const monthSum = monthResponse?.data.reduce(
        (sum, feedback) => sum + feedback.totalFeedbacks,
        0
      );
      setTotalMonthFeedbacksSum(monthSum);

      // Fetch yearly ratings and calculate the sum
      const yearResponse = await getOutletsRatingApi(365);
      setMonthlyAvg(calculateOverallAverageRating(yearResponse?.data));

      const yearSum = yearResponse?.data.reduce(
        (sum, feedback) => sum + feedback.totalFeedbacks,
        0
      );
      setTotalYearlyFeedbacksSum(yearSum);
    } catch (e) {
      console.error('Error fetching feedback summary:', e);
    } finally {
      setLoading(false);
    }
  };

  // get all feedback data
  const getFeedbacksDate = async (query) => {
    setIsLoading(true);
    const response = await fetchWithAuth(getApi() + '/feedbacks', { query });
    setIsLoading(false);
    setAllFeedbacks(response);
  };

  useEffect(() => {
    if (datePopup.isSelected === true) {
      getSummaryApi();
      getMonthAndYearRatingApi();
      getFeedbacksDate(datePopup);
    }
  }, [datePopup]);
  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px',
          padding: '7px 0px'
        }}>
        <HeaderTypography
          sx={{
            fontWeight: 600,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
          }}>
          Feedback Dashboard
        </HeaderTypography>
        {/* <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        /> */}
      </Box>
      <MonthYearlyDayBox>
        {/* Branch of Day */}
        <BranchOfMonthBox>
          <TitleBox>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              Branch of the Week
            </HeaderTypography>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              (Branch Name)
            </HeaderTypography>
          </TitleBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}>
              <Rating
                name="read-only"
                value={3.5}
                readOnly
                sx={{
                  fontSize: '2rem'
                }}
              />
              <Box>(3.1)</Box>
            </Box>
          </Box>
          <ProgressBarBox>
            <Box>
              <Typography variant="body2">34%</Typography>
            </Box>
            <Box sx={barStyle}>
              <LinearProgress
                sx={filledBarStyle}
                variant="determinate"
                value={34}
              />
            </Box>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
          </ProgressBarBox>
        </BranchOfMonthBox>

        {/* Branch of Week */}
        <BranchOfMonthBox>
          <TitleBox>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              Branch of the Day
            </HeaderTypography>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              (Branch Name)
            </HeaderTypography>
          </TitleBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}>
              <Rating
                name="read-only"
                value={4}
                readOnly
                sx={{
                  fontSize: '2rem'
                }}
              />
              <Box>(3.6)</Box>
            </Box>
          </Box>
          <ProgressBarBox>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
            <Box sx={barStyle}>
              <LinearProgress
                sx={filledBarStyle}
                variant="determinate"
                value={34}
              />
            </Box>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
          </ProgressBarBox>
        </BranchOfMonthBox>

        {/* Branch of Month */}
        <BranchOfMonthBox>
          <TitleBox>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              Branch of the Month
            </HeaderTypography>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              (Branch Name)
            </HeaderTypography>
          </TitleBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}>
              <Rating
                name="read-only"
                value={4}
                readOnly
                sx={{
                  fontSize: '2rem'
                }}
              />
              <Box>(3.6)</Box>
            </Box>
          </Box>
          <ProgressBarBox>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
            <Box sx={barStyle}>
              <LinearProgress
                sx={filledBarStyle}
                variant="determinate"
                value={34}
              />
            </Box>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
          </ProgressBarBox>
        </BranchOfMonthBox>
        {/* Branch of Yearly */}
        <BranchOfMonthBox>
          <TitleBox>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              Branch of the Year
            </HeaderTypography>
            <HeaderTypography
              sx={{
                fontWeight: 600,
                fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
              }}>
              (Branch Name)
            </HeaderTypography>
          </TitleBox>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}>
              <Rating
                name="read-only"
                value={4}
                readOnly
                sx={{
                  fontSize: '2rem'
                }}
              />
              <Box>(3.6)</Box>
            </Box>
          </Box>
          <ProgressBarBox>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
            <Box sx={barStyle}>
              <LinearProgress
                sx={filledBarStyle}
                variant="determinate"
                value={34}
              />
            </Box>
            <Box>
              <Typography variant="body2">100%</Typography>
            </Box>
          </ProgressBarBox>
        </BranchOfMonthBox>
      </MonthYearlyDayBox>

      <Box
        my={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px',
          paddingRight: '4rem'
        }}>
        {/* <HeaderTypography
          sx={{
            fontWeight: 600,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
          }}>
          Feedback Dashboard
        </HeaderTypography> */}
        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>
      <Meeting dashBoardData={dashBoardData} isLoading={isLoading} />

      <ReviewSection
        totalMonthFeedbacksSum={totalMonthFeedbacksSum}
        totalYearlyFeedbacksSum={totalYearlyFeedbacksSum}
        loading={loading}
        yearlyAvg={yearlyAvg}
        monthlyAvg={monthlyAvg}
      />

      {/* <PublicReviews allFeedbacks={allFeedbacks} /> */}
      {/* <ProgressBar /> */}
    </>
  );
}

const barStyle = {
  //   flex: 1,
  backgroundColor: '#e0e0e0',
  borderRadius: 1,
  overflow: 'hidden',
  margin: '0 10px',
  height: 10,
  width: '50%'
};
const filledBarStyle = {
  height: '100%'
};


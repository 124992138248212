/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Cancel, DeleteOutlined } from '@mui/icons-material';
import {
  addOutletApi,
  editOutletApi,
  getSingleOutletApi
} from '../../api/outlet/outletApi';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { getmenuItemsApi } from '../../api/menuItems/menuItemsApi';
import { currentSeconds } from '../Shared/shared';

const COLORS = ['yellow', 'red', 'orange'];

const getById = (collection, id) => {
  if (!collection) return null;
  for (const item of collection) if (item._id == id) return item;
  return null;
};

const secondsTime = (seconds) => {
  const date = new Date(seconds * 1000);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return hours + ':' + minutes;
};

const initialValues = {
  name: '',
  tag: '',
  code: '',
  color: '',
  address: '',
  email: '',
  contactNumber: '',
  menuItems: [],
  timings: [],
  timeExclusive: false
};

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

const Popup = ({ isOpened = false, handleClose, item }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menuItems'
  });

  const [outlet, setOutlet] = useState();

  const getSingleOutletFunc = async () => {
    const res = await getSingleOutletApi(item);
    setOutlet(res?.data);
    reset(res?.data);
  };

  useEffect(() => {
    if (!isOpened) reset(initialValues);
    else if (item) getSingleOutletFunc();
  }, [item, reset, isOpened]);
  const [isLoading, setLoading] = useState(false);

  const [menuItems, setMenuItems] = useState([]);

  const callApi = async () => {
    const { data } = await getmenuItemsApi();
    setMenuItems(data);
  };

  useEffect(() => {
    callApi();
  }, []);

  const action = item ? 'Update' : 'Add';
  const actionLower = action.toLocaleLowerCase();
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const result = item
      ? await editOutletApi(item, values)
      : await addOutletApi(values);
    setLoading(false);

    if (result?.status == 200) {
      handleClose();
      return dispatch(
        addSnackbar({
          message: `Outlet ${actionLower} successfully`,
          type: 'success'
        })
      );
    }

    setLoading(false);

    return dispatch(
      addSnackbar({
        message: `Unable to ${actionLower} outlet`,
        type: 'error'
      })
    );
  };

  return (
    <Container>
      <Dialog open={isOpened} onClose={handleClose} maxWidth="xs">
        <DialogTitle id="alert-dialog-title">
          <Typography fontWeight="700">{action} Menu Item</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              variant="outlined"
              {...register('name', { required: 'Name is required' })}
              error={!!errors.name}
              helperText={errors?.name?.message}
            />

            <Box display="flex" gap=".5rem">
              <TextField
                fullWidth
                label="Tag"
                margin="dense"
                variant="outlined"
                {...register('tag', { required: 'Tag is required' })}
                error={!!errors.tag}
                helperText={errors?.tag?.message}
              />
              <TextField
                label="Code"
                margin="dense"
                variant="outlined"
                {...register('code', { required: 'Branch Code is required' })}
                error={!!errors.code}
                helperText={errors?.code?.message}
              />
            </Box>
            <TextField
              fullWidth
              label="Address"
              margin="dense"
              variant="outlined"
              {...register('address')}
              error={!!errors.address}
              helperText={errors?.address?.message}
            />
            <Controller
              render={({ field: { onChange, value, ...props } }) => (
                <Autocomplete
                  freeSolo
                  id="combo-box-demo"
                  inputValue={value}
                  onInputChange={(e, data) => onChange(data)}
                  {...props}
                  options={COLORS}
                  renderInput={(params) => (
                    <TextField
                      margin="dense"
                      {...params}
                      label="Color"
                      error={!!errors.color}
                      helperText={errors?.color?.message}
                    />
                  )}
                />
              )}
              name="color"
              control={control}
            />
            <Box display="flex" gap=".5rem">
              <TextField
                fullWidth
                label="Email"
                margin="dense"
                variant="outlined"
                type="email"
                {...register('email')}
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
              <TextField
                fullWidth
                label="Contact Number"
                margin="dense"
                variant="outlined"
                {...register('contactNumber')}
                error={!!errors.contactNumber}
                helperText={errors?.contactNumber?.message}
              />
            </Box>

            {/* ................... */}

            <FormControlLabel
              label="Outlet Timing"
              control={
                <Controller
                  name={`timeExclusive`}
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                border: '1px solid gray',
                borderRadius: '5px',
                padding: '5px'
              }}>
              <FormControlLabel
                label={
                  <Typography fontSize="12px" fontWeight="600">
                    Start Time
                  </Typography>
                }
                control={
                  <Controller
                    name={`timings.0.start`}
                    control={control}
                    render={({ field: props }) => (
                      <input
                        className="time-component"
                        type="time"
                        {...props}
                        value={secondsTime(props.value)}
                        onChange={(e) => {
                          props.onChange(
                            currentSeconds(`1970-01-01T${e.target.value}:00`)
                          );
                        }}
                      />
                    )}
                  />
                }
              />

              <FormControlLabel
                label={
                  <Typography fontSize="12px" fontWeight="600">
                    End Time
                  </Typography>
                }
                control={
                  <Controller
                    name={`timings.0.end`}
                    control={control}
                    render={({ field: props }) => (
                      <input
                        className="time-component"
                        type="time"
                        {...props}
                        value={secondsTime(props.value)}
                        onChange={(e) => {
                          props.onChange(
                            currentSeconds(`1970-01-01T${e.target.value}:00`)
                          );
                        }}
                      />
                    )}
                  />
                }
              />
            </Box>

            {/* ................... */}

            <TextField
              label="Select Menu Item"
              onChange={(e) => {
                if (!e.target.value) return;
                const menuItem = getById(menuItems, e.target.value);
                append({
                  menuItem: menuItem._id,
                  customPrice: false,
                  price: menuItem.price || 0,
                  discount: menuItem.discount || 0,
                  tax: menuItem.tax || 0,
                  inclusiveTax: menuItem.inclusiveTax || true,
                  fixedDiscount: menuItem.fixedDiscount || false,
                  timing: {
                    start: 0,
                    end: 0
                  },
                  timeExclusive: false,
                  dayExclusive: false,
                  days: []
                });
              }}
              fullWidth
              select
              defaultValue=""
              margin="dense">
              {menuItems
                ?.filter(
                  (menuItem) =>
                    !fields.map((v) => v.menuItem).includes(menuItem._id)
                )
                ?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <Button
              onClick={() => {
                menuItems
                  ?.filter(
                    (menuItem) =>
                      !fields.map((v) => v.menuItem).includes(menuItem._id)
                  )
                  ?.forEach((menuItem) => {
                    append({
                      menuItem: menuItem._id,
                      customPrice: false,
                      price: menuItem.price || 0,
                      discount: menuItem.discount || 0,
                      tax: menuItem.tax || 0,
                      inclusiveTax: menuItem.inclusiveTax || true,
                      fixedDiscount: menuItem.fixedDiscount || false
                    });
                  });
              }}>
              check all
            </Button>
            <Box>
              {fields.map((menuItem, index) => (
                <Box
                  sx={{
                    border: '1px solid gray',
                    margin: '10px 0',
                    borderRadius: '7px',
                    padding: '0 5px'
                  }}
                  key={index}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}>
                    <Tooltip title="Delete Outlet">
                      <IconButton onClick={() => remove(index)}>
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box display="flex" gap=".5rem">
                    <Typography>
                      {getById(menuItems, menuItem.menuItem)?.name}
                    </Typography>

                    <FormControlLabel
                      label="Custom Time"
                      control={
                        <Controller
                          name={`menuItems.${index}.timeExclusive`}
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Custom Days"
                      control={
                        <Controller
                          name={`menuItems.${index}.daysExclusive`}
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                    />

                    <FormControlLabel
                      label="Custom Price"
                      control={
                        <Controller
                          name={`menuItems.${index}.customPrice`}
                          control={control}
                          render={({ field: props }) => (
                            <Checkbox
                              {...props}
                              checked={props.value}
                              onChange={(e) => props.onChange(e.target.checked)}
                            />
                          )}
                        />
                      }
                    />
                  </Box>
                  {watch(`menuItems.${index}.daysExclusive`) && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: '.5rem',
                          border: '1px solid gray',
                          borderRadius: '5px',
                          padding: '5px'
                        }}>
                        <Controller
                          control={control}
                          name={`menuItems.${index}.days`}
                          defaultValue={[]}
                          render={({ field }) => (
                            <FormControl fullWidth sx={{ margin: '10px 0' }}>
                              <InputLabel>Days</InputLabel>
                              <Select
                                multiple
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                                input={<OutlinedInput label="days" />}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      gap: 0.5
                                    }}>
                                    {selected.map((value, i) => (
                                      <Chip
                                        key={i}
                                        label={days[value]}
                                        clickable
                                        deleteIcon={
                                          <Cancel
                                            onMouseDown={(event) =>
                                              event.stopPropagation()
                                            }
                                          />
                                        }
                                        onDelete={() => {
                                          const updatedValue =
                                            field.value.filter(
                                              (day) => day !== value
                                            );

                                          field.onChange(updatedValue);
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )}>
                                {days.map(
                                  (day, index) =>
                                    !field.value.includes(index) && (
                                      <MenuItem key={index} value={index}>
                                        {day}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Box>
                    </>
                  )}

                  {watch(`menuItems.${index}.timeExclusive`) && (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: '.5rem',
                          border: '1px solid gray',
                          borderRadius: '5px',
                          padding: '5px'
                        }}>
                        <FormControlLabel
                          label="Start Time"
                          control={
                            <Controller
                              name={`menuItems.${index}.timing.start`}
                              control={control}
                              render={({ field: props }) => (
                                <input
                                  type="time"
                                  {...props}
                                  value={secondsTime(props.value)}
                                  onChange={(e) => {
                                    props.onChange(
                                      currentSeconds(
                                        `1970-01-01T${e.target.value}:00`
                                      )
                                    );
                                  }}
                                />
                              )}
                            />
                          }
                        />

                        <FormControlLabel
                          label="End Time"
                          control={
                            <Controller
                              name={`menuItems.${index}.timing.end`}
                              control={control}
                              render={({ field: props }) => (
                                <input
                                  type="time"
                                  {...props}
                                  value={secondsTime(props.value)}
                                  onChange={(e) => {
                                    props.onChange(
                                      currentSeconds(
                                        `1970-01-01T${e.target.value}:00`
                                      )
                                    );
                                  }}
                                />
                              )}
                            />
                          }
                        />
                      </Box>
                    </>
                  )}

                  {watch(`menuItems.${index}.customPrice`) && (
                    <>
                      <Box display="flex" justifyContent="center" gap=".5rem">
                        <TextField
                          fullWidth
                          label="Price"
                          margin="dense"
                          type="Number"
                          variant="outlined"
                          {...register(`menuItems.${index}.price`, {
                            required: 'Everybody got a price, what is yours?'
                          })}
                          error={
                            errors.menuItems && errors.menuItems[index]?.price
                          }
                          helperText={
                            errors.menuItems &&
                            errors.menuItems[index]?.price?.message
                          }
                        />
                        <TextField
                          fullWidth
                          label="Discount"
                          type="Number"
                          margin="dense"
                          variant="outlined"
                          {...register(`menuItems.${index}.discount`, {
                            required: 'Put it on discount'
                          })}
                          error={
                            errors.menuItems &&
                            errors.menuItems[index]?.discount
                          }
                          helperText={
                            errors.menuItems &&
                            errors.menuItems[index]?.discount?.message
                          }
                        />

                        <FormControlLabel
                          label="Fixed"
                          control={
                            <Controller
                              name={`menuItems.${index}.fixedDiscount`}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Box>
                      <Box display="flex" justifyContent="center" gap=".5rem">
                        <TextField
                          fullWidth
                          label="Tax"
                          margin="dense"
                          type="Number"
                          variant="outlined"
                          {...register(`menuItems.${index}.tax`, {
                            required: 'Taxes not gonna do themselves'
                          })}
                          error={
                            errors.menuItems && errors.menuItems[index]?.tax
                          }
                          helperText={
                            errors.menuItems &&
                            errors.menuItems[index]?.tax?.message
                          }
                        />

                        <FormControlLabel
                          label="Inclusive"
                          control={
                            <Controller
                              name={`menuItems.${index}.inclusiveTax`}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Box>
                    </>
                  )}
                </Box>
              ))}
            </Box>

            <Box gap="1rem" justifyContent="center" display="flex">
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={isLoading}
                fullWidth>
                {isLoading ? (
                  <>
                    <CircularProgress size={24} color="secondary" />
                    {action}ing
                  </>
                ) : (
                  `${action} Outlet`
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                fullWidth>
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};
export default Popup;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Delete, Edit } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { TopBox } from '../../Shared/shared';
// import PurchaseDialog from './PurchaseDialog';
// import { getExpenseApi } from '../../../api/stocks/purchases/purchasesApi';
import ExpenseDialog from './ExpenseDialog';
import { getExpensesApi } from '../../../api/stocks/expenses/expensesApi';

export default function Expense() {
  const [openDialog, setOpenDialog] = useState({ isOpen: false, id: '' });

  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [expenseData, setExpenseData] = useState([]);

  const getExpense = async () => {
    const res = await getExpensesApi(outlet._id);
    setExpenseData(res.data);
  };

  // handling inner tables
  const [openRows, setOpenRows] = useState(
    Array(expenseData.length).fill(false)
  );

  const handleRowClick = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const callFunc = () => {
    getExpense();
  };

  useEffect(() => {
    getExpense();
  }, []);

  return (
    <>
      <TopBox>
        <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
          Expense Managment
        </Typography>
        <Tooltip title="Add New Purchase">
          <Button
            style={{
              backgroundColor: '#d082f1',
              padding: ' 10px 30px'
            }}
            onClick={() => {
              setOpenDialog({ isOpen: true });
            }}>
            <AddIcon />
            <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
              Add Expense
            </Typography>
          </Button>
        </Tooltip>
      </TopBox>

      {!expenseData ? (
        <Box
          sx={{
            height: 'max-content'
          }}>
          {[...Array(10)].map((arr, i) => (
            <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ background: '#d082f1', color: 'white' }}>
              <TableRow>
                <TableCell>
                  <RemoveRedEyeIcon />
                </TableCell>
                <TableCell>User</TableCell>
                <TableCell>Sale Date</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenseData
                ?.slice(pg * rpg, pg * rpg + rpg)
                .map((singleSet, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            width="0px"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(index);
                            }}>
                            {openRows[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{singleSet.user?.name}</TableCell>
                        <TableCell>{singleSet.saleDate}</TableCell>
                        <TableCell sx={{ maxWidth: '110px' }}>
                          <Tooltip
                            title={
                              <Typography fontSize={15}>
                                {singleSet.desc}
                              </Typography>
                            }
                            placement="top">
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '12px'
                              }}>
                              {singleSet.desc}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            sx={{ color: '#d082f1' }}
                            onClick={() => {
                              setOpenDialog({
                                isOpen: true,
                                id: singleSet._id
                              });
                            }}>
                            <Edit />
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}>
                          <Collapse
                            in={openRows[index]}
                            timeout="auto"
                            unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div">
                                Expenses
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: '17px'
                                      }}>
                                      Expense
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: '17px'
                                      }}>
                                      Value
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: '17px'
                                      }}>
                                      Employee ID
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontWeight: 700,
                                        fontSize: '17px'
                                      }}>
                                      Remarks
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {singleSet.expenses.map(
                                    (historyRow, index) => {
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {historyRow?.expenseCategory?.name}
                                          </TableCell>
                                          <TableCell>
                                            {historyRow?.value}
                                          </TableCell>

                                          {historyRow?.employeeId && (
                                            <TableCell>
                                              {historyRow?.employeeId}
                                            </TableCell>
                                          )}

                                          <TableCell>
                                            {historyRow.remarks}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={expenseData?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ExpenseDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        outletId={outlet?._id}
        callFunc={callFunc}
      />
    </>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { SubmitButton, TextFields } from './Styled-components';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import {
  editIngredientApi,
  getSingleIngredientsApi
} from '../../api/ingredients/ingredientsApi';

const EditIngredient = ({
  open,
  setOpen,
  callFunc,
  ingredientCategories,
  ingredientUnits
}) => {
  const { register } = useForm();

  const [name, setName] = useState('');
  const [itemValue, setItemValue] = useState('');
  const [alertQuantity, setAlertQuantity] = useState('');
  // drop down Category
  const [category, setCategory] = useState('');
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  // drop down Category
  // drop down unit
  const [unit, setUnit] = useState('');
  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };
  // drop down unit
  const [update, setUpdate] = useState('');

  const handleClose = () => {
    setOpen({ isOpen: false });
    clearForm();
  };

  const handleUpdate = async (id) => {
    setUpdate(id);
    let myObj;
    const res = await getSingleIngredientsApi(id);

    if (id) {
      myObj = res;
    }

    setName(myObj?.name || '');
    setItemValue(myObj?.value || '');
    setAlertQuantity(myObj?.alertQuantity);
    setCategory(myObj?.category || '');
    setUnit(myObj?.unit || '');
  };

  const clearForm = () => {
    setName('');
    setItemValue('');
    setAlertQuantity('');
    setCategory('');
    setUnit('');
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const formData = {
      name: data.get('item-name'),
      value: data.get('itemValue'),
      alertQuantity: data.get('alertQuantity'),
      category: data.get('select-category'),
      unit: data.get('select-unit'),
      item_id: update
    };

    try {
      const response = await editIngredientApi(formData);
    } catch (error) {}

    handleClose();
    callFunc();
  };

  useEffect(() => {
    handleUpdate(open.id);
  }, [open, ingredientCategories, ingredientUnits]);
  return (
    <Box>
      <Container>
        <Dialog
          open={open.isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              <Typography color="#6d1b7b" fontWeight="700">
                Edit Ingredient
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={(e) => handleSubmitForm(e)}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <TextFields
                    type="text"
                    placeholder="Enter Item Name"
                    {...register('itemName')}
                    name="item-name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    fullWidth
                    required
                  />

                  <FormControl sx={{ m: 1, width: '100%' }} size="large">
                    <InputLabel>Category</InputLabel>
                    <Select
                      defaultValue=""
                      value={category}
                      {...register('select-category')}
                      label="Category"
                      onChange={handleChangeCategory}>
                      {ingredientCategories?.data.map((cat, index) => (
                        <MenuItem key={index} value={cat._id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '100%' }} size="large">
                    <InputLabel>Unit</InputLabel>
                    <Select
                      defaultValue=""
                      {...register('select-unit')}
                      value={unit}
                      label="Unit"
                      onChange={handleChangeUnit}>
                      {ingredientUnits?.map((unitItem, index) => (
                        <MenuItem key={index} value={unitItem._id}>
                          {unitItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextFields
                    type="number"
                    placeholder="Enter Value"
                    {...register('itemValue')}
                    onChange={(e) => setItemValue(e.target.value)}
                    value={itemValue}
                    fullWidth
                    required
                  />

                  <TextFields
                    type="number"
                    placeholder="Enter Alert Quantity"
                    {...register('alertQuantity')}
                    onChange={(e) => setAlertQuantity(e.target.value)}
                    value={alertQuantity}
                    fullWidth
                    required
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px',
                    width: '100%'
                  }}>
                  <SubmitButton type="submit">Update</SubmitButton>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default EditIngredient;

import { fetchWithAuth } from '../refreshTokenApi';
import areaUrls from './areaUrl';

// get All Areas API
export const getAreasApi = async () => {
  const result = await fetchWithAuth(`${areaUrls.areas}/names`);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// Add Area API
export const addAreasApi = async (AreaData) => {
  const result = await fetchWithAuth(`${areaUrls.areas}`, {
    body: AreaData,
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// delete Area API
export const deleteAreasApi = async (AreaId) => {
  const result = await fetchWithAuth(`${areaUrls.areas}/${AreaId}`, {
    method: 'DELETE'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
// import { StyledTableCell, TableButton } from './Styled-components';
import { useDispatch } from 'react-redux';
import AddEditDisplayScreen from './AddEditDisplayScreen';
import { StyledTableCell, TableButton } from './Styled-component';
import { getDisplayScreensApi } from '../../api/displayscreens/displayScreenApi';

export default function DisplayScreenManagment() {
  const dispatch = useDispatch();
  // handling state and handleChange for tab component
  const [addDisplayScreenPopup, setAddDisplayScreenPopup] = useState({
    isOpen: false,
    id: ''
  });

  const [displayScreens, setDisplayScreens] = useState();

  // calling APIs
  async function displayScreenApi() {
    const res = await getDisplayScreensApi();
    setDisplayScreens(res.data);
  }
  //
  async function callFunc() {
    displayScreenApi();
  }
  useEffect(() => {
    displayScreenApi();
  }, []);

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredIngredients = displayScreens?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.name === 'none' ||
      report?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  return (
    <>
      <Box>
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              label="Search Display Screen"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Tooltip title="Add New Display Screen">
              <TableButton
                style={{
                  padding: ' 10px 30px'
                }}
                onClick={() => {
                  setAddDisplayScreenPopup({ isOpen: true });
                }}>
                <AddIcon />
                <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                  Add Display Screen
                </Typography>
              </TableButton>
            </Tooltip>
          </Box>

          <TableContainer size="small" aria-label="collapsible table">
            <Table>
              <TableHead sx={{ background: '#6d1b7b' }}>
                <TableRow>
                  <StyledTableCell>Sr#</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell colSpan={2}>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredIngredients
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    return (
                      <TableRow key={row._id}>
                        <TableCell align="center" component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row?.desc}</TableCell>

                        <TableCell
                          colSpan={2}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}>
                          <Button
                            onClick={() => {
                              setAddDisplayScreenPopup({
                                isOpen: true,
                                id: row._id
                              });
                            }}>
                            <Delete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    count={filteredIngredients?.length || 0}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </Box>

      <AddEditDisplayScreen
        addDisplayScreenPopup={addDisplayScreenPopup}
        setAddDisplayScreenPopup={setAddDisplayScreenPopup}
        callFunc={callFunc}
      />
    </>
  );
}

import { all } from 'redux-saga/effects';
import { watchAuthSagas } from './sagas/AuthorizeSaga';
import { watchIngredientSetSagas } from './sagas/IngredientSetSaga';
import { watchIngredientSagas } from './sagas/IngredientsSaga';
import { watchChoicesSagas } from './sagas/ChoicesSaga';
import { watchMenuItemsSagas } from './sagas/MenuItemsSaga';
import { watchMenuCategorySagas } from './sagas/MenuCategorySaga';
import { watchOutletMenuSagas } from './sagas/OutletSaga';
import { watchRunningOrdersSagas } from './sagas/OrdersSaga';
import { watchDisplayScreenSagas } from './sagas/DisplaySaga';
import { watchDisplayScreenOrdersSagas } from './sagas/DisplayScreenOrdersSaga';

export default function* rootSaga() {
  yield all([
    watchIngredientSagas(),
    watchAuthSagas(),
    watchIngredientSetSagas(),
    watchChoicesSagas(),
    watchMenuItemsSagas(),
    watchMenuCategorySagas(),
    watchOutletMenuSagas(),
    watchRunningOrdersSagas(),
    watchDisplayScreenSagas(),
    watchDisplayScreenOrdersSagas()
  ]);
}

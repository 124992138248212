import { tokens } from '../../theme';

// *********************Canceled orders count and sale data ************************
const initialTotal = { canceledSale: 0, totalCanceledOrderCount: 0 };
let canceledSale = initialTotal.canceledSale;
let totalCanceledOrderCount = initialTotal.totalCanceledOrderCount;

export function calculateCancelledData(dashboardData) {
  if (dashboardData && dashboardData.cancelled) {
    const canceledData = dashboardData.cancelled.reduce((accumulator, item) => {
      accumulator.canceledSale += item.sales;
      accumulator.totalCanceledOrderCount += item.count;
      return accumulator;
    }, initialTotal);

    canceledSale = canceledData.canceledSale;
    totalCanceledOrderCount = canceledData.totalCanceledOrderCount;
  }

  return { canceledSale, totalCanceledOrderCount };
}

// ***************** total order

export function generateOutletSalesData(outlets, dashboardData) {
  // Function to generate a random HSL color
  function getRandomHSLColor() {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 50%)`;
  }

  // Create an array to store the data for each outlet
  const outletSalesData =
    outlets?.map((outlet) => {
      const totalSales = dashboardData?.hourly?.reduce((sum, hourlyData) => {
        const outletData = hourlyData?.outlets?.find((o) => o?.name === outlet);
        return sum + (outletData ? outletData?.sales : 0);
      }, 0);

      return {
        id: outlet,
        label: outlet,
        value: totalSales,
        color: getRandomHSLColor()
      };
    }) || [];

  return outletSalesData;
}
// *********************total orders count and sale data ************************

export function calculateTotalSalesAndOrders(data) {
  let totalSales = 0;
  let totalOrderCount = 0;

  if (data && Array.isArray(data.hourly)) {
    // reduce to iterate through the hourly data
    data.hourly.reduce((accumulator, hourlyData) => {
      if (hourlyData.outlets && Array.isArray(hourlyData.outlets)) {
        // Iterate through the outlets for each hourly data
        hourlyData.outlets.forEach((outletData) => {
          if (outletData.count) {
            totalOrderCount += outletData.count;
          }
          if (outletData.sales) {
            totalSales += outletData.sales;
          }
        });
      }
      return accumulator;
    }, null);
  }

  return {
    totalSales,
    totalOrderCount
  };
}

// **************************

export const getAllOutlets = (dashboardData) => {
  const outletsToTransform = [
    ...new Set(
      dashboardData?.hourly?.flatMap((hourlyData) =>
        hourlyData?.outlets?.map((outlet) => outlet?.name)
      )
    )
  ];
  // Sort the outlet names alphabetically
  outletsToTransform?.sort();
  return outletsToTransform;
};

export const commonColor = (api) => {
  if (api >= 70 && api <= 100) {
    return '#CCCCCC';
  } else {
    return '#F5F5F5';
  }
};

export const unCommonColor = (api) => {
  if (api >= 40 && api <= 70) {
    return '#AFCBFF';
  } else {
    return '#F5F5F5';
  }
};

export const rareColor = (api) => {
  if (api >= 20 && api <= 40) {
    return '#3BBA6C';
  } else {
    return '#F5F5F5';
  }
};

export const epicColor = (api) => {
  if (api >= 5 && api <= 20) {
    return '#F19B11';
  } else {
    return '#F5F5F5';
  }
};
export const legendaryColor = (api) => {
  if (api >= 0 && api <= 5) {
    return '#EFC20E';
  } else {
    return '#F5F5F5';
  }
};

export const mockPieData = [
  {
    id: 'main branch',
    label: 'Main',
    value: 598778,
    color: 'hsl(104, 70%, 50%)'
  },
  {
    id: 'eden garden',
    label: 'Eden',
    value: 498778,
    color: 'hsl(162, 70%, 50%)'
  },
  {
    id: 'Jinah Colony',
    label: 'Jinah',
    value: 698778,
    color: 'hsl(291, 70%, 50%)'
  },
  {
    id: 'Millat Chowk',
    label: 'Millat',
    value: 418778,
    color: 'hsl(229, 70%, 50%)'
  },
  {
    id: 'Daewoo',
    label: 'Daewoo',
    value: 198778,
    color: 'hsl(344, 70%, 50%)'
  },
  {
    id: 'Sheikhpura',
    label: 'SKP',
    value: 298778,
    color: 'hsl(344, 70%, 50%)'
  }
];

export const mockLineData = [
  {
    id: 'Daewoo',
    color: tokens('dark').greenAccent[500],
    data: [
      {
        x: '10:00',
        y: 101
      },
      {
        x: '11:00',
        y: 75
      },
      {
        x: '12:00',
        y: 36
      },

      {
        x: '13:00',
        y: 216
      },
      {
        x: '14:00',
        y: 36
      }
    ]
  }
  // {
  //   id: 'Jinnah Colony',
  //   color: tokens('dark').blueAccent[300],
  //   data: [
  //     {
  //       x: '10:00',
  //       y: 212
  //     },
  //     {
  //       x: '11:00',
  //       y: 190
  //     },
  //     {
  //       x: '12:00',
  //       y: 270
  //     },
  //     {
  //       x: '13:00',
  //       y: 9
  //     },
  //     {
  //       x: '14:00',
  //       y: 75
  //     },
  //     {
  //       x: '15:00',
  //       y: 175
  //     },
  //     {
  //       x: '16:00',
  //       y: 33
  //     },
  //     {
  //       x: '17:00',
  //       y: 189
  //     },
  //     {
  //       x: '18:00',
  //       y: 97
  //     },
  //     {
  //       x: '19:00',
  //       y: 87
  //     },
  //     {
  //       x: '20:00',
  //       y: 299
  //     },
  //     {
  //       x: '22:00',
  //       y: 251
  //     },
  //     {
  //       x: '23:00',
  //       y: 251
  //     },
  //     {
  //       x: '24:00',
  //       y: 251
  //     },
  //     {
  //       x: '00:00',
  //       y: 251
  //     },
  //     {
  //       x: '01:00',
  //       y: 251
  //     },
  //     {
  //       x: '02:00',
  //       y: 251
  //     },
  //     {
  //       x: '03:00',
  //       y: 251
  //     }
  //   ]
  // },
  // {
  //   id: 'Eden Garden',
  //   color: tokens('dark').redAccent[200],
  //   data: [
  //     {
  //       x: '10:00',
  //       y: 191
  //     },
  //     {
  //       x: '11:00',
  //       y: 136
  //     },
  //     {
  //       x: '12:00',
  //       y: 91
  //     },
  //     {
  //       x: '13:00',
  //       y: 190
  //     },
  //     {
  //       x: '14:00',
  //       y: 211
  //     },
  //     {
  //       x: '15:00',
  //       y: 152
  //     },
  //     {
  //       x: '16:00',
  //       y: 189
  //     },
  //     {
  //       x: '17:00',
  //       y: 152
  //     },
  //     {
  //       x: '18:00',
  //       y: 8
  //     },
  //     {
  //       x: '19:00',
  //       y: 197
  //     },
  //     {
  //       x: '20:00',
  //       y: 107
  //     },
  //     {
  //       x: '22:00',
  //       y: 170
  //     },
  //     {
  //       x: '23:00',
  //       y: 251
  //     },
  //     {
  //       x: '24:00',
  //       y: 251
  //     },
  //     {
  //       x: '00:00',
  //       y: 251
  //     },
  //     {
  //       x: '01:00',
  //       y: 251
  //     },
  //     {
  //       x: '02:00',
  //       y: 251
  //     },
  //     {
  //       x: '03:00',
  //       y: 251
  //     }
  //   ]
  // },
  // {
  //   id: 'Satyana Rd.',
  //   color: tokens('dark').redAccent[200],
  //   data: [
  //     {
  //       x: '10:00',
  //       y: 191
  //     },
  //     {
  //       x: '11:00',
  //       y: 136
  //     },
  //     {
  //       x: '12:00',
  //       y: 91
  //     },
  //     {
  //       x: '13:00',
  //       y: 190
  //     },
  //     {
  //       x: '14:00',
  //       y: 211
  //     },
  //     {
  //       x: '15:00',
  //       y: 152
  //     },
  //     {
  //       x: '16:00',
  //       y: 189
  //     },
  //     {
  //       x: '17:00',
  //       y: 152
  //     },
  //     {
  //       x: '18:00',
  //       y: 8
  //     },
  //     {
  //       x: '19:00',
  //       y: 197
  //     },
  //     {
  //       x: '20:00',
  //       y: 107
  //     },
  //     {
  //       x: '22:00',
  //       y: 170
  //     },
  //     {
  //       x: '23:00',
  //       y: 251
  //     },
  //     {
  //       x: '24:00',
  //       y: 251
  //     },
  //     {
  //       x: '00:00',
  //       y: 251
  //     },
  //     {
  //       x: '01:00',
  //       y: 251
  //     },
  //     {
  //       x: '02:00',
  //       y: 251
  //     },
  //     {
  //       x: '03:00',
  //       y: 251
  //     }
  //   ]
  // },
  // {
  //   id: 'Sheikhupura',
  //   color: tokens('dark').redAccent[200],
  //   data: [
  //     {
  //       x: '10:00',
  //       y: 191
  //     },
  //     {
  //       x: '11:00',
  //       y: 136
  //     },
  //     {
  //       x: '12:00',
  //       y: 91
  //     },
  //     {
  //       x: '13:00',
  //       y: 190
  //     },
  //     {
  //       x: '14:00',
  //       y: 211
  //     },
  //     {
  //       x: '15:00',
  //       y: 152
  //     },
  //     {
  //       x: '16:00',
  //       y: 189
  //     },
  //     {
  //       x: '17:00',
  //       y: 152
  //     },
  //     {
  //       x: '18:00',
  //       y: 8
  //     },
  //     {
  //       x: '19:00',
  //       y: 197
  //     },
  //     {
  //       x: '20:00',
  //       y: 107
  //     },
  //     {
  //       x: '22:00',
  //       y: 170
  //     },
  //     {
  //       x: '23:00',
  //       y: 251
  //     },
  //     {
  //       x: '24:00',
  //       y: 251
  //     },
  //     {
  //       x: '00:00',
  //       y: 251
  //     },
  //     {
  //       x: '01:00',
  //       y: 251
  //     },
  //     {
  //       x: '02:00',
  //       y: 251
  //     },
  //     {
  //       x: '03:00',
  //       y: 251
  //     }
  //   ]
  // },
  // {
  //   id: 'Millat Rd.',
  //   color: tokens('dark').redAccent[800],
  //   data: [
  //     {
  //       x: '10:00',
  //       y: 200
  //     },
  //     {
  //       x: '11:00',
  //       y: 136
  //     },
  //     {
  //       x: '12:00',
  //       y: 91
  //     },
  //     {
  //       x: '13:00',
  //       y: 190
  //     },
  //     {
  //       x: '14:00',
  //       y: 211
  //     },
  //     {
  //       x: '15:00',
  //       y: 152
  //     },
  //     {
  //       x: '16:00',
  //       y: 189
  //     },
  //     // {
  //     //   x: '17:00',
  //     //   y: 152
  //     // },
  //     // {
  //     //   x: '18:00',
  //     //   y: 8
  //     // },
  //     // {
  //     //   x: '19:00',
  //     //   y: 197
  //     // },
  //     // {
  //     //   x: '20:00',
  //     //   y: 107
  //     // },
  //     // {
  //     //   x: '22:00',
  //     //   y: 170
  //     // },
  //     // {
  //     //   x: '23:00',
  //     //   y: 251
  //     // },
  //     // {
  //     //   x: '24:00',
  //     //   y: 251
  //     // },
  //     // {
  //     //   x: '00:00',
  //     //   y: 251
  //     // },
  //     // {
  //     //   x: '01:00',
  //     //   y: 251
  //     // },
  //     // {
  //     //   x: '02:00',
  //     //   y: 251
  //     // },
  //     // {
  //     //   x: '03:00',
  //     //   y: 251
  //     // }
  //   ]
  // }
];

export const TopSellingItem = [
  {
    img: 'https://cdn.eateasily.com/restaurants/profile/app/400X300/16424.jpg',
    img1: 'https://images.deliveryhero.io/image/fd-pk/LH/u4bl-hero.jpg',
    name: 'Special Square Large Pizza',
    price: '15487'
  },
  {
    img: 'https://images.deliveryhero.io/image/fd-pk/Products/6097580.jpg?width=%s',
    img1: 'https://whitescastle.netlify.app/images/menu/square-pizza.jpg',
    name: 'Square Deal',
    price: '15487'
  },
  {
    img: 'https://images.deliveryhero.io/image/talabat/Menuitems/mmw_638123115351830900?width=172&amp;height=172',
    img1: 'https://images.deliveryhero.io/image/fd-pk/Products/1965254.jpg?width=%s',
    name: 'Special Square Small Pizza',
    price: '15487'
  }
];


import axios from 'axios';
import { refreshAccessToken } from '../../refreshTokenApi';
import wasteUrls from './wasteUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// Add Waste API
export const addWasteApi = async (wasteData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(`${wasteUrls.wastes}`, wasteData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${wasteUrls.wastes}`,
        wasteData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Waste API
export const editWasteApi = async (wasteId, wasteData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${wasteUrls.wastes}/${wasteId}`,
      wasteData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${wasteUrls.wastes}/${wasteId}`,
        wasteData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Outlet waste API
export const getWastesApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${wasteUrls.outletWastes}/${outletId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${wasteUrls.outletWastes}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get single Waste
export const getSingleWasteApi = async (wasteId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(`${wasteUrls.wastes}/${wasteId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(`${wasteUrls.wastes}/${wasteId}`, newConfig);
      return data;
    } else {
      return error;
    }
  }
};

// Add Item Waste API
export const addItemWasteApi = async (ItemWasteData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${wasteUrls.itemWaste}`,
      ItemWasteData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${wasteUrls.itemWaste}`,
        ItemWasteData,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

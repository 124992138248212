import React, { useEffect, useState } from 'react';
import {
  BackgroundImageBox,
  GetAndSubmitButton,
  StyledTextField
} from './Styled-components';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import {
  createFeedbackApi,
  getOrderFromInvoiceApi
} from '../../api/feedback/feedbackApi';
import { useLocation, useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { useDispatch } from 'react-redux';
import LocalPizzaSharpIcon from '@mui/icons-material/LocalPizzaSharp';

import { ReactComponent as PizzaSVG } from '../../assets/pizza1.svg';
import { ratingData } from './keys';

const labels = {
  0.5: 'Useless',
  1: 'Useless',
  1.5: 'Poor',
  2: 'Poor',
  2.5: 'Ok',
  3: 'Ok',
  3.5: 'Good',
  4: 'Good',
  4.5: 'Excellent',
  5: 'Excellent'
};

export default function Feedback() {
  const navigate = useNavigate();
  // Initial state with all form fields
  const [formValues, setFormValues] = useState({
    invoiceId: '',
    customerName: '',
    customerContact: '',
    numberOfGuests: '',
    email: '',
    visitDate: '',
    comments: '',
    ...ratingData.reduce((acc, item) => {
      acc[item.key] = '';
      return acc;
    }, {})
  });
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [invoiceId, setInvoiceId] = useState('');
  const [hover, setHover] = useState({});

  const dispatch = useDispatch();
  // get order invoice from liveOrder
  const location = useLocation();
  const { liveOrderId, orderDetail, outlet } = location.state || {};

  // Handle input changes
  const handleInputChange = (event, ratingName) => {
    const { name, value, type } = event.target;

    if (
      type === 'number' ||
      type === 'textarea' ||
      type === 'text' ||
      type === 'email' ||
      type === 'date' ||
      type === 'tel'
    ) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }));
    } else if (type === 'radio') {
      setFormValues((prevValues) => ({
        ...prevValues,
        [ratingName]: parseInt(value)
      }));
    }
  };

  // Get InvoiceId from input field
  const handleInvoiceChange = (event) => {
    setInvoiceId(event.target.value);
  };
  // get single Order Api calling
  const getSingleOrder = async (id) => {
    try {
      setIsLoading(true);
      // const res = await getOrderFromInvoiceApi(id);
      const res = await getOrderFromInvoiceApi(id);

      if (res?.data) {
        setOrder(res?.data);
        dispatch(
          addSnackbar({
            message: 'Order data fetched',
            type: 'success'
          })
        );
      } else {
        dispatch(
          addSnackbar({
            message: 'Order not found!',
            type: 'warning'
          })
        );
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          message: 'Error fetching order',
          type: 'error'
        })
      );
      console.error('Error fetching order:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch order and update form fields when invoiceId changes
  const handleButtonClick = () => {
    getSingleOrder(invoiceId);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const formData = { ...formValues, invoiceId: invoiceId };
      const res = await createFeedbackApi(formData);
      if (res.status === 200) {
        dispatch(
          addSnackbar({
            message: 'FeedBack Submitted Successfully',
            type: 'success'
          })
        );
        navigate('/feedback-submission');
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (order || orderDetail) {
      setFormValues((prevFormValues) => ({
        customerName:
          order?.customerName ||
          orderDetail?.customerName ||
          prevFormValues.customerName ||
          '',
        customerContact:
          order?.customerContact ||
          orderDetail?.customerContact ||
          prevFormValues.customerContact ||
          '',
        outlet: order?.outlet?._id || outlet || prevFormValues.outlet || '',
        order: order?._id || orderDetail?._id || prevFormValues.order || '',
        address:
          order?.deliveryAddress ||
          orderDetail?.deliveryAddress ||
          prevFormValues.address ||
          '',
        visitDate: order?.orderTime
          ? order.orderTime.split('T')[0]
          : orderDetail?.orderTime
          ? orderDetail.orderTime.split('T')[0]
          : prevFormValues.visitDate || ''
      }));
      setInvoiceId((prev) => orderDetail?.invoiceId || prev.invoiceId || '');
    }
  }, [order, orderDetail, liveOrderId]);

  return (
    <>
      <Container
        // maxWidth="lg"
        maxWidth={false}
        sx={{
          minHeight: '100vh',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
        <BackgroundImageBox>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Typography variant="h5" align="center" component="h1" gutterBottom>
              Feedback
            </Typography>
          </Box>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              lg={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '13px',
                width: '100%'
              }}>
              <StyledTextField
                label="Invoice ID"
                variant="outlined"
                name="invoiceId"
                value={invoiceId}
                onChange={handleInvoiceChange}
                required
                fullWidth
                InputProps={{
                  sx: {
                    height: 48
                  }
                }}
                // sx={{
                //   width: '100%'
                // }}
              />
              {/* </Grid> */}
              {/* <Grid
              item
              sm={4}
              // lg={2}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: '13px',
                width: '100%'
              }}> */}
              {liveOrderId ? (
                ''
              ) : (
                <Button
                  style={{
                    padding: '12px 0px',
                    backgroundColor: '#4caf50',
                    width: '40%',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                  onClick={handleButtonClick}
                  variant="outlined">
                  {isloading ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                      Getting Order... <CircularProgress size={15} />
                    </div>
                  ) : (
                    'Get Order'
                  )}
                </Button>
              )}
            </Grid>
            {/* </Grid> */}
            {/* <Grid container spacing={4}> */}
            <Grid item xs={12} sm={6} lg={3}>
              <StyledTextField
                label="Name"
                variant="outlined"
                type="text"
                name="customerName"
                value={formValues.customerName}
                onChange={handleInputChange}
                required
                fullWidth
                InputProps={{ sx: { height: 48 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StyledTextField
                label="Phone"
                variant="outlined"
                type="tel"
                name="customerContact"
                value={formValues.customerContact}
                onChange={handleInputChange}
                required
                fullWidth
                InputProps={{ sx: { height: 48 } }}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <StyledTextField
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                fullWidth
                InputProps={{ sx: { height: 48 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <StyledTextField
                label="Visit Date"
                variant="outlined"
                type="date"
                name="visitDate"
                value={formValues.visitDate}
                onChange={handleInputChange}
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{ sx: { height: 48 } }}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <StyledTextField
                label="Number Of Guests"
                variant="outlined"
                type="number"
                name="numberOfGuests"
                value={formValues.numberOfGuests}
                onChange={handleInputChange}
                required
                fullWidth
                InputProps={{ sx: { height: 48 } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                label="Comments"
                variant="outlined"
                multiline
                rows={4}
                name="comments"
                type="textarea"
                value={formValues.comments}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Box style={{ overflow: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">Category</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">Rating</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratingData?.map(({ key, name }) => (
                  <TableRow key={key}>
                    <TableCell>
                      <Typography variant="body1">{name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: 200,
                          display: 'flex',
                          alignItems: {
                            xs: 'start',
                            sm: 'start',
                            md: 'center'
                          },
                          flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row'
                          }
                        }}>
                        <Rating
                          name={key}
                          value={formValues[key] || 0}
                          onChange={(event, newValue) => {
                            handleInputChange(event, key);
                            setHover((prev) => ({
                              ...prev,
                              [key]: -1
                            }));
                          }}
                          onChangeActive={(event, newHover) =>
                            setHover((prev) => ({
                              ...prev,
                              [key]: newHover
                            }))
                          }
                          icon={
                            <PizzaSVG
                              style={{ width: 24, height: 24, margin: '0 3px' }}
                            />
                          }
                          emptyIcon={
                            <PizzaSVG
                              style={{
                                width: 24,
                                height: 24,
                                margin: '0 3px',
                                opacity: 0.5
                              }}
                            />
                          }
                        />
                        <Box sx={{ ml: 2 }}>
                          {labels[
                            hover[key] !== -1 ? hover[key] : formValues[key]
                          ] || ''}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Divider />
          {/* <Grid container>
            <Grid item> */}
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '30px'
            }}>
            <Button
              totalWidth={true}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
              endIcon={loading ? <CircularProgress size={20} /> : null}
              style={{
                borderRadius: '50px',
                padding: '13px 40px',
                fontSize: 'bold',
                backgroundColor: '#4caf50',
                color: 'white',
                border: 'none',
                cursor: 'pointer'
              }}>
              {loading ? 'Submitting...' : 'Submit Complaint'}
            </Button>
          </Box>
          {/* </Grid>
          </Grid> */}
        </BackgroundImageBox>
      </Container>
    </>
  );
}


import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const outletUrls = {
  outlets: `${baseURL}/outlets`
  //   outletMenu: `${baseURL}/outlets/${outletId}`/menu,
};

export default outletUrls;

// outlets/64230c6350b74893c8f223e1/menu

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getRiderSheetApi } from '../../api/report/reportApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Typography,
  TableCell,
  TablePagination,
  TableRow,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TextField,
  TableBody
} from '@mui/material';
import {
  CountTableCell,
  HeaderTableCell,
  StyledTableCell,
  StyledTableRow
} from './Styled-components';
import Loader from '../Loader/Loader';
import { getCurrentDate } from '../Shared/shared';

export default function RiderSheet() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [saleReport, setSaleReport] = useState([]);

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  // calling APi

  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getRiderSheetApi(outlet?._id, startDate);
      setSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };
  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredOrders = saleReport?.filter(
    (report) =>
      report?.carrier.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      report?.invoiceId?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const totalOrders = saleReport.reduce((accumulator, current) => {
    if (current.carrier.name !== 'FoodPanda Rider') {
      return accumulator + current.numberOfOrders;
    }
    return accumulator;
  }, 0);

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Rider"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Rider Sheet
        </Typography>
        <div>
          <label htmlFor="date">Select Date to see Report</label>
          <input
            className="date-input"
            type="date"
            onChange={handleDate}
            name=""
            id="date"
            value={startDate || ''}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </div>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ background: '#008670', color: 'white' }}>
              <TableRow>
                <HeaderTableCell>sr#</HeaderTableCell>
                <HeaderTableCell>Rider</HeaderTableCell>
                <HeaderTableCell>Total Orders</HeaderTableCell>
                <HeaderTableCell>Max. Trip Time</HeaderTableCell>
                <HeaderTableCell>Min. Trip Time</HeaderTableCell>
                <HeaderTableCell>Avg. Trip Time</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <StyledTableRow
                        panda={report.carrier.name}
                        key={index}
                        sx={{}}>
                        <StyledTableCell panda={report.carrier.name}>
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell panda={report.carrier.name}>
                          {report?.carrier.name}
                        </StyledTableCell>
                        <StyledTableCell panda={report.carrier.name}>
                          {report?.numberOfOrders}
                        </StyledTableCell>
                        <StyledTableCell panda={report.carrier.name}>
                          {Math.round(report?.maxTripTime / 60000)}m
                        </StyledTableCell>
                        <StyledTableCell panda={report.carrier.name}>
                          {Math.round(report?.minTripTime / 60000)}m
                        </StyledTableCell>
                        <StyledTableCell panda={report.carrier.name}>
                          {Math.round(report?.averageTripTime / 60000)}m
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </>

              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>

              <TableRow>
                <CountTableCell sx={{ width: '100px' }}>
                  Average Orders per Rider
                </CountTableCell>
                <CountTableCell>
                  {Math.round(totalOrders / saleReport?.length)}
                </CountTableCell>

                <CountTableCell sx={{ width: '100px' }}>
                  Total Orders
                </CountTableCell>
                <CountTableCell>{totalOrders}</CountTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

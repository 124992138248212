import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import paymentMethodUrls from './paymentMethodUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get Payment Method API
export const getPaymentMethodApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(paymentMethodUrls.paymentMethod, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(paymentMethodUrls.paymentMethod, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add a new Payment method API
export const addPaymentMethodApi = async (pmData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      paymentMethodUrls.paymentMethod,
      pmData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        paymentMethodUrls.paymentMethod,
        pmData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// delete Payment Method API

export const deletePaymentMethodApi = async (pmId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${paymentMethodUrls.paymentMethod}/${pmId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${paymentMethodUrls.paymentMethod}/${pmId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

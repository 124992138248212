/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField
} from '@mui/material';
import {
  CartBox,
  CartCard,
  CartItemBox,
  CustomerInfoBox,
  DineInBox,
  PosButton,
  ExpandMore,
  ButtonsBox,
  IncrDecrBox,
  IncrDecrButton,
  DineInButton,
  TakeAwayButton,
  CatEndButton,
  DiscountBox,
  DiscountParentBox,
  DiscountGrandBox,
  ModifyBox,
  GeneralTypography,
  CartEndButton,
  ModifyCartItemBox,
  ModifyCartItemsBox,
  DiscountChargeBox,
  ModifyButtonBox
} from '../Styled-components';

import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import { Delete } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlatwareIcon from '@mui/icons-material/Flatware';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { useDispatch, useSelector } from 'react-redux';
import Receipt from '../components/Receipt';
import { getPrice } from '../Shared';
import {
  addItemInOrderApi,
  getSingleOrderApi
} from '../../../api/orders/OrdersApi';
import {
  addToCart,
  removeItem,
  setCustomerContact,
  setCustomerName,
  setDeliveryAddress,
  setItemChoice,
  setItemNote,
  setOrderType,
  setTableName,
  resetCart,
  incrementQuantity,
  decrementQuantity
} from '../../../redux/reducers/ducks/CartDuck';
import ModifyDiscount from './ModifyDiscount';
import DeleteOrderItem from './DeleteOrderItem';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import ModifyCharges from './ModifyCharges';
export default function ModifyOrder({
  outlet,
  outletMenu,
  updateTriggerState,
  orderId
}) {
  const dispatch = useDispatch();

  // getting cart from store
  const cart = useSelector((state) => state.cart);
  const {
    items,
    customerName,
    customerContact,
    deliveryAddress,
    deliveryNote,
    orderType,
    charges,
    tableName
  } = cart;

  // handling popup for discount
  const [discountPopup, setDiscountPopup] = useState({ isOpen: false });

  // handling pop Up for print when order updated
  const [print, setPrint] = useState({ isOpen: false, id: '' });
  const running = true;

  // state to get discounted data from child component
  const [childDiscountData, setChildDiscountData] = useState({});

  // charges pop up
  const [chargesPopup, setChargesPopup] = useState({
    isOpen: false
  });

  // clearing discount popup component state when cart gets empty
  if (cart.items.length == 0) {
    for (let key in childDiscountData) {
      delete childDiscountData[key];
    }
  }

  // get total payable amount
  let discountAmount = 0;
  let discountPercentage = 0;
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;

    items.forEach((item) => {
      const menuItem = outletMenu.find(
        (menuItem) => menuItem.menuItem._id === item.menuItem
      );
      let itemPrice = getPrice(menuItem, item);
      totalQuantity += item.quantity;
      totalPrice += itemPrice * item.quantity;
    });

    if (childDiscountData && childDiscountData.fixedDiscount === true) {
      discountAmount = childDiscountData.discount;
      totalPrice -= discountAmount;
    } else if (childDiscountData && childDiscountData.fixedDiscount === false) {
      items.forEach((item) => {
        const menuItem = outletMenu.find(
          (menuItem) => menuItem.menuItem._id === item.menuItem
        );
        let itemPrice = getPrice(menuItem, item);
        discountPercentage =
          (childDiscountData.discount / 100) * (itemPrice * item.quantity);
        totalPrice -= discountPercentage;
      });
    }

    return { totalPrice, totalQuantity };
  };
  const total = getTotal();

  // Managing Cart Item Expand
  const [expanded, setExpanded] = useState([]);
  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // setting up payload to Update order
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async () => {
    const formData = {
      items: cart.items,
      // deliveryAddress: orderType === 'delivery' ? deliveryAddress : '',
      // tableId: orderType === 'dineIn' ? tableId : '',
      outlet: outlet._id
    };

    const errorMessage = '';

    if (errorMessage) {
      dispatch(
        addSnackbar({
          message: errorMessage,
          type: 'error'
        })
      );
    } else {
      setIsDisabled(true);
      setLoading(true);

      const res = await addItemInOrderApi(orderId, formData);
      try {
        if (res.status == 200) {
          setPrint({ isOpen: true, id: res?.data?._id });
          updateTriggerState(true);
          setIsDisabled(false);
          dispatch(resetCart());
          dispatch(
            addSnackbar({
              message: 'Order Updated',
              type: 'success'
            })
          );
        }
      } catch (error) {
        dispatch(
          addSnackbar({
            message: 'An error occurred while updating the order',
            type: 'error'
          })
        );
      } finally {
        setIsDisabled(false);
        setLoading(false); // End loading
      }
    }
  };
  // ============================ single order ===========================

  // get single ordr
  const [singleOrder, setSingleOrder] = useState();
  // get single Order Api calling
  const getSingleOrder = async (orderId) => {
    const res = await getSingleOrderApi(orderId);
    setSingleOrder(res?.data);
  };

  useEffect(() => {
    if (orderId) {
      getSingleOrder(orderId);
      callFunc();
    }
  }, [orderId]);

  // handling pop Up for delete Item from Order
  const [deleteItemPopup, setDeleteItemPopup] = useState({
    isOpen: false,
    OrderId: '',
    itemId: ''
  });

  const callFunc = () => {
    getSingleOrder(orderId);
  };
  // ============================ single order ===========================

  // getting charges
  const totalCharges = charges?.reduce(
    (prev, current) => prev + Number(current.amount),
    0
  );

  const grandTotal = total.totalPrice + totalCharges;

  // disable button after order punch till API Recall
  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <CartBox>
      <DineInBox>
        <DineInButton
          ordertype={singleOrder?.orderType}
          onClick={() => dispatch(setOrderType('dineIn'))}
          variant="outlined"
          startIcon={<FlatwareIcon />}>
          Dine In
        </DineInButton>

        <TakeAwayButton
          ordertype={singleOrder?.orderType}
          onClick={() => dispatch(setOrderType('takeAway'))}
          variant="outlined"
          startIcon={<TakeoutDiningIcon />}>
          Take Away
        </TakeAwayButton>
        <PosButton
          ordertype={singleOrder?.orderType}
          onClick={() => dispatch(setOrderType('delivery'))}
          variant="outlined"
          startIcon={<DeliveryDiningIcon />}>
          Delivery
        </PosButton>
      </DineInBox>
      <CustomerInfoBox>
        <Box
          sx={{
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <TextField
            inputProps={{ style: { padding: 4 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            label="Customer Name"
            variant="outlined"
            value={singleOrder?.customerName || ''}
            onChange={(e) => dispatch(setCustomerName(e.target.value))}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
              }
            }}
          />

          <TextField
            inputProps={{
              style: { padding: 4 }
            }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            label="Customer Contact"
            value={singleOrder?.customerContact || ''}
            onChange={(e) => dispatch(setCustomerContact(e.target.value))}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
              }
            }}
          />
          {orderType === 'delivery' ? (
            <TextField
              inputProps={{
                style: { padding: 4 }
              }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              label="Delivery Address"
              value={singleOrder?.deliveryAddress}
              onChange={(e) => dispatch(setDeliveryAddress(e.target.value))}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
            />
          ) : (
            ''
          )}
          {orderType === 'dineIn' ? (
            <TextField
              inputProps={{
                style: {
                  padding: 4
                }
              }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              label="Table Id"
              value={singleOrder?.tableName}
              onChange={(e) => dispatch(setTableName(e.target.value))}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
            />
          ) : (
            ''
          )}
        </Box>
      </CustomerInfoBox>
      <ModifyCartItemsBox>
        <ModifyBox>
          <Box>
            {singleOrder?.items
              .filter((activeItem) => activeItem.active)
              .map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid gray',
                      borderRadius: '10px',
                      backgroundColor: '#f1f1f1',
                      margin: '3px 4px'
                    }}>
                    <strong>{item.name}</strong>
                    <strong> (Rs. {item.price})</strong>
                    <strong> (Quantity: {item.quantity})</strong>

                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => {
                        // const canDeleteItem =
                        //   singleOrder.items.length === 1 &&
                        //   cart.items.length === 0;

                        // if (canDeleteItem) {
                        //   dispatch(
                        //     addSnackbar({
                        //       message:
                        //         "Last Item can't be deleted, To Delete add items to cart ",
                        //       type: 'error'
                        //     })
                        //   );
                        // } else {
                        setDeleteItemPopup({
                          isOpen: true,
                          orderId: orderId,
                          itemId: item._id
                        });
                        // }
                      }}>
                      <DeleteForeverIcon />
                    </IconButton>
                    <Box>
                      {item?.choices?.map((choice, choiceIndex) => {
                        return (
                          <span key={choiceIndex}>
                            {choice.optionName && (
                              <>
                                [ {choice.optionName}{' '}
                                {choice.price !== 0 && `(Rs.${choice.price})`} ]
                                ,
                              </>
                            )}
                          </span>
                        );
                      })}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </ModifyBox>
        <ModifyCartItemBox>
          {items?.map((cartItem, index) => {
            const outletItem = outletMenu.find(
              (item) => cartItem.menuItem === item.menuItem._id
            );
            const menuItem = outletItem.menuItem;
            let choices = menuItem.choices;

            return (
              <CartCard key={index}>
                <CardActions sx={{ padding: '0px', height: '35px' }}>
                  <ExpandMore
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded[index]}
                    aria-label="show more">
                    <ExpandMoreIcon />
                  </ExpandMore>
                  <IncrDecrBox>
                    <p>{menuItem?.name}</p>
                    <p>
                      Rs.
                      <b>
                        {Math.round(
                          cartItem?.quantity * getPrice(outletItem, cartItem)
                        )}
                      </b>
                    </p>
                    <ButtonsBox>
                      <IncrDecrButton
                        onClick={() => dispatch(decrementQuantity(index))}>
                        -
                      </IncrDecrButton>
                      <p> {cartItem?.quantity} </p>
                      <IncrDecrButton
                        onClick={() => dispatch(incrementQuantity(index))}>
                        +
                      </IncrDecrButton>
                    </ButtonsBox>
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => dispatch(removeItem(index))}>
                      <Delete />
                    </IconButton>
                  </IncrDecrBox>
                </CardActions>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <CardContent>
                    {choices?.map((choice, choiceIndex) => {
                      const optionId = cartItem.choices.find(
                        (choiceObj) => choice._id === choiceObj.choice
                      ).option;
                      const option = choice.choice.options.find(
                        (option) => option.item === optionId
                      );
                      return choice.choice.optional &&
                        choice.choice.options.length === 1 ? (
                        <FormControlLabel
                          key={choiceIndex}
                          label={`${choice.name}(${choice?.choice.options[0].price}) `}
                          control={
                            <Checkbox
                              checked={
                                cartItem.choices.find(
                                  (choiceObj) => choice._id === choiceObj.choice
                                ).option
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                dispatch(
                                  setItemChoice({
                                    index,
                                    choice: choice._id,
                                    option: event.target.checked
                                      ? choice.choice.options[0].item
                                      : null
                                  })
                                );
                              }}
                            />
                          }
                        />
                      ) : (
                        <TextField
                          size="small"
                          key={choiceIndex}
                          defaultValue=""
                          value={option?.item || ''}
                          label={choice?.name}
                          fullWidth
                          margin="normal"
                          select
                          onChange={(event) =>
                            dispatch(
                              setItemChoice({
                                index,
                                choice: choice._id,
                                option: event.target.value
                              })
                            )
                          }>
                          {choice.choice.optional ? (
                            <MenuItem value="">None</MenuItem>
                          ) : (
                            ''
                          )}
                          {choice.choice.options?.map((option, index) => (
                            <MenuItem value={option.item} key={index}>
                              <b style={{ marginRight: '7px' }}>
                                {option.name}
                              </b>
                              Rs:<b> {option.price}</b>
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    })}

                    <TextField
                      fullWidth
                      type="text"
                      label="Additional Info"
                      variant="outlined"
                      defaultValue={cartItem.note}
                      onBlur={(e) =>
                        dispatch(setItemNote({ index, note: e.target.value }))
                      }
                    />
                  </CardContent>
                </Collapse>
              </CartCard>
            );
          })}
        </ModifyCartItemBox>
      </ModifyCartItemsBox>
      <Box sx={{ borderTop: '1px solid gray' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 5px'
          }}>
          <p className="total__p">
            Total ({getTotal().totalQuantity + singleOrder?.items?.length}{' '}
            items)
          </p>

          <strong>
            Rs: {Math.round(grandTotal + singleOrder?.total + singleOrder?.tax)}
          </strong>
        </Box>
        <DiscountParentBox>
          {/* discount */}
          <DiscountChargeBox>
            <DiscountBox>
              <CatEndButton
                variant="contained"
                onClick={() => {
                  setDiscountPopup({
                    isOpen: true
                  });
                }}>
                Discount
              </CatEndButton>
              <Box>
                {singleOrder?.fixedDiscount ? (
                  <strong>{singleOrder?.discount} </strong>
                ) : (
                  <strong>{singleOrder?.discountRate * 100}%</strong>
                )}
              </Box>
            </DiscountBox>

            <DiscountGrandBox>
              <GeneralTypography>
                Discount:
                <strong>{singleOrder?.discount}</strong>
              </GeneralTypography>
            </DiscountGrandBox>
          </DiscountChargeBox>
          {/* charges */}
          <DiscountChargeBox>
            <CatEndButton
              variant="contained"
              onClick={() => {
                setChargesPopup({ isOpen: true });
              }}>
              Charges
            </CatEndButton>
            <DiscountGrandBox>
              <GeneralTypography>
                Charges: <strong> {totalCharges} </strong>
              </GeneralTypography>
            </DiscountGrandBox>
          </DiscountChargeBox>
        </DiscountParentBox>
        <ModifyButtonBox>
          <Button
            sx={{ backgroundColor: '#19a619', borderRadius: '10px' }}
            variant="contained"
            disabled={isDisabled}
            onClick={() => {
              handleFormSubmit();
            }}>
            {loading ? <CircularProgress size={24} /> : 'Update Order'}
          </Button>
          <Button
            sx={{
              backgroundColor: '#e12e2e',
              borderRadius: '10px'
            }}
            variant="contained"
            onClick={() => {
              dispatch(resetCart());
            }}>
            Clear
          </Button>
        </ModifyButtonBox>
      </Box>
      <ModifyDiscount
        updateTriggerState={updateTriggerState}
        discountPopup={discountPopup}
        setDiscountPopup={setDiscountPopup}
        outletId={outlet._id}
        setChildDiscountData={setChildDiscountData}
        singleOrder={singleOrder}
        orderId={orderId}
        callFunc={callFunc}
      />
      <Receipt print={print} setPrint={setPrint} running={running} />
      <DeleteOrderItem
        updateTriggerState={updateTriggerState}
        outletId={outlet._id}
        deleteItemPopup={deleteItemPopup}
        setDeleteItemPopup={setDeleteItemPopup}
        callFunc={callFunc}
      />
      <ModifyCharges
        chargesPopup={chargesPopup}
        setChargesPopup={setChargesPopup}
        singleOrder={singleOrder}
        outletId={outlet._id}
        orderId={orderId}
        updateTriggerState={updateTriggerState}
      />
    </CartBox>
  );
}

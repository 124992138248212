import {
  Typography,
  Box,
  styled,
  Card,
  Button,
  IconButton,
  ToggleButtonGroup
} from '@mui/material';

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  padding: 0
}));

export const SourceBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center'
  // marginTop: '5px',
  // gap: '5px'
}));
// menu Items Section

export const MenuItemBox = styled(Box)(() => ({
  // maxHeight: '800px',
  // height: '500px',
  // width: '100%',
  // overflowY: 'auto',

  // '&::-webkit-scrollbar': {
  //   width: '3px'
  // },
  // '&::-webkit-scrollbar-track': {
  //   background: '#f1f1f1',
  //   boxShadow: 'inset 0 0 5px grey',
  //   borderRadius: '10px'
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   background: '#959191',
  //   borderRadius: '10px',
  //   border: '2px solid #f1f1f1'
  // }
  width: '100%',
  overflowY: 'auto',
  height: '100%',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: '#1F1817',
  cursor: 'pointer',
  height: '170px',
  textAlign: 'left',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  borderRadius: '20px',
  border: '1px solid #cbd5e0',
  transition: '0.3s',
  '&:hover': {
    transform: 'scale(0.9)',
    boxShadow: '0px 0px 10px 4px #cbd5e0'
  }
}));

export const ImageBox = styled(Box)(() => ({
  objectFit: 'cover',
  height: 110,
  width: '90%',
  maxHeight: { xs: 233, md: 167 },
  maxWidth: { xs: 350, md: 250 },
  borderRadius: '10px'
}));

export const NameTypo = styled(Typography)(() => ({
  fontSize: 'clamp(1rem, 2vW + 0.5rem, 1rem)',
  fontWeight: 500,
  color: '#fff'
}));

export const PriceTypo = styled(Typography)`
  font-size: ${({ myfontsize }) => (myfontsize ? '12px' : '16px')};
  font-weight: 600;
  color: #fff;
`;

export const CustomerInfoBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  padding: '0 10px '
}));

export const DetailsBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const CartCard = styled(Card)`
  margin: 2px 0;
  border: 1px solid gray;
  padding: 2px;
  border-radius: 10px;
`;

export const CartBox = styled(Box)(() => ({
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)'
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const DineInButton = styled(Button)(({ ordertype }) => ({
  background: ordertype === 'dineIn' ? '#6D1B7B' : '',
  color: ordertype === 'dineIn' ? '#fff' : '',
  fontSize: '10px',
  lineHeight: 1,
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    background: '#6D1B7Ba1'
  },
  height: '40px',
  padding: '3px'
}));

export const TakeAwayButton = styled(Button)(({ ordertype, theme }) => ({
  background: ordertype === 'takeAway' ? theme.palette.primary.main : '',
  color: ordertype === 'takeAway' ? theme.palette.white.main : '',
  fontSize: '10px',
  lineHeight: 1,
  borderRadius: '10px',
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    background: theme.palette.primary.hover
  },
  height: '40px',
  padding: '3px'
}));

export const PosButton = styled(Button)(({ ordertype, theme }) => ({
  background: ordertype === 'delivery' ? theme.palette.primary.main : '',
  color: ordertype === 'delivery' ? theme.palette.white.main : '',
  fontSize: '10px',
  lineHeight: 1,
  borderRadius: '10px',
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    background: theme.palette.primary.hover
  },
  height: '40px',
  padding: '3px'
}));

export const CartItemBox = styled(Box)(() => ({
  // maxHeight: '300px',
  // height: '280px',
  // overflowY: 'auto',

  // '&::-webkit-scrollbar': {
  //   width: '3px'
  // },
  // '&::-webkit-scrollbar-track': {
  //   background: '#f1f1f1',
  //   boxShadow: 'inset 0 0 5px grey',
  //   borderRadius: '10px'
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   background: '#959191',
  //   borderRadius: '10px',
  //   border: '2px solid #f1f1f1'
  // }
  overflowY: 'auto',
  height: '100%',
  // display: 'flex',
  // flexDirection: 'column',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const ModifyBox = styled(Box)(() => ({
  maxHeight: '500px',
  height: '430px',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  }
}));

export const CategoryButton = styled(Button)(({ theme }) => ({
  // '&:hover': {
  //   background: theme.palette.primary.hover,
  //   color: theme.palette.white.main
  // },
  // fontSize: '10px',
  // height: '40px',
  // padding: '3px'
  fontSize: '10px',
  height: '40px',
  letterSpacing: 0,
  padding: '3px',
  whiteSpace: 'nowrap',
  borderRadius: '10px',

  flexShrink: 0,
  '&:hover': {
    background: theme.palette.primary.hover,
    color: theme.palette.white.main
  }
}));

export const DineInBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // gap: '10px',
  padding: '4px 0px'
  // padding: '7px 0 7px 0'
}));

export const CategoryBox = styled(Box)(() => ({
  // display: 'flex',
  // justifyContent: 'space-around',
  // maxWidth: '100%',
  // width: '100%',
  // overflowX: 'auto',
  // paddingTop: '10px',
  // '&::-webkit-scrollbar': {
  //   width: '8px',
  //   height: '10px'
  // },
  // '&::-webkit-scrollbar-track': {
  //   background: '#f1f1f1'
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   background: '#959191',
  //   borderRadius: '10px',
  //   border: '2px solid #f1f1f1'
  // }
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0px',
  // marginBottom: '3px',
  overflowX: 'scroll',
  paddingTop: '5px',
  maxWidth: '100%',
  width: '100%',
  gap: '7px',

  '&::-webkit-scrollbar': {
    width: '3px',
    height: '7px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 1px #000',
    borderRadius: '1px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  }
}));

export const SearchBox = styled(Box)(() => ({
  display: 'flex',
  // margin: '10px 0 10px 15px'
  // margin: '5px 5px'
  margin: '2px 5px'
}));

export const MenuItemsBox = styled(Box)(() => ({
  height: '100%',
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)'
  display: 'flex',
  flexDirection: 'column'
}));

// orders component section

export const OrdersBox = styled(Box)(() => ({
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  padding: '0px 5px',
  textAlign: 'left',

  // maxHeight: '500px',
  height: '100%',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  }
}));

export const SingleOrderBox = styled(Box)(() => ({
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  // background: '#f1f1f1',
  borderRadius: '10px',
  marginTop: '10px',
  padding: '10px 5px',
  border: '1px solid #b7b7b7'
}));
export const UpperBox = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    background: '#cd7474',
    color: '#000'
  }
}));

export const UserSectionBox = styled(Box)(() => ({}));

export const OutletSectionBox = styled(Box)(() => ({
  borderTop: '1px solid #b7b7b7'
}));
export const OrderTypography = styled(Typography)(() => ({
  fontSize: '12px'
}));

export const OrderStatusBox = styled(Box)(() => ({
  borderTop: '1px solid #b7b7b7'
}));

export const OrdersActionBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderTop: '1px solid #b7b7b7',
  paddingTop: '3px'
}));

export const OrdersActionButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '45px',
  width: '45px',
  height: '25px',
  textTransform: 'none',
  fontSize: '13px'
}));

export const RejectedOrdersActionButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  // minWidth: '45px',
  // width: '45px',
  height: '25px',
  textTransform: 'none',
  fontSize: '12px',
  backgroundColor: '#FED23B',
  color: '#000',
  borderRadius: '10px'
}));

export const AcknowledgeCopyBox = styled(Box)(() => ({
  display: 'flex',
  gap: '7px',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const DiscountGrandBox = styled(Box)(() => ({
  // border: '1px solid gray',
  // borderRadius: '20px',
  // px: '10px'
  border: '1px solid gray',
  borderRadius: '20px',
  padding: '0 10px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const DiscountParentBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  // flexDirection: 'row',
  border: '1px solid gray',
  alignItems: 'center',
  borderRadius: '7px',
  gap: '10px'
}));
export const DiscountChargeBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
}));

export const DiscountBox = styled(Box)(() => ({
  display: 'flex',
  gap: '5px',
  border: '1px solid gray',
  borderRadius: '20px',
  px: '10px'
}));

export const CatEndButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '80px',
  width: '80px',
  height: '25px',
  borderRadius: '20px',
  textTransform: 'none',
  fontSize: '13px'
}));

export const SingleOrderTypography = styled(Typography)(() => ({
  fontSize: '16px'
}));

export const InvoiceTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600'
}));

export const ButtonsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const IncrDecrBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

export const IncrDecrButton = styled(Button)(() => ({
  height: '25px',
  minWidth: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'gainsboro',
  border: 'none',
  borderRadius: '50%',
  '&:hover': {
    boxShadow: '0 2px 2px gray'
  },
  '&:active': {
    boxShadow: 'unset'
  }
}));

export const StyledToggleButton = styled(ToggleButtonGroup)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: '#FF0060'
  }
});

export const GeneralTypography = styled(Typography)(() => ({
  fontSize: '12px',
  letterSpacing: 0,
  display: 'flex',
  justifyContent: 'center'
}));

import React, { useEffect, useState } from 'react';
import { getInventoryHistoryApi } from '../../../api/stocks/inventoryController/inventoryContollerApi';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Loader from '../../Loader/Loader';
import { TopBox } from '../../Shared/shared';

export default function InventoryHistory() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [isLoading, setLoading] = useState(false);
  const [InventoryHistory, setInventoryHistory] = useState([]);
  const getInventoryHistory = async () => {
    try {
      setLoading(true);
      const res = await getInventoryHistoryApi(outlet._id);
      setInventoryHistory(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInventoryHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TopBox my={2}>
            <Typography fontSize={22} fontWeight="600">
              Inventory History
            </Typography>
          </TopBox>
          <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
            <Table size="small" aria-label="collapsible table">
              <TableHead
                sx={{
                  background: '#66C8E0',
                  color: 'white',
                  position: 'sticky',
                  top: 0
                }}>
                <TableRow>
                  {/* <TableCell>From Date</TableCell> */}
                  <TableCell>Sr#</TableCell>
                  <TableCell>Sale Date</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>View </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: 'auto' }}>
                {InventoryHistory?.map((singleSet, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          // '& > *': { borderBottom: 'unset' },
                          '&:hover': {
                            backgroundColor: '#66C8E0a2',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        {/* <TableCell>{singleSet.fromDate}</TableCell> */}
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{singleSet.saleDate}</TableCell>
                        <TableCell>{singleSet?.user?.name}</TableCell>
                        <TableCell sx={{ maxWidth: '110px' }}>
                          <Tooltip
                            title={
                              <Typography fontSize={15}>
                                {singleSet.desc}
                              </Typography>
                            }
                            placement="top">
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontSize: '12px'
                              }}>
                              {singleSet.desc}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <NavLink to={`${singleSet.saleDate}/ingredients`}>
                            <Button>
                              <RemoveRedEyeIcon />
                            </Button>
                          </NavLink>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}

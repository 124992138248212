import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { getClosingReportApi } from '../../../api/report/reportApi';

export default function ClosingSaleChart({
  outletId,
  saleDate,
  setSaleComment
}) {
  const [reportData, setreportData] = useState([]);
  const paymentMethods = Array.from(
    new Set(
      reportData?.reduce(
        (array, cashier) => [
          ...array,
          ...cashier.paymentMethods.map(
            (paymentMethod) => paymentMethod.paymentMethod
          )
        ],
        []
      )
    )
  );

  useEffect(() => {
    getClosingReportApi(outletId, saleDate).then((res) =>
      setreportData(res?.data)
    );
  }, []);

  function formatServiceType(serviceType) {
    let formattedString = serviceType.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedString =
      formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

    return formattedString;
  }
  return (
    <div>
      <Box
        sx={{
          textAlign: 'center'
        }}>
        <Typography fontSize="20px" fontWeight="700">
          Closing Sale Report
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ height: '300px', overflowY: 'auto' }}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ background: '#FFA500', color: 'white' }}>
              <TableRow>
                <TableCell>Order Type</TableCell>
                {paymentMethods?.map((method, index) => {
                  return <TableCell key={index}>{method}</TableCell>;
                })}
                <TableCell>Sale</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{
                        '&:hover': {
                          backgroundColor: '#008670a5',
                          cursor: 'pointer'
                        }
                      }}>
                      <TableCell>
                        {formatServiceType(item?.orderType)}
                      </TableCell>
                      {paymentMethods?.map((method, index) => {
                        return (
                          <TableCell key={index}>
                            <Box>
                              <span>
                                <b>
                                  {Math.round(
                                    item.paymentMethods?.find(
                                      (paymentMethod) =>
                                        paymentMethod.paymentMethod === method
                                    )?.totalSale
                                  ) || 0}
                                </b>
                              </span>
                            </Box>
                          </TableCell>
                        );
                      })}
                      <TableCell>{Math.round(item.totalSale)}</TableCell>
                      <TableCell>{item.totalCount}</TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}

              <TableRow>
                <TableCell>
                  <b>Total</b>
                </TableCell>
                {paymentMethods?.map((method, index) => {
                  return (
                    <TableCell key={index}>
                      <Box>
                        <span>
                          <b>
                            {reportData.reduce(
                              (total, item) =>
                                Math.round(
                                  total +
                                    (item.paymentMethods?.find(
                                      (paymentMethod) =>
                                        paymentMethod.paymentMethod === method
                                    )?.totalSale || 0)
                                ),
                              0
                            )}
                          </b>
                        </span>
                      </Box>
                    </TableCell>
                  );
                })}
                <TableCell>
                  {reportData.reduce(
                    (total, cashier) => Math.round(total + cashier.totalSale),
                    0
                  )}
                </TableCell>
                <TableCell>
                  {reportData.reduce(
                    (total, cashier) => total + cashier.totalCount,
                    0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'end', margin: '10px 0 0 0' }}>
        <TextField
          //   size="small"
          onChange={(e) => setSaleComment(e.target.value)}
          fullWidth
          label="Sale Comment"
          type="text"
        />
      </Box>
    </div>
  );
}


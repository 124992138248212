import { Box, Grid, List, Typography, styled } from '@mui/material';

export const SideBar = styled(Box)(({ theme }) => ({
  borderRight: '1px solid #E4E4E4',
  height: '100%',
  paddingTop: '10px'
  // width: '30%'
}));

export const Typo = styled(Typography)(({ pg, page, i }) => ({
  color: page.toLowerCase() == pg.name.toLowerCase() ? '#FF5C0D' : '#828487',
  fontWeight: page.toLowerCase() == pg.name.toLowerCase() ? '600' : '500',
  fontSize: '16px'
}));

export const TypoIcon = styled(Typography)(({ pg, page, i }) => ({
  fontSize: '22px',
  color: page.toLowerCase() == pg.name.toLowerCase() ? '#FF5C0D' : '#B5B5B5'
}));

export const ElementBox = styled(Box)(({ pg, page, i }) => ({
  display: 'flex',
  gap: '7px',
  alignItems: 'start',
  cursor: 'pointer',
  transition: '0.1s',
  backgroundColor:
    page.toLowerCase() == pg.name.toLowerCase() ? '#f3e6dc' : null,
  borderRight:
    page.toLowerCase() == pg.name.toLowerCase() ? '2px solid #FF5C0D' : null,
  padding: '10px 10px',
  //   marginTop: i == 0 ? '20px' : 0,
  '&:hover': {
    backgroundColor: '#f4e7dc',
    transition: '0.4s'
  }
}));

export const MainGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#F8F9FD',
  height: 'auto',
  overflowY: 'auto',
  maxHeight: '100vh',
  padding: '30px 0'
}));

// /// **************************************

export const ParentBox = styled(Box)(() => ({
  // background: "F8F9FD",
}));

export const ChildGrid = styled(Grid)(() => ({
  display: 'grid',
  justifyContent: 'space-around',
  marginTop: '8px'
}));

export const ParentGrid = styled(Grid)(() => ({}));

export const SaleBox = styled(Box)(({ theme }) => ({
  textAlign: 'start',
  borderRadius: '12px',
  boxShadow: theme.palette.customShadows.light,
  padding: '10px',
  width: 220,
  height: 220,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: '#fff',
  '&:hover': {
    boxShadow: theme.palette.customShadows.heavy,
    transition: '0.4s'
  }
}));

export const ChartBox = styled(Box)(({ theme }) => ({
  // textAlign: 'start',
  borderRadius: '12px',
  boxShadow: theme.palette.customShadows.light,
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#fff',
  '&:hover': {
    boxShadow: theme.palette.customShadows.heavy,
    transition: '0.4s'
  }
}));

export const BoxIcon = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '12px'
}));
export const IconTypography = styled(Box)(({ canceledorders }) => ({
  color: canceledorders ? '#FF3C00' : `#828784`,
  marginTop: '3px',
  alignItems: 'center'
}));
export const NumberBox = styled(Box)(() => ({
  //   marginTop: '20px'
}));
export const ButtonBox = styled(Typography)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px ',
  color: '#FF3C00',
  cursor: 'pointer',
  borderRadius: '8px',
  marginTop: '20px',
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    transition: '0.4s'
  }
}));
export const BoxGrid = styled(Grid)(() => ({
  // display: 'flex',
  // justifyContent: 'space-around',
  // marginTop: '8px',
  // background: '#fff'
  // border: '1px solid gray'
}));
export const BorderBox = styled(Box)(
  ({ isBorder1, isBorder2, isBorder3, isBorder4, isBorder5 }) => ({
    borderTop: isBorder1
      ? '2px solid #8369F1'
      : isBorder2
      ? '2px solid #FB7269'
      : isBorder3
      ? '2px solid #FACC25'
      : isBorder4
      ? '2px solid #74C951'
      : isBorder5
      ? '2px solid #AFE31F'
      : ''
  })
);

export const GridContainer = styled(Grid)(() => ({}));
export const GridSeling = styled(Grid)(() => ({}));

export const SellingGridBox = styled(Box)(({ theme }) => ({
  // padding: '6px 20px'
  borderRadius: '12px',
  boxShadow: theme.palette.customShadows.light,
  padding: '10px',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',

  maxHeight: '800px',
  height: '500px',
  width: '100%',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  },

  '&:hover': {
    boxShadow: theme.palette.customShadows.heavy,
    transition: '0.4s'
  }
}));

export const DateBox = styled(Box)(() => ({
  border: '2px solid #E4E4E4',
  borderRadius: '7px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '10px',
  cursor: 'pointer',
  gap: '6px'
}));

export const DateParentBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'end',
  padding: '0 10px 0 0'
}));

export const SideBarInnerBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'start',
  cursor: 'pointer',
  padding: '4px 0 4px 7px',
  transition: '0.3s'
}));

export const SellingBox = styled(Box)(() => ({
  cursor: 'pointer',
  '&:hover': {
    color: '#19191C'
    // fontSize: '1.1rem'
  }
}));

export const SellingList = styled(List)(({ theme }) => ({
  width: '100%',
  bgcolor: 'background.paper',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: theme.palette.customShadows.heavy,
  borderRadius: '12px',
  marginTop: '15px',
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    transition: '0.4s',
    backgroundColor: '#cfcfcf'
  }
}));

//  ---------------------------- progress Bar ----------------------------

export const StyledBox = styled(Box)`
  margin-right: 8px;
  margin-left: -10px;
`;

export const ProgressBox = styled(Box)(({ isColor }) => ({
  width: '20%',
  fontSize: '10px',
  borderRadius: '5px',
  background: isColor
}));


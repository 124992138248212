/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdVisibility,
  ornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { changeUserKeyApi } from '../../api/users/usersApi';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import swal from 'sweetalert';

export default function ChangeManagerKey() {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // handling form of manual discount with useForm hook
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    event.preventDefault();

    swal('Success', 'Manager Key Changed', 'success');

    const response = await changeUserKeyApi(data);
    if (response.status == 200) {
      dispatch(
        addSnackbar({
          message: `Manager Key Changed`,
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: response?.response?.data?.message,
          type: 'error'
        })
      );
    }
  };

  return (
    <>
      <Typography sx={{ fontSize: 'clamp(1rem, 2vW + 0.5rem, 2rem)' }}>
        Change Manager Key
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '400px',
            borderRadius: '10px',
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
            p: '20px'
          }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
              <TextField
                required
                size="small"
                label="Email"
                type="email"
                {...register('email')}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Enter New Key
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  {...register('key')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}

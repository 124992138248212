/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  authorizeUser,
  updateAuthSuccessStatus
} from '../../redux/reducers/ducks/AuthorizeDuck';
import { useDispatch, useSelector } from 'react-redux';
import './login.css';
import { Box, Button, CircularProgress } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

const Login = () => {
  const dispatch = useDispatch();

  const logo = process.env.REACT_APP_CLIENT_LOGO;

  const { loginResponse } = useSelector((state) => ({
    loginResponse: state.authorize?.loginResponse,
    isAuthSuccess: state.authorize?.isAuthSuccess
  }));

  useEffect(() => {
    if (loginResponse) {
      const { accessToken, refreshToken, user } = loginResponse;
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('persist', true);
      window.location.replace('/');
    }
    dispatch(updateAuthSuccessStatus(false));
  }, [loginResponse]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordClick = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      dispatch(
        authorizeUser({
          email,
          password
        })
      );
    } catch (error) {}
  };

  const bodyBoxStyle = {
    display: 'grid',
    placeItems: 'center',
    margin: 0,
    padding: '0 24px',
    height: '100vh',
    overflowY: 'scroll',
    backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR,
    backgroundImage: process.env.REACT_APP_BACKGROUND,
    color: '#000',
    fontFamily: 'Ubuntu'
  };

  return (
    <div style={bodyBoxStyle}>
      <div className="login">
        <img src={logo} alt="logo" />
        <h3>{process.env.REACT_APP_CLIENT_NAME}</h3>
        <h2>Log in</h2>
        <form className="login-form" onSubmit={handleSubmit}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
            autoComplete="on"
            placeholder="Enter email"
          />
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              required
              placeholder="Enter Password"
              className="password-input"
            />
            <div
              className="password-toggle-button"
              onClick={handleShowPasswordClick}>
              {showPassword ? 'Hide' : 'Show'}
            </div>
          </div>
          {/* <button type="submit">LOGIN</button> */}

          <Button variant="contained" type="submit">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              LogIn
              <LoginIcon />
            </Box>
          </Button>

          {/* <a href="#">Forget your Password?</a> */}
        </form>
      </div>
    </div>
  );
};

export default Login;

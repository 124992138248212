/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tooltip
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { addIngredientSetApi } from '../../api/ingredientSet/ingredientSetApi';
import { ingredientRequest } from '../../redux/reducers/ducks/IngredientDuck';

const getById = (collection, id) => {
  for (let item of collection) if (item._id == id) return item;
  return null;
};

const AddIngredientSet = ({
  addPopup,
  setAddPopup,
  callFunc,
  displayScreen
}) => {
  const dispatch = useDispatch();
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const ingredientResponse = useSelector((state) => state.ingredient);

  useEffect(() => {
    if (!ingredientResponse || !ingredientResponse.ingredientResponse) {
      dispatch(ingredientRequest());
    } else {
      setIngredientOptions(ingredientResponse?.ingredientResponse);
    }
  }, [dispatch, ingredientResponse, callFunc]);

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      shortName: '',
      display: '',
      size: '',
      desc: '',
      ingredients: []
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const handleFormSubmit = async (values, onSubmitProps) => {
    let response = await addIngredientSetApi(values);
    if (response.status === 200) {
      handleClose();
      callFunc();
    }
  };

  const handleClose = () => {
    setAddPopup({ isOpen: false });
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              <Typography color="#6d1b7b" fontWeight="700">
                Create New Ingredient Set
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <TextField
                  {...register('name', { required: true })}
                  margin="normal"
                  label="Name"
                  fullWidth
                />
                <TextField
                  {...register('shortName', { required: true })}
                  margin="normal"
                  label="Short Name"
                  fullWidth
                />
                <TextField
                  {...register('size', { required: true })}
                  defaultValue=""
                  label="Select Size"
                  fullWidth
                  margin="normal"
                  select>
                  <MenuItem value="Small">Small</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Large">Large</MenuItem>
                </TextField>
                <TextField
                  {...register('display', { required: true })}
                  //   defaultValue=""
                  label="Select Display"
                  fullWidth
                  margin="normal"
                  select>
                  {displayScreen?.map((display) => (
                    <MenuItem key={display._id} value={display._id}>
                      {display.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  {...register('desc')}
                  label="Description"
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Click to Add Ingredients"
                  onChange={(e) =>
                    e.target.value &&
                    append({
                      ingredient: e.target.value,
                      quantity: '',
                      consumedIn: {
                        dineIn: true,
                        takeAway: true,
                        delivery: true
                      }
                    })
                  }
                  value={''}
                  fullWidth
                  select
                  margin="normal">
                  <MenuItem key="" value="">
                    Select Ingredient
                  </MenuItem>
                  {ingredientOptions
                    ?.filter(
                      (ingredient) =>
                        !fields
                          .map((v) => v.ingredient)
                          .includes(ingredient._id)
                    )
                    ?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </TextField>
                <Box>
                  {fields.map((ingredient, index) => (
                    <Box
                      sx={{
                        border: '1px solid gray',
                        margin: '10px 0',
                        borderRadius: '7px',
                        padding: '0 5px'
                      }}
                      key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}>
                        <Tooltip title="Delete Ingredient">
                          <IconButton
                            sx={{
                              color: 'red',
                              width: '10%'
                            }}
                            onClick={() => remove(index)}>
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '10px'
                        }}>
                        <TextField
                          disabled={true}
                          value={
                            getById(ingredientOptions, ingredient.ingredient)
                              ?.name
                          }
                        />
                        <TextField
                          {...register(`ingredients.${index}.quantity`, {
                            required: true
                          })}
                          type="number"
                          inputProps={{
                            maxLength: 13,
                            step: 0.001
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getById(
                                  ingredientOptions,
                                  ingredient.ingredient
                                )?.unit?.code || ''}
                              </InputAdornment>
                            )
                          }}
                          variant="outlined"
                        />
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <FormControlLabel
                          label="Dine In"
                          control={
                            <Controller
                              name={`ingredients.${index}.consumedIn.dineIn`}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Take Away"
                          control={
                            <Controller
                              name={`ingredients.${index}.consumedIn.takeAway`}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Delivery"
                          control={
                            <Controller
                              name={`ingredients.${index}.consumedIn.delivery`}
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>

                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddIngredientSet;

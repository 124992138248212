import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import customerUrls from './customerUrl';

// here start
const accessToken = localStorage.getItem('accessToken');

// get all Customers Data API
export const searchCustomerApi = async (number) => {
  const apiParams = `digits=${number}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${customerUrls.searchCustomer}?${apiParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${customerUrls.searchCustomer}?${apiParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Customer API

export const addCustomerApi = async (customerData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${customerUrls.addCustomer}`,
      customerData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${customerUrls.addCustomer}`,
        customerData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// add customer Address API
export const addCustomerAddressApi = async (userId, addressData) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.post(
      `${customerUrls.user}/${userId}/address`,
      addressData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${customerUrls.user}/${userId}/address`,
        addressData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit customer Address API
export const editCustomerAddressApi = async (
  userId,
  addressId,
  addressData
) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.put(
      `${customerUrls.user}/${userId}/address/${addressId}`,
      addressData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${customerUrls.user}/${userId}/address/${addressId}`,
        addressData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Delete customer Address API
export const deleteCustomerAddressApi = async (userId, addressId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.delete(
      `${customerUrls.user}/${userId}/address/${addressId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${customerUrls.user}/${userId}/address/${addressId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get Customer Info API
export const getCustomerInfoApi = async (userId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(`${customerUrls.user}/${userId}/info`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${customerUrls.user}/${userId}/info`,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

// Update customer Name API
export const updateCustomerNameApi = async (userId, nameData) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.put(
      `${customerUrls.user}/${userId}/name`,
      nameData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${customerUrls.user}/${userId}/name`,
        nameData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// add customer Address API
export const addCustomerContactApi = async (userId, contactData) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.post(
      `${customerUrls.user}/${userId}/contact`,
      contactData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${customerUrls.user}/${userId}/contact`,
        contactData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Delete customer Contact API
export const deleteCustomerContactApi = async (customerId, contactId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.delete(
      `${customerUrls.user}/${customerId}/contact/${contactId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${customerUrls.user}/${customerId}/contact/${contactId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

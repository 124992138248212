import { getApi } from '../../../components/Shared/shared';
const baseURL = getApi();

export const wasteUrls = {
  wastes: `${baseURL}/wastes`,
  outletWastes: `${baseURL}/wastes/outlet`,
  itemWaste: `${baseURL}/wastes/items`
};

export default wasteUrls;

// http://103.18.20.49:3637/api/wastes/items

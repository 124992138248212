import { styled } from '@mui/system';
import { TextField, Button, Typography, TableCell } from '@mui/material';

export const StyledTypography = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;

export const TextFields = styled(TextField)`
  margin-top: 2%;
  margin-bottom: 2%;
  padding-right: 4%;
  padding-left: 4%;
  // display: block;
`;
export const AboutTypography = styled(Typography)`
  //   font-size: ${({ myfontsize }) => (myfontsize ? '18px' : '30px')};
  font-size: 35px;
  font-weight: 700;
  text-align: center;
`;

export const SubmitButton = styled(Button)`
  color: #ffffff;
  font-weight: bold;
  background-color: #6d1b7b;
  &:hover {
    background-color: #6d1b7ba1;
  }
`;


export const StyledTableCell = styled(TableCell)`
  text-align: center;
  color: #ffffff;
`;

import { ROUTES } from './routes';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CategoryIcon from '@mui/icons-material/Category';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DatasetIcon from '@mui/icons-material/Dataset';
import OutletIcon from '@mui/icons-material/Outlet';
import TtyIcon from '@mui/icons-material/Tty';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import NoAccountsTwoToneIcon from '@mui/icons-material/NoAccountsTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedbackIcon from '@mui/icons-material/Feedback';
import AddTaskIcon from '@mui/icons-material/AddTask';

let drawerList = [];
drawerList = [
  {
    route: ROUTES.ingredients,
    literal: 'Ingredients',
    Icon: CategoryIcon,
    access: 'ingredients'
  },
  {
    route: ROUTES.ingredientSet,
    literal: 'Ingredient Set',
    Icon: DatasetIcon,
    access: 'ingredientSets'
  },
  {
    route: ROUTES.menu,
    literal: 'Menu',
    Icon: WidgetsIcon,
    access: 'menuItems'
  },
  {
    route: ROUTES.choices,
    literal: 'Choices',
    Icon: AutoAwesomeMotionIcon,
    access: 'choices'
  },
  {
    route: ROUTES.outlet,
    literal: 'Outlets',
    Icon: OutletIcon,
    access: 'viewOutlets'
  },
  {
    route: ROUTES.callCenter,
    literal: 'Call Center',
    Icon: TtyIcon,
    access: 'callCenter'
  },
  {
    route: ROUTES.users,
    literal: 'Users',
    Icon: ManageAccountsIcon,
    access: 'userManagement'
  },
  {
    route: ROUTES.deliveryMonitor,
    literal: 'Delivery Monitor',
    Icon: DeliveryDiningIcon,
    access: 'deliveryMonitor'
  },
  {
    route: ROUTES.reporting,
    literal: 'Reporting',
    Icon: AssessmentIcon,
    access: 'overallReports'
  },
  {
    route: ROUTES.dashBoard,
    literal: 'DashBoard',
    Icon: DashboardIcon,
    access: 'overallReports'
  },

  {
    route: ROUTES.ingredientManagment,
    literal: 'Managment',
    Icon: RoomPreferencesIcon,
    access: 'ingredients'
  },
  {
    route: ROUTES.blockUser,
    literal: 'Block User',
    Icon: NoAccountsTwoToneIcon,
    access: 'bannedUsers'
  },
  {
    route: ROUTES.feedback,
    literal: 'Feedbacks',
    Icon: FeedbackIcon,
    access: 'feedbacks'
  }
  // {
  //   route: ROUTES.complaint,
  //   literal: 'Complaint',
  //   Icon: AddTaskIcon,
  //   access: 'complaint'
  // }
];

export const DRAWER_LIST = drawerList;

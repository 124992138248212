import { fetchWithAuth } from '../refreshTokenApi';
import callCenterUrl from './callCenterUrl';

// get all running orders API

export const getPendingOrdersApi = async (outlet) => {
  const result = await fetchWithAuth(callCenterUrl.pending, {
    query: {
      outlet
    }
  });
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

export const getUnverifiedOrdersApi = async () => {
  const result = await fetchWithAuth(callCenterUrl.unverified);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// accept order from call center

export const acceptCallCenterOrderApi = async (orderId) => {
  const result = await fetchWithAuth(
    `${callCenterUrl.orders}/${orderId}/accept`,
    {
      method: 'PUT'
    }
  );
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// transfer order from one outlet to other
export const transferCallCenterOrderApi = async (orderId, formData) => {
  const result = await fetchWithAuth(
    `${callCenterUrl.orders}/${orderId}/transfer`,
    {
      body: formData,
      method: 'PUT'
    }
  );
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// Reject call center order API

export const rejectCallCenterOrderApi = async (orderId, managerData) => {
  const result = await fetchWithAuth(
    `${callCenterUrl.orders}/${orderId}/reject`,
    {
      body: {
        ref: {
          key: managerData.key,
          user: managerData.user
        },
        reason: managerData.reason
      },
      method: 'PUT'
    }
  );
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// delete rejected order from branch in call center

export const deleteRejectedOrderApi = async (orderId) => {
  const result = await fetchWithAuth(
    `${callCenterUrl.orders}/${orderId}/rejected`,
    {
      method: 'DELETE'
    }
  );
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

/* eslint-disable no-unused-vars */

import { Box, Typography, styled } from '@mui/material';

export function getApi() {
  let api = process.env.REACT_APP_LOCAL_HOST_API || 'http://127.0.0.1:3000';
  // let dynamic = process.env.REACT_APP_DYNAMIC_IP || false;
  let dynamic = false;
  if (!dynamic) return api;
  let current = window.location.href;
  return (
    current.split(':')[0] +
    ':' +
    current.split(':')[1] +
    ':' +
    api.split(':')[2]
  );
}

export const formatAmount = (value) => {
  const positiveVal = Math.abs(value);

  return (positiveVal || 0).toFixed(2);
};

export const formatQuantity = (value) => {
  const positiveVal = Math.abs(value);
  return (positiveVal || 0).toFixed(3);
};

export const WHITES_CASTLE_IMAGE = `
https://whitescastle.com/_next/image?url=https%3A%2F%2Fem-cdn.eatmubarak.pk%2F55379%2Flogo%2F1668260293.png&w=1920&q=90
`;

export const GLASSES_GIF = `
https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExaGYxbjA5Nmp6Nm5yYThhZ3M4a3RrYzlvdnlvZjNlMW95Nnl5azJneiZlcD12MV9naWZzX3NlYXJjaCZjdD1n/TamGVAGxDTYDNt3dpn/giphy.gif
`;

export const BOY_IMG = `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMhH4QdsdXYuaosCUXBDp7PdXEnFaI5hVMJA&usqp=CAU`;

export const permisssions = {
  modifyOrder: 'orderModifications',
  addDiscount: 'manualDiscount',
  waiter: 'serveOrders',
  rider: 'deliverOrders',
  careOf: 'careOf',
  appDiscount: 'appDiscount'
};

const tzOptions = {
  timeZone: 'Etc/GMT+5',
  timeZoneName: 'short'
};

export const currentSeconds = (dateString = new Date()) => {
  const date = new Date(dateString).getTime();
  const day = 24 * 60 * 60;
  return (((date / 1000) % day) + day) % day;
};

export const inTime = (timing, seconds = currentSeconds()) =>
  timing.start <= timing.end
    ? seconds >= timing.start && timing.end >= seconds
    : seconds <= timing.start && timing.end <= seconds;

export const inTimings = (timings, seconds = currentSeconds()) =>
  timings.some((timing) => inTime(timing, seconds));

// const dateString = currentDate.toLocaleString('en-US', tzOptions);

export const timeDiffMinuts = (timestamp1, timestamp2) => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  const diffInMilliseconds = date2 - date1;
  const diffInMinutes = diffInMilliseconds / (1000 * 60);
  return diffInMinutes;
};

// extract time from time stamp ---> format ===>  12:00 PM
export const extractTime = (timestamp) => {
  const date = new Date(timestamp);
  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  // Extract the time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  let meridiem = 'AM';
  // Convert hours to 12-hour format and determine AM/PM
  if (hours >= 12) {
    meridiem = 'PM';
    hours = hours % 12;
  }
  // Handle midnight and noon
  if (hours === 0) {
    hours = 12;
  }
  // Pad hours with leading zeros
  hours = String(hours).padStart(2, '0');
  // Combine the components into the desired format
  const formattedDate = `${hours}:${minutes} ${meridiem}`;
  return formattedDate;
};

// Time stamp //=> format  13-06-2023 08:37

export const timestamp = (orderTime) => {
  const date = new Date(orderTime);
  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  return formattedDate;
};

export const currentTimeDiff = (time) => {
  const backendTimestamp = new Date(time);
  const currentDate = new Date();

  const backendTimeMilliseconds = backendTimestamp.getTime();
  const currentTimeMilliseconds = currentDate.getTime();

  const timeDifferenceMinutes = Math.floor(
    (currentTimeMilliseconds - backendTimeMilliseconds) / (1000 * 60)
  );

  return timeDifferenceMinutes;
};

// get current Date

export const getCurrentDate = () => {
  const date = new Date().toLocaleString('en-US', {
    timeZone: 'Asia/Karachi'
  });
  const currentDate = new Date(date);
  const hours = currentDate.getHours();
  if (hours < 6) {
    currentDate.setDate(currentDate.getDate() - 1); // show previous date if before 06:00 AM
  }
  return currentDate.toISOString().split('T')[0];
};

export const HeaderTypography = styled(Typography)(() => ({
  fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px'
}));

// Time stamp for recepit component

export const timestampLocalString = (orderTime) => {
  const date = new Date(orderTime).toLocaleString('en-US', tzOptions);
  return date;
  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  return formattedDate;
};

export const timestampLocalString1 = () => {
  const date = new Date().toLocaleString('en-US', tzOptions);
  return date;
  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  return formattedDate;
};

// converting timestamp to unix code

export function convertIsoToUnix(isoTimestamp) {
  const unixTimestamp = new Date(isoTimestamp).getTime();
  return unixTimestamp;
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// function DateFormatter(input) {
//   const joiner = input?.toString()?.split(' ')?.slice(0, 4)?.join(' ');
//   return joiner;
// }

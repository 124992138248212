/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getOrderStatusReportApi } from '../../../api/report/reportApi';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { CountTableCell, HeaderTableCell } from '../Styled-components';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { timestamp } from '../../POS/Shared';
import OrderDetails from './OrderDetails';
import Loader from '../../Loader/Loader';
import { HeaderTypography } from '../../Shared/shared';
import DatePickerComponent from '../../Shared/DatePickerComponent';
import ToggleButton from '../../Shared/Toggle/Toggle';

export default function OrdersStatus() {
  // handling popup for order details

  const [details, setDetails] = useState({ isOpen: false });

  const [checked, setChecked] = useState(false);

  const callFunc = (type) => {
    type == 'Completed' ? setChecked(true) : setChecked(false);
  };
  // handling date
  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  useEffect(() => {
    if (datePopup.isSelected === true) {
      getOrderStatusReport();
    }
  }, [datePopup, checked]);

  // calling APi

  const [orderStatus, setOrderStatus] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const getOrderStatusReport = async () => {
    try {
      setLoading(true);
      const response = await getOrderStatusReportApi(
        datePopup?.startDate,
        datePopup?.endDate,
        !checked ? ['cashedOut'] : []
      );
      setOrderStatus(response?.data);
      setLoading(false);
      setpg(0);
      setSearchQuery('');

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const statusColor = {
    Rejected: 'orangeRed',
    Cancelled: '#ff2313',
    Completed: 'darkGreen',
    'On The Way': 'orange',
    Delivered: 'darkGreen',
    Preparing: '#2ecc71',
    Pending: 'purple'
  };
  const orderStatusMap = orderStatus?.map((report) => {
    const invoiceId = report._id.slice(-5);
    const status =
      report.cancelled && !report.accepted
        ? 'Rejected'
        : report.cancelled
        ? 'Cancelled'
        : report.cashedOut
        ? 'Completed'
        : report?.delivered
        ? 'Delivered'
        : report.dispatched
        ? 'On The Way'
        : report.accepted
        ? 'Preparing'
        : 'Pending';
    const orderFrom =
      report.acceptedBy?._id !== report.user?._id
        ? 'Call Center'
        : 'Branch End';
    const grandTotal =
      (report?.chargesTax || 0) +
      report?.totalCharges +
      (report?.tax || 0) +
      report?.total;
    const timeString = timestamp(report?.orderTime);
    return {
      ...report,
      invoiceId: invoiceId.toUpperCase(),
      status,
      orderFrom,
      grandTotal,
      timeString,
      searchString: (
        invoiceId +
        timeString +
        status +
        grandTotal +
        orderFrom +
        report?.user?.name +
        report?.customerName +
        report?.customerContact +
        report?.deliveryAddress +
        report?.outlet?.tag +
        report?.grandTotal
      ).toLowerCase()
    };
  });

  const filteredOrders = orderStatusMap?.filter((report) => {
    const reportString = report.searchString;
    const terms = searchQuery.toLowerCase().split(' ');
    return terms.every((term) => {
      return reportString.includes(term);
    });
  });

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const totalAmount = orderStatus?.reduce(
    (prev, current) =>
      prev +
      (current?.chargesTax || 0) +
      current?.totalCharges +
      (current?.tax || 0) +
      current?.total,
    0
  );

  const exportOrders = filteredOrders?.map((order) => ({
    orderTime: timestamp(order?.orderTime),
    total: Math.round(order?.total),
    paymentMethodName: order?.paymentMethod?.name,
    cashierName: order?.cashier?.name,
    outletName: order?.outlet?.tag,
    userName: order?.user?.name,
    paymentMethod: order?.paymentMethod?.name
  }));

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Order"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <HeaderTypography
          sx={{
            fontWeight: 600,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
          }}>
          Order Status
        </HeaderTypography>

        <ToggleButton
          callFunc={callFunc}
          states={{ first: 'Running', second: 'Completed' }}
        />
        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#008670',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>ID</HeaderTableCell>
                <HeaderTableCell>Order Time</HeaderTableCell>
                <HeaderTableCell>From</HeaderTableCell>
                <HeaderTableCell>Order source</HeaderTableCell>
                <HeaderTableCell>Cust. Name</HeaderTableCell>
                <HeaderTableCell>Contact</HeaderTableCell>
                <HeaderTableCell>Address</HeaderTableCell>
                <HeaderTableCell>Type</HeaderTableCell>
                <HeaderTableCell>Branch</HeaderTableCell>
                <HeaderTableCell>Total</HeaderTableCell>
                <HeaderTableCell>Punched By</HeaderTableCell>
                <HeaderTableCell>Order Status</HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{report?.invoiceId}</TableCell>
                        <TableCell>{report?.timeString}</TableCell>
                        <TableCell>{report?.orderFrom}</TableCell>
                        <TableCell>{report?.source?.name}</TableCell>

                        <TableCell>{report?.customerName || 'N/A'}</TableCell>
                        <TableCell>
                          {report?.customerContact || 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={report?.deliveryAddress || 'Not Available'}
                            placement="top">
                            <Typography
                              sx={{
                                fontSize: '12px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                // whiteSpace: 'nowrap',
                                maxWidth: '150px'
                              }}>
                              {report?.deliveryAddress || 'N/A'}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{report?.orderType || ''}</TableCell>
                        <TableCell> {report?.outlet.tag || ''} </TableCell>
                        <TableCell>
                          {Math.round(report?.grandTotal) || 0}
                        </TableCell>
                        <TableCell>{report?.user?.name || 0}</TableCell>
                        <Tooltip
                          title={
                            <Typography
                              style={{
                                fontSize: '18px'
                              }}>
                              {report?.carrier?.name && report?.carrier?.phone
                                ? `${report?.carrier?.name} ${report?.carrier?.phone}`
                                : 'Not Available'}
                            </Typography>
                          }
                          placement="top">
                          <TableCell
                            sx={{ width: '150px', textAlign: 'center' }}>
                            <b
                              style={{
                                backgroundColor: statusColor[report?.status],
                                padding: '8px 12px',
                                borderRadius: '6px',
                                color: 'white'
                              }}>
                              {report?.status}
                            </b>
                          </TableCell>
                        </Tooltip>

                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({ isOpen: true, id: report?._id });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
          <TableRow>
            <CountTableCell>Total orders:</CountTableCell>
            <CountTableCell>{orderStatus?.length}</CountTableCell>
            <CountTableCell>Total Sales:</CountTableCell>
            <CountTableCell>{totalAmount}</CountTableCell>
          </TableRow>
        </TableContainer>
      )}

      {/* <CsvDownloader
        datas={exportOrders}
        text="Export to CSV"
        filename="Call Center Report"
      /> */}
      <OrderDetails details={details} setDetails={setDetails} />
    </>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { deleteIngredientSetApi } from '../../api/ingredientSet/ingredientSetApi';

export default function DeleteIngredientSet({ value, callFunc, setValue }) {
  const deleteRow = async (id) => {
    try {
      const response = await deleteIngredientSetApi(id);
      //   notify(response);
    } catch (e) {}
    callFunc();
    setValue({ isOpen: false });
    handleClose();
  };

  const handleClose = () => {
    callFunc();
    setValue({ isOpen: false });
  };

  return (
    <div>
      <Dialog
        open={value.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            cancel
          </Button>
          <Button onClick={() => deleteRow(value.id)}>Delete </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { getApi } from '../../../components/Shared/shared';
const baseURL = getApi();

export const expensesUrls = {
  expenses: `${baseURL}/expenses`,
  outletExpenses: `${baseURL}/expenses/outlet`,
  expenseCategories: `${baseURL}/expense-categories`
};

export default expensesUrls;

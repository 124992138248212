/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  getDaySheetApi,
  getOutletWiseReportApi
} from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import { getOutletsApi } from '../../api/outlet/outletApi';
import {
  CountTableCell,
  HoverTableRow,
  TableCellBorder
} from './Styled-components';
import { DateBox } from '../Shared/Shared-Styled-Components';
import DatePickerComponent from '../Shared/DatePickerComponent';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';

export default function OutletSaleReport() {
  const dispatch = useDispatch();
  const [outlets, setOutlets] = useState([]);

  const [outletSaleReport, setOutletSaleReport] = useState([]);

  const [selectOutlet, setSelectOutlet] = useState('');

  const handleChange = (event) => {
    setSelectOutlet(event.target.value);
  };

  // getting all outlets
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };
  // handlng date

  useEffect(() => {
    getOutlets();
  }, []);

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const reportApi = async () => {
    if (selectOutlet === '') {
      dispatch(
        addSnackbar({
          message: 'Kindly Select an Outlet...',
          type: 'warning'
        })
      );
    } else {
      try {
        const resp = await getDaySheetApi(selectOutlet, datePopup.startDate);
        setLoading(true);
        const response = await getOutletWiseReportApi(
          selectOutlet,
          datePopup?.startDate,
          datePopup?.endDate
        );
        setOutletSaleReport(response?.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  const totals = outletSaleReport?.reduce(
    (acc, entry) => {
      entry.orders.forEach((order) => {
        if (order.orderType === 'dineIn') {
          acc.dineIn.sales += order.sale;
          acc.dineIn.count += order.count;
        } else if (order.orderType === 'takeAway') {
          acc.takeAway.sales += order.sale;
          acc.takeAway.count += order.count;
        } else if (order.orderType === 'delivery') {
          acc.delivery.sales += order.sale;
          acc.delivery.count += order.count;
        }
      });

      acc.cancelledSales += entry.cancelledSale;
      acc.cancelledCount += entry.cancelledCount;
      acc.totalDiscount += entry.discount;

      return acc;
    },
    {
      dineIn: { sales: 0, count: 0 },
      takeAway: { sales: 0, count: 0 },
      delivery: { sales: 0, count: 0 },
      cancelledSales: 0,
      cancelledCount: 0,
      totalDiscount: 0
    }
  );

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Branch Wise Sale Report
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel>Select Outlet</InputLabel>
            <Select
              value={selectOutlet}
              label="Select Outlet"
              onChange={handleChange}>
              {outlets.map((outlet, i) => (
                <MenuItem key={i} value={outlet._id}>
                  {outlet.tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DatePickerComponent
            datePopup={datePopup}
            setDatePopup={setDatePopup}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ background: '#037DFF', color: 'white' }}>
                <TableCellBorder align="center">Date</TableCellBorder>
                <TableCellBorder align="center" colSpan={2}>
                  DineIn
                </TableCellBorder>
                <TableCellBorder align="center" colSpan={2}>
                  TakeAway
                </TableCellBorder>
                <TableCellBorder align="center" colSpan={2}>
                  Delivery
                </TableCellBorder>
                <TableCellBorder align="center" colSpan={2}>
                  Canceled
                </TableCellBorder>
                <TableCellBorder align="center">Total Discount</TableCellBorder>
                {/* <TableCellBorder align="center">Total Count</TableCellBorder> */}

                {/* <TableCellBorder align="center">Total Sales</TableCellBorder> */}
                {/* <TableCellBorder align="center">
                  Total Average Sales
                </TableCellBorder> */}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCellBorder align="center">Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                {/* <TableCellBorder align="center">Average sale</TableCellBorder> */}

                <TableCellBorder align="center"> Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                {/* <TableCellBorder align="center">Average sale</TableCellBorder> */}

                <TableCellBorder align="center"> Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                {/* <TableCellBorder align="center">Average sale</TableCellBorder> */}

                <TableCellBorder align="center"> Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                {/* <TableCellBorder align="center">Average sale</TableCellBorder> */}
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {outletSaleReport
                  ?.sort((a1, a2) => (a1.saleDate < a2.saleDate ? -1 : 1))
                  ?.map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#037DFFa5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell align="center">{report?.saleDate}</TableCell>

                        {/* dineIn section */}
                        {report.orders
                          .filter((order) => order.orderType == 'dineIn')
                          ?.map((order, i) => (
                            <React.Fragment key={i}>
                              <TableCell align="center">
                                {order.count}
                              </TableCell>
                              <TableCell align="center">
                                {Math.round(order.sale)}
                              </TableCell>
                            </React.Fragment>
                          ))}

                        {/* takeAway section */}
                        {report.orders
                          .filter((order) => order.orderType == 'takeAway')
                          ?.map((order, i) => (
                            <React.Fragment key={i}>
                              <TableCell align="center">
                                {order.count}
                              </TableCell>
                              <TableCell align="center">
                                {Math.round(order.sale)}
                              </TableCell>
                            </React.Fragment>
                          ))}
                        {/* delivery section */}
                        {report.orders
                          .filter((order) => order.orderType == 'delivery')
                          ?.map((order, i) => (
                            <React.Fragment key={i}>
                              <TableCell align="center">
                                {order.count}
                              </TableCell>
                              <TableCell align="center">
                                {Math.round(order.sale)}
                              </TableCell>
                            </React.Fragment>
                          ))}

                        {/* cancled section */}
                        <TableCell align="center">
                          {report?.cancelledCount}
                        </TableCell>
                        <TableCell align="center">
                          {report?.cancelledSale}
                        </TableCell>
                        <TableCell align="center">
                          {Math.round(report?.discount)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <TableCellBorder>
                    {/* Total Hours: <b> {saleReport.length}</b> */}
                  </TableCellBorder>
                  {/* dineIn section  */}
                  <TableCellBorder>
                    DineIn Count:
                    <b>{totals?.dineIn.count}</b>
                  </TableCellBorder>
                  <TableCellBorder>
                    DineIn Sales:
                    <b>{Math.round(totals.dineIn.sales)}</b>
                  </TableCellBorder>

                  {/* takeAway avg section  */}
                  <TableCellBorder>
                    TakeAway Count:
                    <b>{totals?.takeAway?.count}</b>
                  </TableCellBorder>
                  <TableCellBorder>
                    TakeAway Sales:
                    <b>{Math.round(totals?.takeAway?.sales)}</b>
                  </TableCellBorder>

                  {/* delivery section  */}
                  <TableCellBorder>
                    Delivery Count:
                    <b>{totals?.delivery?.count}</b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Delivery Sales:
                    <b>{Math.round(totals?.delivery?.sales)}</b>
                  </TableCellBorder>

                  {/* Cancled section  */}
                  <TableCellBorder>
                    Cancled Count:
                    <b>{totals.cancelledCount}</b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Cancled Sales:
                    <b>{Math.round(totals.cancelledSales)}</b>
                  </TableCellBorder>

                  {/* total discount */}
                  <TableCellBorder>
                    Total Discount:
                    <b>{Math.round(totals.totalDiscount)}</b>
                  </TableCellBorder>

                  {/* <TableCellBorder>
                    Total Count:
                    <b></b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Total Sales:
                    <b></b>
                  </TableCellBorder> */}
                </TableRow>
              </>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <HoverTableRow>
                <CountTableCell>Total Orders:</CountTableCell>
                <CountTableCell>
                  {totals.dineIn.count +
                    totals.takeAway.count +
                    totals.delivery.count}
                </CountTableCell>
              </HoverTableRow>
              <HoverTableRow>
                <CountTableCell>Total sales:</CountTableCell>
                <CountTableCell>
                  {totals.dineIn.sales +
                    totals.takeAway.sales +
                    totals.delivery.sales}
                </CountTableCell>
              </HoverTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

const urls = {
  auth: {
    signup: `${baseURL}/auth/register`,
    login: `${baseURL}/auth/login`,
    refresh: `${baseURL}/auth/refresh`,
    logout: '/auth/logout',
    forget_password: '/auth/forgotPassword/otp',
    update_forget_password: '/auth/forgotPassword'
  }
};

export default urls;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Chip,
  OutlinedInput,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { Cancel } from '@mui/icons-material';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { addAreasApi, deleteAreasApi } from '../../api/areas/areaAPi';

const AddEditAreas = ({ addAreasPopup, setAddAreasPopup, callFunc }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {}
  });

  const [outlets, setOutlets] = useState();
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };

  useEffect(() => {
    if (addAreasPopup.id) {
      reset();
    }
    getOutlets();
  }, [addAreasPopup.isOpen]);

  const onSubmit = async (data) => {
    const res = await addAreasApi(data);
    if (res.status == 200) {
      dispatch(
        addSnackbar({
          message: 'New Area Added',
          type: 'success'
        })
      );
      handleClose();
      callFunc();
    } else {
      dispatch(
        addSnackbar({
          message: res.response.data.message,
          type: 'error'
        })
      );
    }
  };

  const handleClose = () => {
    setAddAreasPopup({ isOpen: false });
    reset();
  };

  return (
    <Box>
      <Container>
        <Dialog open={addAreasPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {addAreasPopup?.id ? (
                <Typography color="#FFA500" fontWeight="700">
                  Delete Area
                </Typography>
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Add New Area
                </Typography>
              )}
            </DialogTitle>
            {!addAreasPopup.id ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('name', { required: true })}
                    margin="normal"
                    label="Enter Area Name i.e Style City (Eden Garden Nawab Block), Masjid Ismail Road"
                    fullWidth
                  />

                  <TextField
                    {...register('city', { required: true })}
                    margin="normal"
                    label="Enter City Name"
                    fullWidth
                  />
                  <TextField
                    {...register('desc')}
                    margin="normal"
                    label="Description"
                    fullWidth
                  />

                  <Controller
                    control={control}
                    name="assignedOutlets"
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl fullWidth sx={{ margin: '10px 0' }}>
                        <InputLabel>Outlets</InputLabel>
                        <Select
                          multiple
                          {...field}
                          input={<OutlinedInput label="outlet" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map((value, i) => (
                                <Chip
                                  key={i}
                                  label={value.tag}
                                  clickable
                                  deleteIcon={
                                    <Cancel
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  onDelete={() => {
                                    const newValue = field.value.filter(
                                      (item) => item._id !== value._id
                                    );

                                    field.onChange(newValue);
                                  }}
                                />
                              ))}
                            </Box>
                          )}>
                          {outlets
                            ?.filter(
                              (outlet) =>
                                !field.value
                                  .map((v) => v._id)
                                  .includes(outlet._id)
                            )
                            .map((outlet) => (
                              <MenuItem
                                key={outlet._id}
                                value={{ tag: outlet.tag, _id: outlet._id }}>
                                {outlet.tag}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <TextField
                    {...register('priority')}
                    margin="normal"
                    label="priority"
                    type="number"
                    fullWidth
                  />

                  <TextField
                    {...register('polygon')}
                    margin="normal"
                    label="polygon"
                    fullWidth
                  />
                  <FormControlLabel
                    control={<Checkbox {...register('active')} />}
                    label="active"
                  />

                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>Are you sure to delete this Area?</DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      const res = await deleteAreasApi(addAreasPopup?.id);
                      if (res.status == 200) {
                        dispatch(
                          addSnackbar({
                            message: 'Area Deleted',
                            type: 'warning'
                          })
                        );
                        handleClose();
                        callFunc();
                      }
                    }}>
                    Delete
                  </Button>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditAreas;

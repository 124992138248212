/* eslint-disable no-unused-vars */
import axios from 'axios';
import { fetchWithAuth, refreshAccessToken } from '../refreshTokenApi';
import ordersUrl from './OrdersUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// Add Order API

export const addOrdersApi = async (orderData) => {
  const result = await fetchWithAuth(ordersUrl.orders, {
    body: orderData,
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// get all running orders API

export const getRunningOrdersApi = async (outlet) => {
  const result = await fetchWithAuth(ordersUrl.running, {
    query: { outlet }
  });
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// get all dineIn and cashed out orders API

export const getDineInToFeedbackOrdersApi = async (outlet) => {
  const result = await fetchWithAuth(ordersUrl.dineInToFeedback, {
    query: { outlet }
  });
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// {{API}}/api/orders/no-feedback?outlet={{JC}}

// get a single Order

export const getSingleOrderApi = async (orderId) => {
  if (!orderId) return null;
  const result = await fetchWithAuth(`${ordersUrl.orders}/${orderId}`);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// modify Outlet API

// delete order API

// export const deleteOrderApi = async (orderId, managerData) => {
//   const result = await fetchWithAuth(`${ordersUrl.orders}/${orderId}`, {
//     body: {
//       ref: {
//         key: managerData.key,
//         user: managerData.user
//       },
//       reason: managerData.reason
//     },
//     method: 'DELETE'
//   });
//   if (result.message) return { error: { data: { message: result.message } } };
//   return { data: result };
// };

export const deleteOrderApi = async (orderId, managerData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
    data: {
      ref: {
        key: managerData.key,
        user: managerData.user
      },
      reason: managerData.reason
    }
  };

  try {
    const response = await axios.delete(
      `${ordersUrl.orders}/${orderId}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` },
        data: {
          ref: {
            key: managerData.key,
            user: managerData.user
          },
          reason: managerData.reason
        }
      };
      try {
        const newResponse = await axios.delete(
          `${ordersUrl.orders}/${orderId}`,
          newConfig
        );
        return newResponse;
      } catch (error) {
        console.error(error);
        throw new Error('Failed to delete order');
      }
    } else {
      console.error(error);
      throw new Error('Failed to delete order');
    }
  }
};

// Order Cashout Api

export const cashoutOrderApi = async (formData, orderId) => {
  const result = await fetchWithAuth(`${ordersUrl.orders}/${orderId}/cashout`, {
    body: formData,
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// export const cashoutOrderAp = async (formData, orderId) => {
//   const config = {
//     headers: { Authorization: `Bearer ${accessToken}` }
//   };
//   try {
//     const data = await axios.post(
//       `${ordersUrl.orders}/${orderId}/cashout`,
//       formData,
//       config
//     );
//     return data;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const newAccessToken = await refreshAccessToken();
//       const newConfig = {
//         headers: { Authorization: `Bearer ${newAccessToken}` }
//       };
//       const data = await axios.post(
//         `${ordersUrl.orders}/${orderId}/cashout`,
//         formData,
//         newConfig
//       );
//       return data;
//     } else {
//       console.error(error);
//       return error;
//     }
//   }
// };

// get all running Running Orders Display API

export const getOrderOperationDisplayApi = async (outletId, displayId) => {
  const result = await fetchWithAuth(`${ordersUrl.operation}`, {
    query: { outlet: outletId, display: displayId }
  });
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// export const getOrderOperationDisplayAp = async (outletId, displayId) => {
//   const outletParams = `outlet=${outletId}`;
//   const displayParams = `display=${displayId}`;
//   const config = { headers: { Authorization: `Bearer ${accessToken}` } };

//   try {
//     const data = await axios.get(
//       `${ordersUrl.operation}?${outletParams}&${displayParams}`,
//       config
//     );
//     return data;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const newAccessToken = await refreshAccessToken();
//       const newConfig = {
//         headers: { Authorization: `Bearer ${newAccessToken}` }
//       };
//       const data = await axios.get(
//         `${ordersUrl.operation}?${outletParams}&${displayParams}`,
//         newConfig
//       );
//       return data;
//     } else {
//       console.error(error);
//       return null;
//     }
//   }
// };

// Order Preparing Status API

export const orderPrepStatusApi = async (orderId, itemId, optionId) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/items/${itemId}/choices/${optionId}/preparing`,
      null,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/items/${itemId}/choices/${optionId}/preparing`,
        null,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Order Done Status API

export const orderReadyStatusApi = async (orderId, itemId, optionId) => {
  const result = await fetchWithAuth(
    `${ordersUrl.orders}/${orderId}/items/${itemId}/choices/${optionId}/ready`,
    {
      method: 'POST'
    }
  );
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// export const orderReadyStatusApi = async (orderId, itemId, optionId) => {
//   const config = {
//     headers: { Authorization: `Bearer ${accessToken}` }
//   };
//   try {
//     const data = await axios.post(
//       `${ordersUrl.orders}/${orderId}/items/${itemId}/choices/${optionId}/ready`,
//       null,
//       config
//     );
//     return data;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const newAccessToken = await refreshAccessToken();
//       const newConfig = {
//         headers: { Authorization: `Bearer ${newAccessToken}` }
//       };
//       const data = await axios.post(
//         `${ordersUrl.orders}/${orderId}/items/${itemId}/choices/${optionId}/ready`,
//         null,
//         newConfig
//       );
//       return data;
//     } else {
//       console.error(error);
//       return null;
//     }
//   }
// };

// Order Done Status API

export const orderAllReadyApi = async (orderId, displayId) => {
  const displayParams = `display=${displayId}`;

  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/ready?${displayParams}`,
      null,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/ready?${displayParams}`,
        null,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// =============================== Modify Order Section ===============================

// Delete Item from to be Modified Order API

export const deleteItemFromOrderApi = async (orderId, itemId, managerData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
    data: {
      ref: {
        key: managerData.key,
        user: managerData.user
      },
      reason: managerData.reason
    }
  };

  try {
    const response = await axios.delete(
      `${ordersUrl.orders}/${orderId}/items/${itemId}`,
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` },
        data: {
          ref: {
            key: managerData.key,
            user: managerData.user
          },
          reason: managerData.reason
        }
      };
      try {
        const newResponse = await axios.delete(
          `${ordersUrl.orders}/${orderId}/items/${itemId}`,
          newConfig
        );
        return newResponse;
      } catch (error) {
        console.error(error);
        throw new Error('Failed to delete Item');
      }
    } else {
      console.error(error);
      throw new Error('Failed to delete Item');
    }
  }
};

// Edit Menu Item API
export const addItemInOrderApi = async (orderId, orderData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${ordersUrl.orders}/${orderId}/items`,
      orderData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${ordersUrl.orders}/${orderId}/items`,
        orderData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Edit Discount API

export const modifyDiscountApi = async (orderId, orderData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${ordersUrl.orders}/${orderId}/discount`,
      orderData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${ordersUrl.orders}/${orderId}/discount`,
        orderData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Edit Charges API

export const modifyChargesApi = async (orderId, orderData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${ordersUrl.orders}/${orderId}/charges`,
      orderData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${ordersUrl.orders}/${orderId}/charges`,
        orderData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get all ready-to-dispatch orders

export const getReadyToDispatchApi = async (outletId) => {
  const outletParams = `outlet=${outletId}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${ordersUrl.dispatch}?${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${ordersUrl.dispatch}?${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get all ready-to-serve orders

export const getReadyToServeApi = async (outletId) => {
  const outletParams = `outlet=${outletId}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(`${ordersUrl.serve}?${outletParams}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${ordersUrl.serve}?${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};
//  Order Dispatch API

export const serveOrderApi = async (orderId) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/dispatch`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/dispatch`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

//  Order Dispatch API

export const dispatchOrderApi = async (orderId, riderData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/dispatch`,
      riderData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/dispatch`,
        riderData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get all Cut-table/ready-to-assemble orders

export const getReadyToAssembleApi = async (outletId) => {
  const outletParams = `outlet=${outletId}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${ordersUrl.assemble}?${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${ordersUrl.assemble}?${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

//  Order Assemble API

export const assembleOrderApi = async (orderId) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/assemble`,
      null,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/assemble`,
        null,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// complimentary Order Cashout Api

export const complimentaryOrderApi = async (managerData, orderId) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  try {
    const response = await axios.post(
      `${ordersUrl.orders}/${orderId}/free`,
      {
        ref: {
          key: managerData.key,
          user: managerData.user
        },
        reason: managerData.reason
      },
      config
    );
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      try {
        const newResponse = await axios.post(
          `${ordersUrl.orders}/${orderId}/free`,
          {
            ref: {
              key: managerData.key,
              user: managerData.user,
              reason: managerData.reason
            }
          },
          newConfig
        );
        return newResponse;
      } catch (error) {
        console.error(error);
        throw new Error('Failed Complimentry order');
      }
    } else {
      console.error(error);
      throw new Error('Failed to Complimentry order');
    }
  }
};

// export const complimentaryOrderApi = async (managerData, orderId) => {
//   // const config = {
//   //   headers: { Authorization: `Bearer ${accessToken}` }
//   // };

//   const config = {
//     headers: { Authorization: `Bearer ${accessToken}` },
//     ref: {
//       key: managerData.key,
//       user: managerData.user,
//       reason: managerData.reason
//     }
//   };
//   try {
//     const response = await axios.post(
//       `${ordersUrl.orders}/${orderId}/free`,
//       ref,
//       config
//     );
//     return response;
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       const newAccessToken = await refreshAccessToken();
//       const newConfig = {
//         headers: { Authorization: `Bearer ${newAccessToken}` },
//         ref: {
//           key: managerData.key,
//           user: managerData.user,
//           reason: managerData.reason
//         }
//       };
//       try {
//         const newResponse = await axios.post(
//           `${ordersUrl.orders}/${orderId}/free`,
//           ref,
//           newConfig
//         );
//         return newResponse;
//       } catch (error) {
//         console.error(error);
//         throw new Error('Failed to delete Item');
//       }
//     } else {
//       console.error(error);
//       throw new Error('Failed to delete Item');
//     }
//   }
//   // try {
//   //   const data = await axios.post(
//   //     `${ordersUrl.orders}/${orderId}/free`,
//   //     formData,
//   //     config
//   //   );
//   //   return data;
//   // }

//   // catch (error) {
//   //   if (error.response && error.response.status === 401) {
//   //     const newAccessToken = await refreshAccessToken();
//   //     const newConfig = {
//   //       headers: { Authorization: `Bearer ${newAccessToken}` }
//   //     };
//   //     const data = await axios.post(
//   //       `${ordersUrl.orders}/${orderId}/free`,
//   //       formData,
//   //       newConfig
//   //     );
//   //     return data;
//   //   } else {
//   //     console.error(error);
//   //     return null;
//   //   }
//   // }
// };

// get all ready-to-deliver orders

export const getReadyToDeliverApi = async (outletId) => {
  const outletParams = `outlet=${outletId}`;

  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${ordersUrl.deliver}?${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${ordersUrl.deliver}?${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

//  Order deliver API

export const deliverOrderApi = async (orderId) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${ordersUrl.orders}/${orderId}/deliver`,
      null,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${ordersUrl.orders}/${orderId}/deliver`,
        null,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

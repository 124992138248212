/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Grid,
  Divider,
  LinearProgress,
  Rating,
  CircularProgress
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOutletsApi } from '../../api/outlet/outletApi';
import Popup from './popup';
import {
  getFeedbackSummaryApi,
  getOutletsRatingApi
} from '../../api/feedback/feedbackApi';

function getRGB(c) {
  return parseInt(c, 16) || c;
}

function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor) {
  if (!hexColor) return 0;
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}

function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

function getContrastColor(color) {
  const whiteContrast = getContrast(color, '#F0F0F0');
  const blackContrast = getContrast(color, '#1A1A1A');
  return whiteContrast > blackContrast ? '#f0f0f0' : '#1A1A1A'; // ? "#ffffff" : "#000000";
}

const Outlet = () => {
  const [outlets, setOutlets] = useState([]);
  const [outletFeedback, setOutletFeedback] = useState([]);
  const [yearly, setYearly] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const outletResponse = useSelector(
    (state) => state.authorize?.loginResponse?.user?.outlets
  );
  const user = useSelector((state) => state.authorize?.loginResponse?.user);

  useEffect(() => {
    setOutlets(outletResponse);
    // callApi();
  }, []);

  const [popup, setPopup] = useState(false);
  const [selected, setSelected] = useState({});

  const openEditPopup = (item) => {
    setSelected(item._id);
    setPopup(true);
  };

  const closePopup = () => {
    setPopup(false);
  };

  const openAddPopup = () => {
    setSelected('');
    setPopup(true);
  };

  // Calling API
  const ratingApi = async () => {
    try {
      setIsLoading(true);
      const response = await getOutletsRatingApi(30);
      const respons = await getOutletsRatingApi(365);
      setOutletFeedback(response?.data);
      setYearly(respons?.data);
    } catch (e) {
      console.error('Error fetching feedback summary:', e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    ratingApi();
  }, []);
  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Outlets
        </Typography>
        <div>
          {user?.role?.name == 'super' && (
            <Button variant="contained" onClick={openAddPopup}>
              Add New Outlet
            </Button>
          )}
        </div>
      </Box>
      <Popup isOpened={popup} item={selected} handleClose={closePopup} />
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 1, sm: 2, md: 3 }}>
        {outlets?.map((item, index) => {
          const outletFeed = outletFeedback?.find(
           (feedback) => item._id === feedback._id
          ); 

          const outletYearlyFeed = yearly?.find(
            (feedback) => item._id === feedback._id
          );

          return (
            <Grid item key={index} xs={1} sm={1} md={1}>
              <Card sx={{ minWidth: 275, minHeight: 200 }}>
                <CardContent>
                  <Box
                    sx={{
                      backgroundColor: item.color,
                      height: '65px',
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '4px',
                      marginTop: 0,
                      marginBottom: '3px'
                    }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 'bold',
                        color: getContrastColor(item.color)
                      }}
                      component="div">
                      {item.tag}
                    </Typography>
                  </Box>
                  <Typography color="text.secondary">{item.address}</Typography>
                </CardContent>
                <CardActions
                  sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <NavLink to={`/outlet/${item.code.toLowerCase()}`}>
                    <Button variant="contained" size="small">
                      Enter
                    </Button>
                  </NavLink>
                  {(user?.role?.name == 'super' ||
                    user?.role?.access.includes('outlets')) && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => openEditPopup(item)}>
                      Edit
                    </Button>
                  )}
                </CardActions>
                {/* add rating section */}
                <Divider />
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around'
                    }}>
                    <CircularProgress size={24} />
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Box sx={ratingBarStyle} key={index}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>M:</Typography>

                      <Rating
                        size="small"
                        precision={0.5}
                        value={outletFeed?.averageRating || 0}
                        // value={4}
                        readOnly
                      />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>Y:</Typography>
                      <Rating
                        size="small"
                        precision={0.5}
                        value={outletYearlyFeed?.averageRating || 0}
                        // value={4}
                        readOnly
                      />
                    </Box>

                    {/* <Box sx={barStyle}>
                    <LinearProgress
                      sx={filledBarStyle}
                      variant="determinate"
                      value={9}
                    />
                  </Box> */}
                    {/* <Box style={{ width: '' }}>
                    <Typography variant="body2">
                      ({outletFeed?.totalFeedbacks || 0})
                    </Typography>
                  </Box> */}
                  </Box>
                )}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const ratingBarStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5px 10px'
};
const barStyle = {
  flex: 1,
  backgroundColor: '#e0e0e0',
  borderRadius: 1,
  overflow: 'hidden',
  margin: '0 10px',
  height: 10
  // width: '60%'
};
const filledBarStyle = (width) => ({
  height: '100%',
  //   backgroundColor: '#333',
  width: width
});

export default Outlet;

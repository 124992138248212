import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import menuCaterogy from './menuCategoryUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// // get a single Menu Category
// export const getSingleMenuCategoryApi = async (categoryId) => {
//   if (!categoryId) {
//     return null;
//   }
//   return apiCall('get', `${menuCaterogy.category}/${categoryId}`);
// };

// // Add Menu Category API
// export const addMenuCategoryApi = async (categoryData) => {
//   return apiCall('post', menuCaterogy.category, categoryData);
// };

export const deleteMenuCategoryApi = async (categoryId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!categoryId) {
    return null;
  }
  try {
    const data = await axios.delete(
      `${menuCaterogy.category}/${categoryId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.delete(
        `${menuCaterogy.category}/${categoryId}`,
        newConfig
      );
      return data;
    } else {
      return null;
    }
  }
};

// // Edit Menu Category API
// export const editMenuCategoryApi = async (categoryId, categoryData) => {
//   return apiCall('put', `${menuCaterogy.category}/${categoryId}`, categoryData);
// };

// get all Menu Categories API
export const getMenuCategoriesApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(menuCaterogy.category, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(menuCaterogy.category, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get a single Menu Category
export const getSingleMenuCategoryApi = async (categoryId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!categoryId) {
    return null;
  }
  try {
    const data = await axios.get(
      `${menuCaterogy.category}/${categoryId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${menuCaterogy.category}/${categoryId}`,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

// Add Menu Category API
export const addMenuCategoryApi = async (categoryData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(menuCaterogy.category, categoryData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        menuCaterogy.category,
        categoryData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Menu Category API
export const editMenuCategoryApi = async (categoryId, categoryData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${menuCaterogy.category}/${categoryId}`,
      categoryData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${menuCaterogy.category}/${categoryId}`,
        categoryData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Menu Category API

export const deleteChoiceApi = async (categoryId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${menuCaterogy.category}/${categoryId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${menuCaterogy.category}/${categoryId}`,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

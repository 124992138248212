import { createSlice } from '@reduxjs/toolkit';

export const snackbarInitialState = {
  open: false,
  type: 'info',
  message: '',
  timeout: 3000
};

export const SnackbarSlice = createSlice({
  name: 'snackbar',
  initialState: snackbarInitialState,
  reducers: {
    addSnackbar: (_state, action) => ({
      ...snackbarInitialState,
      ...action.payload,
      open: true
    }),
    clearSnackbar: (state) => ({ ...state, open: false })
  }
});

export const { addSnackbar, clearSnackbar } = SnackbarSlice.actions;
export default SnackbarSlice.reducer;

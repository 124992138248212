import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  Typography
} from '@mui/material';
import {
  ChartBox,
  GridContainer,
  GridSeling,
  SellingBox,
  SellingGridBox,
  SellingList
} from './Styled-component';
import OutletBarChart from './OutletBarChart';
import BarChartReport from './BarChartReport';
import Loader from '../Loader/Loader';
import SkeletonCard from '../Shared/Toggle/Skeleton';
import { getOutletsApi } from '../../api/outlet/outletApi';

export default function BusinessSummary({
  dashboardItemData,
  isLoading,
  startDate,
  endDate
}) {
  const [getItemId, setItemId] = useState('');
  const [outlets, setOutlets] = useState([]);

  // console.log(getItemId, 'itemId');

  let singleItemData = dashboardItemData?.find(
    (item) => item._id === getItemId
  );

  //get all outlets
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };

  const allOutlets = outlets?.map((outletName) => outletName.code);

  // const allOutlets = [
  //   'Eden',
  //   'Main',
  //   'Daewoo',
  //   'Jinah',
  //   'SKP',
  //   'Millat',
  //   'Wapda'
  // ];

  const salesData = singleItemData?.outlets?.map((outlet, index) => ({
    code: allOutlets[index],
    name: outlet.name,
    uv: outlet.sales,
    pv: outlet.count,
    amt: 2400
  }));

  useEffect(() => {
    getOutlets();
  }, []);

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item lg={6} md={6} sm={12}>
          <ChartBox sx={{ height: '60vh' }}>
            {salesData ? (
              isLoading ? (
                <Loader />
              ) : (
                <Box display="flex" flexDirection="column">
                  <OutletBarChart salesData={salesData} />
                  <p>Branch Wise Item Sale Performance</p>
                </Box>
              )
            ) : (
              <>Click on the Item to see graph</>
            )}
          </ChartBox>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <ChartBox height="60vh">
            {salesData ? (
              isLoading ? (
                <Loader />
              ) : (
                <Box display="flex" flexDirection="column">
                  <BarChartReport salesData={salesData} />
                  <p>Branch Wise Sold Item Count</p>
                </Box>
              )
            ) : (
              <>Click on the Item to see graph</>
            )}
          </ChartBox>
        </Grid>
      </Grid>

      <GridContainer container spacing={2}>
        <GridSeling item xl={6} lg={6} md={6} sm={12} xs={12}>
          <SellingGridBox>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}>
                Top selling items
              </Typography>
            </Box>

            {dashboardItemData?.map((item, i) =>
              startDate && endDate ? (
                isLoading ? (
                  <SkeletonCard />
                ) : (
                  <SellingBox
                    onClick={() => {
                      setItemId(item._id);
                    }}>
                    <SellingList
                      sx={{
                        backgroundColor: getItemId === item._id ? '#999' : '',
                        color: getItemId === item._id ? '#fff' : ''
                      }}>
                      <ListItem
                        sx={{
                          width: '70%',
                          gap: '12px'
                        }}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 80, height: 80 }}
                            alt="Remy Sharp"
                            src={item?.img}
                          />
                        </ListItemAvatar>
                        <Typography>{item?.name}</Typography>
                      </ListItem>
                      <Box sx={{ width: '25%' }}>Item Sale: {item?.sales}</Box>
                    </SellingList>
                  </SellingBox>
                )
              ) : (
                <></>
              )
            )}
          </SellingGridBox>
        </GridSeling>

        <GridSeling item xl={6} lg={6} md={6} sm={12} xs={12}>
          <SellingGridBox>
            <Box>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}>
                Top grossing items
              </Typography>
            </Box>
            {dashboardItemData?.map((item, i) =>
              startDate && endDate ? (
                isLoading ? (
                  <SkeletonCard />
                ) : (
                  <SellingBox
                    onClick={() => {
                      setItemId(item._id);
                    }}>
                    <SellingList
                      sx={{
                        backgroundColor: getItemId === item._id ? '#999' : '',
                        color: getItemId === item._id ? '#fff' : ''
                      }}>
                      <ListItem
                        sx={{
                          width: '70%',
                          gap: '12px'
                        }}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 80, height: 80 }}
                            alt="Delicious Pizza full of cheese."
                            src={item?.img}
                          />
                        </ListItemAvatar>
                        <Typography>{item?.name}</Typography>
                      </ListItem>
                      <Box sx={{ width: '25%' }}>Total Items: {item.count}</Box>
                    </SellingList>
                  </SellingBox>
                )
              ) : (
                <></>
              )
            )}
          </SellingGridBox>
        </GridSeling>
      </GridContainer>
    </>
  );
}


import { getFeedbackSummaryApi } from '../../../api/feedback/feedbackApi';

/* eslint-disable no-unused-vars */
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Rating,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import DatePickerComponent from '../../Shared/DatePickerComponent';
import { HeaderTableCell } from '../Styled-components';
import { HeaderTypography } from '../../Shared/shared';
import './feedBackSummary.css';
import { ratingData } from '../../Feedback/keys';
export default function FeedBackSummaryReport() {
  // getFeedbackSummaryApi

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  const [feedbackSummary, setFeedbackSummary] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const reportApi = async () => {
    try {
      setIsLoading(true);
      const response = await getFeedbackSummaryApi(
        datePopup?.startDate,
        datePopup?.endDate
      );
      setFeedbackSummary(response?.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <HeaderTypography
          sx={{
            fontWeight: 600,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
          }}>
          Branch Wise Feedback Summary
        </HeaderTypography>
        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#008670',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>Outlet Tag</HeaderTableCell>
                <HeaderTableCell>Total Feedbacks</HeaderTableCell>
                {ratingData.map(({ name }, index) => (
                  <HeaderTableCell key={index}>{name}</HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {feedbackSummary
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {row?.outletTag ? row.outletTag : 'Others'}
                        </TableCell>
                        <TableCell>{row?.totalFeedbacks || 0}</TableCell>
                        {ratingData.map(({ key }, index) => (
                          <TableCell key={index}>
                            <div className="rating-container">
                              <Rating
                                value={row[key] || 0}
                                precision={0.5}
                                size="small"
                                readOnly
                              />
                              <span className="rating-value">
                                ({(row[key] || 0).toFixed(2)})
                              </span>
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={feedbackSummary?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}


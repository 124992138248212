/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
// import {
//   dispatchOrderApi,
//   getReadyToDispatchApi
// } from '../../../api/displayscreens/displayScreenApi';
import {
  AddressTypo,
  DoneButton,
  FullGrid,
  Items,
  LowerSection,
  MiddleSection,
  ParentSection,
  ReportChip,
  Timer,
  TimerRing,
  UpperSection,
  UpperTypography
} from './Styled-components';
import { extractTime } from '../../Shared/shared';
import { permisssions } from '../../Shared/shared';
import PrintIcon from '@mui/icons-material/Print';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import Receipt from '../../POS/components/Receipt';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import {
  dispatchOrderApi,
  getReadyToDispatchApi,
  getSingleOrderApi
} from '../../../api/orders/OrdersApi';

// import Orders from '../../POS/Orders';
import Orders from './Orders';
import { Printing } from '../../Printing/Printing';
import { useReactToPrint } from 'react-to-print';

export default function DispatchDisplay() {
  const dispatch = useDispatch();
  const params = useParams();
  const { code } = params;

  // getting outlet from Redux state on the basis of params
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  // call API to get ReadyToDispatch orders
  const [readyToDispatchOrders, setReadyToDispatchOrders] = useState([]);
  const getReadyToDispatch = async () => {
    const res = await getReadyToDispatchApi(outlet._id);
    setReadyToDispatchOrders(res?.data);
  };

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);
  const handleToggle = () => {
    setChecked(!checked);
  };

  // calling API to get permited users who can apply discount
  const [riders, setRiders] = useState([]);
  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outlet._id,
      permisssions.rider
    );
    setRiders(response?.data);
  };

  const [dispatchRiders, setDispatchRiders] = useState({});
  // calling API after 30 seconds if outo refresh is on
  useEffect(() => {
    getReadyToDispatch();
    getUsersWithPermission();
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        getReadyToDispatch();
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [checked]);

  const [itemBoxStates, setItemBoxStates] = useState([]);

  const dispatchOrder = async (orderId) => {
    const orderType = readyToDispatchOrders.find(
      (order) => order._id == orderId
    ).orderType;
    if (orderType == 'dineIn') {
      const res = await dispatchOrderApi(orderId);
      if (res && res.status === 200) {
        getReadyToDispatch();
        setItemBoxStates((states) => [...states, orderId]);
      }
    } else {
      const carrier = dispatchRiders[orderId];
      if (!carrier)
        return dispatch(
          addSnackbar({ message: 'Rider not selected', type: 'error' })
        );
      const res = await dispatchOrderApi(orderId, { carrier });
      if (res && res.status === 200) {
        getReadyToDispatch();
        setItemBoxStates((states) => [...states, orderId]);
      }
    }
  };

  // handling pop Up for print
  const [print, setPrint] = useState({ isOpen: false, id: '' });
  const running = true;

  const refr = useRef();

  const [singlePrintOrder, setSinglePrintOrder] = useState();

  const handlePrint = useReactToPrint({
    content: () => refr.current
  });

  const triggerPrint = async (id) => {
    const res = await getSingleOrderApi(id);
    if (res.data) {
      setSinglePrintOrder(res?.data);
    }
    setTimeout(handlePrint, 0);
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <Printing ref={refr} order={singlePrintOrder} />
      </div>
      <Box
        sx={{
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}>
        {/* <Box display="flex" gap="10px">
          <NavLink to={`dispatch-sheet`}>
            <ReportChip label="Dispatch Sheet" variant="outlined" />
          </NavLink>
          <NavLink to={`rider-sheet`}>
            <ReportChip label="Rider Sheet" variant="outlined" />
          </NavLink>
        </Box> */}
        <Box>
          <Typography variant="h5" component="h4" color="green">
            Dispatch Orders
          </Typography>
          <FormControlLabel
            label="Auto Refresh Orders"
            control={<Switch checked={checked} onChange={handleToggle} />}
          />
        </Box>
      </Box>

      <Grid container>
        <Grid item xl={2} lg={2}>
          <Orders
            outletId={outlet._id}
            checked={checked}
            triggerPrint={triggerPrint}
          />
        </Grid>
        <Grid item xl={10} lg={10}>
          <Grid position="relative" container spacing={2}>
            {readyToDispatchOrders
              .filter((order) => !order.dispatched)
              .map((order, index) => {
                return (
                  <FullGrid
                    key={order._id}
                    item
                    xl={2}
                    lg={3}
                    md={4}
                    xs={12}
                    states={itemBoxStates.includes(order._id)}>
                    <ParentSection>
                      <UpperSection>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                          <Box>
                            <UpperTypography>
                              {order?.invoiceId ||
                                order._id.slice(-5).toUpperCase()}
                            </UpperTypography>

                            <UpperTypography>
                              {order.customerName}
                            </UpperTypography>
                            <UpperTypography>
                              {order.orderType.toUpperCase()}
                            </UpperTypography>
                          </Box>
                          <TimerRing>
                            <Timer>{extractTime(order.createdAt)} </Timer>
                          </TimerRing>
                        </Box>

                        <AddressTypo>{order?.deliveryAddress}</AddressTypo>
                      </UpperSection>

                      <MiddleSection states={itemBoxStates.includes(order._id)}>
                        <Items>
                          {order.items.map((item, itemIndex) => {
                            const modified =
                              item.createdAt.substring(0, 18) !==
                              order.createdAt.substring(0, 18);
                            const deleted = item.active ? false : true;

                            return (
                              <React.Fragment key={itemIndex}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '5px 0'
                                  }}>
                                  {deleted ? (
                                    <span>
                                      <del
                                        style={{
                                          fontSize: '16px',
                                          color: '#E3242B'
                                        }}>
                                        {item?.name}
                                      </del>
                                      <del
                                        style={{
                                          fontSize: '16px',
                                          color: '#E3242B'
                                        }}>
                                        X {`(${item.quantity})`}
                                      </del>
                                    </span>
                                  ) : modified ? (
                                    <span
                                      style={{
                                        color: 'green',
                                        fontSize: '16px'
                                      }}>
                                      <span>{item?.name}</span>X
                                      {`(${item.quantity})`}
                                    </span>
                                  ) : (
                                    <span>
                                      <p
                                        style={{
                                          fontSize: '16px'
                                        }}>
                                        {item?.name}
                                      </p>
                                      X {`(${item.quantity})`}
                                    </span>
                                  )}
                                </Box>
                                <span>
                                  <Typography
                                    style={{
                                      fontSize: '14px',
                                      color: 'gray'
                                    }}>
                                    (
                                    {item?.choices
                                      ?.reduce(
                                        (prev, current) =>
                                          (prev += current.optionName
                                            ? current.optionName +
                                              ` ${
                                                current.price
                                                  ? ` Rs. ${current.price}`
                                                  : ''
                                              }` +
                                              ', '
                                            : ''),
                                        ''
                                      )
                                      .slice(0, -2)}
                                    )
                                  </Typography>
                                </span>
                                {!item.note == '' && (
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      padding: 0,
                                      margin: '10px 0'
                                    }}>
                                    Note: <b> {item.note}</b>
                                  </p>
                                )}
                                <Divider />
                              </React.Fragment>
                            );
                          })}
                        </Items>
                      </MiddleSection>

                      <Box>
                        {order?.orderType === 'dineIn' ||
                        order?.orderType === 'takeAway' ? (
                          <p
                            style={{
                              fontSize: '16px'
                            }}>
                            Waiter : {order?.carrier?.name}
                          </p>
                        ) : (
                          <Box sx={{ padding: '5px 10px' }}>
                            {order?.deliveryNote && (
                              <>
                                <b>Order Note:</b> {order?.deliveryNote}
                              </>
                            )}
                            <Autocomplete
                              required
                              id="free-solo-2-demo"
                              disableClearable
                              onChange={(event, value) => {
                                setDispatchRiders((orders) => ({
                                  ...orders,
                                  [order._id]: value._id
                                }));
                              }}
                              options={riders}
                              getOptionLabel={(rider) => rider.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Rider"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: 'search'
                                  }}
                                />
                              )}
                            />
                          </Box>
                        )}

                        <LowerSection>
                          <Button
                            onClick={() => {
                              triggerPrint(order._id);
                              // setPrint({ isOpen: true, id: order._id });
                            }}>
                            <PrintIcon />
                          </Button>
                          <p>
                            Total Items:
                            {order?.items.length}
                          </p>
                          <DoneButton
                            type="button"
                            onClick={() => {
                              dispatchOrder(order._id);
                            }}>
                            Dispatch
                          </DoneButton>
                        </LowerSection>
                      </Box>
                    </ParentSection>
                  </FullGrid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>

      <Receipt print={print} setPrint={setPrint} running={running} />
    </>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  Checkbox,
  MenuItem,
  FormControlLabel,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  getUsersApi,
  getUsersWithPermissionApi
} from '../../../api/users/usersApi';
import { modifyDiscountApi } from '../../../api/orders/OrdersApi';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import { permisssions } from '../../Shared/shared';

export default function ModifyDiscount({
  discountPopup,
  setDiscountPopup,
  updateTriggerState,
  outletId,
  singleOrder,
  orderId,
  callFunc
}) {
  const dispatch = useDispatch();

  // handling state and handleChange for tab component
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      permisssions.modifyOrder
    );
    setUsers(response?.data);
  };

  const [careOfUsers, setCareOfUsers] = useState([]);

  const getUsers = async () => {
    const response = await getUsersWithPermissionApi('', permisssions.careOf);
    if (response.status == 200) {
      setCareOfUsers(response?.data);
    } else {
      return;
    }
  };
  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, reset, control } = useForm();
  const watchCheckbox = watch('fixedDiscount');
  const discountLabel = watchCheckbox
    ? 'Fixed Discount'
    : 'Percentage Discount';

  const onSubmit = async (data) => {
    const res = await modifyDiscountApi(orderId, data);
    if (res.status == 200) {
      callFunc();
      updateTriggerState(true);
      dispatch(
        addSnackbar({
          message: `Discount Updated`,
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: res.response.data.message,
          type: 'error'
        })
      );
    }
    handleClose();
  };

  useEffect(() => {
    getUsers();
    getUsersWithPermission();
    if (singleOrder) {
      reset({
        fixedDiscount: singleOrder.fixedDiscount,
        discount: singleOrder.fixedDiscount
          ? singleOrder.discount
          : singleOrder.discountRate * 100,
        ref: {
          user: singleOrder.discountRef,
          key: ''
        },
        reason: singleOrder.discountReason,
        careOf: singleOrder.careOf
      });
    }
  }, [singleOrder, setValue]);

  const handleClose = () => {
    reset();
    callFunc();
    setDiscountPopup('isOpen', false);
  };
  return (
    <div>
      <Dialog
        className="dialog"
        open={discountPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Modify Discount</DialogTitle>

        <Box
          sx={{
            height: '300px'
          }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Manual Discount" />
              <Tab label="Voucher" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <Box sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  <Controller
                    sx={{ width: '100%' }}
                    control={control}
                    name="ref.user"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        select
                        defaultValue=""
                        label="Name">
                        {users?.map((user, index) => (
                          <MenuItem key={index} value={user._id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <TextField
                    size="small"
                    label="Manager Key"
                    type="password"
                    {...register('ref.key')}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  <TextField
                    sx={{ width: '50%' }}
                    size="small"
                    label={discountLabel}
                    {...register('discount')}
                  />

                  <FormControlLabel
                    sx={{ width: '50%' }}
                    label="Fixed"
                    control={
                      <Checkbox
                        {...register('fixedDiscount')}
                        defaultChecked={
                          Boolean(singleOrder?.fixedDiscount) || null
                        }
                      />
                    }
                  />
                </Box>
                <Box>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Reason for Discount"
                    type="text"
                    {...register('reason')}
                  />
                  <Controller
                    name="careOf"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        defaultValue=""
                        label="Care Of"
                        fullWidth
                        margin="normal"
                        select>
                        <MenuItem value="Branch End">Branch End</MenuItem>

                        {careOfUsers?.map((user) => (
                          <MenuItem value={user._id} key={user._id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
                <Button type="submit" variant="contained">
                  Update Discount
                </Button>
              </form>
            </Box>
          )}
          {tabValue === 1 && <Box sx={{ p: 3 }}>Voucher</Box>}
        </Box>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

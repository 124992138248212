/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Table,
  TableHead,
  //   TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Avatar,
  Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { WHITES_CASTLE_IMAGE } from '../../Shared/shared';
import { ColumnTable } from '../Styled-components';

const UserInformation = () => {
  const user = useSelector((state) => state.authorize?.loginResponse?.user);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          mb: '15px'
        }}>
        <Avatar
          src={user?.profilePicture || process.env.REACT_APP_CLIENT_LOGO}
          alt={user.name}
          //   src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMhH4QdsdXYuaosCUXBDp7PdXEnFaI5hVMJA&usqp=CAU"
          sx={{ width: 150, height: 150, border: '1px dotted gray' }}
        />
        <Typography variant="h5"> {`${user.name}'s Profile`} </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
          //   height: '80vh'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '400px',
            borderRadius: '10px',
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
            p: '20px'
          }}>
          <TableContainer component={Paper}>
            <Table border="1">
              <TableHead>
                <TableRow>
                  <ColumnTable>Name</ColumnTable>
                  <TableCell colSpan={3}>{user.name}</TableCell>
                </TableRow>

                <TableRow>
                  <ColumnTable>email</ColumnTable>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <ColumnTable>Phone</ColumnTable>
                  <TableCell>{user.phone}</TableCell>
                </TableRow>

                <TableRow>
                  <ColumnTable>Role</ColumnTable>
                  <TableCell>{user.role.name}</TableCell>
                </TableRow>

                <TableRow>
                  <ColumnTable>Assigned Outlets</ColumnTable>
                  <TableCell>
                    <ul>
                      {user?.outlets.map((outlet) => (
                        <li key={outlet._id}>{outlet.tag}</li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <ColumnTable>Display Screens</ColumnTable>
                  <TableCell>
                    <ul>
                      {user?.displays.map((disp) => (
                        <li key={disp._id}>{disp.name}</li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default UserInformation;

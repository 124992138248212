/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  resetCart,
  setItems,
  setSelectedOutlet
} from '../../redux/reducers/ducks/CartDuck';
import { outletMenuRequest } from '../../redux/reducers/ducks/OutletDuck';
import {
  ImageBox,
  ItemBox,
  NameTypo,
  PriceTypo,
  DetailsBox,
  MenuItemBox,
  CategoryBox,
  MenuItemsBox,
  SearchBox,
  CategoryButton
} from './Styled-components';
import { Outlet, useParams } from 'react-router-dom';
import Orders from './Orders';
import Cart from './Cart';
import { getPrice } from './Shared';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { menuItemsRequest } from '../../redux/reducers/ducks/MenuItemsDuck';
import SwitchOutletConfirm from './SwitchOutletConfirm';
import { theme } from '../../theme';

export default function CallCenter() {
  const dispatch = useDispatch();

  // handling state for confirm outlet switch

  const [switchOutlet, setSwitchOutlet] = useState({
    isOpen: false,
    value: ''
  });

  // getinng all outlets
  const [outlets, setOutlets] = useState([]);
  const [loading, setLoading] = useState(false);
  const getOutlets = async () => {
    setLoading(true);
    try {
      const response = await getOutletsApi();
      setOutlets(response.data);
    } catch (error) {
      console.error('Failed to fetch outlets:', error);
    } finally {
      setLoading(false);
    }
  };

  const [menuItems, setMenuItem] = useState([]);

  const cart = useSelector((state) => state.cart);
  const handleChangeOutlet = (event) => verifyOutletSwitch(event.target.value);
  const verifyOutletSwitch = (outletId) => {
    if (!outletId) return;
    const outlet = outlets.find((o) => o._id === outletId);
    const items = cart.items.reduce((array, item) => {
      if (
        !outlet.menuItems?.find(
          (menuItem) => item?.menuItem === menuItem?.menuItem
        )
      ) {
        array.push(item);
      }
      return array;
    }, []);
    if (items.length) {
      const itemNames = items.map(
        (item) =>
          menuItems.find((menuItem) => menuItem._id === item.menuItem).name
      );
      setSwitchOutlet({ isOpen: true, value: outletId, items: itemNames });
    } else dispatch(setSelectedOutlet(outletId));
  };

  const forceSwitch = async () => {
    const outlet = outlets.find((o) => o._id === switchOutlet.value);
    dispatch(
      setItems(
        cart.items.filter((item) =>
          outlet.menuItems.find(
            (menuItem) => item.menuItem === menuItem.menuItem
          )
        )
      )
    );
    setSwitchOutlet({ isOpen: false, value: '', items: [] });

    dispatch(setSelectedOutlet(outlet._id));
  };

  // getting all menuItems
  const menuItemsResponse = useSelector((state) => state.menuItems);

  useEffect(() => {
    if (!menuItemsResponse || !menuItemsResponse.menuItemsResponse) {
      dispatch(menuItemsRequest());
    } else {
      setMenuItem(menuItemsResponse?.menuItemsResponse?.data);
    }
    getOutlets();
  }, [dispatch, menuItemsResponse]);

  // calling API over some data modification to update store

  async function callFunc() {
    dispatch(menuItemsRequest());
  }

  // getting outlet menu from matching outlet in tab
  let outletMenu = outlets.find((outlet) => outlet._id === cart.outlet);

  const outletMenuItems =
    outletMenu?.menuItems?.map((outletItem) => {
      const newItem = { ...outletItem };
      newItem.menuItem = menuItems.find(
        (item) => item._id === outletItem.menuItem
      );
      return newItem;
    }) || menuItems.map((item) => ({ menuItem: item }));

  // getting unique categories
  const uniqueCategories = [
    ...new Set(
      outletMenuItems?.map((singleItem) => singleItem?.menuItem?.category?.name)
    )
  ];

  // state for search query
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const handleCategoryClick = (category) => {
    setSearchQuery('');
    setSelectedCategory(category);
  };

  // Search module
  const filteredMenuItems = outletMenuItems?.filter((item) => {
    const terms = searchQuery.split(' ');
    return (
      (!selectedCategory ||
        item.menuItem?.category?.name === selectedCategory) &&
      terms.every((term) => {
        return item?.menuItem?.name.toLowerCase().includes(term.toLowerCase());
      })
    );
  });

  // ==================================******=============================

  // call runningOrdersAPi in child component i.e Orders component
  const [trigger, setTrigger] = useState({
    status: false
    // order: singleOrder
  });
  // here sending this function to cart child and get trigger value from it and
  // than to send it to orders component
  const updateTriggerState = (value) => {
    setTrigger({ status: value });
  };

  return (
    <>
      <Grid sx={{ height: '100%', backgroundColor: '' }} container spacing={1}>
        <Grid height="100%" item sm={12} md={4} lg={2}>
          <Orders
            outletId={cart.outlet}
            trigger={trigger}
            setTrigger={setTrigger}
            verified={true}
          />
        </Grid>

        <Grid
          item
          sm={12}
          md={4}
          lg={4}
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
          {/* <Box sx={{ padding: '5px' }}>
            <ToggleButtonGroup
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px'
              }}
              color="primary"
              value={cart.outlet}
              exclusive
              onChange={handleChangeOutlet}
              aria-label="Outlets">
              {outlets.map((outlet, index) => (
                <ToggleButton
                  key={index}
                  sx={{
                    fontSize: '10px',
                    '&.MuiToggleButton-root.Mui-selected': {
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                      borderRadius: '3px'
                    },
                    height: '30px'
                  }}
                  value={outlet._id}>
                  {outlet.tag}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box> */}
          <Box
            sx={{
              margin: '4px 0px'
            }}>
            <FormControl fullWidth size="small">
              <InputLabel id="outlet-select-label">Select Outlets</InputLabel>
              <Select
                labelId="outlet-select-label"
                id="outlet-select"
                value={cart.outlet}
                label="Select Outlets"
                onChange={handleChangeOutlet}
                inputProps={{
                  style: { padding: 4 }
                }}
                sx={{
                  borderRadius: '10px'
                }}>
                {outlets.map((outlet) => (
                  <MenuItem key={outlet._id} value={outlet._id}>
                    {outlet.tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Cart
            outlets={outlets}
            outlet={cart.outlet}
            outletMenuItems={outletMenuItems}
            updateTriggerState={updateTriggerState} // function to get update trigger state
            handleChangeOutlet={verifyOutletSwitch}
          />
        </Grid>

        <Grid
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          item
          sm={12}
          md={8}
          lg={6}>
          <MenuItemsBox>
            <CategoryBox>
              <CategoryButton
                variant={selectedCategory ? 'outlined' : 'contained'}
                onClick={() => {
                  setSelectedCategory(null);
                }}>
                All
              </CategoryButton>

              {uniqueCategories.map((category, index) => {
                return (
                  <CategoryButton
                    key={index}
                    variant={
                      category === selectedCategory ? 'contained' : 'outlined'
                    }
                    onClick={() => handleCategoryClick(category)}>
                    {category}
                  </CategoryButton>
                );
              })}
            </CategoryBox>
            <SearchBox>
              <TextField
                fullWidth
                label="Search"
                variant="outlined"
                type="text"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
              />
            </SearchBox>
            <MenuItemBox>
              <Grid container>
                {filteredMenuItems?.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Grid
                        onClick={() => dispatch(addToCart(item))}
                        item
                        lg={3}
                        md={4}
                        xs={6}>
                        <ItemBox>
                          <NameTypo>{item.menuItem.shortName}</NameTypo>
                          <ImageBox
                            component="img"
                            alt="The house from the offer."
                            src={
                              item.menuItem.img
                                ? item.menuItem.img
                                : process.env.REACT_APP_CLIENT_LOGO
                            }
                          />
                          <DetailsBox>
                            <PriceTypo>{getPrice(item)}</PriceTypo>
                          </DetailsBox>
                        </ItemBox>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </MenuItemBox>
          </MenuItemsBox>
        </Grid>
      </Grid>
      <Outlet />
      <SwitchOutletConfirm
        switchOutlet={switchOutlet}
        setSwitchOutlet={setSwitchOutlet}
        // setSelectedOutlet={setSelectedOutlet}
        forceSwitch={forceSwitch}
      />
    </>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import {
  addInventoryApi,
  initiateInventoryApi
} from '../../../api/stocks/inventoryController/inventoryContollerApi';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import Loader from '../../Loader/Loader';
import Dialogue from './Dialogue';
import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';
import { TopBox, formatQuantity } from '../../Shared/shared';
import ClosingSaleChart from './ClosingSaleChart';

export default function InventoryController() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [openDialog, setOpenDialog] = useState({ isOpen: false });

  const [isgetLoading, setLoading] = useState(false);

  const [inventory, setInventory] = useState([]);
  const [saleComment, setSaleComment] = useState('');

  // disable button after order punch till API Recall
  const [isDisabled, setIsDisabled] = useState(false);

  const initInventory = async () => {
    try {
      setLoading(true);
      const res = await initiateInventoryApi(outlet._id);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Got Inventory',
            type: 'success'
          })
        );
      } else {
        dispatch(
          addSnackbar({
            message: res?.response?.data?.message,
            type: 'error'
          })
        );
        setOpenDialog({
          isOpen: true,
          outlet: outlet,
          msg: res?.response?.data?.message
        });
      }
      setInventory(res.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const getInventory = inventory?.ingredients;

  const { register, handleSubmit, setValue, control } = useForm({});

  const [collectedData, setCollectedData] = useState({});

  const onSubmit = async (data) => {
    const { ingredients } = data;

    setCollectedData({
      ingredients: ingredients,
      outlet: outlet._id,
      desc: data.desc
    });
  };

  // confirm dialogue

  const [addPopup, setAddPopup] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const confirmSubmit = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    setErrorMessage('');

    try {
      const formData = {
        ingredients: collectedData.ingredients,
        outlet: outlet._id,
        desc: collectedData.desc,
        saleComment: saleComment
      };
      const res = await addInventoryApi(formData);

      if (res?.status == 200) {
        swal('Success', 'Inventory added Successfully', 'success');
        navigate(`/outlet/${outlet.code.toLowerCase()}/inventory-history`);
        handleConfirmClose();
        callFunc();
      } else {
        setErrorMessage(res?.response?.data?.message);
        dispatch(
          addSnackbar({
            message: errorMessage,
            type: 'error'
          })
        );
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handleConfirmClose = () => {
    setAddPopup(false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };
  // confirm dialogue

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    setValue(`ingredients[${index}].closing`, value);
  };

  // using ref to go to next input instead of submitting the form on enter press
  const inputRefs = useRef([]);
  const handleInputKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // going to next textField
      const nextIndex = index + 1;
      const totalInputs = getInventory.length + 1;
      if (nextIndex < totalInputs) {
        inputRefs.current[nextIndex]?.focus();
      }
    }
  };

  useEffect(() => {
    initInventory();
  }, []);

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = getInventory?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.name === 'none'
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isgetLoading ? (
          <Loader />
        ) : (
          <>
            <TopBox my={2}>
              <TextField
                label="Search Ingredient"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              Inventory Managment
            </TopBox>

            <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
              <Table size="small" aria-label="collapsible table">
                <TableHead
                  sx={{
                    background: '#66C8E0',
                    color: 'white',
                    position: 'sticky',
                    top: 0,
                    zIndex: '1300'
                  }}>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Opening</TableCell>
                    <TableCell>Purchases</TableCell>
                    <TableCell>Wastes</TableCell>
                    <TableCell>Transfer IN/OUT</TableCell>
                    <TableCell>Usage</TableCell>
                    <TableCell>Computed Stocks</TableCell>
                    <TableCell align="center">Actual Stocks</TableCell>
                    <TableCell>Diffrence</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflow: 'auto' }}>
                  {filteredOrders?.map((singleSet, index) => {
                    const transfer =
                      singleSet?.transfersIn - singleSet?.transfersOut;
                    const remaining =
                      singleSet.opening +
                      singleSet.purchases -
                      singleSet?.wastes +
                      transfer -
                      singleSet?.consumption;
                    return (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            '&:hover': {
                              backgroundColor: '#66C8E0a2',
                              cursor: 'pointer',
                              transition: '0.4s'
                            }
                          }}>
                          <TableCell>{singleSet.name}</TableCell>
                          <TableCell>{singleSet.unit}</TableCell>
                          <TableCell>
                            {(
                              Math.round(singleSet?.opening * 100) / 100
                            ).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {formatQuantity(singleSet.purchases)}
                            {/* {(
                              Math.round(singleSet.purchases * 100) / 100
                            ).toFixed(2)} */}
                          </TableCell>
                          <TableCell>
                            {
                              formatQuantity(singleSet?.wastes)
                              // (
                              //   Math.round(singleSet?.wastes * 100) / 100
                              // ).toFixed(2)
                            }
                          </TableCell>
                          <TableCell>{formatQuantity(transfer)}</TableCell>
                          <TableCell>
                            {
                              formatQuantity(singleSet?.consumption)
                              // (Math.round(singleSet?.consumption * 100) / 100).toFixed(2)
                            }
                          </TableCell>
                          <TableCell>{formatQuantity(remaining)}</TableCell>
                          <Controller
                            control={control}
                            name={`ingredients[${index}].closing`}
                            render={({ field }) => {
                              return (
                                <>
                                  <TableCell>
                                    <TextField
                                      size="small"
                                      label="add value"
                                      // required
                                      type="number"
                                      {...field}
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      onKeyDown={(event) =>
                                        handleInputKeyDown(event, index)
                                      }
                                      inputRef={(ref) =>
                                        (inputRefs.current[index] = ref)
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {field.value - (remaining || 0)}
                                  </TableCell>
                                  <TableCell>
                                    {Math.round(
                                      singleSet.value * (field.value || 0)
                                    )}
                                  </TableCell>
                                </>
                              );
                            }}
                          />
                          <TableCell style={{ display: 'none' }}>
                            <input
                              type="hidden"
                              {...register(`ingredients[${index}].ingredient`)}
                              value={singleSet.ingredient}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow></TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                margin: '10px'
              }}>
              <TextField
                size="small"
                label="Description"
                type="text"
                {...register(`desc`)}
                onKeyDown={handleInputKeyDown}
              />
              <Button
                variant="contained"
                disabled={isDisabled}
                onClick={() => {
                  setAddPopup(true);
                }}
                type="submit">
                {isDisabled ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}>
                    <SaveIcon />
                    Submit
                  </Box>
                )}
              </Button>
            </Box>
          </>
        )}
        <Dialogue openDialog={openDialog} setOpenDialog={setOpenDialog} />

        <Dialog
          open={addPopup}
          onClose={handleConfirmClose}
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '100%'
              }
            }
          }}>
          <Box>
            <>
              <DialogContent>
                <Typography color="#FFA500" fontWeight="700">
                  Confirm Submission
                </Typography>
                <Typography>Are you sure to Add Inventory?</Typography>

                <ClosingSaleChart
                  saleDate={inventory?.saleDate}
                  outletId={outlet?._id}
                  setSaleComment={setSaleComment}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleConfirmClose} autoFocus>
                  cancel
                </Button>
                <Button
                  disabled={isButtonDisabled}
                  variant="contained"
                  onClick={() => {
                    confirmSubmit();
                  }}>
                  {isLoading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </DialogActions>
            </>
          </Box>
        </Dialog>
      </form>
    </>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { deliveryMonitorApi } from '../../../api/report/reportApi';
import { HeaderTableCell, TopBox } from '../Styled-components';
import {
  timeDiffMinuts,
  timestamp,
  currentTimeDiff,
  HeaderTypography
} from '../../Shared/shared';
import Loader from '../../Loader/Loader';
import Details from './Details';
import CountUp from 'react-countup';

export default function DeliveryMonitor() {
  // handling popup for order details
  const [details, setDetails] = useState({ isOpen: false });

  // calling APi
  const [report, setReport] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const deliveryMonitorReport = async () => {
    try {
      setLoading(true);
      const response = await deliveryMonitorApi();
      setReport(response?.data);
      setLoading(false);
      setpg(0);
      setSearchQuery('');
      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const categories = [
    {
      name: 'All',
      filter: () => true,
      reverse: true
    },
    {
      name: 'Kitchen',
      filter: (order) =>
        !order.cancelled && !order.cashedOut && !order.dispatched,
      reverse: false
    },
    {
      name: 'Delivery',
      filter: (order) => !order.cancelled && !order.cashedOut,
      reverse: false
    },
    {
      name: 'Finished',
      filter: (order) => order.cancelled || order.cashedOut,
      reverse: true
    }
  ];
  const [categoryFilter, setCategoryFilter] = useState(2); // 2nd index is delivery

  const filteredReport = report
    .sort(
      (o1, o2) =>
        o1.orderTime.localeCompare(o2.orderTime) *
        (categories[categoryFilter].reverse ? -1 : 1)
    )
    .filter(categories[categoryFilter].filter);

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const statusColor = {
    Rejected: 'orangeRed',
    Cancelled: '#ff2313',
    Completed: 'darkGreen',
    'On The Way': 'orange',
    Delivered: 'darkGreen',
    Preparing: '#2ecc71',
    Pending: 'purple'
  };
  const orderStatusMap = filteredReport?.map((report) => {
    const invoiceId = report.invoiceId;
    const status =
      report.cancelled && !report.accepted
        ? 'Rejected'
        : report.cancelled
        ? 'Cancelled'
        : report.cashedOut
        ? 'Completed'
        : report.delivered
        ? 'Delivered'
        : report.dispatched
        ? 'On The Way'
        : report.accepted
        ? 'Preparing'
        : 'Pending';
    const orderFrom =
      report.acceptedBy?._id !== report.user?._id
        ? 'Call Center'
        : 'Branch End';
    const grandTotal =
      (report?.chargesTax || 0) +
      report?.totalCharges +
      (report?.tax || 0) +
      report?.total;
    const timeString = timestamp(report?.orderTime);
    return {
      ...report,
      status,
      orderFrom,
      grandTotal,
      timeString,
      searchString: (
        invoiceId +
        timeString +
        status +
        grandTotal +
        orderFrom +
        report?.user?.name +
        report?.customerName +
        report?.customerContact +
        report?.deliveryAddress +
        report?.outlet?.tag +
        report?.grandTotal
      ).toLowerCase()
    };
  });

  const filteredOrders = orderStatusMap?.filter((report) => {
    const reportString = report.searchString;
    const terms = searchQuery.toLowerCase().split(' ');
    return terms.every((term) => reportString.includes(term));
  });

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    deliveryMonitorReport();
    // calling API after 30 seconds if outo refresh is on
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        deliveryMonitorReport();
        // dateNow = new Date();
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [checked]);

  return (
    <>
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
        <TopBox>
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around'
                // gap: '10px',
              }}>
              {categories.map((category, i) => (
                <Box key={i}>
                  <Button
                    sx={{ borderRadius: '10px' }}
                    variant={categoryFilter == i ? 'contained' : 'outlined'}
                    onClick={() => setCategoryFilter(i)}>
                    {category.name}
                  </Button>
                </Box>
              ))}
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <TextField
                label="Search Order"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <HeaderTypography
                sx={{
                  fontWeight: 600,
                  fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)'
                }}>
                Delivery Monitoring
              </HeaderTypography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  label="Refresh"
                  control={<Switch checked={checked} onChange={handleToggle} />}
                />
                <Box>
                  {isLoading ? (
                    <CircularProgress width={100} size={24} color="secondary" />
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </TopBox>

        <TableContainer component={Paper} style={{}}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                // background: '#884A39',
                background: '#000066',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>Order Id</HeaderTableCell>
                {categoryFilter == 'kitchen' ? (
                  <HeaderTableCell>Kitchen Time</HeaderTableCell>
                ) : categoryFilter == 'delivering' ? (
                  <HeaderTableCell>Dispatch Time</HeaderTableCell>
                ) : (
                  <HeaderTableCell>Delivery Time</HeaderTableCell>
                )}

                <HeaderTableCell>Order Time</HeaderTableCell>
                <HeaderTableCell>From</HeaderTableCell>
                <HeaderTableCell>Cust. Name</HeaderTableCell>
                <HeaderTableCell>Contact</HeaderTableCell>
                <HeaderTableCell>Branch</HeaderTableCell>
                <HeaderTableCell>Total</HeaderTableCell>
                <HeaderTableCell>Punched By</HeaderTableCell>
                <HeaderTableCell>Order Status</HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto', height: '100%' }}>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    const myTime =
                      report?.delivered ||
                      report?.cashedOut ||
                      report?.cancelled;
                    const deliveringTime = myTime
                      ? Math.floor(timeDiffMinuts(report?.orderTime, myTime))
                      : currentTimeDiff(report?.orderTime);

                    return (
                      <TableRow key={index}>
                        <TableCell>{report?.invoiceId}</TableCell>

                        <TableCell
                          sx={{
                            width: '150px',
                            textAlign: 'center'
                          }}>
                          <b
                            style={{
                              backgroundColor:
                                deliveringTime < 25
                                  ? '#19e6d2'
                                  : deliveringTime >= 25 && deliveringTime < 31
                                  ? '#4fcdff'
                                  : deliveringTime >= 31 && deliveringTime < 35
                                  ? '#ffc707'
                                  : deliveringTime >= 35
                                  ? '#ff7171'
                                  : '',
                              padding: '8px 12px',
                              borderRadius: '10px',
                              color: '#000'
                            }}>
                            {deliveringTime}m
                          </b>
                        </TableCell>

                        <TableCell>{report?.timeString}</TableCell>
                        <TableCell>{report?.orderFrom}</TableCell>
                        <TableCell>{report?.customerName}</TableCell>
                        <TableCell>{report?.customerContact || ''} </TableCell>
                        <TableCell> {report?.outlet?.tag || ''} </TableCell>
                        <TableCell>
                          {Math.round(report?.grandTotal) || 0}
                        </TableCell>
                        <TableCell>{report?.user?.name || 0}</TableCell>

                        <Tooltip
                          title={
                            <Typography
                              style={{
                                fontSize: '18px'
                              }}>
                              {' '}
                              {report?.carrier?.name && report?.carrier?.phone
                                ? `${report?.carrier?.name} ${report?.carrier?.phone}`
                                : 'Not Available'}
                            </Typography>
                          }
                          placement="top">
                          <TableCell
                            sx={{ width: '150px', textAlign: 'center' }}>
                            <b
                              style={{
                                backgroundColor: statusColor[report?.status],
                                padding: '8px 12px',
                                borderRadius: '10px',
                                color: 'white'
                              }}>
                              {report?.status}
                            </b>
                          </TableCell>
                        </Tooltip>

                        {console.log(report, 'reppppp')}
                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({ isOpen: true, id: report?._id });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
            // backgroundColor: '#000066'
          }}>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            count={filteredOrders?.length || 0}
            rowsPerPage={rpg}
            page={pg}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              // color: '#fff',
              '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows, .MuiTablePagination-actions':
                {
                  // color: '#fff'
                },
              '.MuiTablePagination-root': {
                borderBottom: 'none' // Removes the bottom border
              }
            }}
          />
        </Box>
      </Box>
      <Details details={details} setDetails={setDetails} />
    </>
  );
}

import axios from 'axios';
import { refreshAccessToken } from '../../refreshTokenApi';
import expensesUrls from './expensesUrls';
// here start
const accessToken = localStorage.getItem('accessToken');

// Add Purchase API

export const addExpenseApi = async (expenseData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${expensesUrls.expenses}`,
      expenseData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${expensesUrls.expenses}`,
        expenseData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Purchases API
export const editExpenseApi = async (expenseId, expenseData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${expensesUrls.expenses}/${expenseId}`,
      expenseData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${expensesUrls.expenses}/${expenseId}`,
        expenseData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Outlet Expenses API
export const getExpensesApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${expensesUrls.outletExpenses}/${outletId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${purchasesUrls.outletExpenses}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get single expense
export const getSingleExpenseApi = async (expenseId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${expensesUrls.expenses}/${expenseId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${expensesUrls.expenses}/${expenseId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// ........................ expense Categories ...............

// get Outlet Expenses API
export const getExpenseCategoriesApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(`${expensesUrls.expenseCategories}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${purchasesUrls.expenseCategories}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

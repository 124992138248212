/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/OutletDuck';
import { getOutletMenuApi } from '../../../api/outlet/outletApi';

export function* outletMenu({ payload }) {
  try {
    const outletMenu = yield call(() => getOutletMenuApi(payload));
    yield put(actions.updateOutletMenuSuccessStatus(true));
    yield put(
      actions.outletMenuResponse({
        response: outletMenu
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchOutletMenuSagas() {
  yield takeLatest(actions.outletMenuRequest.type, outletMenu);
}

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import expireIn from 'redux-persist-transform-expire-in';
import ingredient from './ducks/IngredientDuck';
import authorize from './ducks/AuthorizeDuck';
import ingredientSet from './ducks/IngredietSetDuck';
import choices from './ducks/ChoicesDuck';
import snackbar from './ducks/SnackbarDuck';
import menuItems from './ducks/MenuItemsDuck';
import menuCategory from './ducks/MenuCategoryDuck';
import outletMenues from './ducks/OutletDuck';
import runningOrders from './ducks/OrdersDuck';
import displayScreens from './ducks/DisplayDuck';
import displayScreenOrders from './ducks/DisplayScreenOrdersDuck';
import cart from './ducks/CartDuck';
import modifyOrder from './ducks/ModifyOrderDuck';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// https://github.com/sirLisko/redux-persist-transform-expire-in
const expirationDelay = 14 * 24 * 60 * 60 * 1000; // expire in 14 days
const expirationKey = 'expirationKey';
const appVersion = process.env?.REACT_APP_VERSION ?? '0.0.0';
const persistConfig = {
  key: `rootstore_${appVersion}`,
  storage,
  transforms: [expireIn(expirationDelay, expirationKey, [])]
};

const reducers = {
  authorize: persistReducer(persistConfig, authorize),
  // cart: persistReducer(persistConfig, cart),
  snackbar,
  cart,
  modifyOrder,
  ingredient,
  ingredientSet,
  choices,
  menuItems,
  menuCategory,
  outletMenues,
  runningOrders,
  displayScreens,
  displayScreenOrders
};
const rootReducer = combineReducers(reducers);

export default rootReducer;

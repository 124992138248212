import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const walkSheetUrls = {
  walksheet: `${baseURL}/ws-questions/sections`,
  addWalkSheet: `${baseURL}/walksheet/submit`,
  walkSheetHistory: `${baseURL}/walksheet/outlet`,
  getWalkSheet: `${baseURL}/walksheet`
};

export default walkSheetUrls;

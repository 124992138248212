import React, { useEffect, useState } from 'react';
// import { getInventoryHistoryApi } from '../../../api/stocks/inventoryController/inventoryContollerApi';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getWalkSheetHistoryApi } from '../../api/walkSheet/walkSheetApi';
import { timestamp } from '../Shared/shared';
import { TopBox } from './Styled-components';

export default function WalkSheet() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*'];
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1];

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [walkSheetHistory, setWalkSheetHistory] = useState([]);
  const getWalkSheetHistory = async () => {
    const res = await getWalkSheetHistoryApi(outlet._id);
    setWalkSheetHistory(res.data);
  };

  useEffect(() => {
    getWalkSheetHistory();
  }, []);

  return (
    <>
      <Container>
        <TopBox my={2}>
          <NavLink to={`add-walk-sheet`}>
            <Button variant="contained">Add Walk Sheet</Button>
          </NavLink>
        </TopBox>

        <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
          <Table size="small" aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#66C8E0',
                color: 'white',
                position: 'sticky',
                top: 0
              }}>
              <TableRow>
                {/* <TableCell>From Date</TableCell> */}
                <TableCell>Sr#</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Submit Time</TableCell>
                <TableCell>Shift</TableCell>
                <TableCell>User</TableCell>
                <TableCell>View </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              {walkSheetHistory?.map((singleSet, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow
                      sx={{
                        // '& > *': { borderBottom: 'unset' },
                        '&:hover': {
                          backgroundColor: '#66C8E0a2',
                          cursor: 'pointer',
                          transition: '0.4s'
                        }
                      }}>
                      {/* <TableCell>{singleSet.fromDate}</TableCell> */}
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{singleSet.saleDate}</TableCell>
                      <TableCell>{timestamp(singleSet.createdAt)}</TableCell>
                      <TableCell>{singleSet.shift}</TableCell>
                      <TableCell>{singleSet?.user?.name}</TableCell>

                      <TableCell>
                        <NavLink
                          to={`${singleSet.saleDate}/${singleSet.shift}/sections`}>
                          <Button>
                            <RemoveRedEyeIcon />
                          </Button>
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}

/* eslint-disable no-unused-vars */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue, green, purple } from '@mui/material/colors';

export let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 900,
      lg: 1200,
      mobile: 1155,
      homepageMobileLayout: 1280,
      xl: 1536,
      '2xl': 1660, // Desktop and larger
      '3xl': 1920 // Large desktop,
    }
  },
  palette: {
    type: 'dark',
    primary: {
      // main: '#063C94',
      main: process.env.REACT_APP_PRIMARY_COLOR || '#fff',
      hover: process.env.REACT_APP_PRIMARY_COLOR_HOVER || '#fff',
      light: '#063c941a',
      dark: '#042A67',
      contrastText: '#ffffff'
    },
    secondary: {
      // main: '#8094AE',
      // main: '#EFC20E',
      main: process.env.REACT_APP_SECONDARY_COLOR || '#000',
      main: '#c0392b',
      light: '#F2CE3E',
      dark: '#A78709'
    },
    white: {
      main: '#FFFFFF',
      light: '#FFFFFF',
      dark: '#FFFFFF',
      contrastText: '#000000'
    },
    error: {
      main: '#D6312B',
      light: '#DE5A55',
      dark: '#95221E'
    },
    divider: 'rgba(0, 0, 0, 0.23)',
    success: {
      main: '#008516',
      light: '#339D44',
      dark: '#005D0F'
    },
    info: {
      main: '#FFBB5C',
      light: '#FF9B50',
      dark: '#E25E3E'
    },
    customShadows: {
      light: '1px 1px 12px -1px rgba(0, 0, 0, 0.12)',
      medium: '5px 4px 11px 0px rgba(0,0,0,0.15)',
      heavy:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)'
    }
  },
  typography: {
    fontFamily: `"Open Sans", "Helvetica Neue", "Arial", sans-serif`,
    fontSize: 14,
    h1: {
      fontWeight: 600,
      fontSize: 24
    }
  }
});

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: '#000000',
        color: '#ffffff'
      },
      '.img-fluid': {
        maxWidth: '100%',
        height: 'auto'
      }
    }
  }
};

export const tokens = (mode) => ({
  ...(mode === 'dark'
    ? {
        grey: {
          100: '#e0e0e0',
          200: '#c2c2c2',
          300: '#a3a3a3',
          400: '#858585',
          500: '#666666',
          600: '#525252',
          700: '#3d3d3d',
          800: '#292929',
          900: '#141414'
        },
        primary: {
          100: '#d0d1d5',
          200: '#a1a4ab',
          300: '#727681',
          400: '#1F2A40',
          500: '#141b2d',
          600: '#101624',
          700: '#0c101b',
          800: '#080b12',
          900: '#040509'
        },
        greenAccent: {
          100: '#dbf5ee',
          200: '#b7ebde',
          300: '#94e2cd',
          400: '#70d8bd',
          500: '#4cceac',
          600: '#3da58a',
          700: '#2e7c67',
          800: '#1e5245',
          900: '#0f2922'
        },
        redAccent: {
          100: '#f8dcdb',
          200: '#f1b9b7',
          300: '#e99592',
          400: '#e2726e',
          500: '#db4f4a',
          600: '#af3f3b',
          700: '#832f2c',
          800: '#58201e',
          900: '#2c100f'
        },
        blueAccent: {
          100: '#e1e2fe',
          200: '#c3c6fd',
          300: '#a4a9fc',
          400: '#868dfb',
          500: '#6870fa',
          600: '#535ac8',
          700: '#3e4396',
          800: '#2a2d64',
          900: '#151632'
        }
      }
    : {
        grey: {
          100: '#141414',
          200: '#292929',
          300: '#3d3d3d',
          400: '#525252',
          500: '#666666',
          600: '#858585',
          700: '#a3a3a3',
          800: '#c2c2c2',
          900: '#e0e0e0'
        },
        primary: {
          100: '#040509',
          200: '#080b12',
          300: '#0c101b',
          400: '#f2f0f0', // manually changed
          500: '#141b2d',
          600: '#1F2A40',
          700: '#727681',
          800: '#a1a4ab',
          900: '#d0d1d5'
        },
        greenAccent: {
          100: '#0f2922',
          200: '#1e5245',
          300: '#2e7c67',
          400: '#3da58a',
          500: '#4cceac',
          600: '#70d8bd',
          700: '#94e2cd',
          800: '#b7ebde',
          900: '#dbf5ee'
        },
        redAccent: {
          100: '#2c100f',
          200: '#58201e',
          300: '#832f2c',
          400: '#af3f3b',
          500: '#db4f4a',
          600: '#e2726e',
          700: '#e99592',
          800: '#f1b9b7',
          900: '#f8dcdb'
        },
        blueAccent: {
          100: '#151632',
          200: '#2a2d64',
          300: '#3e4396',
          400: '#535ac8',
          500: '#6870fa',
          600: '#868dfb',
          700: '#a4a9fc',
          800: '#c3c6fd',
          900: '#e1e2fe'
        }
      })
});

theme = responsiveFontSizes(theme);

// import { createTheme } from "@mui/material/styles";

// export const theme = createTheme({
//   palette: {
//     mode: "light",
//     primary: {
//       main: "#063C94",
//       light: "#063c941a",
//       dark: "#042A67",
//       contrastText: "#ffffff",
//     },
//     secondary: {
//       main: "#EFC20E",
//       light: "#F2CE3E",
//       dark: "#A78709",
//     },
//     error: {
//       main: "#D6312B",
//       light: "#DE5A55",
//       dark: "#95221E",
//     },
//     divider: "rgba(0, 0, 0, 0.23)",
//     success: {
//       main: "#008516",
//       light: "#339D44",
//       dark: "#005D0F",
//     },
//     text: {
//       primary: "rgba(0, 0, 0, 0.87)",
//       secondary: "rgba(0, 0, 0, 0.6)",
//     },
//     black: {
//       main: "#000000",
//       light: "#000000",
//       dark: "#000000",
//       contrastText: "#FFFFFF",
//     },
//     info: {
//       main: "#1976D2",
//       light: "#fff",
//     },
//     white: {
//       main: "#FFFFFF",
//       light: "#FFFFFF",
//       dark: "#FFFFFF",
//       contrastText: "#000000",
//     },
//     progress: {
//       main: "linear-gradient(270deg, #063C94 -644.38%, #5794F8 100%)",
//       light: "linear-gradient(270deg, #063C94 0%, #5794F8 100%)",
//       dark: "#FFFFFF",
//       contrastText: "#000000",
//     },
//   },
//   breakpoints: {
//     values: {
//       xs: 375,
//       sm: 428,
//       md: 744,
//       lg: 1024,
//       mobile: 1155,
//       homepageMobileLayout: 1280,
//       xl: 1440,
//       "2xl": 1660, // Desktop and larger
//       "3xl": 1920, // Large desktop,
//     },
//   },
//   screenMinHeights: {
//     xs: 812, // Iphone Mini
//     sm: 926, // Iphone Pro Max
//     md: 1133, // Ipad Mini
//     lg: 1366, // Ipad Pro
//     xl: 1024, // Laptop and larger
//   },
//   typography: {
//     fontFamily: `"Open Sans", "Helvetica Neue", "Arial", sans-serif`,
//     fontSize: 14,
//     h1: {
//       fontWeight: 600,
//       fontSize: 24,
//       lineHeight: "133.4%",
//     },
//   },
//   customShadows: {
//     light: "1px 1px 12px -1px rgba(0, 0, 0, 0.12)",
//     medium: "5px 4px 11px 0px rgba(0,0,0,0.15)",
//     heavy:
//       "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12)",
//   },
// });

// export default theme;

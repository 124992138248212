/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { ingredientUrls } from './ingredientUrls';
import { refreshAccessToken } from '../refreshTokenApi';

// here start
let accessToken = localStorage.getItem('accessToken');

// ....................Ingredient Categories.............................
// get Ingredient Categories
export const getIngredientCategoriesApi = async () => {
  let res = await axios.get(ingredientUrls.ingredientCategories.category);
  return res;
};

export const addIngredientCategoryApi = async (ingredientCategory) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      ingredientUrls.ingredientCategories.category,
      ingredientCategory,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        ingredientUrls.ingredientCategories.category,
        ingredientCategory,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

// Delete Ingredient Unit API

export const deleteIngredientCategoryApi = async (catId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!catId) {
    return null;
  }
  try {
    const data = await axios.delete(
      `${ingredientUrls.ingredientCategories.category}/${catId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.delete(
        `${ingredientUrls.ingredientCategories.category}/${catId}`,
        newConfig
      );
      return data;
    } else {
      return null;
    }
  }
};

// ....................Ingredient Categories.............................

// ....................Ingredient Units.............................

// get Ingredient Units
export const getIngredientUnitApi = async () => {
  let res = await axios.get(ingredientUrls.ingredientUnit.unit);
  return res;
};

// Add Ingredient Unit API

export const addIngredientUnitApi = async (ingredientUnit) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      ingredientUrls.ingredientUnit.unit,
      ingredientUnit,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        ingredientUrls.ingredientUnit.unit,
        ingredientUnit,
        newConfig
      );
      return data;
    } else {
      return error;
    }
  }
};

// Delete Ingredient Unit API

export const deleteIngredientUnitApi = async (unitId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!unitId) {
    return null;
  }
  try {
    const data = await axios.delete(
      `${ingredientUrls.ingredientUnit.unit}/${unitId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.delete(
        `${ingredientUrls.ingredientUnit.unit}/${unitId}`,
        newConfig
      );
      return data;
    } else {
      return null;
    }
  }
};
// ....................Ingredient Units.............................

// get Ingredient API Call
export const getIngredientsApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const { data } = await axios.get(ingredientUrls.ingredient, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.get(ingredientUrls.ingredient, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Single Ingredient API Call
export const getSingleIngredientsApi = async (ingredientId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!ingredientId) {
    return null;
  }
  try {
    const { data } = await axios.get(
      `${ingredientUrls.ingredient}/${ingredientId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.get(
        `${ingredientUrls.ingredient}/${ingredientId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Ingredient API

export const addIngredientApi = async (ingredient) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      ingredientUrls.ingredient,
      ingredient,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        ingredientUrls.ingredient,
        ingredient,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// update Ingredient API

export const editIngredientApi = async (updatedIngredientData) => {
  const ingredientId = updatedIngredientData.item_id;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!ingredientId) {
    return null;
  }
  try {
    const { data } = await axios.put(
      `${ingredientUrls.ingredient}/${ingredientId}`,
      updatedIngredientData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.put(
        `${ingredientUrls.ingredient}/${ingredientId}`,
        updatedIngredientData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Ingredient API

export const deleteIngredientApi = async (ingredientId, accessToken) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!ingredientId) {
    return null;
  }
  try {
    const { data } = await axios.delete(
      `${ingredientUrls.ingredient}/${ingredientId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const { data } = await axios.delete(
        `${ingredientUrls.ingredient}/${ingredientId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// /* eslint-disable prefer-const */
// /* eslint-disable react/prop-types */
// import React from 'react';
// import {
//   Box,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   SwipeableDrawer,
//   useMediaQuery,
//   Typography,
//   Button
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import logo from '../../assets/logo.png';
// import { NavLink } from 'react-router-dom';
// import Orders from '../POS/Orders';
// import CloseIcon from '@mui/icons-material/Close';

// export default function SwipeableTemporaryDrawer({
//   data,
//   refr,
//   triggerPrint,
//   singlePrintOrder,
//   outletId,
//   trigger,
//   setTrigger,
//   orderTypes
// }) {
//   const myDisplay = useMediaQuery('(max-width: 900px)');

//   const [state, setState] = React.useState({
//     left: false
//   });

//   const toggleDrawer = (anchor, open) => (event) => {
//     if (
//       event &&
//       event.type === 'keydown' &&
//       (event.key === 'Tab' || event.key === 'Shift')
//     ) {
//       return;
//     }

//     setState({ ...state, [anchor]: open });
//   };
//   const list = (anchor) => (
//     <Box
//       sx={{
//         textAlign: 'center',
//         height: '100%',
//         width: '350px'
//       }}
//       role="presentation">
//       <Box sx={{ color: 'white' }}>
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'end',
//             margin: '10px 10px 0 0'
//           }}>
//           <Button onClick={toggleDrawer(anchor, false)}>
//             <CloseIcon />
//           </Button>
//         </Box>

//         <Orders
//           refr={refr}
//           triggerPrint={triggerPrint}
//           singlePrintOrder={singlePrintOrder}
//           outletId={outletId}
//           trigger={trigger}
//           setTrigger={setTrigger}
//           orderTypes={orderTypes}
//         />
//       </Box>
//     </Box>
//   );

//   return (
//     <Box>
//       {['left'].map((anchor) => (
//         <React.Fragment key={anchor}>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'space-between',
//               width: '100%'
//             }}>
//             <Typography
//               sx={{ color: '#fff', fontSize: '20px' }}
//               onClick={toggleDrawer(anchor, true)}>
//               Live Orders
//             </Typography>
//             <Typography
//               sx={{ color: '#fff', fontSize: '20px' }}
//               onClick={toggleDrawer(anchor, true)}>
//               Menu
//             </Typography>
//           </Box>

//           <SwipeableDrawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//             onOpen={toggleDrawer(anchor, true)}>
//             {list(anchor)}
//           </SwipeableDrawer>

//           <SwipeableDrawer
//             anchor={anchor}
//             open={state[anchor]}
//             onClose={toggleDrawer(anchor, false)}
//             onOpen={toggleDrawer(anchor, true)}>
//             {list(anchor)}
//           </SwipeableDrawer>
//         </React.Fragment>
//       ))}
//     </Box>
//   );
// }

/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Box,
  SwipeableDrawer,
  useMediaQuery,
  Typography,
  Button,
  TextField,
  Grid
} from '@mui/material';
import Orders from '../POS/Orders';
import CloseIcon from '@mui/icons-material/Close';
import {
  CategoryBox,
  CategoryButton,
  DetailsBox,
  ImageBox,
  ItemBox,
  MenuItemBox,
  MenuItemsBox,
  NameTypo,
  PriceTypo,
  SearchBox
} from '../POS/Styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/reducers/ducks/CartDuck';
import PendingOrders from '../CallCenter/Orders';

export default function SwipeableTemporaryDrawer({
  refr,
  triggerPrint,
  singlePrintOrder,
  outletId,
  trigger,
  setTrigger,
  orderTypes,
  // props for menu
  selectedCategory,
  setSelectedCategory,
  theme,
  uniqueCategories,
  handleCategoryClick,
  setSearchQuery,
  searchQuery,
  filteredMenuItems,
  getPrice
}) {
  const myDisplay = useMediaQuery('(max-width: 900px)');

  const [state, setState] = React.useState({
    left: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const dispatch = useDispatch();
  const access = useSelector(
    (state) => state.authorize?.loginResponse?.user?.role?.access
  );

  const hasAccess = access.includes('pendingOrders');

  const list = (anchor, title) => (
    <Box
      sx={{
        textAlign: 'center',
        height: '100%',
        width: myDisplay ? '350px' : '350px'
      }}
      role="presentation">
      <Box sx={{ color: 'white' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5px 10px',
            backgroundColor: '#333'
          }}>
          <Typography variant="h6" sx={{ color: '#fff' }}>
            {title}
          </Typography>
          <Button onClick={toggleDrawer(anchor, false)} sx={{ color: '#fff' }}>
            <CloseIcon />
          </Button>
        </Box>

        {anchor === 'left' ? (
          hasAccess ? (
            <PendingOrders
              refr={refr}
              triggerPrint={triggerPrint}
              singlePrintOrder={singlePrintOrder}
              outletId={outletId}
              trigger={trigger}
              setTrigger={setTrigger}
              orderTypes={orderTypes}
            />
          ) : (
            <Orders
              refr={refr}
              triggerPrint={triggerPrint}
              singlePrintOrder={singlePrintOrder}
              outletId={outletId}
              trigger={trigger}
              setTrigger={setTrigger}
              orderTypes={orderTypes}
            />
          )
        ) : (
          <Box
            sx={{
              padding: '5px',
              // backgroundColor: '#f0f0f0',
              height: '100%'
            }}>
            <MenuItemsBox style={{ boxShadow: 'none' }}>
              <CategoryBox>
                <CategoryButton
                  variant="outlined"
                  sx={{
                    background: !selectedCategory
                      ? theme?.palette?.primary?.main
                      : '',
                    color: !selectedCategory ? theme.palette.white.main : '',
                    borderRadius: '10px',
                    marginRight: '2px'
                  }}
                  onClick={() => setSelectedCategory(null)}>
                  All
                </CategoryButton>

                {uniqueCategories.map((category, index) => (
                  <CategoryButton
                    key={index}
                    sx={{
                      background:
                        category === selectedCategory
                          ? theme?.palette.primary.main
                          : '',
                      color:
                        category === selectedCategory
                          ? theme?.palette.white.main
                          : '',
                      borderRadius: '10px',
                      marginRight: '2px'
                    }}
                    variant="outlined"
                    onClick={() => handleCategoryClick(category)}>
                    {category}
                  </CategoryButton>
                ))}
              </CategoryBox>

              <SearchBox>
                <TextField
                  sx={{ width: '100%', borderRadius: '10px' }}
                  label="Search"
                  variant="outlined"
                  type="text"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </SearchBox>

              <MenuItemBox>
                <Grid container>
                  {filteredMenuItems?.map((item, i) => (
                    <Grid
                      key={i}
                      onClick={() => dispatch(addToCart(item))}
                      item
                      lg={3}
                      md={4}
                      xs={6}>
                      <ItemBox>
                        <NameTypo>{item.menuItem.shortName}</NameTypo>
                        <ImageBox
                          component="img"
                          alt="The house from the offer."
                          src={
                            item.menuItem.img
                              ? item.menuItem.img
                              : process.env.REACT_APP_CLIENT_LOGO
                          }
                        />
                        <DetailsBox>
                          <PriceTypo>{getPrice(item)}</PriceTypo>
                        </DetailsBox>
                      </ItemBox>
                    </Grid>
                  ))}
                </Grid>
              </MenuItemBox>
            </MenuItemsBox>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        {/* "Live Orders" on the left side */}
        <Button
          variant="contained"
          sx={{
            color: '#fff',
            borderRadius: '10px',
            padding: '5px',
            margin: '5px 0'
          }}
          onClick={toggleDrawer('left', true)}>
          Live Orders
        </Button>

        {/* "Menu" on the right side */}
        <Button
          variant="contained"
          sx={{
            color: '#fff',
            borderRadius: '10px',
            padding: '5px',
            margin: '5px 0'
          }}
          onClick={toggleDrawer('right', true)}>
          Menu
        </Button>
      </Box>

      {/* Swipeable Drawer for Live Orders (left) */}
      <SwipeableDrawer
        anchor="left"
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}>
        {list('left', 'Live Orders')}
      </SwipeableDrawer>

      {/* Swipeable Drawer for Menu (right) */}
      <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}>
        {list('right', 'Menu')}
      </SwipeableDrawer>
    </Box>
  );
}

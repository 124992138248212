import { Feed } from '@mui/icons-material';

export const ROUTES = {
  main: '/',
  ingredients: '/ingredients',
  menu: '/menu',
  ingredientSet: '/ingredient-set',
  choices: '/choices',
  pos: '/pos',
  outlet: '/outlet',
  callCenter: '/callCenter',
  OrdersStatus: '/orders-status',
  users: '/users-managment',
  deliveryMonitor: '/delivery-monitor',
  changeKey: 'change-key',
  roleManagement: 'role-management',
  reporting: '/reporting',
  changePassword: 'change-password',
  userInformation: 'user-information',
  ingredientManagment: '/ingredient-managment',
  displayScreenManagement: 'display-screen-management',
  walkSheet: 'walk-sheet',
  blockUser: '/block-user',
  dashBoard: '/dash-board',
  feedback: '/feedbacks',
  feedbackSubmission: '/feedback-submission',
  complaint: '/complaint'
};

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import {
  OrdersActionButton,
  OrdersBox,
  SingleOrderBox,
  UserSectionBox,
  OutletSectionBox,
  OrderStatusBox,
  OrdersActionBox,
  OrderTypography,
  UpperBox,
  SearchBox
} from './Styled-components';
import { runningOrdersRequest } from '../../../redux/reducers/ducks/OrdersDuck';
import { useDispatch, useSelector } from 'react-redux';
import Receipt from '../../POS/components/Receipt';
import { extractTime } from '../../Shared/shared';
import OrderDetails from '../../Shared/OrderDetail/OrderDetails';

export default function Orders({ outletId, checked, triggerPrint }) {
  const dispatch = useDispatch();

  const running = true;
  const [runningOrders, setRunningOrders] = useState([]);

  // handling pop Up for Order details
  const [details, setDetails] = useState({ isOpen: false, id: null });

  // getting runningOrdersResponse from store
  const runningOrdersResponse = useSelector((state) => state.runningOrders);
  useEffect(() => {
    if (
      !runningOrdersResponse ||
      !runningOrdersResponse.runningOrdersResponse
    ) {
      dispatch(runningOrdersRequest(outletId));
    }
  }, []);

  useEffect(() => {
    if (runningOrdersResponse && runningOrdersResponse.runningOrdersResponse) {
      setRunningOrders(runningOrdersResponse.runningOrdersResponse?.data);
    }
    // calling API after 30 seconds if outo refresh is on
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        dispatch(runningOrdersRequest(outletId));
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [dispatch, runningOrdersResponse, checked]);

  // getting delivery Orders
  const deliveryOrders = runningOrders.filter(
    (order) => order.orderType == 'delivery' && !order.carrier
  );

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = deliveryOrders.filter(
    (order) =>
      order?._id?.slice(-5)?.includes(searchQuery) ||
      order?.invoiceId?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
      order?.tableName?.includes(searchQuery) ||
      order?.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order?.customerContact?.includes(searchQuery) ||
      order?.orderType?.toLowerCase()?.includes(searchQuery.toLowerCase())
  );

  // handling pop Up for print
  const [print, setPrint] = useState({ isOpen: false, id: '' });

  return (
    <Box>
      <SearchBox>
        <TextField
          label="Search Order"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </SearchBox>
      <OrdersBox>
        {filteredOrders?.map((order, index) => {
          return (
            <Box key={index}>
              <SingleOrderBox>
                <UpperBox>
                  <UserSectionBox>
                    <OrderTypography>ID:{order?.invoiceId}</OrderTypography>
                    <OrderTypography>Name:{order.customerName}</OrderTypography>
                    <OrderTypography>
                      Contact:{order.customerContact}
                    </OrderTypography>
                    {/* <OrderTypography>Second Contact:{order.customerContact}</OrderTypography> */}
                  </UserSectionBox>
                  <OutletSectionBox>
                    {order.orderType === 'dineIn' && (
                      <>
                        <OrderTypography>
                          Table No: {order?.tableName}
                        </OrderTypography>
                        <OrderTypography>
                          Waiter:
                          {order?.carrier?.name ? order?.carrier?.name : 'N/A'}
                        </OrderTypography>
                      </>
                    )}

                    {order.orderType === 'delivery' && (
                      <>
                        <OrderTypography>
                          Dileviry Address: {order.deliveryAddress}
                        </OrderTypography>
                        <OrderTypography>
                          Rider: {order?.carrier?.name}
                        </OrderTypography>
                      </>
                    )}
                    {order.orderType === 'takeAway' && (
                      <>
                        <OrderTypography>
                          Dispatched:
                          {order?.dispatched
                            ? order?.dispatched
                            : 'Not dispatched'}
                        </OrderTypography>
                      </>
                    )}
                  </OutletSectionBox>

                  <OrderStatusBox>
                    <OrderTypography>
                      Preparing:
                      {`${order.items.preparing} / ${order.items.count}`}
                    </OrderTypography>
                    <OrderTypography
                      sx={{
                        paddingLeft: '10px',
                        backgroundColor:
                          order.items.ready === order.items.count
                            ? 'green'
                            : '',
                        color:
                          order.items.ready === order.items.count ? '#fff' : '',
                        fontWeight:
                          order.items.ready === order.items.count ? '700' : '',
                        fontSize:
                          order.items.ready === order.items.count ? '15px' : ''
                      }}>
                      Done: {`${order.items.ready} / ${order.items.count}`}
                    </OrderTypography>
                  </OrderStatusBox>
                </UpperBox>
                <Typography>
                  Time:
                  {extractTime(new Date(order.createdAt))}
                </Typography>
                <OrdersActionBox>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      setDetails({ isOpen: true, id: order._id });
                    }}>
                    Details
                  </OrdersActionButton>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      triggerPrint(order._id);
                      // setPrint({ isOpen: true, id: order._id });
                    }}>
                    Print
                  </OrdersActionButton>
                </OrdersActionBox>
              </SingleOrderBox>
            </Box>
          );
        })}
      </OrdersBox>

      <OrderDetails details={details} setDetails={setDetails} />
      <Receipt print={print} setPrint={setPrint} running={running} />
    </Box>
  );
}

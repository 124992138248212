import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import displayScreenUrls from './displayScreenUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get Display Screens API
export const getDisplayScreensApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(displayScreenUrls.displayScreens, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(displayScreenUrls.displayScreens, newConfig);
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Add Display Screen API

export const addDisplayScreensApi = async (displayScreendata) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      displayScreenUrls.displayScreens,
      displayScreendata,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        displayScreenUrls.displayScreens,
        displayScreendata,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Display Screen API

export const deleteDisplayScreenApi = async (displayScreenId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!displayScreenId) {
    return null;
  }
  try {
    const data = await axios.delete(
      `${displayScreenUrls.displayScreens}/${displayScreenId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${displayScreenUrls.displayScreens}/${displayScreenId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

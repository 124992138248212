/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import {
  addIngredientApi,
  getIngredientsApi
} from '../../../api/ingredients/ingredientsApi';
import * as actions from '../../reducers/ducks/IngredientDuck';

export function* ingredient({ payload }) {
  try {
    const ingredient = yield call(getIngredientsApi);
    yield put(actions.updateIngredientSuccessStatus(true));
    yield put(
      actions.ingredientResponse({
        response: ingredient
      })
    );
  } catch (error) {
    console.error(error);
  }
}

// export function* addIngredient({ payload }) {
//   try {
//     const newIngredient = yield call(addIngredientApi, payload);
//     yield put(actions.updateIngredientSuccessStatus(true));
//     yield put(
//       actions.ingredientResponse({
//         response: newIngredient
//       })
//     );
//   } catch (error) {
//     console.error(error);
//   }
// }

export function* watchIngredientSagas() {
  yield takeLatest(actions.ingredientRequest.type, ingredient);
  // yield takeLatest(actions.addIngredient.type, addIngredient);
}

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import {
  addIngredientCategoryApi,
  deleteIngredientCategoryApi
} from '../../api/ingredients/ingredientsApi';

const AddEditCategory = ({
  addCategoryPopup,
  setAddCategoryPopup,
  callFunc
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    if (addCategoryPopup.id) {
      reset();
    }
  }, [addCategoryPopup.isOpen]);

  const onSubmit = async (data) => {
    const res = await addIngredientCategoryApi(data);
    if (res.status == 200) {
      dispatch(
        addSnackbar({
          message: 'New Category Created',
          type: 'success'
        })
      );
      handleClose();
      callFunc();
    } else {
      dispatch(
        addSnackbar({
          message: res.response.data.message,
          type: 'error'
        })
      );
    }
  };

  const handleClose = () => {
    setAddCategoryPopup({ isOpen: false });
    reset();
  };

  return (
    <Box>
      <Container>
        <Dialog open={addCategoryPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {addCategoryPopup?.id ? (
                <Typography color="#FFA500" fontWeight="700">
                  Delete Category
                </Typography>
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Create New Category
                </Typography>
              )}
            </DialogTitle>
            {!addCategoryPopup.id ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('name', { required: true })}
                    margin="normal"
                    label="Ingredient Category Name"
                    fullWidth
                  />
                  <TextField
                    {...register('desc', { required: true })}
                    margin="normal"
                    label="Description"
                    fullWidth
                  />

                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>
                  Are you sure to delete this Category?
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      const res = await deleteIngredientCategoryApi(
                        addCategoryPopup?.id
                      );
                      if (res.status == 200) {
                        dispatch(
                          addSnackbar({
                            message: 'Category Deleted',
                            type: 'warning'
                          })
                        );
                        handleClose();
                        callFunc();
                      }
                    }}>
                    Delete
                  </Button>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditCategory;

import axios from 'axios';
import { refreshAccessToken } from '../../refreshTokenApi';
import inventoryContollerUrls from './inventoryControllerUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get Initaite Inventory API
export const initiateInventoryApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${inventoryContollerUrls.inventoryControllers}/${outletId}/initiate`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${inventoryContollerUrls.inventoryControllers}/${outletId}/initiate`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// Add Inventory API
export const addInventoryApi = async (inventoryData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      `${inventoryContollerUrls.inventoryController}`,
      inventoryData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        `${inventoryContollerUrls.inventoryController}`,
        inventoryData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get Inventory History API
export const getInventoryHistoryApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${inventoryContollerUrls.inventoryControllers}/${outletId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${inventoryContollerUrls.inventoryControllers}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get Inventory History Ingredients API
export const getInventoryHistoryIngredientsApi = async (outletId, saleDate) => {
  const outletParams = `outlet/${outletId}`;
  const dateParams = `date/${saleDate}`;

  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${inventoryContollerUrls.inventoryController}/${outletParams}/${dateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${inventoryContollerUrls.inventoryController}/${outletParams}/${dateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

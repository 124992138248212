import { Box, Button, Grid, Typography, styled } from '@mui/material';

// ****************************************** Kitchen Display New Card*************************

export const FullGrid = styled(Grid)(({ states }) => ({
  position: states ? 'absolute' : '',
  opacity: states ? 0 : '',
  top: states ? 0 : '',
  left: states ? 0 : '',
  transform: states ? 'translateY(-300px)' : '',
  transition: states ? 'all 0.6s ease-out' : '',
  '&:hover': {}
}));

export const ParentSection = styled(Box)(({ theme }) => ({
  border: '1px solid gray',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '300px',
  width: '250px',
  boxShadow: theme.palette.customShadows.light,
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {}
}));

export const UpperSection = styled(Box)(({ theme }) => ({
  height: '20%',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '10px 10px 0 0px',
  textAlign: 'left',
  color: '#fff',
  padding: '10px',
  fontSize: '14px',
  fontWeight: '600',
  gap: '20px'
}));

export const UpperTypography = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  padding: '2px 0'
}));

export const AddressTypo = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  padding: '2px 0'
}));

export const TimerRing = styled(Box)(() => ({
  border: '3px solid #ff9d00',
  borderRadius: '50%',
  width: '65px',
  height: '65px',
  padding: '3px',
  boxSizing: 'content-box'
}));

export const Timer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.white.main,
  width: '65px',
  height: '65px',
  border: '2px solid #bee3dd',
  borderRadius: '50%',
  color: '#1aaf96',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: '700'
}));

export const MiddleSection = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  minHeight: '200px',
  height: 'fit-content'
}));

export const Items = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'start',
  padding: '0 10px',
  boxShadow: theme.palette.customShadows.light,
  fontSize: '17px',
  fontWeight: '600',
  lineHeight: 0
}));

export const LowerSection = styled(Box)(() => ({
  height: '15%',
  borderTop: '3px solid #008670',
  fontSize: '17px',
  fontWeight: '600',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 10px 0 10px'
}));

export const DoneButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '30px',
  fontSize: '17px',
  fontWeight: '700',
  height: '50px',
  width: '100px',
  color: theme.palette.white.light,
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    backgroundColor: theme.palette.primary.main
  }
}));

import React from 'react';
import { Box, Skeleton } from '@mui/material';
export default function SkeletonCard() {
  return (
    <Box sx={{ margin: '10px 0' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 10px'
        }}>
        <Skeleton animation="pulse" variant="circular" width={70} height={70} />
        <Skeleton animation="wave" height={20} width="30%" />
        <Skeleton animation="wave" height={20} width="30%" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Skeleton
          animation="wave"
          height={20}
          width="80%"
          style={{ marginBottom: 6 }}
        />
      </Box>
    </Box>
  );
}


/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/DisplayScreenOrdersDuck';
import { getOrderOperationDisplayApi } from '../../../api/orders/OrdersApi';

export function* displayScreenOrders({ payload }) {
  try {
    const displayScreenOrders = yield call(() =>
      getOrderOperationDisplayApi(payload.outlet, payload.screen)
    );
    yield put(actions.updateDisplayScreenOrdersSuccessStatus(true));
    yield put(
      actions.displayScreenOrdersResponse({
        response: displayScreenOrders
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchDisplayScreenOrdersSagas() {
  yield takeLatest(
    actions.displayScreenOrdersRequest.type,
    displayScreenOrders
  );
}

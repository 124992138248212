/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import { permisssions } from '../../Shared/shared';
import { useDispatch, useSelector } from 'react-redux';
import { setDiscount } from '../../../redux/reducers/ducks/CartDuck';

export default function Discount({
  discountPopup,
  setDiscountPopup,
  outletId
  // setChildDiscountData
}) {
  // handling state and handleChange for tab component
  const [tabValue, setTabValue] = useState(0);
  const { discount, fixedDiscount, discountRef } = useSelector(
    (state) => state.cart
  );
  const dispatch = useDispatch();
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      outletId ? permisssions.addDiscount : permisssions.appDiscount
    );
    setUsers(response?.data);
  };
  useEffect(() => {
    getUsersWithPermission();
  }, []);

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, reset, control } = useForm({
    defaultValues: {
      discount,
      fixedDiscount,
      discountRef
      // discountReason
    }
  });

  const watchCheckbox = watch('fixedDiscount');

  const discountLabel = watchCheckbox // watchCheckbox
    ? 'Fixed Discount'
    : 'Percentage Discount';

  const onSubmit = (data) => {
    console.log(data, 'data');

    // setChildDiscountData(data);
    dispatch(setDiscount(data));
    handleClose();
  };
  const handleClose = () => {
    reset();
    setDiscountPopup('isOpen', false);
  };
  useEffect(() => {
    reset({
      discount,
      fixedDiscount,
      discountRef
    });
  }, [discountPopup]);
  return (
    <div>
      <Dialog
        maxWidth="auto"
        open={discountPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Add Discount</DialogTitle>

        <Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Manual Discount" />
              <Tab label="Voucher" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <Box sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  {/* <FormControl sx={{ width: '50%' }} size="small">
                    <InputLabel>Name</InputLabel> */}
                  <Controller
                    name="discountRef.user"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        defaultValue=""
                        {...field}
                        label="Name"
                        // onChange={handleChangeUserName}
                        select
                        fullWidth>
                        {users?.map((user, index) => (
                          <MenuItem key={index} value={user._id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  {/* </FormControl> */}

                  <TextField
                    size="small"
                    label="Manager Key"
                    type="password"
                    {...register('discountRef.key')}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  <TextField
                    sx={{ width: '50%' }}
                    size="small"
                    label={discountLabel}
                    {...register('discount')}
                  />
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    label="Fixed"
                    control={<Checkbox {...register('fixedDiscount')} />}
                  />
                </Box>
                <Box>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Reason for Discount"
                    type="text"
                    {...register('reason')}
                  />
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '10px',
                    margin: '5px auto'
                  }}>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    variant="outlined"
                    onClick={handleClose}
                    autoFocus>
                    close
                  </Button>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    type="submit"
                    variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <TextField
                    size="small"
                    fullWidth
                    label="Voucher"
                    type="text"
                    {...register('voucher')}
                  />
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '10px',
                    margin: '5px auto'
                  }}>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    variant="outlined"
                    onClick={handleClose}
                    autoFocus>
                    close
                  </Button>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    type="submit"
                    variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Box>

        {/* <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  Typography,
  Box,
  DialogContent
} from '@mui/material';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function SwitchOutletConfirm({
  switchOutlet,
  setSwitchOutlet,
  forceSwitch
}) {
  const handleClose = () => {
    setSwitchOutlet('isOpen', false);
  };

  // setSelectedOutlet(switchOutlet?.value)
  return (
    <div>
      <Dialog
        className="dialog"
        sx={{ textAlign: 'left' }}
        open={switchOutlet?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <ReportProblemIcon sx={{ color: 'yellow', fontSize: '50px' }} />
        </DialogTitle>
        <DialogContent>
          <ul>
            {switchOutlet?.items?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <Typography>
            These Menu Items are not available in the branch you are going to
            switch
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={forceSwitch} autoFocus>
            Switch anyway
          </Button>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Grid, Typography } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ItemBox, ReportChip } from './Styled-components';
import { useSelector } from 'react-redux';

export default function BMDetails() {
  const userDetails = useSelector(
    (state) => state.authorize?.loginResponse?.user
  );
  const accesses = userDetails?.role?.access;
  const isSuper = userDetails?.role?.name === 'super';

  const reports = [
    {
      name: 'Outlet Sale Report',
      route: 'outlet-sale-report',
      access: 'riderSheet'
    },
    {
      name: 'Over-All Report',
      route: 'over-all-report',
      access: 'overallReports'
    },
    {
      name: 'Deleted Items Report',
      route: 'deleted-items-report',
      access: 'overallReports'
    },
    {
      name: 'Discount Report',
      route: 'discount-report',
      access: 'overallReports'
    },
    {
      name: 'Canceled Report',
      route: 'canceled-report',
      access: 'overallReports'
    },
    {
      name: 'PRA Report',
      route: 'pra-report',
      access: 'praReports'
    },
    {
      name: 'All Outlets Detailed Report',
      route: 'outlets-detail-report',
      access: 'detailedReport'
    }
  ];

  const feedbackReports = [
    {
      name: 'Feedback Dashbord',
      route: 'feedback-dashbord',
      access: 'FeedbackDashbord'
    },
    {
      name: 'All Feedback',
      route: 'feedbacks',
      access: 'feedbackReports'
    },

    {
      name: 'Feedback Summary',
      route: 'feedbacks-summary',
      access: 'feedbackReports'
    },
    {
      name: 'Feedback Reports',
      route: 'feedbacks-reports',
      access: 'feedbackReports'
    }
  ];

  const ComplaintReports = [
    {
      name: 'All Complaints',
      route: 'complaint',
      access: 'complaintReports'
    }
  ];
  const filteredReports = isSuper
    ? reports
    : reports.filter((report) => accesses.includes(report.access));
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} xs={6}>
          <Typography variant="h5" component="h2">
            Reporting
          </Typography>
          <ItemBox>
            {filteredReports.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <NavLink to={item.route}>
                    <ReportChip label={item.name} variant="outlined" />
                  </NavLink>
                </React.Fragment>
              );
            })}
          </ItemBox>
        </Grid>

        <Grid item lg={3} md={3} xs={6}>
          <Typography variant="h5" component="h2">
            Feedback Reports
          </Typography>
          <ItemBox>
            {feedbackReports.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <NavLink to={item.route}>
                    <ReportChip label={item.name} variant="outlined" />
                  </NavLink>
                </React.Fragment>
              );
            })}
          </ItemBox>
        </Grid>

        {/* <Grid item lg={3} md={3} xs={6}>
          <Typography variant="h5" component="h2">
            Complaint Reports
          </Typography>
          <ItemBox>
            {ComplaintReports.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <NavLink to={item.route}>
                    <ReportChip label={item.name} variant="outlined" />
                  </NavLink>
                </React.Fragment>
              );
            })}
          </ItemBox>
        </Grid> */}
      </Grid>
    </div>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getRegisterReportApi } from '../../api/report/reportApi';
import { getCurrentDate } from '../Shared/shared';

export default function RegisterReport({ value, setValue }) {
  // getting outlet ID for API call

  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [reportData, setreportData] = useState([]);

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  const paymentMethods = Array.from(
    new Set(
      reportData?.reduce(
        (array, cashier) => [
          ...array,
          ...cashier.paymentMethods.map(
            (paymentMethod) => paymentMethod.paymentMethod
          )
        ],
        []
      )
    )
  );

  const reportApi = async () => {
    try {
      const response = await getRegisterReportApi(outlet?._id, startDate);
      setreportData(response?.data);
      if (response) {
      }
    } catch (e) {}
  };

  const handleClose = () => {
    setreportData([]);
    setValue({ isOpen: false });
  };

  useEffect(() => {}, [value.isOpen]);

  return (
    <div>
      <Dialog open={value.isOpen} onClose={handleClose} maxWidth="auto">
        <Box
          sx={{
            textAlign: 'center',
            height: '700px',
            marginInline: 'auto'
          }}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography fontSize="20px" fontWeight="700">
                Report
              </Typography>
            </DialogContentText>

            <Box
              my={2}
              sx={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <label htmlFor="date">Select Date to see Report</label>
              <input
                className="date-input"
                type="date"
                onChange={handleDate}
                name=""
                id="date"
                value={startDate || ''}
              />
              <Button
                size="small"
                variant="contained"
                onClick={() => {
                  reportApi();
                }}>
                Get Report
              </Button>
            </Box>

            <TableContainer
              component={Paper}
              sx={{ height: '300px', overflowY: 'auto' }}>
              <Table aria-label="collapsible table">
                <TableHead sx={{ background: '#FFA500', color: 'white' }}>
                  <TableRow>
                    <TableCell>Cashier</TableCell>
                    {paymentMethods?.map((method, index) => {
                      return <TableCell key={index}>{method}</TableCell>;
                    })}
                    <TableCell>Sale</TableCell>
                    <TableCell>Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>{item.cashierName}</TableCell>
                          {paymentMethods?.map((method, index) => {
                            return (
                              <TableCell key={index}>
                                <Box>
                                  <span>
                                    <b>
                                      {Math.round(
                                        item.paymentMethods?.find(
                                          (paymentMethod) =>
                                            paymentMethod.paymentMethod ===
                                            method
                                        )?.totalSale
                                      )}
                                    </b>
                                  </span>
                                </Box>
                              </TableCell>
                            );
                          })}
                          <TableCell>{Math.round(item.totalSale)}</TableCell>
                          <TableCell>{item.totalCount}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}

                  <TableRow>
                    <TableCell>
                      <b>Total</b>
                    </TableCell>
                    {paymentMethods?.map((method, index) => {
                      return (
                        <TableCell key={index}>
                          <Box>
                            <span>
                              <b>
                                {reportData.reduce(
                                  (total, item) =>
                                    Math.round(
                                      total +
                                        (item.paymentMethods?.find(
                                          (paymentMethod) =>
                                            paymentMethod.paymentMethod ===
                                            method
                                        )?.totalSale || 0)
                                    ),
                                  0
                                )}
                              </b>
                            </span>
                          </Box>
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      {reportData.reduce(
                        (total, cashier) =>
                          Math.round(total + cashier.totalSale),
                        0
                      )}
                    </TableCell>
                    <TableCell>
                      {reportData.reduce(
                        (total, cashier) => total + cashier.totalCount,
                        0
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

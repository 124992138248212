import { getApi } from '../../components/Shared/shared';

const baseURL = getApi();

export const complaintUrls = {
  createComplaint: `${baseURL}/complaints`,
  complaints: `${baseURL}/complaints`
};

export default complaintUrls;


import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  choicesResponse: null,
  isSuccess: false
};

const choicesSlice = createSlice({
  name: 'choices',
  initialState: INITIAL_STATE,
  reducers: {
    choicesResponse(state, { payload }) {
      return {
        ...state,
        choicesResponse: payload.response
      };
    },
    choicesRequest: (state) => state,
    updateChoicesSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const { choicesRequest, choicesResponse, updateChoicesSuccessStatus } =
  choicesSlice.actions;

export default choicesSlice.reducer;

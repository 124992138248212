/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { getSaleReportApi } from '../../api/report/reportApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Receipt from '../POS/components/Receipt';
import CanceledOrdersReport from './CanceledOrdersReport';
import { CountTableCell, HeaderTableCell } from './Styled-components';
import Loader from '../Loader/Loader';
import { getCurrentDate } from '../Shared/shared';
import { theme } from '../../theme';
import { useReactToPrint } from 'react-to-print';
import { getSingleOrderApi } from '../../api/orders/OrdersApi';
import { Printing } from '../Printing/Printing';

const calculateType = (orders, type) => {
  return orders?.reduce(
    (prev, current) =>
      current.orderType == type
        ? {
            sale:
              prev.sale +
              current.total +
              (current.tax || 0) +
              current.totalCharges +
              (current.chargesTax || 0),
            count: prev.count + 1,
            discount: prev.discount + current.discount
          }
        : prev,
    { sale: 0, count: 0, discount: 0 }
  );
};

export default function SalesReport() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [saleReport, setSaleReport] = useState([]);

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  // calling APi

  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getSaleReportApi(outlet?._id, startDate);
      setSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // printing any order
  const [print, setPrint] = useState({ isOpen: false, id: '' });
  const refr = useRef();

  const [singlePrintOrder, setSinglePrintOrder] = useState();

  const handlePrint = useReactToPrint({
    content: () => refr.current
  });

  const triggerPrint = async (id) => {
    const res = await getSingleOrderApi(id);
    if (res.data) {
      setSinglePrintOrder(res?.data);
    }
    setTimeout(handlePrint, 0);
  };

  const cashedOut = saleReport?.filter((order) => order.cashedOut);
  const cancelled = saleReport?.filter((order) => order.cancelled);

  const dineIn = calculateType(cashedOut, 'dineIn');
  const takeAway = calculateType(cashedOut, 'takeAway');
  const delivery = calculateType(cashedOut, 'delivery');

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = cashedOut?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.invoiceId?.toLowerCase().includes(term) ||
          report?.customerName?.toLowerCase().includes(term) ||
          report?.customerContact?.toLowerCase().includes(term) ||
          report?.orderType?.toLowerCase().includes(term) ||
          report?.paymentMethod?.name?.toLowerCase().includes(term) ||
          (term === 'discount' && report.discount > 0)
        );
      })
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <div style={{ display: 'none' }}>
        <Printing ref={refr} order={singlePrintOrder} />
      </div>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Sales
        </Typography>
        <Box>
          <label htmlFor="date">Select Date to see Report</label>
          <input
            className="date-input"
            type="date"
            onChange={handleDate}
            name=""
            id="date"
            value={startDate || ''}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    Search Order by Id , Customer Name, Contact, Order Type,
                    Payment Method
                  </Typography>
                  <TextField
                    label="Search Order"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>

          <Table size="small" aria-label="collapsible table">
            <TableHead
              sx={{ background: theme.palette.primary.main, color: 'white' }}>
              <TableRow>
                <HeaderTableCell>Sr#</HeaderTableCell>
                <HeaderTableCell>order ID</HeaderTableCell>
                <HeaderTableCell>Customer</HeaderTableCell>
                <HeaderTableCell>Contact</HeaderTableCell>
                <HeaderTableCell>Cashier name</HeaderTableCell>
                <HeaderTableCell>Order Type</HeaderTableCell>
                <HeaderTableCell>Discount</HeaderTableCell>
                <HeaderTableCell>payment method</HeaderTableCell>
                <HeaderTableCell>Charges</HeaderTableCell>
                <HeaderTableCell>Total</HeaderTableCell>
                <HeaderTableCell>Print</HeaderTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    // getting charges

                    const grandTotal = Math.round(
                      report?.total +
                        (report?.tax || 0) +
                        report.totalCharges +
                        (report?.chargesTax || 0)
                    );
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FFA500a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell width="5px"> {index + 1}</TableCell>
                        <TableCell>
                          #{report?.invoiceId || report?._id?.slice(-5)}
                        </TableCell>
                        <TableCell> {report?.customerName}</TableCell>
                        <TableCell> {report?.customerContact} </TableCell>
                        <TableCell> {report?.cashier?.name} </TableCell>
                        <TableCell>{report?.orderType}</TableCell>
                        <TableCell>{Math.round(report?.discount)}</TableCell>
                        <TableCell>{report?.paymentMethod?.name}</TableCell>
                        <TableCell>
                          {Math.round(report?.totalCharges)}
                        </TableCell>
                        <TableCell>{grandTotal}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              triggerPrint(report?._id);
                            }}>
                            <PrintIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
              <TableRow>
                <CountTableCell>DineIn orders:</CountTableCell>
                <CountTableCell>{dineIn.count}</CountTableCell>
                <CountTableCell>DineIn Sales:</CountTableCell>
                <CountTableCell>{Math.round(dineIn.sale)}</CountTableCell>
                <CountTableCell>DineIn Discount:</CountTableCell>
                <CountTableCell>{Math.round(dineIn.discount)}</CountTableCell>
              </TableRow>
              <TableRow>
                <CountTableCell>TakeAway orders:</CountTableCell>
                <CountTableCell>{takeAway.count}</CountTableCell>
                <CountTableCell>TakeAway Sales:</CountTableCell>
                <CountTableCell>{Math.round(takeAway.sale)}</CountTableCell>
                <CountTableCell>TakeAway Discount:</CountTableCell>
                <CountTableCell>{Math.round(takeAway.discount)}</CountTableCell>
              </TableRow>
              <TableRow>
                <CountTableCell>Delivery orders:</CountTableCell>
                <CountTableCell>{delivery.count}</CountTableCell>
                <CountTableCell>Delivery Sales:</CountTableCell>
                <CountTableCell>{Math.round(delivery.sale)}</CountTableCell>
                <CountTableCell>Delivery Discount:</CountTableCell>
                <CountTableCell>{Math.round(delivery.discount)}</CountTableCell>
              </TableRow>
              <TableRow>
                <CountTableCell>Total orders:</CountTableCell>
                <CountTableCell>
                  {delivery.count + takeAway.count + dineIn.count}
                </CountTableCell>
                <CountTableCell>Total Sales:</CountTableCell>
                <CountTableCell>
                  {Math.round(delivery.sale + takeAway.sale + dineIn.sale)}
                </CountTableCell>
                <CountTableCell>Total Discount:</CountTableCell>
                <CountTableCell>
                  {delivery.discount + takeAway.discount + dineIn.discount}
                </CountTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <Box mt={10}>
          <CanceledOrdersReport cancelled={cancelled} />
        </Box>
      )}

      <Receipt print={print} setPrint={setPrint} />
    </>
  );
}

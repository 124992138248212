/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/ChoicesDuck';
import { getChoicesApi } from '../../../api/choices/choicesApi';

export function* choices({ payload }) {
  try {
    const choices = yield call(getChoicesApi);
    yield put(actions.updateChoicesSuccessStatus(true));
    yield put(
      actions.choicesResponse({
        response: choices
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchChoicesSagas() {
  yield takeLatest(actions.choicesRequest.type, choices);
}

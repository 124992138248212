import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  runningOrdersResponse: null,
  isSuccess: false
};

const runningOrdersSlice = createSlice({
  name: 'runningOrders',
  initialState: INITIAL_STATE,
  reducers: {
    runningOrdersResponse(state, { payload }) {
      return {
        ...state,
        runningOrdersResponse: payload.response
      };
    },
    runningOrdersRequest: (state) => state,
    updateRunningOrdersSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  runningOrdersRequest,
  runningOrdersResponse,
  updateRunningOrdersSuccessStatus
} = runningOrdersSlice.actions;

export default runningOrdersSlice.reducer;

import { fetchWithAuth } from '../refreshTokenApi';
import feedbackUrls from './feedbackUrls';

export const getFeedbackSummaryApi = async (startDate, endDate) => {
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const result = await fetchWithAuth(
    `${feedbackUrls.feedbackSummaryReport}?${startDateParams}&${endDateParams}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// create a new Feedback

export const createFeedbackApi = async (data) => {
  const result = await fetchWithAuth(feedbackUrls.createFeedback, {
    body: data,
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

export const getOrderFromInvoiceApi = async (id) => {
  const result = await fetchWithAuth(
    `${feedbackUrls.getOrderFromInvoice}/${id}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// outlets ratings
export const getOutletsRatingApi = async (days) => {
  const result = await fetchWithAuth(
    `${feedbackUrls.outLetsRating}?days=${days}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};


// /* eslint-disable prefer-const */
// /* eslint-disable no-unused-vars */
// import React, { useEffect, useRef, useState } from 'react';
// import {
//   Box,
//   Button,
//   FormControlLabel,
//   Grid,
//   Skeleton,
//   Switch,
//   TextField
// } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import { addToCart, resetCart } from '../../redux/reducers/ducks/CartDuck';
// import { outletMenuRequest } from '../../redux/reducers/ducks/OutletDuck';
// import {
//   ImageBox,
//   ItemBox,
//   NameTypo,
//   PriceTypo,
//   DetailsBox,
//   MenuItemBox,
//   CategoryBox,
//   MenuItemsBox,
//   SearchBox,
//   CategoryButton,
//   GeneralTypography
// } from './Styled-components';
// import Header from '../Header/Header';
// import { Outlet, useParams } from 'react-router-dom';
// import Orders from './Orders';
// import Cart from './Cart';
// import { getPrice } from './Shared';
// import Receipt from './components/Receipt';
// import ModifyOrder from './ModifyOrder/ModifyOrder';
// import { theme } from '../../theme';
// import { useReactToPrint } from 'react-to-print';
// import { getSingleOrderApi } from '../../api/orders/OrdersApi';
// import { Printing } from '../Printing/Printing';

// export default function POS() {
//   const running = true;
//   const dispatch = useDispatch();
//   const modifyOrder = useSelector((state) => state.cart.modifyOrder);

//   const refr = useRef();

//   const [singlePrintOrder, setSinglePrintOrder] = useState();

//   const handlePrint = useReactToPrint({
//     content: () => refr.current
//   });

//   const triggerPrint = async (id) => {
//     const res = await getSingleOrderApi(id);
//     if (res.data) {
//       setSinglePrintOrder(res?.data);
//     }
//     setTimeout(handlePrint, 0);
//   };

//   // getting outlet code from params and outletId from Store on the basis of code
//   const { code, pos } = useParams();
//   const outlet = useSelector((state) =>
//     state.authorize?.loginResponse?.user?.outlets?.find(
//       (outlet) => outlet.code.toLowerCase() === code
//     )
//   );
//   if (!outlet) {
//     return <>Outlet Not Found...</>;
//   }

//   const posMap = {
//     delivery: ['delivery'],
//     'dine-in': ['dineIn', 'takeAway'],
//     pos: ['dineIn', 'takeAway', 'delivery']
//   };

//   const orderTypes = pos ? posMap[pos] : posMap['pos'];

//   // getting Outlet Menu response and calling API if not
//   const [outletMenu, setOutletMenu] = useState([]);
//   const outletMenuResponse = useSelector((state) => state.outletMenues);

//   useEffect(() => {
//     if (!outletMenuResponse || !outletMenuResponse.outletMenuResponse) {
//       dispatch(outletMenuRequest(outlet._id));
//     } else {
//       setOutletMenu(outletMenuResponse.outletMenuResponse?.data.menuItems);
//     }
//   }, [dispatch, outletMenuResponse, callFunc]);

//   // calling API over some data modification to update store
//   async function callFunc() {
//     dispatch(outletMenuRequest(outlet._id));
//   }

//   // call runningOrdersAPi in child component i.e Orders component
//   const [trigger, setTrigger] = useState({
//     status: false
//     // order: singleOrder
//   });
//   // here sending this function to cart child and get trigger value from it and
//   // than to send it to orders component
//   const updateTriggerState = (value) => {
//     setTrigger({ status: value });
//   };

//   // getting unique categories
//   const uniqueCategories = [
//     ...new Set(
//       outletMenu?.map((singleItem) => singleItem?.menuItem?.category?.name)
//     )
//   ];

//   // state for search query
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');
//   const handleCategoryClick = (category) => {
//     setSelectedCategory(category);
//   };

//   // handling switch to switch POS to delivery
//   // const [deliveryPos, setDeliveryPos] = useState(false);

//   // const handleToggle = () => {
//   //   setDeliveryPos(!deliveryPos);
//   // };

//   // Search module
//   const filteredMenuItems = outletMenu?.filter((item) => {
//     const terms = searchQuery.split(' ');
//     return (
//       (!selectedCategory || item.menuItem.category.name === selectedCategory) &&
//       terms.every((term) => {
//         return item.menuItem.name.toLowerCase().includes(term.toLowerCase());
//       })
//     );
//   });
//   // setting up Skeleton
//   if (outletMenu?.length === 0) {
//     return (
//       <Box height="max-content">
//         {[...Array(10)].map((arr, i) => (
//           <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
//         ))}
//       </Box>
//     );
//   }
//   const [isSmall, setIsSmall] = useState(window.innerWidth <= 900);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmall(window.innerWidth <= 900);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   return (
//     <>
//       <Header />

//       <div style={{ display: 'none' }}>
//         <Printing ref={refr} order={singlePrintOrder} />
//       </div>

//       <Grid container spacing={1}>
//         <Grid item sm={12} md={0} lg={2}>
//           <Orders
//             refr={refr}
//             triggerPrint={triggerPrint}
//             singlePrintOrder={singlePrintOrder}
//             outletId={outlet._id}
//             trigger={trigger}
//             setTrigger={setTrigger}
//             orderTypes={orderTypes}
//           />
//         </Grid>

//         <Grid item sm={12} md={5} lg={4}>
//           {!modifyOrder ? (
//             <Cart
//               triggerPrint={triggerPrint}
//               singlePrintOrder={singlePrintOrder}
//               outlet={outlet}
//               outletMenu={outletMenu}
//               updateTriggerState={updateTriggerState} // function to get update trigger state
//               orderTypes={orderTypes}
//             />
//           ) : (
//             <ModifyOrder
//               orderId={modifyOrder}
//               outlet={outlet}
//               outletMenu={outletMenu}
//               updateTriggerState={updateTriggerState}
//             />
//           )}
//         </Grid>

//         <Grid item sm={12} md={7} lg={6}>
//           <MenuItemsBox>
//             <CategoryBox>
//               <CategoryButton
//                 variant="outlined"
//                 sx={{
//                   background: !selectedCategory
//                     ? theme.palette.primary.main
//                     : '',
//                   color: !selectedCategory ? theme.palette.white.main : ''
//                 }}
//                 onClick={() => {
//                   setSelectedCategory(null);
//                 }}>
//                 All
//               </CategoryButton>

//               {uniqueCategories.map((category, index) => {
//                 return (
//                   <Box key={index}>
//                     <CategoryButton
//                       sx={{
//                         background:
//                           category === selectedCategory
//                             ? theme.palette.primary.main
//                             : '',
//                         color:
//                           category === selectedCategory
//                             ? theme.palette.white.main
//                             : ''
//                       }}
//                       variant="outlined"
//                       onClick={() => handleCategoryClick(category)}>
//                       {category}
//                     </CategoryButton>
//                   </Box>
//                 );
//               })}
//             </CategoryBox>

//             <SearchBox>
//               <TextField
//                 label="Search"
//                 variant="outlined"
//                 type="text"
//                 size="small"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//             </SearchBox>

//             <MenuItemBox>
//               <Grid container>
//                 {filteredMenuItems?.map((item, i) => {
//                   return (
//                     <React.Fragment key={i}>
//                       <Grid
//                         onClick={() => dispatch(addToCart(item))}
//                         item
//                         lg={3}
//                         md={4}
//                         xs={6}>
//                         <ItemBox>
//                           <NameTypo>{item.menuItem.shortName}</NameTypo>
//                           <ImageBox
//                             component="img"
//                             alt="The house from the offer."
//                             src={
//                               item.menuItem.img
//                                 ? item.menuItem.img
//                                 : process.env.REACT_APP_CLIENT_LOGO
//                             }
//                           />
//                           <DetailsBox>
//                             <PriceTypo>{getPrice(item)}</PriceTypo>
//                           </DetailsBox>
//                         </ItemBox>
//                       </Grid>
//                     </React.Fragment>
//                   );
//                 })}
//               </Grid>
//             </MenuItemBox>
//           </MenuItemsBox>
//         </Grid>
//       </Grid>
//       <Outlet />
//     </>
//   );
// }

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
  TextField,
  useMediaQuery
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, resetCart } from '../../redux/reducers/ducks/CartDuck';
import { outletMenuRequest } from '../../redux/reducers/ducks/OutletDuck';
import {
  ImageBox,
  ItemBox,
  NameTypo,
  PriceTypo,
  DetailsBox,
  MenuItemBox,
  CategoryBox,
  MenuItemsBox,
  SearchBox,
  CategoryButton,
  GeneralTypography
} from './Styled-components';
import Header from '../Header/Header';
import { Outlet, useParams } from 'react-router-dom';
import Orders from './Orders';
import Cart from './Cart';
import { getPrice } from './Shared';
import Receipt from './components/Receipt';
import ModifyOrder from './ModifyOrder/ModifyOrder';
import { theme } from '../../theme';
import { useReactToPrint } from 'react-to-print';
import { getSingleOrderApi } from '../../api/orders/OrdersApi';
import { Printing } from '../Printing/Printing';
export default function POS() {
  const dispatch = useDispatch();
  const modifyOrder = useSelector((state) => state.cart.modifyOrder);

  const refr = useRef();
  const [singlePrintOrder, setSinglePrintOrder] = useState();

  const handlePrint = useReactToPrint({
    content: () => refr.current
  });

  const triggerPrint = async (id) => {
    const res = await getSingleOrderApi(id);
    if (res.data) {
      setSinglePrintOrder(res?.data);
    }
    setTimeout(handlePrint, 0);
  };

  const { code, pos } = useParams();
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [outletMenu, setOutletMenu] = useState([]);
  const outletMenuResponse = useSelector((state) => state.outletMenues);

  useEffect(() => {
    if (!outletMenuResponse || !outletMenuResponse.outletMenuResponse) {
      dispatch(outletMenuRequest(outlet?._id));
    } else {
      setOutletMenu(outletMenuResponse.outletMenuResponse?.data.menuItems);
    }
  }, [dispatch, outletMenuResponse]);

  const posMap = {
    delivery: ['delivery'],
    'dine-in': ['dineIn', 'takeAway'],
    pos: ['dineIn', 'takeAway', 'delivery']
  };

  const orderTypes = pos ? posMap[pos] : posMap['pos'];

  const [trigger, setTrigger] = useState({
    status: false
  });

  const updateTriggerState = (value) => {
    setTrigger({ status: value });
  };

  const uniqueCategories = [
    ...new Set(
      outletMenu?.map((singleItem) => singleItem?.menuItem?.category?.name)
    )
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredMenuItems = outletMenu?.filter((item) => {
    const terms = searchQuery.split(' ');
    return (
      (!selectedCategory || item.menuItem.category.name === selectedCategory) &&
      terms.every((term) => {
        return item.menuItem.name.toLowerCase().includes(term.toLowerCase());
      })
    );
  });

  const myDisplay = useMediaQuery('(max-width: 900px)');

  if (!outlet) {
    return <div>Outlet Not Found...</div>;
  }

  if (outletMenu?.length === 0) {
    return (
      <Box height="max-content">
        {[...Array(10)].map((arr, i) => (
          <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
        ))}
      </Box>
    );
  }

  return (
    <>
      {myDisplay && (
        <Header
          refr={refr}
          triggerPrint={triggerPrint}
          singlePrintOrder={singlePrintOrder}
          outletId={outlet._id}
          trigger={trigger}
          setTrigger={setTrigger}
          orderTypes={orderTypes}
          // props for menu

          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          theme={theme}
          uniqueCategories={uniqueCategories}
          handleCategoryClick={handleCategoryClick}
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          filteredMenuItems={filteredMenuItems}
          getPrice={getPrice}
        />
      )}

      <div style={{ display: 'none' }}>
        <Printing ref={refr} order={singlePrintOrder} />
      </div>

      <Grid sx={{ height: '100%', backgroundColor: '' }} container spacing={1}>
        {!myDisplay && (
          <Grid height="100%" item sm={0} md={0} lg={2}>
            <Orders
              refr={refr}
              triggerPrint={triggerPrint}
              singlePrintOrder={singlePrintOrder}
              outletId={outlet._id}
              trigger={trigger}
              setTrigger={setTrigger}
              orderTypes={orderTypes}
            />
          </Grid>
        )}

        <Grid height="100%" width="100%" item sm={12} md={5} lg={4}>
          {!modifyOrder ? (
            <Cart
              triggerPrint={triggerPrint}
              singlePrintOrder={singlePrintOrder}
              outlet={outlet}
              outletMenu={outletMenu}
              updateTriggerState={updateTriggerState}
              orderTypes={orderTypes}
            />
          ) : (
            <ModifyOrder
              orderId={modifyOrder}
              outlet={outlet}
              outletMenu={outletMenu}
              updateTriggerState={updateTriggerState}
            />
          )}
        </Grid>

        {!myDisplay && (
          <Grid
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
            item
            sm={12}
            md={7}
            lg={6}>
            <MenuItemsBox>
              <CategoryBox>
                <CategoryButton
                  variant="outlined"
                  sx={{
                    background: !selectedCategory
                      ? theme?.palette?.primary?.main
                      : '',
                    color: !selectedCategory ? theme.palette.white.main : '',
                    borderRadius: '10px',
                    marginRight: '2px'
                  }}
                  onClick={() => setSelectedCategory(null)}>
                  All
                </CategoryButton>

                {uniqueCategories.map((category, index) => (
                  <CategoryButton
                    key={index}
                    sx={{
                      background:
                        category === selectedCategory
                          ? theme?.palette.primary.main
                          : '',
                      color:
                        category === selectedCategory
                          ? theme?.palette.white.main
                          : '',
                      borderRadius: '10px',
                      marginRight: '2px'
                    }}
                    variant="outlined"
                    onClick={() => handleCategoryClick(category)}>
                    {category}
                  </CategoryButton>
                ))}
              </CategoryBox>

              <SearchBox>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  type="text"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px'
                    }
                  }}
                />
              </SearchBox>

              <MenuItemBox>
                <Grid container>
                  {filteredMenuItems?.map((item, i) => (
                    <Grid
                      key={i}
                      onClick={() => dispatch(addToCart(item))}
                      item
                      lg={3}
                      md={4}
                      xs={6}>
                      <ItemBox>
                        <NameTypo>{item.menuItem.shortName}</NameTypo>
                        <ImageBox
                          component="img"
                          alt="The house from the offer."
                          src={
                            item.menuItem.img
                              ? item.menuItem.img
                              : process.env.REACT_APP_CLIENT_LOGO
                          }
                        />
                        <DetailsBox>
                          <PriceTypo>{getPrice(item)}</PriceTypo>
                        </DetailsBox>
                      </ItemBox>
                    </Grid>
                  ))}
                </Grid>
              </MenuItemBox>
            </MenuItemsBox>
          </Grid>
        )}
      </Grid>
      <Outlet />
    </>
  );
}

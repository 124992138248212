import { Box, styled, TableCell, Typography } from '@mui/material';

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  // boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px'
}));

export const HeaderTableCell = styled(TableCell)(() => ({
  color: '#ffffff'
}));

export const SingleOrderTypography = styled(Typography)(() => ({
  fontSize: '14px'
}));

import { Box, FormControl, styled } from '@mui/material';

export const DateBox = styled(Box)(() => ({
  border: '2px solid #E4E4E4',
  borderRadius: '7px',
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '10px',
  cursor: 'pointer',
  gap: '6px'
}));


/* eslint-disable no-unused-vars */

import axios from 'axios';
import React, { useState } from 'react';
import './Signup.css';

export default function Signup() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: ''
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postData();
  };

  function postData() {
    axios
      .post('http://localhost:8800/api/auth/register')
      .then((response) => {})
      .catch((error) => {});

    // axios({
    //   method: 'post',
    //   url: 'http://localhost:8800/api/auth/register',
    //   data: formData
    // });
  }

  return (
    <div className="sign-up">
      <h1>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-control">
          <label>Name</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control">
          <label>Email</label>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control">
          <label>Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-control">
          <label></label>
          <button className="sign-up-button" type="submit">
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  getDispatchSheetApi,
  getKitchenSheetApi
} from '../../api/report/reportApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Typography,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TextField,
  TableBody
} from '@mui/material';
import { extractTime } from '../POS/Shared';
import { formatAmount, getCurrentDate, timeDiffMinuts } from '../Shared/shared';
import {
  CountTableCell,
  HeaderTableCell,
  HoverTableRow
} from './Styled-components';
import Loader from '../Loader/Loader';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
import { getDisplayScreensApi } from '../../api/displayscreens/displayScreenApi';

export default function KitchenSheet() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [saleReport, setSaleReport] = useState([]);

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  // calling APi

  const [isLoading, setLoading] = useState(false);
  const [displays, setDisplays] = useState();

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getKitchenSheetApi(outlet?._id, startDate);
      const res = await getDisplayScreensApi();
      setDisplays(res?.data);
      setSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };
  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = saleReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.invoiceId?.toLowerCase().includes(term) ||
          report?.customerName?.toLowerCase().includes(term) ||
          report?.orderType?.toLowerCase().includes(term) ||
          report?.assembledBy?.name?.toLowerCase().includes(term)
        );
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  {
  }

  // calculating total and avg kitchen time and trip time
  const result = saleReport.reduce(
    (accumulator, report) => {
      // the time from which we want to take diff should be first argument
      const prepTimeDiff = timeDiffMinuts(report?.orderTime, report?.prepared);
      const assembleTimeDiff = timeDiffMinuts(
        report?.prepared,
        report?.assembled
      );

      const KitchenTimeDiff = timeDiffMinuts(
        report?.orderTime,
        report?.assembled
      );

      accumulator.prepTime.totalMinutes += prepTimeDiff || 0;
      accumulator.prepTime.orderCount++;

      accumulator.assemble.totalMinutes += assembleTimeDiff || 0;
      accumulator.assemble.orderCount++;

      accumulator.kitchen.totalMinutes += KitchenTimeDiff || 0;
      accumulator.kitchen.orderCount++;

      return accumulator;
    },
    {
      prepTime: { totalMinutes: 0, orderCount: 0 },
      assemble: { totalMinutes: 0, orderCount: 0 },
      kitchen: { totalMinutes: 0, orderCount: 0 }
    }
  );

  const prepTotalMinutes = result.prepTime.totalMinutes;
  const prepAverageMinutes = prepTotalMinutes / result.prepTime.orderCount;

  const assembleTotalMinutes = result.assemble.totalMinutes;
  const assembleAverageMinutes =
    assembleTotalMinutes / result.assemble.orderCount;

  const kitchenTotalMinutes = result.kitchen.totalMinutes;
  const kitchenAverageMinutes = kitchenTotalMinutes / result.kitchen.orderCount;

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Order"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Kitchen Sheet
        </Typography>
        <div>
          <label htmlFor="date">Select Date to see Report</label>
          <input
            className="date-input"
            type="date"
            onChange={handleDate}
            name=""
            id="date"
            value={startDate || ''}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </div>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table>
            <TableHead sx={{ background: '#008670', color: 'white' }}>
              <TableRow>
                <HeaderTableCell>sr#</HeaderTableCell>
                <HeaderTableCell>Invoice</HeaderTableCell>
                <HeaderTableCell>Customer</HeaderTableCell>
                <HeaderTableCell>Order Type</HeaderTableCell>
                <HeaderTableCell>Amount</HeaderTableCell>
                <HeaderTableCell>Punch Time</HeaderTableCell>
                <HeaderTableCell>Prep. Time</HeaderTableCell>
                <HeaderTableCell>Assemble Time</HeaderTableCell>
                <HeaderTableCell>Kitchen Time</HeaderTableCell>
                <HeaderTableCell>Done At</HeaderTableCell>
                <HeaderTableCell>CashOut Time</HeaderTableCell>
                <HeaderTableCell>Assembled By</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <HoverTableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{report?.invoiceId}</TableCell>
                        <TableCell>{report?.customerName}</TableCell>
                        <TableCell>{report?.orderType}</TableCell>
                        <TableCell>
                          {formatAmount(report?.total + report?.tax) || ''}
                        </TableCell>
                        <TableCell>
                          {extractTime(new Date(report?.orderTime))}
                        </TableCell>
                        <TableCell>
                          <Box display="flex" gap="2px" alignItems="center">
                            <>
                              {Math.round(
                                timeDiffMinuts(
                                  report?.orderTime,
                                  report?.prepared
                                )
                              )}
                              m
                            </>
                            <Tooltip
                              title={report?.displays.map((display, i) => (
                                <>
                                  <div margin="10px 0">
                                    {`${i + 1}).`}
                                    <b fontSize="18px">{display.name}</b>{' '}
                                    {Math.round(
                                      timeDiffMinuts(
                                        report?.orderTime,
                                        display.prepared
                                      )
                                    )}
                                    m
                                  </div>
                                </>
                              ))}>
                              <InfoIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {Math.round(
                            timeDiffMinuts(report?.prepared, report?.assembled)
                          )}
                          m
                        </TableCell>

                        <TableCell>
                          {Math.round(
                            timeDiffMinuts(report?.orderTime, report?.assembled)
                          )}
                          m
                        </TableCell>

                        <TableCell>
                          {extractTime(new Date(report?.assembled)) || ''}
                        </TableCell>

                        <TableCell>
                          {extractTime(new Date(report?.cashedOut)) || 0}
                        </TableCell>

                        <TableCell>{report?.assembledBy?.name}</TableCell>
                      </HoverTableRow>
                    );
                  })}
              </>

              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
          <Table>
            <TableBody>
              <HoverTableRow>
                <CountTableCell>Average Table Prepration Time:</CountTableCell>
                <CountTableCell>
                  {/* {Math.round(prepAverageMinutes || 0)}m */}
                  <Table>
                    <TableRow>
                      <TableCell>Table</TableCell>
                      <TableCell>Orders</TableCell>
                      <TableCell>Time</TableCell>
                    </TableRow>
                    {displays?.map((d) => {
                      const orders = saleReport
                        .map((order) => {
                          const disp = order.displays.find(
                            (display) => d._id == display._id
                          )?.prepared;
                          if (!disp) return;
                          return timeDiffMinuts(order.orderTime, disp);
                        })
                        .filter((order) => order);

                      return (
                        <TableRow key={d._id}>
                          <TableCell>{d.name}</TableCell>
                          <TableCell>{orders.length}</TableCell>
                          <TableCell>
                            {formatAmount(
                              orders.reduce((acc, prev) => prev + acc, 0) /
                                orders.length
                            )}
                            m
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </CountTableCell>
              </HoverTableRow>

              <HoverTableRow>
                <CountTableCell>Average Prepration Time:</CountTableCell>
                <CountTableCell>
                  {formatAmount(prepAverageMinutes || 0)}m
                </CountTableCell>
              </HoverTableRow>
              <HoverTableRow>
                <CountTableCell>Average Assemble Time:</CountTableCell>
                <CountTableCell>
                  {formatAmount(assembleAverageMinutes || 0)}m
                </CountTableCell>
              </HoverTableRow>
              <HoverTableRow>
                <CountTableCell>Average Kitchen Time:</CountTableCell>
                <CountTableCell>
                  {formatAmount(kitchenAverageMinutes || 0)}m
                </CountTableCell>
              </HoverTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  displayScreenResponse: null,
  isSuccess: false
};

const DisplayScreensSlice = createSlice({
  name: 'displayScreens',
  initialState: INITIAL_STATE,
  reducers: {
    displayScreenResponse(state, { payload }) {
      return {
        ...state,
        displayScreenResponse: payload.response
      };
    },
    displayScreenRequest: (state) => state,
    updateDisplayScreenSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  displayScreenRequest,
  displayScreenResponse,
  updateDisplayScreenSuccessStatus
} = DisplayScreensSlice.actions;

export default DisplayScreensSlice.reducer;

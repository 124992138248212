/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/OrdersDuck';
import { getRunningOrdersApi } from '../../../api/orders/OrdersApi';

export function* runningOrders({ payload }) {
  try {
    const runningOrders = yield call(() => getRunningOrdersApi(payload));
    yield put(actions.updateRunningOrdersSuccessStatus(true));
    yield put(
      actions.runningOrdersResponse({
        response: runningOrders
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchRunningOrdersSagas() {
  yield takeLatest(actions.runningOrdersRequest.type, runningOrders);
}

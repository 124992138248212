/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getDispatchSheetApi } from '../../api/report/reportApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Typography,
  TableCell,
  TablePagination,
  TableRow,
  Tooltip,
  Box,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TextField,
  TableBody
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { extractTime } from '../POS/Shared';
import { getCurrentDate, timeDiffMinuts } from '../Shared/shared';
import {
  CountTableCell,
  HeaderTableCell,
  HoverTableRow
} from './Styled-components';
import Loader from '../Loader/Loader';
import OrderDetails from './CallCenterOrders/OrderDetails';

export default function DispatchSheet() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  // handling popup for order details
  const [details, setDetails] = useState({ isOpen: false });

  const [saleReport, setSaleReport] = useState([]);

  // handlng date
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
  }, []);

  const handleDate = (event) => {
    setStartDate(event.target.value);
  };

  // calling APi

  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getDispatchSheetApi(outlet?._id, startDate);
      setSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };
  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = saleReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.invoiceId?.toLowerCase().includes(term) ||
          report?.customerName?.toLowerCase().includes(term) ||
          report?.customerContact?.toLowerCase().includes(term) ||
          report?.carrier?.name?.toLowerCase().includes(term) ||
          report?.paymentMethod?.name?.toLowerCase().includes(term)
        );
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // calculating total and avg kitchen time and trip time
  const result = saleReport?.reduce(
    (accumulator, report) => {
      const kitchenTimeDiff = timeDiffMinuts(
        report?.orderTime,
        report?.assembled
      );
      const dispatchTimeDiff = timeDiffMinuts(
        report?.assembled,
        report?.dispatched
      );

      accumulator.kitchen.totalMinutes += kitchenTimeDiff;
      accumulator.kitchen.orderCount++;

      accumulator.dispatch.totalMinutes += dispatchTimeDiff || 0;
      accumulator.dispatch.orderCount++;

      if (report?.cashedOut) {
        const tripTimeDiff = timeDiffMinuts(
          report?.dispatched,
          report?.cashedOut
        );

        accumulator.trip.totalMinutes += tripTimeDiff || 0;
        accumulator.trip.orderCount++;
      }

      return accumulator;
    },
    {
      kitchen: { totalMinutes: 0, orderCount: 0 },
      dispatch: { totalMinutes: 0, orderCount: 0 },
      trip: { totalMinutes: 0, orderCount: 0 }
    }
  );

  const kitchenTotalMinutes = result?.kitchen?.totalMinutes;
  const kitchenAverageMinutes = kitchenTotalMinutes / result.kitchen.orderCount;

  const tripTotalMinutes = result.trip.totalMinutes;
  const tripAverageMinutes = tripTotalMinutes / result.trip.orderCount;

  const dispatchTotalMinutes = result.dispatch.totalMinutes;
  const dispatchAverageMinutes =
    dispatchTotalMinutes / result.dispatch.orderCount;

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Search Order by Id, Cust. Name, Contact or Rider"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Dispatch Sheet
        </Typography>
        <div>
          <label htmlFor="date">Select Date to see Report</label>
          <input
            className="date-input"
            type="date"
            onChange={handleDate}
            name=""
            id="date"
            value={startDate || ''}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </div>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#008670',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>sr#</HeaderTableCell>
                <HeaderTableCell>Invoice</HeaderTableCell>
                <HeaderTableCell>Customer</HeaderTableCell>
                <HeaderTableCell>Customer Contact</HeaderTableCell>
                <HeaderTableCell>Delivery Address</HeaderTableCell>
                <HeaderTableCell>Amount</HeaderTableCell>
                <HeaderTableCell>Punch Time</HeaderTableCell>
                <HeaderTableCell>Kitchen Time</HeaderTableCell>
                <HeaderTableCell>Dispatch Time</HeaderTableCell>
                <HeaderTableCell>Rider OUT Time</HeaderTableCell>
                <HeaderTableCell>Rider IN Time</HeaderTableCell>
                <HeaderTableCell>Trip Time</HeaderTableCell>
                <HeaderTableCell>Rider Name</HeaderTableCell>
                <HeaderTableCell>Rider Phone </HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <HoverTableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{report?.invoiceId}</TableCell>
                        <TableCell>{report?.customerName}</TableCell>
                        <TableCell>{report?.customerContact}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={report?.deliveryAddress}
                            placement="top">
                            <Typography
                              sx={{
                                fontSize: '12px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '150px'
                              }}>
                              {report?.deliveryAddress}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {Math.round(report?.total + report?.tax) || ''}
                        </TableCell>

                        <TableCell>
                          {extractTime(new Date(report?.orderTime))}
                        </TableCell>
                        <TableCell>
                          {Math.round(
                            timeDiffMinuts(report?.orderTime, report?.assembled)
                          )}
                          m
                        </TableCell>
                        <TableCell>
                          {Math.round(
                            timeDiffMinuts(
                              report?.assembled,
                              report?.dispatched
                            )
                          )}
                          m
                        </TableCell>
                        <TableCell>
                          {extractTime(new Date(report?.dispatched)) || ''}
                        </TableCell>
                        <TableCell>
                          {extractTime(new Date(report?.cashedOut)) || 0}
                        </TableCell>
                        <TableCell>
                          {Math.round(
                            timeDiffMinuts(
                              report?.dispatched,
                              report?.cashedOut
                            )
                          ) || 0}
                          m
                        </TableCell>

                        <TableCell>{report?.carrier?.name}</TableCell>
                        <TableCell>{report?.carrier?.phone}</TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({ isOpen: true, id: report?._id });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </HoverTableRow>
                    );
                  })}
              </>
              <HoverTableRow>
                <CountTableCell>Total Kitchen Time:</CountTableCell>
                <CountTableCell>
                  {kitchenTotalMinutes.toFixed(2) || 0}m
                </CountTableCell>
                <CountTableCell>Average Kitchen Time:</CountTableCell>
                <CountTableCell>
                  {kitchenAverageMinutes.toFixed(2) || 0}m
                </CountTableCell>
              </HoverTableRow>

              <HoverTableRow>
                <CountTableCell>Total Dispatch Time:</CountTableCell>
                <CountTableCell>
                  {dispatchTotalMinutes.toFixed(2) || 0}m
                </CountTableCell>
                <CountTableCell>Average Dispatch Time:</CountTableCell>
                <CountTableCell>
                  {dispatchAverageMinutes.toFixed(2) || 0}m
                </CountTableCell>
              </HoverTableRow>

              <HoverTableRow>
                <CountTableCell>Total Trip Time:</CountTableCell>
                <CountTableCell>
                  {tripTotalMinutes.toFixed(2) || 0}m
                </CountTableCell>
                <CountTableCell>Average Trip Time:</CountTableCell>
                <CountTableCell>
                  {tripAverageMinutes.toFixed(2) || 0}m
                </CountTableCell>
              </HoverTableRow>

              <HoverTableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </HoverTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <OrderDetails details={details} setDetails={setDetails} />
    </>
  );
}

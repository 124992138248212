/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  Typography,
  Checkbox
} from '@mui/material';
import { deleteOrderApi } from '../../../api/orders/OrdersApi';
import { useForm } from 'react-hook-form';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import { deleteOrderReason } from '../Shared';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import { permisssions } from '../../Shared/shared';
import { resetCart } from '../../../redux/reducers/ducks/CartDuck';

export default function ConfirmDelete({
  deletePopup,
  setDeletePopup,
  callFunc,
  outletId
}) {
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      permisssions.modifyOrder
    );
    setUsers(response?.data);
  };
  useEffect(() => {
    getUsersWithPermission();
  }, []);

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      autocompleteInput: ''
    }
  });

  const handleChangeUserName = (event) => {
    setValue('userName', event.target.value);
  };
  const handleChangeReason = (event) => {
    setValue('reason', event.target.value);
  };

  const onSubmit = async (data) => {
    const formData = {
      user: data.userName,
      key: data.key,
      reason: data.reason
    };

    try {
      const res = await deleteOrderApi(deletePopup.id, formData);
      if (res.status == 200) {
        dispatch(resetCart());
        handleClose();
        dispatch(
          addSnackbar({
            message: res?.data?.message || 'Order Deleted',
            type: 'info'
          })
        );
      } else {
        dispatch(
          addSnackbar({
            message: res.response.data.message || `UnAuthorized`,
            type: 'error'
          })
        );
      }
    } catch {}
  };

  const handleClose = () => {
    callFunc();
    setDeletePopup('isOpen', false);
  };

  return (
    <div>
      <Dialog
        open={deletePopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Delete Order</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Required Manager Key to Cancel this Order
            </DialogContentText>

            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel>Name</InputLabel>
              <Select
                defaultValue=""
                {...register('userName')}
                label="Name"
                onChange={handleChangeUserName}>
                {users?.map((user, index) => (
                  <MenuItem key={index} value={user._id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              size="small"
              name="key"
              label="Manager Key"
              type="password"
              {...register('key')}
              fullWidth
              variant="outlined"
            />

            <FormControlLabel
              label="Add Manual Reason"
              control={<Checkbox checked={checked} onChange={handleChange} />}
            />

            {!checked ? (
              <FormControl sx={{ width: '100%' }} size="small">
                <InputLabel>Reason</InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  {...register('reason')}
                  label="Reason"
                  onChange={handleChangeReason}>
                  {deleteOrderReason?.map((reas, index) => (
                    <MenuItem key={index} value={reas.title}>
                      {reas.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <TextField
                autoFocus
                size="small"
                fullWidth
                margin="dense"
                name="reason"
                label="Reason"
                {...register('reason')}
                variant="outlined"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              close
            </Button>
            <Button type="submit" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export const ratingData = [
  {
    key: 'foodQuality',
    name: 'Food Quality'
  },
  {
    key: 'foodTaste',
    name: 'Food Taste'
  },
  {
    key: 'serviceQuality',
    name: 'Quality of Service'
  },
  {
    key: 'serviceSpeed',
    name: 'Speed of Service'
  },
  {
    key: 'valueOfMoney',
    name: 'Value of Money'
  },
  {
    key: 'valueOfHotness',
    name: 'Hotness and Freshness'
  },
  {
    key: 'valueOfPastExperience',
    name: 'Past Experience'
  },
  {
    key: 'staffBehavior',
    name: 'Friendliness of Crew'
  },
  {
    key: 'cleanliness',
    name: 'Cleanliness'
  },
  {
    key: 'recommendation',
    name: 'Likelihood of Recommendation'
  }
];


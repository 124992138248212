/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Tooltip,
  Skeleton,
  TablePagination,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Delete, Edit } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import AddChoices from './AddChoices';
import DeleteChoices from './DeleteChoices';
import EditChoices from './EditChoices';
import { choicesRequest } from '../../redux/reducers/ducks/ChoicesDuck';
import { StyledTableCell, TableButton } from '../ingredients/Styled-components';
import { theme } from '../../theme';

export default function Choices() {
  const dispatch = useDispatch();

  // state for delete popUp
  const [value, setValue] = useState({ isOpen: false, id: '' });
  // state for Edit popUp
  const [open, setOpen] = useState({ isOpen: false, id: '' });
  // state for Add popUp
  const [addPopup, setAddPopup] = useState({ isOpen: false });

  // setting data to show
  const [choices, setChoices] = useState([]);

  // table rows handling
  const [openRows, setOpenRows] = useState(Array(choices?.length).fill(false));

  const handleRowClick = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  // fetching data from store and calling API through Saga
  const [choicesData, setChoicesData] = useState([]);
  const choicesResponse = useSelector((state) => state.choices);

  useEffect(() => {
    if (!choicesResponse || !choicesResponse.choicesResponse) {
      dispatch(choicesRequest());
    } else {
      setChoicesData(choicesResponse);
      setChoices(choicesData?.choicesResponse?.data);
    }
  }, [dispatch, choicesResponse, callFunc]);

  // calling API over some data modification to update store
  function callFunc() {
    dispatch(choicesRequest());
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredChoices = choices?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.user?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Choice"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Choices
        </Typography>
        <div>
          <Tooltip title="Add Choice">
            <Button
              variant="contained"
              style={{ padding: ' 10px 30px' }}
              onClick={() => {
                setAddPopup({ isOpen: true });
              }}>
              <AddIcon />
              <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                Add Choice
              </Typography>
            </Button>
          </Tooltip>
        </div>
      </Box>

      {!choicesResponse.isSuccess ? (
        <Box
          sx={{
            height: 'max-content'
          }}>
          {[...Array(10)].map((arr, i) => (
            <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <TableHead sx={{ background: theme.palette.primary.main }}>
              <TableRow>
                <TableCell />
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Default Option</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <React.Fragment>
                {filteredChoices
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((singleSet, index) => {
                    let res = '';
                    singleSet.options.map((item, i) => {
                      if (item.item._id === singleSet.defaultOption) {
                        res = item.name;
                      }
                    });

                    return (
                      <React.Fragment key={index}>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              width="0px"
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRowClick(index);
                              }}>
                              {openRows[index] ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>{singleSet.name}</TableCell>
                          <TableCell>{singleSet.user?.name}</TableCell>
                          <TableCell>
                            {singleSet.active == true ? 'Active' : 'In Active'}
                          </TableCell>
                          <TableCell>{res}</TableCell>
                          <TableCell
                            colSpan={2}
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              onClick={() => {
                                setOpen({ isOpen: true, id: singleSet._id });
                              }}>
                              <Edit />
                            </Button>
                            <Button
                              onClick={() => {
                                setValue({ isOpen: true, id: singleSet._id });
                              }}>
                              <Delete />
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={6}>
                            <Collapse
                              in={openRows[index]}
                              timeout="auto"
                              unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div">
                                  Options
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Option Name</TableCell>
                                      <TableCell>Price</TableCell>
                                      <TableCell>Ingredient Set Name</TableCell>
                                      <TableCell>Display</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {singleSet?.options?.map(
                                      (optionRow, index) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell>
                                              {optionRow.name}
                                            </TableCell>
                                            <TableCell>
                                              {optionRow.price}
                                            </TableCell>
                                            <TableCell>
                                              {optionRow.item.name}
                                            </TableCell>
                                            <TableCell>
                                              {optionRow.item.display.name}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      }
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>

              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                count={choices?.length || 0}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditChoices
        open={open}
        setOpen={setOpen}
        callFunc={callFunc}
        // displayScreen={displayScreen}
      />

      <DeleteChoices value={value} setValue={setValue} callFunc={callFunc} />

      <AddChoices
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
      />
    </>
  );
}

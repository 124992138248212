/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Loader from '../Loader/Loader';
import { formatDate, getCurrentDate } from '../Shared/shared';
import {
  DeliveryDining,
  Functions,
  Paid,
  PlusOne,
  ShoppingBag,
  TableRestaurant
} from '@mui/icons-material';
import urls from '../../api/auth/authUrls';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { useSelector } from 'react-redux';
import { DateBox } from '../Shared/Shared-Styled-Components';
import DatePickerComponent from '../Shared/DatePickerComponent';

const fetchWithAuth = async (URL, { body, query, method } = {}) => {
  const newUrl = query
    ? URL + '?' + new URLSearchParams(query).toString()
    : URL;
  try {
    const result = await fetch(newUrl, {
      headers: {
        'Content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`
      },
      body: body && JSON.stringify(body),
      method
    });
    if (result.status == 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        // user not logged in
        return { message: 'Refresh token not available' };
      }
      // get access token
      const refreshResponse = await fetch(urls.auth.refresh, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
          // Authorization: "Bearer " + user.refreshToken,
        },
        // encode refreshToken
        body: JSON.stringify({ refreshToken: refreshToken })
      });
      if (refreshResponse.status === 401) {
        // remove refresh token
      }
      if (refreshResponse.status === 200) {
        const newUser = await refreshResponse.json();
        // updateUser({ ...newUser, refreshToken });
        // update access token
        localStorage.setItem('accessToken', newUser.accessToken);

        const latestResponse = await fetch(newUrl, {
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${newUser.accessToken}`
          },
          body: body ? JSON.stringify(body) : undefined,
          method
        });
        return await latestResponse.json();
      }
    }
    return result.json();
  } catch (err) {
    return { error: err, message: 'Some unexpected error occurred' };
  }
};

export default function OverAllReport() {
  const outlets = useSelector(
    (state) => state.authorize?.loginResponse?.user?.outlets
  );

  const [OverAllReport, setOverAllReport] = useState([]);

  // handlng date
  const [swap, setSwap] = useState('sale');
  const [types, setTypes] = useState([
    'dineIn',
    'takeAway',
    'delivery',
    'total'
  ]);

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const reportApi = async () => {
    setLoading(true);
    const result = await fetchWithAuth(
      process.env.REACT_APP_LOCAL_HOST_API + '/reports/overall-sales',
      {
        query: {
          startDate: datePopup.startDate,
          endDate: datePopup.endDate
        }
      }
    );
    if (result.message) return result.message;
    else setOverAllReport(result);
    setLoading(false);
  };

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = OverAllReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return report?.saleDate?.toLowerCase().includes(term);
      })
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  const toggleSwap = () => {
    setSwap((swap) => (swap == 'count' ? 'sale' : 'count'));
  };

  const totals = OverAllReport.reduce(
    (prev, curr) => {
      return curr.outlets.reduce((prev1, curr1) => {
        const outlet = prev1.outlets.find(
          (outletF) => outletF.code == curr1.code
        );
        if (outlet) {
          curr1.orders.reduce((prev2, curr2) => {
            const orders = prev2.orders.find(
              (orderT) => orderT.orderType == curr2.orderType
            );
            if (orders) {
              orders.count = orders.count + curr2.count;
              orders.sale += curr2.sale;
              orders.discount += curr2.discount;
            } else {
              prev2.orders.push({
                orderType: curr2.orderType,
                count: curr2.count,
                sale: curr2.sale,
                discount: curr2.discount,
                priority: curr1.priority
              });
            }
            return prev2;
          }, outlet);
        } else {
          prev1.outlets.push({
            code: curr1.code,
            tag: curr1.tag,
            saleDate: curr.saleDate,
            orders: curr1.orders.map((orderT) => ({
              orderType: orderT.orderType,
              count: orderT.count,
              sale: orderT.sale,
              discount: orderT.discount
            }))
          });
        }
        return prev1;
      }, prev);
    },
    { outlets: [] }
  );

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <ToggleButton onClick={toggleSwap}>{swap}</ToggleButton>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Overall Sales
        </Typography>
        <div>
          <DatePickerComponent
            datePopup={datePopup}
            setDatePopup={setDatePopup}
          />
        </div>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer
          component={Paper}
          sx={{ overflowY: 'auto', width: 'calc(100vw - 100px)' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Search Report by Item name</Typography>
                  <TextField
                    label="Search Report"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </TableCell>
                <TableCell>
                  <ToggleButtonGroup
                    value={types}
                    onChange={(_, newTypes) => setTypes(newTypes)}
                    aria-label="order types">
                    <ToggleButton value="dineIn" aria-label="Dine In">
                      <TableRestaurant />
                    </ToggleButton>
                    <ToggleButton value="takeAway" aria-label="Take Away">
                      <ShoppingBag />
                    </ToggleButton>
                    <ToggleButton value="delivery" aria-label="Delivery">
                      <DeliveryDining />
                    </ToggleButton>
                    <ToggleButton value="total" aria-label="Total">
                      <Functions />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
                <TableCell>
                  <ToggleButtonGroup
                    value={swap}
                    exclusive
                    onChange={(_, value) => setSwap(value)}
                    aria-label="Count Or Sale">
                    <ToggleButton value="count" aria-label="left aligned">
                      <PlusOne />
                    </ToggleButton>
                    <ToggleButton value="sale" aria-label="centered">
                      <Paid />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>

          <Table
            size="small"
            aria-label="collapsible table"
            sx={{
              display: 'block',
              width: '100%',
              overflowX: 'scroll'
            }}>
            <TableHead sx={{ background: '#FFA500', color: 'white' }}>
              <TableRow>
                <TableCell>Sale Report</TableCell>
                {outlets
                  .filter((outlet) => !outlet.sandbox)
                  .map((outlet) => (
                    <TableCell key={outlet._id} colSpan={types.length}>
                      {outlet.tag}
                    </TableCell>
                  ))}
                {/* <TableCell colSpan={types.length}>Main Branch</TableCell>
                <TableCell colSpan={types.length}>Jinnah Colony</TableCell>
                <TableCell colSpan={types.length}>Eden Garden</TableCell>
                <TableCell colSpan={types.length}>GM Abad</TableCell>
                <TableCell colSpan={types.length}>Millat Chowk</TableCell>
                <TableCell colSpan={types.length}>Daewoo Road</TableCell>
                <TableCell colSpan={types.length}>Sheikhupura</TableCell> */}
                <TableCell colSpan={types.length}>Total</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Date</TableCell>

                {outlets
                  .filter((outlet) => !outlet.sandbox)
                  .map((outlet) =>
                    types.map((type) => (
                      <TableCell key={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </TableCell>
                    ))
                  )}
                {types.map((type) => (
                  <TableCell key={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {filteredOrders
                  .sort((a1, a2) => (a1.saleDate < a2.saleDate ? -1 : 1))
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FFA500a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell> {report?.saleDate}</TableCell>
                        {outlets
                          ?.filter((outlet) => !outlet.sandbox)
                          .map((outletS) => {
                            const outlet = report?.outlets.find(
                              (outlet) => outlet.code === outletS.code
                            );

                            return types.map((type) =>
                              type == 'total' ? (
                                <TableCell key={type}>
                                  {Math.round(
                                    outlet?.orders.reduce(
                                      (prev, curr) => curr[swap] + prev,
                                      0
                                    )
                                  )}
                                </TableCell>
                              ) : (
                                <TableCell key={type}>
                                  {Math.round(
                                    (outlet?.orders?.find(
                                      (group) => group.orderType == type
                                    ) || [])[swap] || 0
                                  )}
                                </TableCell>
                              )
                            );
                          })}
                        {types.map((type) =>
                          type == 'total' ? (
                            <TableCell key={type}>
                              {Math.round(
                                report?.outlets.reduce(
                                  (prev, curr) =>
                                    prev +
                                    curr.orders.reduce(
                                      (prev, curr) => curr[swap] + prev,
                                      0
                                    ),
                                  0
                                )
                              )}
                            </TableCell>
                          ) : (
                            <TableCell key={type}>
                              {Math.round(
                                report?.outlets.reduce(
                                  (prev, curr) =>
                                    prev +
                                      (curr.orders?.find(
                                        (group) => group.orderType == type
                                      ) || [])[swap] || 0,
                                  0
                                )
                              )}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    );
                  })}
              </>
              <TableRow
                // key={index}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFA500a5',
                    cursor: 'pointer',
                    transition: '0.4s'
                  }
                }}>
                <TableCell>
                  <b> Total</b>
                </TableCell>
                {outlets
                  ?.filter((outlet) => !outlet.sandbox)
                  .map((outletS) => {
                    const outlet = totals?.outlets.find(
                      (outlet) => outlet.code === outletS.code
                    );
                    return types.map((type) =>
                      type == 'total' ? (
                        <TableCell key={type}>
                          <b>
                            {Math.round(
                              outlet?.orders.reduce(
                                (prev, curr) => curr[swap] + prev,
                                0
                              )
                            )}
                          </b>
                        </TableCell>
                      ) : (
                        <TableCell key={type}>
                          <b>
                            {Math.round(
                              (outlet?.orders?.find(
                                (group) => group.orderType == type
                              ) || [])[swap] || 0
                            )}
                          </b>
                        </TableCell>
                      )
                    );
                  })}
                {types.map((type) =>
                  type == 'total' ? (
                    <TableCell key={type}>
                      <b>
                        {Math.round(
                          totals?.outlets.reduce(
                            (prev, curr) =>
                              prev +
                              curr.orders.reduce(
                                (prev, curr) => curr[swap] + prev,
                                0
                              ),
                            0
                          )
                        )}
                      </b>
                    </TableCell>
                  ) : (
                    <TableCell key={type}>
                      <b>
                        {Math.round(
                          totals?.outlets.reduce(
                            (prev, curr) =>
                              prev +
                                (curr.orders?.find(
                                  (group) => group.orderType == type
                                ) || [])[swap] || 0,
                            0
                          )
                        )}
                      </b>
                    </TableCell>
                  )
                )}
              </TableRow>

              <TableRow
                // key={index}
                sx={{
                  '&:hover': {
                    backgroundColor: '#FFA500a5',
                    cursor: 'pointer',
                    transition: '0.4s'
                  }
                }}>
                <TableCell>
                  <b> Average</b>
                </TableCell>
                {outlets
                  .filter((outlet) => !outlet.sandbox)
                  .map((outletS) => {
                    const outlet = totals?.outlets.find(
                      (outlet) => outlet.code === outletS.code
                    );
                    return types.map((type) =>
                      type == 'total' ? (
                        <TableCell key={type}>
                          <b>
                            {Math.round(
                              outlet?.orders.reduce(
                                (prev, curr) => curr[swap] + prev,
                                0
                              ) / OverAllReport?.length
                            )}
                          </b>
                        </TableCell>
                      ) : (
                        <TableCell key={type}>
                          <b>
                            {Math.round(
                              ((outlet?.orders?.find(
                                (group) => group.orderType == type
                              ) || [])[swap] || 0) / OverAllReport?.length
                            )}
                          </b>
                        </TableCell>
                      )
                    );
                  })}
                {types.map((type) =>
                  type == 'total' ? (
                    <TableCell key={type}>
                      <b>
                        {Math.round(
                          totals?.outlets.reduce(
                            (prev, curr) =>
                              prev +
                              curr.orders.reduce(
                                (prev, curr) => curr[swap] + prev,
                                0
                              ),
                            0
                          ) / OverAllReport?.length
                        )}
                      </b>
                    </TableCell>
                  ) : (
                    <TableCell key={type}>
                      <b>
                        {Math.round(
                          totals?.outlets.reduce(
                            (prev, curr) =>
                              prev +
                                (curr.orders?.find(
                                  (group) => group.orderType == type
                                ) || [])[swap] || 0,
                            0
                          ) / OverAllReport?.length
                        )}
                      </b>
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

// router.get(
//   '/consumptions',
//   verifyToken,
//   canManage('salesReport'),
//   async (req, res) => {
//     const startDate = req.query.startDate || saleDate();
//     const endDate = req.query.endDate || startDate;
//     const outlet = req.query.outlet;

//     try {
//       const orders = await Order.find({
//         saleDate: { $gte: startDate, $lte: endDate },
//         outlet: outlet,
//         cashedOut: { $ne: null },
//         active: true,
//       }).populate({
//         path: 'items.choices.consumptions.ingredient',
//         select: 'unit category',
//         populate: ['unit', 'category'],
//       });
//       const ingredientConsumptions = [];
//       orders.forEach(order => {
//         order.items.forEach(item => {
//           item.choices.forEach(choice => {
//             choice.consumptions?.forEach(foodIngredient => {
//               const oldIngredient = ingredientConsumptions.find(
//                 ingredient =>
//                   ingredient.id === foodIngredient.ingredient._id.toString(),
//               );
//               if (!oldIngredient) {
//                 ingredientConsumptions.push({
//                   id: foodIngredient.ingredient._id.toString(),
//                   name: foodIngredient.name,
//                   unit: foodIngredient.ingredient.unit.code,
//                   category: foodIngredient.ingredient.category.name,
//                   dineIn:
//                     foodIngredient.quantity *
//                     (order.orderType === 'dineIn' ? item.quantity : 0),
//                   takeAway:
//                     foodIngredient.quantity *
//                     (order.orderType === 'takeAway' ? item.quantity : 0),
//                   delivery:
//                     foodIngredient.quantity *
//                     (order.orderType === 'delivery' ? item.quantity : 0),
//                   total: foodIngredient.quantity * item.quantity,
//                 });
//                 return;
//               }
//               oldIngredient.dineIn +=
//                 (order.orderType === 'dineIn' ? item.quantity : 0) *
//                 foodIngredient.quantity;
//               oldIngredient.takeAway +=
//                 (order.orderType === 'takeAway' ? item.quantity : 0) *
//                 foodIngredient.quantity;
//               oldIngredient.delivery +=
//                 (order.orderType === 'delivery' ? item.quantity : 0) *
//                 foodIngredient.quantity;
//               oldIngredient.total += item.quantity * foodIngredient.quantity;
//             });
//           });
//         });
//       });
//       res.status(200).json(ingredientConsumptions);
//     } catch (err) {
//       res.status(500).json(err);
//     }
//   },
// );

import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import menuItemsUrl from './menuItemsUrl';

// here start
const accessToken = localStorage.getItem('accessToken');

// get all Menu Items API
export const getmenuItemsApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(menuItemsUrl.menuItems, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(menuItemsUrl.menuItems, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get all Menu Items API
export const getmenuItemsDetailedApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(`${menuItemsUrl.menuItems}/detailed`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${menuItemsUrl.menuItems}/detailed`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get a single Menu Item
export const getSingleMenuItemApi = async (menuItemId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!menuItemId) {
    return null;
  }
  try {
    const data = await axios.get(
      `${menuItemsUrl.menuItems}/${menuItemId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${menuItemsUrl.menuItems}/${menuItemId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Menu Item API
export const addMenuItemApi = async (menuItemData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(menuItemsUrl.menuItems, menuItemData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        menuItemsUrl.menuItems,
        menuItemData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Menu Item API
export const editMenuItemApi = async (menuItemId, menuItemData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${menuItemsUrl.menuItems}/${menuItemId}`,
      menuItemData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${menuItemsUrl.menuItems}/${menuItemId}`,
        menuItemData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Menu Item API

export const deleteMenuItemApi = async (menuItemId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${menuItemsUrl.menuItems}/${menuItemId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${menuItemsUrl.menuItems}/${menuItemId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

import { put, call, takeLatest } from 'redux-saga/effects';
import { logInApi, registerUserApi } from '../../../api/auth/authApi';
import * as actions from '../../reducers/ducks/AuthorizeDuck';
import { addSnackbar } from '../../reducers/ducks/SnackbarDuck';

// const dispatch = useDispatch();
// export function* authorize({ payload }) {
//   try {
//     const user = yield call(logInApi, payload);

//     yield put(actions.updateAuthSuccessStatus(true));
//     if (user) {
//       yield put(actions.loginResponse({ response: user?.data }));
//     }
//   } catch (error) {}
// }

export function* authorize({ payload }) {
  try {
    const user = yield call(logInApi, payload);

    yield put(actions.updateAuthSuccessStatus(true));
    if (user) {
      yield put(actions.loginResponse({ response: user?.data }));
    }
  } catch (error) {
    yield put(
      addSnackbar({
        message: `${error?.response.data?.message}`,
        type: 'error'
      })
    );
  }
}

export function* register({ payload }) {
  try {
    const user = yield call(registerUserApi, payload);
    yield put(actions.updateAuthSuccessStatus(true));
    if (user) {
      yield put(actions.loginResponse({ response: user?.data }));
    }
  } catch (error) {
  } finally {
  }
}

export function* watchAuthSagas() {
  yield takeLatest(actions.registerUser.type, register);
  yield takeLatest(actions.authorizeUser.type, authorize);
}

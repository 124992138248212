/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { banUserApi, unBanUserApi } from '../../api/users/usersApi';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';

const AddEditBlockedUser = ({ addPopup, setAddPopup, callFunc }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      phone: '',
      reason: ''
    }
  });

  const onSubmit = async (data) => {
    const formData = {
      phone: data.phone,
      reason: data.reason
    };

    const res = await banUserApi(formData);
    if (res.status == 200) {
      dispatch(
        addSnackbar({
          message: 'User Added to BlackList',
          type: 'success'
        })
      );
      handleClose();
      callFunc();
    } else {
      dispatch(
        addSnackbar({
          message: res?.response?.data?.message,
          type: 'error'
        })
      );
    }
  };

  const handleClose = () => {
    setAddPopup({ isOpen: false });
    reset({
      phone: '',
      reason: ''
    });
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {!addPopup.id ? (
                <Typography color="#FFA500" fontWeight="700">
                  BlackList User
                </Typography>
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Remove User From BlackList
                </Typography>
              )}
            </DialogTitle>
            {!addPopup.isDelete ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('phone', { required: true })}
                    margin="normal"
                    label="Phone"
                    fullWidth
                    error={Boolean(errors.phone)}
                    helperText={
                      errors.phone ? 'Phone is required and must be Unique' : ''
                    }
                  />

                  <TextField
                    {...register('reason', { required: true })}
                    margin="normal"
                    label="Enter Reason"
                    fullWidth
                    error={Boolean(errors.reason)}
                    helperText={errors.reason ? 'Enter Reason' : ''}
                  />

                  <Button variant="contained" color="primary" type="submit">
                    Ban
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>Are you sure to Un-Ban this user?</DialogContent>
                <DialogActions>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Button onClick={handleClose} autoFocus>
                      cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const res = await unBanUserApi(addPopup.id);
                        if (res.status == 200) {
                          dispatch(
                            addSnackbar({
                              message: 'User Removed From BlackList',
                              type: 'info'
                            })
                          );
                          handleClose();
                          callFunc();
                        } else {
                          dispatch(
                            addSnackbar({
                              message: res?.response?.data?.message,
                              type: 'error'
                            })
                          );
                        }
                      }}>
                      Un-Ban
                    </Button>
                  </form>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditBlockedUser;

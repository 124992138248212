/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Tooltip
} from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { addChoiceApi } from '../../api/choices/choicesApi';
import { ingredientSetRequest } from '../../redux/reducers/ducks/IngredietSetDuck';
import { FieldBox } from './Styled-components';

const getById = (collection, id) => {
  for (let item of collection) if (item._id == id) return item;
  return null;
};

const AddChoices = ({ addPopup, setAddPopup, callFunc }) => {
  const dispatch = useDispatch();
  const [ingredientSet, setIngredientSet] = useState([]);

  // fetching data from store and calling API through Saga

  const ingredientSetResponse = useSelector((state) => state.ingredientSet);
  useEffect(() => {
    if (
      !ingredientSetResponse ||
      !ingredientSetResponse.ingredientSetResponse
    ) {
      dispatch(ingredientSetRequest());
    } else {
      setIngredientSet(ingredientSetResponse?.ingredientSetResponse?.data);
    }
  }, [dispatch, ingredientSetResponse, callFunc]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    errors
  } = useForm({
    defaultValues: {
      name: '',
      defaultOption: null,
      optional: false,
      desc: '',
      options: []
    }
  });

  const handleDefaultCheck = (event) => {
    const value = event.target.value;
    // If the same checkbox is clicked again, uncheck it
    // watch();
    if (value === watch('defaultOption')) {
      setValue('defaultOption', null);
    } else {
      setValue('defaultOption', value);
      // watch();
    }
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  });

  const handleFormSubmit = async (values, onSubmitProps) => {
    let response = await addChoiceApi(values);
    if (response.status === 200) {
      handleClose();
      callFunc();
    }
  };

  const handleClose = () => {
    setAddPopup({ isOpen: false });
    reset();
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup?.isOpen} onClose={handleClose}>
          <Box
            sx={{
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}
          >
            <DialogTitle id="alert-dialog-title">
              <Typography color="#6d1b7b" fontWeight="700">
                Create New Choice
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <TextField
                  {...register('name', { required: true })}
                  margin="normal"
                  label="Name"
                  fullWidth
                />
                <FormControlLabel
                  label="Optional"
                  control={
                    <Controller
                      name={`optional`}
                      control={control}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          checked={props.value}
                          onChange={(e) => props.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />

                <TextField
                  {...register('desc')}
                  label="Description"
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Click to Add Options"
                  onChange={(e) =>
                    e.target.value &&
                    append({
                      item: e.target.value,
                      name: '',
                      price: ''
                    })
                  }
                  value={''}
                  fullWidth
                  select
                  margin="normal"
                >
                  {ingredientSet
                    ?.filter(
                      (ingredientSet) =>
                        !fields.map((v) => v.item).includes(ingredientSet._id)
                    )
                    ?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </TextField>
                <Box>
                  {fields.map((option, index) => (
                    <FieldBox key={index}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-between'
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                getValues('defaultOption') == option.item
                              }
                              onChange={handleDefaultCheck}
                              value={option.item}
                            />
                          }
                          label="Set Default"
                        />
                        <Tooltip title="Delete Option">
                          <IconButton
                            sx={{
                              color: 'red',
                              width: '10%'
                            }}
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '10px'
                        }}
                      >
                        <TextField
                          disabled={true}
                          value={getById(ingredientSet, option.item)?.name}
                          label="Ingredient Set"
                        />
                        <TextField
                          {...register(`options.${index}.name`, {
                            required: true
                          })}
                          variant="outlined"
                          label="Name"
                        />
                        <TextField
                          {...register(`options.${index}.price`, {
                            required: true
                          })}
                          type="number"
                          label="Price"
                          variant="outlined"
                        />
                      </Box>
                    </FieldBox>
                  ))}
                </Box>

                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddChoices;

/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  createUserApi,
  deleteUserApi,
  editUserApi
} from '../../api/users/usersApi';
import { getRolesApi } from '../../api/roles/rolesApi';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { getDisplayScreensApi } from '../../api/displayscreens/displayScreenApi';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { Cancel } from '@mui/icons-material';

const AddEditUser = ({ addPopup, setAddPopup, users, callFunc }) => {
  const dispatch = useDispatch();
  const userData = users?.find((user) => user?._id == addPopup?.id);

  const [roles, setRoles] = useState();
  const [outlets, setOutlets] = useState();
  const [displays, setDisplays] = useState();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    // defaultValue: ''
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      role: '',
      outlets: [],
      displays: []
    }
  });

  const getRoles = async () => {
    const res = await getRolesApi();
    setRoles(res?.data);
  };
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };

  const getDisplayScreens = async () => {
    const res = await getDisplayScreensApi();
    setDisplays(res?.data);
  };

  useEffect(() => {
    getRoles();
    getOutlets();
    getDisplayScreens();
  }, []);

  useEffect(() => {
    if (addPopup.id) {
      const outletIds = userData?.outlets.map((out) => out._id);
      const displayIds = userData?.displays.map((disp) => disp._id);
      reset({
        name: userData?.name,
        email: userData?.email,
        phone: userData?.phone,
        role: userData?.role?._id,
        assignedOutlets: outlets.filter((outlet) =>
          outletIds.includes(outlet._id)
        ),
        displays: displays.filter((display) => displayIds.includes(display._id))
      });
    }
  }, [addPopup.isOpen]);

  const onSubmit = async (data) => {
    const outlets = data.assignedOutlets.map((outlet) => outlet._id);
    const displays = data.displays.map((display) => display._id);
    const formData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      password: data.password,
      role: data.role,
      outlets: outlets,
      displays: displays
    };

    if (!addPopup.isDelete && addPopup.id) {
      const res = await editUserApi(addPopup.id, formData);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'User Credentials Updated',
            type: 'success'
          })
        );
        handleClose();
        callFunc();
      }
    } else if (addPopup.isDelete && addPopup.id) {
    } else if (!addPopup.isDelete && !addPopup.id) {
      const res = await createUserApi(formData);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'User Created',
            type: 'success'
          })
        );
        handleClose();
        callFunc();
      } else {
        dispatch(
          addSnackbar({
            message: res?.response?.data?.message,
            type: 'error'
          })
        );
      }
    }
  };

  const handleClose = () => {
    setAddPopup({ isOpen: false });
    reset({
      name: '',
      email: '',
      phone: '',
      role: '',
      outlets: [],
      displays: []
    });
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {!addPopup.isDelete ? (
                !addPopup.id ? (
                  <Typography color="#FFA500" fontWeight="700">
                    Create User
                  </Typography>
                ) : (
                  <Typography color="#FFA500" fontWeight="700">
                    Edit User
                  </Typography>
                )
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Delete User
                </Typography>
              )}
            </DialogTitle>
            {!addPopup.isDelete ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('name', { required: true })}
                    margin="normal"
                    label="Name"
                    fullWidth
                    error={Boolean(errors.name)}
                    helperText={errors.name ? 'Name is required' : ''}
                  />
                  {/* <TextField
                    {...register('email', { required: true })}
                    margin="normal"
                    label="email"
                    error={Boolean(errors.email)}
                    helperText={
                      errors.email ? 'Email is required and must be Unique' : ''
                    }
                    fullWidth
                  /> */}
                  <TextField
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address, must include @ '
                      }
                    })}
                    margin="normal"
                    label="Email"
                    error={Boolean(errors.email)}
                    helperText={errors.email ? errors.email.message : ''}
                    fullWidth
                  />
                  <TextField
                    {...register('phone', { required: true })}
                    margin="normal"
                    label="Phone"
                    fullWidth
                    error={Boolean(errors.phone)}
                    helperText={
                      errors.phone ? 'Phone is required and must be Unique' : ''
                    }
                  />
                  <TextField
                    {...register('password')}
                    margin="normal"
                    type="password"
                    label="Password"
                    fullWidth
                  />

                  <Controller
                    control={control}
                    name="role"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Select Role"
                        fullWidth
                        margin="normal"
                        select
                        error={Boolean(errors.role)}
                        helperText={errors.role ? 'Role is required' : ''}>
                        {roles?.map((role) => (
                          <MenuItem key={role._id} value={role._id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />

                  <Controller
                    control={control}
                    name="assignedOutlets"
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl fullWidth sx={{ margin: '10px 0' }}>
                        <InputLabel>Outlets</InputLabel>
                        <Select
                          multiple
                          {...field}
                          input={<OutlinedInput label="outlet" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map((value, i) => (
                                <Chip
                                  key={i}
                                  label={value.tag}
                                  clickable
                                  deleteIcon={
                                    <Cancel
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  onDelete={() => {
                                    const newValue = field.value.filter(
                                      (item) => item._id !== value._id
                                    );

                                    field.onChange(newValue);
                                  }}
                                />
                              ))}
                            </Box>
                          )}>
                          {outlets
                            ?.filter(
                              (outlet) =>
                                !field.value
                                  .map((v) => v._id)
                                  .includes(outlet._id)
                            )
                            .map((outlet) => (
                              <MenuItem
                                key={outlet._id}
                                value={{ tag: outlet.tag, _id: outlet._id }}>
                                {outlet.tag}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Controller
                    control={control}
                    name="displays"
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl fullWidth sx={{ margin: '10px 0' }}>
                        <InputLabel>Display Screens</InputLabel>
                        <Select
                          multiple
                          {...field}
                          input={<OutlinedInput label="displays" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map((value, i) => (
                                <Chip
                                  key={i}
                                  label={value.name}
                                  clickable
                                  deleteIcon={
                                    <Cancel
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  onDelete={() => {
                                    const updatedValue = field.value.filter(
                                      (display) => display._id !== value._id
                                    );

                                    field.onChange(updatedValue);
                                  }}
                                />
                              ))}
                            </Box>
                          )}>
                          {displays
                            ?.filter(
                              (display) =>
                                !field.value
                                  .map((v) => v._id)
                                  .includes(display._id)
                            )
                            .map((display) => (
                              <MenuItem
                                key={display._id}
                                value={{
                                  name: display.name,
                                  _id: display._id
                                }}>
                                {display.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>Are you sure to delete this user?</DialogContent>
                <DialogActions>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Button onClick={handleClose} autoFocus>
                      cancel
                    </Button>
                    <Button
                      onClick={async () => {
                        const res = await deleteUserApi(addPopup.id);
                        if (res.status == 200) {
                          dispatch(
                            addSnackbar({
                              message: 'User Deleted',
                              type: 'warning'
                            })
                          );
                          handleClose();
                          callFunc();
                        }
                      }}>
                      Delete
                    </Button>
                  </form>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditUser;

/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  Skeleton,
  TextField,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete, Edit } from '@mui/icons-material';
import { menuItemsRequest } from '../../redux/reducers/ducks/MenuItemsDuck';
import { useDispatch, useSelector } from 'react-redux';
import { TableButton } from '../ingredients/Styled-components';
import AddIcon from '@mui/icons-material/Add';
import AddMenuItem from './AddMenuItem';
import DeleteMenuItem from './DeleteMenuItem';
import EditMenuItem from './EditMenuItem';
import { ImageBox } from './Styled-components';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function MenuItems() {
  const dispatch = useDispatch();

  // state for delete popUp
  const [value, setValue] = useState({ isOpen: false, id: '' });
  // state for Edit popUp
  const [open, setOpen] = useState({ isOpen: false, id: '' });
  // state for Add popUp
  const [addPopup, setAddPopup] = useState({ isOpen: false });

  const [expanded, setExpanded] = useState([]);

  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const [menuItems, setMenuItem] = useState([]);

  const menuItemsResponse = useSelector((state) => state.menuItems);

  useEffect(() => {
    if (!menuItemsResponse || !menuItemsResponse.menuItemsResponse) {
      dispatch(menuItemsRequest());
    } else {
      setMenuItem(menuItemsResponse?.menuItemsResponse?.data);
    }
  }, [dispatch, menuItemsResponse]);

  // calling API over some data modification to update store

  async function callFunc() {
    dispatch(menuItemsRequest());
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredMenuItems = menuItems.filter((item) => {
    const nameWords = item?.name?.split(/(?=[A-Z])/);
    return nameWords.some((word) => word.toLowerCase().startsWith(searchQuery));
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '5px',
          mt: '10px'
        }}>
        <TextField
          label="Search Menu Item"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Tooltip title="Add Menu Item">
          <Button
            variant="contained"
            style={{ padding: ' 10px 30px' }}
            onClick={() => {
              setAddPopup({ isOpen: true });
            }}>
            <AddIcon />
            <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
              Add Menu Item
            </Typography>
          </Button>
        </Tooltip>
      </Box>

      {!menuItemsResponse.isSuccess ? (
        <Grid container>
          {[...Array(10)].map((arr, i) => (
            <Grid key={i} my={2} item lg={3} md={4} xs={12}>
              <Skeleton
                animation="wave"
                variant="circular"
                width={40}
                height={40}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="60%"
                style={{ marginBottom: 6 }}
              />
              <Skeleton animation="wave" height={10} width="40%" />
              <Skeleton
                sx={{ height: 220, width: 220 }}
                animation="wave"
                variant="rectangular"
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container>
          {filteredMenuItems?.map((menu, index) => {
            return (
              <Grid key={index} my={2} item lg={3} md={4} xs={6}>
                <Card
                  sx={{
                    maxWidth: 250,
                    marginInline: 'auto',
                    backgroundColor: '#1F1817',
                    color: '#fff'
                  }}>
                  <CardHeader
                    sx={{
                      padding: '3px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                    action={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          sx={{ color: '#fff' }}
                          onClick={() => {
                            setOpen({ isOpen: true, id: menu._id });
                          }}>
                          <Edit />
                        </IconButton>

                        <IconButton
                          sx={{ color: '#fff' }}
                          onClick={() => {
                            setValue({ isOpen: true, id: menu._id });
                          }}>
                          <Delete />
                        </IconButton>
                      </Box>
                    }
                    title={
                      <Tooltip title={menu.name} placement="top">
                        <Typography
                          variant="h6"
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            whiteSpace: 'nowrap',
                            maxWidth: '150px'
                          }}>
                          {menu.name}
                        </Typography>
                      </Tooltip>
                    }
                    subheader={
                      <Typography sx={{ color: '#fff', fontSize: '12px' }}>
                        {menu.category.name}
                      </Typography>
                    }
                  />
                  <CardMedia style={{ height: 150 }}>
                    <ImageBox
                      component="img"
                      alt="The house from the offer."
                      src={
                        menu?.img ? menu.img : process.env.REACT_APP_CLIENT_LOGO
                      }
                    />
                  </CardMedia>
                  <CardContent sx={{ padding: '5px' }}>
                    <Typography variant="body2" color="#fff">
                      {menu.desc}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ padding: '0' }}>
                    <ExpandMore
                      sx={{
                        color: '#fff'
                      }}
                      onClick={() => handleExpandClick(index)}
                      aria-expanded={expanded[index]}
                      aria-label="show more">
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </CardActions>
                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <CardContent>
                      <TableContainer key={index} component={Paper}>
                        <Table aria-label="collapsible table">
                          <TableHead
                            sx={{ background: '#FFA500', color: 'white' }}>
                            <TableRow>
                              <TableCell color="#fff">Choice Name</TableCell>
                              <TableCell>Linked Choice</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {menu.choices.map((choice, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell color="#fff">
                                    {choice.name}
                                  </TableCell>
                                  <TableCell>{choice.choice.name}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}

      <AddMenuItem
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
        // displayScreen={displayScreen}
      />

      <EditMenuItem
        open={open}
        setOpen={setOpen}
        callFunc={callFunc}
        // displayScreen={displayScreen}
      />

      <DeleteMenuItem value={value} setValue={setValue} callFunc={callFunc} />
    </>
  );
}

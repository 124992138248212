/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@mui/material';

import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatDate } from './shared';
import { DateBox } from './Shared-Styled-Components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function DatePickerComponent({ datePopup, setDatePopup }) {
  const currentDate = new Date();
  const [pickDate, setPickDate] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'selection'
    }
  ]);

  const startDate = formatDate(pickDate[0]?.startDate);
  const endDate = formatDate(pickDate[0]?.endDate);

  const handleClose = () => {
    setDatePopup({
      isOpen: false,
      startDate: startDate,
      endDate: endDate,
      isSelected: true
    });
  };

  return (
    <div>
      <DateBox onClick={() => setDatePopup({ isOpen: true })}>
        <CalendarMonthIcon sx={{ color: '#FF7527' }} />
        {datePopup?.startDate == '' && datePopup.endDate == '' ? (
          <Typography>YYYY:MM:DD - YYYY:MM:DD</Typography>
        ) : (
          <Typography>
            {datePopup?.startDate} - {datePopup?.endDate}
          </Typography>
        )}
      </DateBox>

      <Dialog
        open={datePopup?.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Pick a Date</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* to get report */}
          </DialogContentText>

          <DateRange
            editableDateInputs={true}
            onChange={(item) => setPickDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={pickDate}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  Box,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  DialogContent,
  CircularProgress
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { DeleteOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import {
  addItemWasteApi,
  editWasteApi,
  getSingleWasteApi
} from '../../../api/stocks/waste/wasteApi';
import { ingredientSetRequest } from '../../../redux/reducers/ducks/IngredietSetDuck';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

const getById = (collection, id) => {
  for (const item of collection) if (item._id == id) return item;
  return null;
};

export default function ItemWasteDialog({
  openItemDialog,
  setOpenItemDialog,
  outletId,
  callFunc
}) {
  // get single purchase Api
  const [singleWaste, setSingleWaste] = useState();
  const getSingleWaste = async () => {
    if (openDialog.id) {
      const res = await getSingleWasteApi(openDialog.id);
      setSingleWaste(res.data);
      reset(res?.data);
    }
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: singleWaste
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items'
  });

  const dispatch = useDispatch();

  // fetching data from store and calling API through Saga
  const [ingredientSet, setIngredientSet] = useState([]);

  const ingredientSetResponse = useSelector((state) => state.ingredientSet);
  useEffect(() => {
    if (
      !ingredientSetResponse ||
      !ingredientSetResponse.ingredientSetResponse
    ) {
      dispatch(ingredientSetRequest());
    } else {
      setIngredientSet(ingredientSetResponse?.ingredientSetResponse?.data);
    }
  }, [dispatch, ingredientSetResponse, callFunc]);

  const [formData, setFormData] = useState({});

  const onSubmit = async (data) => {
    setFormData({
      outlet: outletId,
      items: data.items,
      desc: data.desc
    });

    // if (openItemDialog.id) {
    //   const res = await editWasteApi(openItemDialog.id, formData);
    //   if (res.status == 200) {
    //     handleClose();
    //     callFunc();
    //   }
    // } else {
    //   const res = await addItemWasteApi(formData);
    //   if (res.status == 200) {
    //     dispatch(addSnackbar({ message: `Item Waste Added`, type: 'success' }));
    //     handleClose();
    //     callFunc();
    //   } else {
    //     dispatch(
    //       addSnackbar({
    //         message: res?.response?.data?.message,
    //         type: 'error'
    //       })
    //     );
    //   }
    // }
  };

  // confirm dialogue
  const [addPopup, setAddPopup] = useState(false);

  // handling state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const confirmSubmit = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    setErrorMessage('');

    if (openItemDialog.id) {
      const res = await editWasteApi(openItemDialog.id, formData);
      if (res?.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Item Waste Updated',
            type: 'success'
          })
        );
        handleConfirmClose();
        handleClose();
        callFunc();
      } else {
        dispatch(
          addSnackbar({
            message: res?.response?.data?.message,
            type: 'error'
          })
        );
      }
    } else {
      try {
        const res = await addItemWasteApi(formData);
        if (res?.status == 200) {
          dispatch(
            addSnackbar({
              message: 'Item Waste Added',
              type: 'success'
            })
          );
          handleConfirmClose();
          handleClose();
          callFunc();
        } else {
          setErrorMessage(res?.response?.data?.message);
          dispatch(
            addSnackbar({
              message: errorMessage,
              type: 'error'
            })
          );
        }
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleConfirmClose = () => {
    setAddPopup(false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };
  // // confirm dialogue

  useEffect(() => {
    if (openItemDialog.id) {
      getSingleWaste();
    }
  }, [openItemDialog]);

  const handleClose = () => {
    reset();
    setOpenItemDialog('isOpen', false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openItemDialog?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {openItemDialog.id ? (
          <DialogTitle>Edit Item Waste</DialogTitle>
        ) : (
          <DialogTitle>Add Item Waste</DialogTitle>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Description"
                  type="text"
                  {...register(`desc`)}
                />

                <Autocomplete
                  id="free-solo-2-demo"
                  disableClearable
                  onChange={(e, value) =>
                    value &&
                    append({
                      ingredientSet: value._id,
                      quantity: '',
                      desc: ''
                    })
                  }
                  options={ingredientSet?.filter(
                    (ingredient) =>
                      !fields.map((v) => v.ingredient).includes(ingredient._id)
                  )}
                  getOptionLabel={(ingredient) => ingredient.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Click to Add Item"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search'
                      }}
                    />
                  )}
                />
              </Box>

              <Box>
                {fields.map((ingredient, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      border: '1px solid gray',
                      margin: '10px 0',
                      borderRadius: '7px',
                      padding: '10px 5px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                    key={index}>
                    <Box width="20%">
                      <Typography>
                        {getById(ingredientSet, ingredient.ingredientSet)?.name}
                      </Typography>
                    </Box>

                    <TextField
                      size="small"
                      {...register(`items.${index}.quantity`, {
                        required: true
                      })}
                      type="number"
                      variant="outlined"
                    />

                    <TextField
                      size="small"
                      label="Description"
                      type="text"
                      {...register(`items.${index}.desc`)}
                    />
                    <Tooltip title="Delete Item">
                      <IconButton
                        sx={{
                          color: 'red',
                          width: '10%'
                        }}
                        onClick={() => remove(index)}>
                        <DeleteOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <DialogActions>
            <Button
              type="submit"
              disabled={isButtonDisabled}
              onClick={() => {
                setAddPopup(true);
              }}
              variant="contained">
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              close
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={addPopup} onClose={handleConfirmClose}>
        <Box
          sx={{
            textAlign: 'center',
            width: 'min(100% -30px, 700px)',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#FFA500" fontWeight="700">
              Confirm Submit
            </Typography>
          </DialogTitle>
          <>
            <DialogContent>
              <p>Are you sure to Add Item Waste?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose} autoFocus>
                cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                onClick={() => {
                  confirmSubmit();
                }}>
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getPRAReportApi } from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import { getCurrentDate, timestamp } from '../Shared/shared';
import { getOutletsApi } from '../../api/outlet/outletApi';
import {
  CountTableCell,
  HeaderTableCell,
  HoverTableRow
} from './Styled-components';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OrderDetails from '../Shared/OrderDetail/OrderDetails';
import DatePickerComponent from '../Shared/DatePickerComponent';

export default function PRAReport() {
  const [outlets, setOutlets] = useState([]);

  const [PRAOrdersReport, setPRAOrdersReport] = useState([]);

  const [selectOutlet, setSelectOutlet] = useState();

  const handleChange = (event) => {
    setSelectOutlet(event.target.value);
  };

  // getting all outlets
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };
  // handlng date

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;
  useEffect(() => {
    getOutlets();
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getPRAReportApi(selectOutlet, startDate, endDate);
      setPRAOrdersReport(response?.data);
      setLoading(false);
      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = PRAOrdersReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.invoiceId?.toLowerCase().includes(term) ||
          report?.customer?.name?.toLowerCase().includes(term) ||
          report?.customerName?.toLowerCase().includes(term) ||
          report?.user?.name?.toLowerCase().includes(term) ||
          report?.cancelledBy?.name?.toLowerCase().includes(term) ||
          report?.cancelledRef?.name?.toLowerCase().includes(term) ||
          report?.cancelledReason?.toLowerCase().includes(term)
        );
      })
  );

  // Calculate total tax total sale, using reduce

  const { totalSale, totalTax } = PRAOrdersReport.reduce(
    (acc, order) => {
      const { total, totalCharges, tax, chargesTax } = order;
      acc.totalSale += total + totalCharges + tax + chargesTax;
      acc.totalTax += tax + chargesTax;
      return acc;
    },
    { totalSale: 0, totalTax: 0 }
  );

  // calling order Details Component
  const [details, setDetails] = useState({ isOpen: false, id: '' });

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1rem)'
          }}>
          Branch Wise PRA Report
        </Typography>
        <Box display="flex" alignItems="center">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="demo-select-small-label">Select Outlet</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectOutlet}
              label="Select Outlet"
              onChange={handleChange}>
              {outlets.map((outlet, i) => (
                <MenuItem key={i} value={outlet._id}>
                  {outlet.tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePickerComponent
            datePopup={datePopup}
            setDatePopup={setDatePopup}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TextField
                    label="Search Order by Id, Cust. Name, Contact or Rider"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>

          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#A076F9',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>#</HeaderTableCell>
                <HeaderTableCell>Order Id</HeaderTableCell>
                <HeaderTableCell>Order Time</HeaderTableCell>
                <HeaderTableCell>OrderBy</HeaderTableCell>
                <HeaderTableCell>Customer</HeaderTableCell>
                <HeaderTableCell>Contact</HeaderTableCell>
                <HeaderTableCell>CashedOut By</HeaderTableCell>

                <HeaderTableCell>Order Amount</HeaderTableCell>
                <HeaderTableCell>Discount</HeaderTableCell>
                <HeaderTableCell>Discount Rate</HeaderTableCell>
                <HeaderTableCell>Tax Amount</HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#A076F9a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{report?.invoiceId}</TableCell>
                        <TableCell>{timestamp(report?.orderTime)}</TableCell>

                        <TableCell>{report?.user?.name}</TableCell>
                        <TableCell>
                          {report?.customer?.name ||
                            report?.customerName ||
                            'walk-in-customer'}
                        </TableCell>
                        <TableCell>
                          {Math.round(report?.customerContact)}
                        </TableCell>
                        <TableCell>{report?.cashier?.name}</TableCell>
                        <TableCell>
                          {Math.round(
                            report?.total +
                              report?.totalCharges +
                              report?.tax +
                              report?.chargesTax
                          )}
                        </TableCell>

                        <TableCell>{Math.round(report?.discount)}</TableCell>
                        <TableCell>
                          {Math.round(report?.discountRate * 100)}%
                        </TableCell>

                        <TableCell>
                          {Math.round(report?.tax + report?.chargesTax)}
                        </TableCell>

                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({ isOpen: true, id: report?._id });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <CountTableCell sx={{ width: '100px' }}>
                  Total Orders
                </CountTableCell>
                <CountTableCell>{PRAOrdersReport?.length}</CountTableCell>
                <CountTableCell sx={{ width: '100px' }}>
                  Total Sale
                </CountTableCell>
                <CountTableCell>{Math.round(totalSale)}</CountTableCell>
                <CountTableCell sx={{ width: '100px' }}>
                  Total Tax
                </CountTableCell>
                <CountTableCell>{Math.round(totalTax)}</CountTableCell>

                <CountTableCell sx={{ width: '100px' }}>
                  Average Sale
                </CountTableCell>
                <CountTableCell>
                  {Math.round(totalSale / PRAOrdersReport?.length)}
                </CountTableCell>

                <CountTableCell sx={{ width: '100px' }}>
                  Average Tax
                </CountTableCell>
                <CountTableCell>
                  {Math.round(totalTax / PRAOrdersReport?.length)}
                </CountTableCell>
              </TableRow>

              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <OrderDetails details={details} setDetails={setDetails} />
    </>
  );
}

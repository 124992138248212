/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';
import { deleteOrderApi } from '../../../api/orders/OrdersApi';
import { useForm } from 'react-hook-form';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import { deleteOrderReason } from '../Shared';
import { useDispatch } from 'react-redux';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import { permisssions } from '../../Shared/shared';
import { rejectCallCenterOrderApi } from '../../../api/callCenter/callCenterApi';

export default function RejectOrderCC({
  rejectOrderPopup,
  setRejectOrderPopup,
  callFunc,
  outletId
}) {
  const dispatch = useDispatch();

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      permisssions.modifyOrder
    );
    setUsers(response?.data);
  };
  useEffect(() => {
    getUsersWithPermission();
  }, []);

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch } = useForm();
  const inputValue = watch('autocompleteInput');

  const handleChangeUserName = (event) => {
    setValue('userName', event.target.value);
  };

  const [options, setOptions] = useState(deleteOrderReason);

  const onSubmit = async (data) => {
    const formData = {
      user: data.userName,
      key: data.key,
      reason: data.autocompleteInput?.title || ''
    };

    try {
      const res = await rejectCallCenterOrderApi(
        rejectOrderPopup.orderId,
        formData
      );
      if (res.status == 200) {
        callFunc();
        setRejectOrderPopup({ isOpen: false });
        handleClose();
        dispatch(
          addSnackbar({
            message: res?.data?.message || 'Order Rejected',
            type: 'warning'
          })
        );
      }
    } catch {
      dispatch(
        addSnackbar({
          message: `UnAuthorized`,
          type: 'error'
        })
      );
    }
  };

  const handleClose = () => {
    callFunc();
    setRejectOrderPopup('isOpen', false);
  };

  return (
    <div>
      <Dialog
        open={rejectOrderPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Reject Order</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Required Manager Key to Reject this Order
            </DialogContentText>

            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel>Name</InputLabel>
              <Select
                defaultValue=""
                {...register('userName')}
                label="Name"
                onChange={handleChangeUserName}>
                {users?.map((user, index) => (
                  <MenuItem key={index} value={user._id}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              autoFocus
              margin="dense"
              name="key"
              label="Manager Key"
              type="password"
              {...register('key')}
              fullWidth
              variant="outlined"
            />

            <Autocomplete
              options={options}
              noOptionsText="Enter to create a new option"
              getOptionLabel={(option) => option.title}
              onChange={(e, newValue) => {
                setValue('autocompleteInput', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select"
                  variant="outlined"
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      options.findIndex((o) => o.title === inputValue) === -1
                    ) {
                      setOptions((o) => o.concat({ title: inputValue }));
                    }
                  }}
                />
              )}
            />

            {/* <Button type="submit">Submit</Button> */}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              close
            </Button>
            <Button
              type="submit"
              variant="outlined"
              // onClick={
              // () => deleteOrder(value.id)
              // }
            >
              Reject
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CountTableCell } from './Styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFoodSaleReportApi } from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import CsvDownloader from 'react-csv-downloader';

import { getCurrentDate } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';

export default function FoodSaleReport() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [foodSaleReport, setFoodSaleReport] = useState([]);

  // handlng date

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;
  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  // const handleDateChange = (event, isStartDate) => {
  //   const selectedDate = event.target.value;
  //   if (isStartDate) {
  //     setStartDate(selectedDate);
  //   } else {
  //     setEndDate(selectedDate);
  //   }
  // };

  // useEffect(() => {
  //   setStartDate(getCurrentDate());
  //   setEndDate(getCurrentDate());
  // }, []);

  // calling APi
  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getFoodSaleReportApi(
        outlet?._id,
        startDate,
        endDate
      );
      setFoodSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = foodSaleReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return report?.item?.toLowerCase().includes(term);
      })
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Report"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Food Sales
        </Typography>
        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small" aria-label="collapsible table">
            <TableHead sx={{ background: '#FFA500', color: 'white' }}>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>DineIn</TableCell>
                <TableCell>TakeAway</TableCell>
                <TableCell>Delivery</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#FFA500a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell> {report?.item}</TableCell>
                        <TableCell> {report?.dineIn || 0} </TableCell>
                        <TableCell> {report?.takeAway || 0} </TableCell>
                        <TableCell>{report?.delivery || 0}</TableCell>
                        <TableCell>{report?.total || 0}</TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
            {/* <CsvDownloader
              datas={foodSaleReport}
              text="Export to CSV"
              filename="Food Sale Report"
            /> */}
        </TableContainer>
      )}
    </>
  );
}

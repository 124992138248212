/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Box,
  Tabs,
  Tab
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  getUsersApi,
  getUsersWithPermissionApi
} from '../../../api/users/usersApi';
import { permisssions } from '../../Shared/shared';

export default function Discount({
  discountPopup,
  setDiscountPopup,
  outletId,
  setChildDiscountData,
  applyVoucher
}) {
  // handling state and handleChange for tab component
  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      permisssions.addDiscount
    );
    setUsers(response?.data);
  };

  const [careOfUsers, setCareOfUsers] = useState([]);

  const getUsers = async () => {
    const response = await getUsersWithPermissionApi('', permisssions.careOf);
    if (response.status == 200) {
      setCareOfUsers(response?.data);
    } else {
      return;
    }
  };

  useEffect(() => {
    getUsers();
    getUsersWithPermission();
  }, []);

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, reset, control } = useForm();
  const watchCheckbox = watch('fixedDiscount');
  const discountLabel = watchCheckbox
    ? 'Fixed Discount'
    : 'Percentage Discount';

  const handleChangeUserName = (event) => {
    setValue('userName', event.target.value);
  };

  useEffect(() => {
    if (applyVoucher) {
      setValue('voucher', 'defense10');
    }
  }, [applyVoucher, setValue]);

  const onSubmit = (data) => {
    setChildDiscountData(data);

    console.log(data);

    handleClose();
    // Handle form submission logic here
  };
  const handleClose = () => {
    reset();
    setDiscountPopup('isOpen', false);
  };
  return (
    <div>
      <Dialog
        className="dialog"
        maxWidth="auto"
        open={discountPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Add Discount</DialogTitle>

        <Box
          sx={{
            height: '300px'
          }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'center'
            }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <Tab label="Manual Discount" />
              <Tab label="Voucher" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <Box sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  <FormControl sx={{ width: '50%' }} size="small">
                    <InputLabel>Name</InputLabel>
                    <Select
                      defaultValue=""
                      {...register('userName')}
                      label="Name"
                      onChange={handleChangeUserName}>
                      {users?.map((user, index) => (
                        <MenuItem key={index} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    size="small"
                    label="Manager Key"
                    type="password"
                    {...register('key')}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: '10px',
                    marginBottom: '10px'
                  }}>
                  <TextField
                    sx={{ width: '50%' }}
                    size="small"
                    label={discountLabel}
                    {...register('discount')}
                  />
                  <FormControlLabel
                    sx={{ width: '50%' }}
                    label="Fixed"
                    control={<Checkbox {...register('fixedDiscount')} />}
                  />
                </Box>
                <Box>
                  <TextField
                    required
                    size="small"
                    fullWidth
                    label="Reason for Discount"
                    type="text"
                    {...register('reason')}
                  />
                  <Controller
                    name="careOf"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size="small"
                        defaultValue=""
                        label="Care Of"
                        fullWidth
                        margin="normal"
                        select>
                        <MenuItem value="Branch End">Branch End</MenuItem>
                        {careOfUsers?.map((user) => (
                          <MenuItem value={user._id} key={user._id}>
                            {user.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </form>
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ p: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <TextField
                    size="small"
                    fullWidth
                    label="Voucher"
                    type="text"
                    {...register('voucher')}
                  />
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    gap: '10px',
                    margin: '5px auto'
                  }}>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    variant="outlined"
                    onClick={handleClose}
                    autoFocus>
                    close
                  </Button>
                  <Button
                    sx={{ borderRadius: '10px', padding: '' }}
                    type="submit"
                    variant="contained">
                    Submit
                  </Button>
                </Box>
              </form>
            </Box>
          )}
        </Box>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import { Box, Typography, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from '@mui/material';
import { InvoiceTypography } from '../Styled-components';
import { getSingleOrderApi } from '../../../api/orders/OrdersApi';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import '../../../index.css';
import { timestamp } from '../Shared';
import crop from '../../../assets/shufblack.png';
import pra from '../../../assets/pra.png';
import QRCode from 'react-qr-code';

export default function Receipt({ print, setPrint, cancelled, running }) {
  // getting outlet name from redux on the basis of params
  const params = useParams();
  let code = params.code;
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  let orderId = print?.id;

  const handlePrintClick = () => {
    const toPrint = document.getElementById('dialogPrint');
    let printWindow = window.open('', '', 'width=500');
    printWindow.document.write(toPrint.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();

    printWindow.onfocus = () => {
      setTimeout(() => printWindow.close(), 500);
      handleClose();
    };
    // window.print();
  };
  const [order, setOrder] = useState();

  // get single Order Api calling
  const getSingleOrder = async (orderId) => {
    const res = await getSingleOrderApi(orderId);
    setOrder(res?.data);
  };

  useEffect(() => {
    if (print.isOpen) {
      getSingleOrder(orderId);
    }
  }, [print.isOpen]);

  const handleClose = () => {
    setPrint({ isOpen: false });
  };

  const filteredItems = order?.items?.filter(
    (activeItem) => activeItem.active === true
  );

  // converting orderType to uppercase
  const textConverter = (orderType) => {
    let convertedText =
      orderType?.charAt(0).toUpperCase() + orderType?.slice(1);
    return convertedText;
  };

  // getting charges
  const charges = order?.charges.reduce(
    (prev, current) => prev + current.amount,
    0
  );

  const grandTotal = order?.total + (order?.tax || 0) + charges;

  // some additional CSS

  const divFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  };
  const textStyle = {
    fontSize: '14px',
    margin: 0,
    padding: 0,
    fontWeight: '700'
  };

  const paragraph = {
    margin: 0,
    padding: 0
  };

  return (
    <div>
      <Dialog
        className="dialog"
        PaperProps={{
          sx: {
            width: '100%',
            height: 'auto',
            margin: 0,
            background: '#ffffff',
            color: '#000000'
          }
        }}
        open={print?.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <div
            id="dialogPrint"
            style={{
              padding: 0,
              margin: 0,
              fontFamily:
                "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
            }}>
            <div>
              <h3 style={{ width: '100%', textAlign: 'center' }}>
                {outlet.name}
              </h3>
              <p style={{ margin: 0, width: '100%', textAlign: 'center' }}>
                Phone:
                {process.env.REACT_APP_BRANCH_CONTACT || ''}
                {process.env.REACT_APP_BRANCH_SECOND_CONTACT || ''}
              </p>
              <hr />
              <div style={divFlex}>
                <span style={textStyle}>
                  Ref# {order?.invoiceId?.slice(-10)}
                </span>
                <span style={textStyle}>{textConverter(order?.orderType)}</span>
                {order?.preOrder ? (
                  <span style={textStyle}>(Pre-Order)</span>
                ) : null}

                <span style={textStyle}>
                  {cancelled ? 'Canceled' : running ? 'Un-Paid' : 'Paid'}
                </span>
                {order?.source?.name ||
                  (!order?.orderType === 'dineIn' && (
                    <span> SRC: {order?.source?.name}</span>
                  ))}
              </div>
              <hr />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ textAlign: 'left' }}>
                  <p style={textStyle}> Date: {timestamp(order?.orderTime)}</p>
                  <p style={textStyle}>
                    Name:
                    {order?.customerName
                      ? order?.customerName
                      : 'Walk-In-Customer'}
                  </p>
                  {order?.deliveryAddress ? (
                    <p style={textStyle}>Address: {order?.deliveryAddress} </p>
                  ) : (
                    ''
                  )}
                  {order?.customerContact && (
                    <p style={textStyle}>Contact: {order?.customerContact} </p>
                  )}
                  {order?.alternativeContact && (
                    <p style={textStyle}>
                      Second Contact: {order?.alternativeContact}
                    </p>
                  )}

                  {order?.waiter && (
                    <p style={textStyle}>Waiter: {order?.waiter} </p>
                  )}
                  {order?.tableName && (
                    <p style={textStyle}>Table: {order?.tableName} </p>
                  )}
                </div>
                <div>
                  {order?.praInvoice && (
                    <>
                      <img
                        style={{
                          maxWidth: 128
                        }}
                        src={pra}
                        alt="Punjab Revenue Authority"
                      />
                      <div
                        style={{
                          height: 'auto',
                          margin: '0 auto',
                          maxWidth: 120,
                          width: '100%'
                        }}>
                        <QRCode
                          size={300}
                          style={{
                            height: 'auto',
                            maxWidth: '100%',
                            width: '100%'
                          }}
                          value={order?.praInvoice}
                          viewBox={`0 0 300 300`}
                        />
                      </div>

                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          margin: 0
                        }}>
                        {order?.praInvoice}
                      </p>
                    </>
                  )}
                </div>
              </div>
              <hr />
              {filteredItems?.map((item, index) => {
                return (
                  <div
                    style={{
                      margin: '10px 0',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center'
                    }}
                    key={index}>
                    <div>
                      <p style={paragraph}>
                        {item.name} <b>X {item.quantity}</b>
                      </p>

                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          margin: 0,
                          padding: 0
                        }}>
                        (
                        {item?.choices
                          ?.reduce(
                            (prev, current) =>
                              (prev += current.optionName
                                ? current.optionName +
                                  `${
                                    current.price ? ` Rs. ${current.price}` : ''
                                  }` +
                                  ', '
                                : ''),
                            ''
                          )
                          .slice(0, -2)}
                        )
                      </p>
                    </div>
                    <div>
                      <p style={paragraph}>
                        {Math.round(
                          (item.discountedPrice + item?.tax || 0) *
                            item.quantity
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
              <div style={{ borderTop: '1px solid black', marginTop: '10px' }}>
                <div style={divFlex}>
                  <span style={textStyle}>Sub-Total:</span>
                  <span style={textStyle}>
                    {Math.round(order?.subTotal + order?.subTax)}
                  </span>
                </div>
              </div>
              {order?.discount !== 0 && (
                <div style={{ borderTop: '1px solid black' }}>
                  <div style={divFlex}>
                    <span style={textStyle}>Discount:</span>
                    <span style={textStyle}>{Math.round(order?.discount)}</span>
                  </div>
                </div>
              )}
              {order?.givenAmount && (
                <div style={{ borderTop: '1px solid black' }}>
                  <div style={divFlex}>
                    <span style={textStyle}>
                      Given Amount: {order?.givenAmount}
                      {order?.paymentMethod && (
                        <span>({order?.paymentMethod.tag})</span>
                      )}
                    </span>

                    <span style={textStyle}>
                      Change: {Math.round(grandTotal - order?.givenAmount)}
                    </span>
                  </div>
                </div>
              )}
              {!charges == 0 && (
                <div
                  style={{
                    borderTop: '1px solid black',
                    borderBottom: '1px solid black'
                  }}>
                  <div style={divFlex}>
                    <span style={textStyle}>Charges:</span>
                    <span style={textStyle}>{Math.round(charges)}</span>
                  </div>
                </div>
              )}
              <div
                style={{
                  borderTop: '1px solid black',
                  borderBottom: '1px solid black'
                }}>
                <div style={divFlex}>
                  <span style={textStyle}>Total:</span>
                  <span style={textStyle}>{Math.round(grandTotal)}</span>
                </div>
                <div style={divFlex}>
                  {order?.praInvoice && (
                    <>
                      <span style={textStyle}>Tax:</span>
                      <span style={textStyle}>
                        {Math.round(order?.tax + order?.chargesTax)}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {!order?.deliveryNote == '' && (
                <>
                  Dilevery Note: <i>{order?.deliveryNote}</i>
                </>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                color: '#000',
                padding: '5px'
              }}>
              <img
                style={{
                  maxWidth: '100%',
                  width: '40px',
                  height: '40px'
                }}
                src={crop}
                alt="SHUF.Co"
              />

              <div style={{ color: '#000' }}>
                <p style={paragraph}>
                  Powered By: <b style={{ fontSize: '18px' }}>SHUFCO.org</b>
                </p>
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            className="no-print"
            variant="contained"
            onClick={handleClose}
            autoFocus>
            close
          </Button>
          <Button
            className="no-print"
            variant="contained"
            onClick={handlePrintClick}>
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const dashboardUrls = {
  dashboardReport: `${baseURL}/reports/dashboard`,
  dashboardItemReport: `${baseURL}/reports/menu-report`
};

export default dashboardUrls;


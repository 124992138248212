import { Box, Button, Chip, Typography, styled } from '@mui/material';

export const ItemBox = styled(Box)(({ itemBoxStates, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  backgroundColor: itemBoxStates === true ? '#42ba96' : '',
  color: itemBoxStates ? '#fff' : '',
  transition: 'all 0.4s ease-in-out',
  minHeight: '200px',
  textAlign: 'left',
  justifyContent: 'space-between',
  boxShadow: theme.palette.customShadows.light,
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {}
}));

export const OrdersActionButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  padding: '2px 4px',
  height: '25px',
  textTransform: 'none',
  fontSize: '11px'
}));
export const AddressTypo = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 400,
  padding: '2px 0'
}));
// ****************************************** Kitchen Display Card Adnan*************************

export const ContainerSection = styled(Box)(({ theme, itemBoxStates }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  minHeight: '400px',
  borderRadius: '7px',
  margin: '4px',
  transition: '0.3s',
  backgroundColor: itemBoxStates === true ? '#42ba96' : '',
  color: itemBoxStates === true ? '#fff' : '',
  boxShadow: theme.palette.customShadows.heavy,
  '&:hover': {
    boxShadow: theme.palette.customShadows.medium
    // backgroundColor: '#F1F1F1'
  }
}));
export const Top = styled(Box)(() => ({
  // height: "25%",
  borderRadius: '6px 6px 0 0'
}));
export const TimeBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-around',
  color: 'white'
  // backgroundColor: 'green',
  // borderTopLeftRadius: '17px',
  // borderTopRightRadius: '17px'
}));
export const Time2 = styled(Box)(() => ({
  textAlign: 'center',
  flexDirection: 'column',
  display: 'flex'
}));
export const TakeHading = styled(Box)(() => ({
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  // backgroundColor: '#303952',
  fontSize: '18px',
  fontWeight: 'bold'
}));
export const LineTag1 = styled(Box)(() => ({
  border: '1px solid #000000'
}));
export const Center = styled(Box)(() => ({
  flexGrow: '1'
}));
export const MidlleSection = styled(Box)(() => ({
  paddingLeft: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start'
}));
export const ItemSection = styled(Box)(() => ({
  textAlign: 'start',
  marginInlineStart: '2px',
  borderRadius: '10px'
}));
export const Shot = styled(Box)(() => ({
  marginLeft: '30px',
  color: '#b42525'
}));
export const Tag2 = styled(Box)(() => ({
  marginInline: '10px',
  marginBlock: '3px',
  border: '1px solid #aeadad'
}));
export const Bottom = styled(Box)(() => ({
  // height: "10%",
  backgroundColor: '#64B5F6',
  borderRadius: ' 7px '
}));
export const BtnSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '4px 0',
  gap: '10px',
  color: '#fff'
}));

export const EndButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '30px',
  fontSize: '17px',
  fontWeight: '700',
  height: '50px',
  width: '150px',
  color: theme.palette.white.light,
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    backgroundColor: theme.palette.primary.hover
  }
}));

export const OrderChip = styled(Chip)(({ theme }) => ({
  // backgroundColor: theme.palette.info.dark,
  backgroundColor: '#607D8B',
  maxWidth: '40%',

  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.info.light
  }
}));

import { Box } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

const data = [
  {
    name: ' Millat',
    uv: 4000,
    pv: 400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2400
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2400
  }
];

export default function OutletBarChart({ salesData }) {
  return (
    <BarChart
      width={500}
      height={300}
      data={salesData}
      // data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="code" />
      <YAxis />
      <Tooltip
        content={({ payload }) => {
          if (payload && payload.length) {
            const data = payload[0].payload;
            return (
              <Box
                sx={{
                  background: '#999',
                  color: '#f1f1f1',
                  padding: '10px 5px',
                  borderRadius: '7px'
                }}>
                <strong>Branch:</strong> {data.name}
                <p>
                  <strong>Item Sales:</strong> {data.uv}
                </p>
                {/* <strong>Item Count:</strong> {data.pv} */}
              </Box>
            );
          }
          return null;
        }}
      />

      <Legend />
      <Bar dataKey="uv" name="Sales" fill="#82ca9d" />
      {/* <Bar dataKey="pv" name="Order Count" fill="#8884d8" /> */}
    </BarChart>
  );
}


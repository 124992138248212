import { Button, TableCell, styled } from '@mui/material';

export const TableButton = styled(Button)`
margin-bottom:1%;
color:#ffffff;
background: #6d1b7b;
&:hover {
  background-color: #6d1b7ba1;
}
},
`;

export const StyledTableCell = styled(TableCell)`
  text-align: center;
  color: #ffffff;
`;

/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export default function Dialogue({ openDialog, setOpenDialog }) {
  const navigate = useNavigate();
  const toPOS = () => {
    navigate(`/outlet/${openDialog?.outlet?.code?.toLowerCase()}/pos`);
  };
  const handleClose = () => {
    setOpenDialog({ isOpen: false });
  };

  return (
    <Dialog open={openDialog?.isOpen ?? false} onClose={handleClose}>
      {/* <DialogTitle>Alert</DialogTitle> */}

      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <ReportProblemIcon sx={{ color: 'yellow', fontSize: '50px' }} />
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {openDialog?.msg}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={toPOS} variant="contained">
          Go-to POS
        </Button>
        <Button variant="outlined" onClick={handleClose} autoFocus>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

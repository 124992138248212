import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  outletMenuResponse: null,
  isSuccess: false
};

const outletMenuSlice = createSlice({
  name: 'outletMenues',
  initialState: INITIAL_STATE,
  reducers: {
    outletMenuResponse(state, { payload }) {
      return {
        ...state,
        outletMenuResponse: payload.response
      };
    },
    outletMenuRequest: (state) => state,
    updateOutletMenuSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  outletMenuRequest,
  outletMenuResponse,
  updateOutletMenuSuccessStatus
} = outletMenuSlice.actions;

export default outletMenuSlice.reducer;

/* eslint-disable no-unused-vars */
import { Menu, alpha } from '@mui/material';
import styled from 'styled-components';

// select Box starts here
export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    borderTop: '2px solid #0894FF',
    //   marginTop: theme.spacing(1),
    minWidth: 180,
    width: 250,
    // color:
    //   theme.palette.mode === 'light'
    //     ? 'rgb(55, 65, 81)'
    //     : theme.palette.grey[300],
    color: 'rgb(55, 65, 81)',

    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        // color: theme.palette.text.secondary,
        //   marginRight: theme.spacing(1.5),
        backgroundColor: 'transparent'
      }
      //   '&:active': {
      //     backgroundColor: alpha(
      //       theme.palette.primary.main,
      //       theme.palette.action.selectedOpacity
      //     )
      //   }
    }
  }
}));

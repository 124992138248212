import React from 'react';
import { Box, TextField } from '@mui/material';

const SearchComponent = ({ searchQuery, handleSearchChange }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
      <TextField
        label="Search"
        variant="outlined"
        size="small"
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default SearchComponent;


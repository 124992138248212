/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCategoryWiseSaleReportApi } from '../../api/report/reportApi';
import { CountTableCell, HeaderTableCell } from './Styled-components';
import Loader from '../Loader/Loader';
import { Cell, Pie, PieChart } from 'recharts';
import { getCurrentDate } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group E', value: 200 },
//   { name: 'Group F', value: 200 },
//   { name: 'Group G', value: 200 }
// ];

// const COLORS = [
// '#0088FE',
// '#00C49F',
// '#FFBB28',
// '#FF8042',
// '#3AA6B9',
// '#73BBC9',
// '#643843'
// ];

// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({
//   cx,
//   cy,
//   midAngle,
//   innerRadius,
//   outerRadius,
//   percent,
//   index
// }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       x={x}
//       y={y}
//       fill="white"
//       textAnchor={x > cx ? 'start' : 'end'}
//       dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };
// =============================

export default function CategoryWiseSaleReport() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [saleReport, setSaleReport] = useState([]);

  // handling date

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;
  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  const millisecondsDiff = new Date(endDate) - new Date(startDate);
  const daysToGetAvg = Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24)) + 1;

  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  // const handleDateChange = (event, isStartDate) => {
  //   const selectedDate = event.target.value;
  //   if (isStartDate) {
  //     setStartDate(selectedDate);
  //   } else {
  //     setEndDate(selectedDate);
  //   }
  // };

  // useEffect(() => {
  //   const currentDate = getCurrentDate();
  //   setStartDate(currentDate);
  //   setEndDate(currentDate);
  // }, []);

  //  calling APi

  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getCategoryWiseSaleReportApi(
        outlet?._id,
        startDate,
        endDate
      );
      setSaleReport(response?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredOrders = saleReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return report?.name?.toLowerCase().includes(term);
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  const totalSales = saleReport?.reduce(
    (accomulator, current) => accomulator + current.sales,
    0
  );

  // =============================

  // const totalSales = saleReport.reduce(
  //   (total, category) => total + category.sales,
  //   0
  // );

  // Create data for the pie chart with percentages
  // const data = saleReport.map((category) => ({
  //   name: `${category.name} (${((category.sales / totalSales) * 100).toFixed(
  //     2
  //   )}%)`,
  //   value: category.sales
  // }));

  // const COLORS = [
  //   '#0088FE',
  //   '#00C49F',
  //   '#FFBB28',
  //   '#FF8042',
  //   '#3AA6B9',
  //   '#73BBC9',
  //   '#643843'
  // ];

  // const RADIAN = Math.PI / 180;

  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor={x > cx ? 'start' : 'end'}
  //       dominantBaseline="central">
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };
  // =============================

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Category"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Category Wise Sales
        </Typography>

        {/* <div>
          <label htmlFor="start-date">Start Date:</label>
          <input
            className="date-input"
            id="start-date"
            type="date"
            value={startDate || ''}
            onChange={(event) => handleDateChange(event, true)}
          />

          <label htmlFor="end-date">End Date:</label>
          <input
            className="date-input"
            id="end-date"
            type="date"
            value={endDate || ''}
            onChange={(event) => handleDateChange(event, false)}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button>
        </div> */}

        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>

      {/* ----------------------------- */}
      {/* <PieChart width={420} height={420}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={200}
          fill="#8884d8"
          dataKey="value">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart> */}
      {/* ----------------------------- */}
      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small">
            <TableHead sx={{ background: '#545B77' }}>
              <TableRow>
                <HeaderTableCell>Category</HeaderTableCell>
                <HeaderTableCell>Items Quantity</HeaderTableCell>
                <HeaderTableCell>Sales</HeaderTableCell>
                <HeaderTableCell>Percentage </HeaderTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <>
                {filteredOrders
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#8CC043a2',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell>{report?.name}</TableCell>
                        <TableCell>{report?.itemsQuantity}</TableCell>
                        <TableCell>{Math.round(report?.sales)}</TableCell>
                        <TableCell>
                          {Number((report?.sales / totalSales) * 100).toFixed(
                            2
                          )}
                          %
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <CountTableCell>Total Sales:</CountTableCell>
                  <CountTableCell>{Math.round(totalSales)}</CountTableCell>
                </TableRow>
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

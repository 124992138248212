/* eslint-disable react/prop-types */
import React, { createContext, useState, useContext } from 'react';

const DrawerContext = createContext();

const DrawerContextProvider = ({ children, userRole }) => {
  const [isOpened, toggleIsOpened] = useState(false);
  const isAuthorize = userRole === 'super';

  return (
    <DrawerContext.Provider value={{ isOpened, toggleIsOpened, isAuthorize }}>
      {children}
    </DrawerContext.Provider>
  );
};

const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error(
      'useDrawerContext must be used within a DrawerContextProvider'
    );
  }
  return context;
};

export { DrawerContextProvider, useDrawerContext };

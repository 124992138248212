/* eslint-disable no-unused-vars */
import {
  Typography,
  Box,
  styled,
  Card,
  Button,
  IconButton,
  Chip
} from '@mui/material';

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  padding: 0
}));

// menu Item Section

export const MenuItemBox = styled(Box)(() => ({
  // maxHeight: '500px',
  // height: '465px',
  width: '100%',
  overflowY: 'auto',
  height: '100%',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: '#1F1817',
  cursor: 'pointer',
  height: '170px',
  textAlign: 'left',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  borderRadius: '20px',
  border: '1px solid #cbd5e0',
  transition: '0.3s',
  margin: '2px',
  '&:hover': {
    transform: 'scale(0.9)',
    boxShadow: '0px 0px 6px 4px #cbd5e0'
  }
}));

export const NameTypo = styled(Typography)(() => ({
  fontSize: " ${({ myfontsize }) => (myfontsize ? '8px' : '12px')}",
  fontWeight: 600,
  color: '#fff'
}));

export const ImageBox = styled(Box)(() => ({
  objectFit: 'cover',
  height: 110,
  width: '90%',
  maxHeight: { xs: 233, md: 167 },
  maxWidth: { xs: 350, md: 250 },
  borderRadius: '10px'
}));

export const CustomerInfoBox = styled(Box)(() => ({
  padding: '7px 0 '
}));

export const SourceBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  // marginTop: '2px'
  padding: '0 5px'
}));

export const GeneralTypography = styled(Typography)(() => ({
  fontSize: '12px',
  letterSpacing: 0,
  display: 'flex',
  justifyContent: 'center'
}));

export const PriceTypo = styled(Typography)`
  font-size: ${({ myfontsize }) => (myfontsize ? '12px' : '16px')};
  font-weight: 600;
  color: #fff;
`;

export const DetailsBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const CartCard = styled(Card)`
  margin: 5px;
  border: 1px solid gray;
  padding: 2px;
  border-radius: 10px;
`;

export const CartBox = styled(Box)(() => ({
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const DineInButton = styled(Button)(({ ordertype, theme }) => ({
  background: ordertype === 'dineIn' ? theme.palette.primary.main : '',
  color: ordertype === 'dineIn' ? '#fff' : '',
  fontSize: '10px',
  lineHeight: 1,
  letterSpacing: 0,
  height: '40px',
  padding: '3px',
  '&:hover': {
    background: theme.palette.primary.hover,
    color: '#fff'
  }
}));

export const TakeAwayButton = styled(Button)(({ ordertype, theme }) => ({
  background: ordertype === 'takeAway' ? theme.palette.primary.main : '',
  color: ordertype === 'takeAway' ? '#fff' : '',
  fontSize: '10px',
  lineHeight: 1,
  letterSpacing: 0,
  height: '40px',
  padding: '3px',
  '&:hover': {
    background: theme.palette.primary.hover,
    color: '#fff'
  }
}));

export const PosButton = styled(Button)(({ ordertype, theme }) => ({
  background: ordertype === 'delivery' ? theme.palette.primary.main : '',
  color: ordertype === 'delivery' ? '#fff' : '',
  fontSize: '10px',
  lineHeight: 1,
  letterSpacing: 0,
  padding: '3px',
  height: '40px',
  '&:hover': {
    background: theme.palette.primary.hover,
    color: '#fff'
  }
}));
export const CartEndButton = styled(Button)(() => ({
  fontSize: '12px',
  lineHeight: 1,
  letterSpacing: 0,
  // padding: '3px 3px',
  height: '40px'
}));

export const CartItemBox = styled(Box)(() => ({
  overflowY: 'auto',
  height: '100%',
  // display: 'flex',
  // flexDirection: 'column',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const ModifyCartItemsBox = styled(Box)(() => ({
  // maxHeight: '350px',
  // height: '330px',
  overflowY: 'auto',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

export const ModifyCartItemBox = styled(Box)(() => ({
  // maxHeight: '170px',
  // height: '165px',
  overflowY: 'auto',
  height: '100%',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const ModifyBox = styled(Box)(() => ({
  // maxHeight: '220px',
  // height: '200px',
  overflowY: 'auto',
  height: '100%',
  borderBottom: '1px solid gray',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

// export const CategoryButton = styled(Button)(({ propCat, theme }) => ({
//   fontSize: '10px',
//   height: '40px',
//   backgroundColor:
//     selectedCategory === propCat ? theme.palette.primary.main : '',
//   color: propCat === selectedCategory ? theme.palette.white.main : '',
//   letterSpacing: 0,
//   padding: '3px',
//   '&:hover': {
//     background: theme.palette.primary.hover
//   }
// }));

export const CategoryButton = styled(Button)(
  ({ propCat, theme, selectedCategory }) => ({
    // fontSize: '10px',
    // height: '40px',
    // // backgroundColor:
    // //   selectedCategory === propCat ? theme.palette.primary.main : '',
    // // color: propCat === selectedCategory ? theme.palette.white.main : '',
    // letterSpacing: 0,
    // padding: '3px',
    // '&:hover': {
    //   background: theme.palette.primary.hover,
    //   color: theme.palette.white.main
    // }
    fontSize: '10px',
    height: '40px',
    letterSpacing: 0,
    padding: '3px',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    '&:hover': {
      background: theme.palette.primary.hover,
      color: theme.palette.white.main
    }
  })
);

export const DineInBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around'
  // padding: '10px 0 0 0'
}));

export const CategoryBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0px',
  // marginBottom: '3px',
  overflowX: 'scroll',
  paddingTop: '5px',
  maxWidth: '100%',
  width: '100%',
  gap: '7px',

  '&::-webkit-scrollbar': {
    width: '3px',
    height: '7px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    boxShadow: 'inset 0 0 1px #000',
    borderRadius: '1px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #f1f1f1'
  }
}));

export const SearchBox = styled(Box)(() => ({
  display: 'flex',
  // margin: '5px 0 5px 5px'
  margin: '2px 5px'
}));

export const MenuItemsBox = styled(Box)(() => ({
  height: '100%',
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  display: 'flex',
  flexDirection: 'column'
}));

// orders component section

export const OrdersBox = styled(Box)(() => ({
  // boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  padding: '0 5px',
  textAlign: 'left',

  // maxHeight: '490px',123
  height: '100%',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '3px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f7f7f7',
    boxShadow: 'inset 0 0 5px grey',
    borderRadius: '10px'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#959191',
    borderRadius: '10px',
    border: '2px solid #073c94'
  }
}));

export const SingleOrderBox = styled(Box)(() => ({
  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
  background: '#f1f1f1',
  borderRadius: '7px',
  marginTop: '10px',
  padding: '10px 5px'
}));
export const UpperBox = styled(Box)(({ preorder }) => ({
  cursor: 'pointer',
  transition: '0.2s',
  '&:hover': {
    padding: '3px',
    borderRadius: '5px',
    color: '#fff',
    background: preorder
      ? '#331e48'
      : 'linear-gradient(to bottom right,#403F44, #1E1B32 )'
  }
}));

export const UserSectionBox = styled(Box)(() => ({}));

export const OutletSectionBox = styled(Box)(() => ({
  borderTop: '1px solid #d3cfcf'
}));
export const OrderTypography = styled(Typography)(({}) => ({
  fontSize: '12px'
  // fontSize: theme.typography.fontSize
  // color: 'theme.secondary.dark'
}));

export const OrderStatusBox = styled(Box)(() => ({
  borderTop: '1px solid #d3cfcf'
}));

export const OrdersActionBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  borderTop: '1px solid #d3cfcf',
  paddingTop: '3px'
}));

export const OrdersActionButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '45px',
  width: '45px',
  height: '25px',
  textTransform: 'none',
  fontSize: '12px',
  borderRadius: '10px',
  padding: '10px 25px'
}));

export const CustomerButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '30px',
  width: '30px',
  height: '20px',
  textTransform: 'none',
  fontSize: '13px'
}));

export const CustomerAddButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '25px',
  width: '25px',
  // height: '30px',
  textTransform: 'none'
}));
export const DiscountChargeBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px'
}));
export const DiscountGrandBox = styled(Box)(() => ({
  border: '1px solid gray',
  borderRadius: '20px',
  padding: '0 10px',
  height: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export const DiscountParentBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  // flexDirection: 'row',
  border: '1px solid gray',
  alignItems: 'center',
  borderRadius: '7px',
  gap: '10px'
}));

export const DiscountBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  border: '1px solid gray',
  borderRadius: '20px',
  px: '10px'
}));

export const CatEndButton = styled(Button)(() => ({
  lineHeight: 0,
  letterSpacing: 0,
  minWidth: '80px',
  width: '80px',
  height: '25px',
  borderRadius: '20px',
  textTransform: 'none',
  fontSize: '13px'
}));
export const ModifyButtonBox = styled(Box)(() => ({
  marginTop: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  gap: '10px'
}));

export const SingleOrderTypography = styled(Typography)(() => ({
  fontSize: '14px'
}));

export const InvoiceTypography = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: '600'
}));

export const ButtonsBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const IncrDecrBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}));

export const IncrDecrButton = styled(Button)(() => ({
  height: '25px',
  minWidth: '25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'gainsboro',
  border: 'none',
  borderRadius: '50%',
  '&:hover': {
    boxShadow: '0 2px 2px gray'
  },
  '&:active': {
    boxShadow: 'unset'
  }
}));

export const OrderChip = styled(Chip)(({ theme, param }) => ({
  backgroundColor:
    param === 'way' ? theme.palette.info.light : theme.palette.success.main,
  color: '#fff',
  '&:hover': {
    backgroundColor:
      param === 'way' ? theme.palette.info.dark : theme.palette.success.light
  }
}));

/* eslint-disable no-unused-vars */
import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const PlaceBox = styled(Box)`
  background-color: #614b64;
  color: #fff;
  width: fit-content;
  border-radius: 10px;
  padding: 0 5px;
  text-align: center;
  margin: 0 3px;
  padding: 0 5px;
`;

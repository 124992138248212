import axios from 'axios';
import dashboardUrls from './dashboardUrls';
import { refreshAccessToken } from '../refreshTokenApi';

// here start
const accessToken = localStorage.getItem('accessToken');
// get all running orders API

export const getDashboardReportApi = async (startDate, endDate) => {
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${dashboardUrls.dashboardReport}?${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${dashboardUrls.dashboardReport}?${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

export const getDashboardItemReportApi = async (startDate, endDate) => {
  const startDateParams = `startDate=${startDate}`;
  const endDateParams = `endDate=${endDate}`;
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${dashboardUrls.dashboardItemReport}?${startDateParams}&${endDateParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${dashboardUrls.dashboardItemReport}?${startDateParams}&${endDateParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};
// {{API}}/api/reports/menu-report?startDate=2023-10-01&endDate=2023-10-22

// export const getDashboardReportApi = async (outlet) => {
//     const result = await fetchWithAuth(callCenterUrl.pending, {
//       query: {
//         outlet
//       }
//     });
//     if (result.message) return { error: { data: { message: result.message } } };
//     return { data: result };
//   };


/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  addPaymentMethodApi,
  deletePaymentMethodApi
} from '../../api/paymentMethod/paymentMethodApi';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';

const AddEditPaymentMethod = ({
  addPaymentMethodPopup,
  setAddPaymentMethodPopup,
  callFunc
}) => {
  const dispatch = useDispatch();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    if (addPaymentMethodPopup.id) {
      reset();
    }
  }, [addPaymentMethodPopup.isOpen]);

  const onSubmit = async (data) => {
    if (addPaymentMethodPopup.id) {
    } else {
      const res = await addPaymentMethodApi(data);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'New Payment Method Added',
            type: 'success'
          })
        );
        handleClose();
        callFunc();
      } else {
        dispatch(
          addSnackbar({
            message: res.response.data.message,
            type: 'error'
          })
        );
      }
    }
  };

  const handleClose = () => {
    setAddPaymentMethodPopup({ isOpen: false });
    reset();
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPaymentMethodPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {addPaymentMethodPopup.id ? (
                <Typography color="#FFA500" fontWeight="700">
                  Delete Payment Method
                </Typography>
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Add New Payment Method
                </Typography>
              )}
            </DialogTitle>
            {!addPaymentMethodPopup.id ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('name', { required: true })}
                    margin="normal"
                    label="Payment Method Name"
                    fullWidth
                  />

                  <TextField
                    {...register('tag', { required: true })}
                    margin="normal"
                    label="Tag"
                    fullWidth
                  />

                  <TextField
                    {...register('desc', { required: true })}
                    margin="normal"
                    label="Description"
                    fullWidth
                  />
                  <Box
                    sx={{
                      border: '1px dotted gray',
                      marginX: '25px',
                      borderRadius: '10px',
                      padding: '10px'
                    }}>
                    Over-ride Tax
                    <TextField
                      {...register('rate')}
                      margin="normal"
                      label="Tax Rate"
                      type="number"
                      fullWidth
                    />
                    <FormControlLabel
                      control={<Checkbox {...register('inclusiveTax')} />}
                      label="Inclusive Tax"
                    />
                    <FormControlLabel
                      control={<Checkbox {...register('active')} />}
                      label="Active"
                    />
                  </Box>

                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>
                  Are you sure to delete this Payment Method?
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      const res = await deletePaymentMethodApi(
                        addPaymentMethodPopup?.id
                      );
                      if (res.status == 200) {
                        dispatch(
                          addSnackbar({
                            message: 'Payment Method Deleted',
                            type: 'warning'
                          })
                        );
                        handleClose();
                        callFunc();
                      }
                    }}>
                    Delete
                  </Button>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditPaymentMethod;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { getBannedUsersApi } from '../../api/users/usersApi';
import AddIcon from '@mui/icons-material/Add';
import Loader from '../Loader/Loader';
import { HeaderTableCell } from './Styled-components';
import { Delete } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import AddEditBlockedUser from './AddEditBlockedUser';
import { theme } from '../../theme';

export default function BlockUser() {
  const [addPopup, setAddPopup] = useState({ isOpen: false, id: '' });

  const user = useSelector((state) => state.authorize?.loginResponse?.user);

  const [users, setUsers] = useState([]);

  // calling APi
  const [isLoading, setLoading] = useState(false);

  const getbanned = async () => {
    try {
      setLoading(true);
      const res = await getBannedUsersApi();
      setUsers(res.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // calling API over some data modification to update store
  async function callFunc() {
    getbanned();
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }
  const filteredUsers = users?.filter(
    (user) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => user?.name?.toLowerCase().includes(term)) ||
      user?.name === 'none' ||
      user?.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.email === 'none' ||
      user?.role?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.role?.name === 'none'
  );
  let usersToDisplay = [];
  if (user?.role?.name === 'super') {
    usersToDisplay = filteredUsers;
  } else {
    usersToDisplay = filteredUsers.filter((u) => u?.role?.name !== 'super');
  }

  const paginatedUsersToDisplay = usersToDisplay.slice(
    pg * rpg,
    pg * rpg + rpg
  );

  // handle open Rows
  const [openRows, setOpenRows] = useState(Array(users.length).fill(false));

  const handleRowClick = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  useEffect(() => {
    getbanned();
  }, []);
  return (
    <>
      <>
        <Box
          my={2}
          sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'space-around',
            boxShadow: '0px 0px 2px 1px #cbd5e0',
            margin: '10px'
          }}>
          <TextField
            label="Search BlackListed User"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Typography
            sx={{
              fontSize: '30px',
              fontWeight: 700
            }}>
            BlackListed Users
          </Typography>
          <Tooltip title="Create a User">
            <Button
              variant="contained"
              style={{
                padding: ' 10px 30px'
              }}
              onClick={() => {
                setAddPopup({ isOpen: true, id: '' });
              }}>
              <AddIcon />
              <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                BlackList User
              </Typography>
            </Button>
          </Tooltip>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}></Box>

        {isLoading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper} style={{ maxHeight: '600px' }}>
            <Table size="small" aria-label="collapsible table">
              <TableHead
                sx={{
                  background: theme.palette.primary.main,
                  color: 'white',
                  position: 'sticky',
                  top: 0,
                  zIndex: '1300'
                }}>
                <TableRow>
                  <HeaderTableCell>sr#</HeaderTableCell>
                  <HeaderTableCell>Name</HeaderTableCell>
                  <HeaderTableCell>Phone</HeaderTableCell>
                  <HeaderTableCell>BlackListed By</HeaderTableCell>
                  <HeaderTableCell>BlackList Reason</HeaderTableCell>
                  <HeaderTableCell>Action</HeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: 'auto' }}>
                {paginatedUsersToDisplay.map((singleSet, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{singleSet?.name}</TableCell>
                        <TableCell>{singleSet?.phone}</TableCell>
                        <TableCell>{singleSet?.modifiedBy?.name}</TableCell>
                        <TableCell>{singleSet?.banReason}</TableCell>
                        <TableCell
                          sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            onClick={() => {
                              setAddPopup({
                                isOpen: true,
                                id: singleSet._id,
                                isDelete: true
                              });
                            }}>
                            <Delete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[20, 50, 100]}
                    count={users?.length || 0}
                    rowsPerPage={rpg}
                    page={pg}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <AddEditBlockedUser
          addPopup={addPopup}
          setAddPopup={setAddPopup}
          users={users}
          callFunc={callFunc}
        />
      </>
    </>
  );
}

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const feedbackUrls = {
  feedbackSummaryReport: `${baseURL}/feedbacks/summary`,
  createFeedback: `${baseURL}/feedbacks`,
  getOrderFromInvoice: `${baseURL}/orders/invoice`,
  outLetsRating: `${baseURL}/feedbacks/outLets-rating`
};

export default feedbackUrls;


/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/MenuItemsDuck';
import { getmenuItemsApi } from '../../../api/menuItems/menuItemsApi';

export function* menuItems({ payload }) {
  try {
    const menuItems = yield call(getmenuItemsApi);
    yield put(actions.updateMenuItemsSuccessStatus(true));
    yield put(
      actions.menuItemsResponse({
        response: menuItems
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchMenuItemsSagas() {
  yield takeLatest(actions.menuItemsRequest.type, menuItems);
}

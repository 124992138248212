/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import { getAccessesApi, getRolesApi } from '../../api/roles/rolesApi';
import { Add, Delete, Edit } from '@mui/icons-material';
import { ImageBox } from './Styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { WHITES_CASTLE_IMAGE } from '../Shared/shared';
import { styled } from '@mui/material/styles';
import AddEditRole from './AddEditRole';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  // marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function RoleManagement() {
  const [addPopup, setAddPopup] = useState({ isOpen: false, id: '' });

  const [roles, setRoles] = useState([]);
  const [access, setAccess] = useState([]);

  const getRoles = async () => {
    const res = await getRolesApi();
    setRoles(res.data);
  };

  const getAccess = async () => {
    const res = await getAccessesApi();
    setAccess(res.data);
  };

  useEffect(() => {
    getRoles();
    getAccess();
  }, []);

  const callFunc = () => {
    getRoles();
  };

  const [expanded, setExpanded] = useState([]);

  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const images = [
    {
      name: 'super',
      src: 'https://www.pngitem.com/pimgs/m/227-2270039_roles-and-responsibility-icon-png-download-duties-and.png'
    },
    {
      name: 'admin',
      src: 'https://static.thenounproject.com/png/3324336-200.png'
    },
    {
      name: 'manager',
      src: 'https://banner2.cleanpng.com/20180514/iw/kisspng-computer-icons-download-share-icon-5af96b48e1d7b0.2090780415262953689251.jpg'
    },
    {
      name: 'moneyman',
      src: 'https://cdn2.iconfinder.com/data/icons/homeless-man-beggar/241/beggar-010-512.png'
    },
    {
      name: 'callAgent',
      src: 'https://cdn-icons-png.flaticon.com/512/49/49128.png'
    },
    {
      name: 'waiter',
      src: 'https://cdn2.iconfinder.com/data/icons/food-and-drink-49/100/Waiter-03-512.png'
    },
    {
      name: 'rider',
      src: 'https://cdn1.iconfinder.com/data/icons/logistics-transportation-vehicles/202/logistic-shipping-vehicles-002-512.png'
    },
    {
      name: 'cashier',
      src: 'https://icon-library.com/images/cashier-icon/cashier-icon-17.jpg'
    },
    {
      name: 'dispatcher',
      src: 'https://static.vecteezy.com/system/resources/previews/010/331/734/original/dispatcher-controlling-flight-way-glyph-icon-illustration-vector.jpg'
    },
    {
      name: 'kitchen',
      src: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/chef-1608324-1362780.png'
    },
    {
      name: 'assembler',
      src: 'https://static.thenounproject.com/png/186452-200.png'
    },
    {
      name: 'ufoneCSR',
      src: 'https://cdn-icons-png.flaticon.com/512/49/49128.png'
    },
    {
      name: 'reporter',
      src: 'https://static.thenounproject.com/png/2768578-200.png'
    },
    {
      name: 'orderTaker',
      src: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/taking-order-1482063-1254393.png'
    }
  ];

  return (
    <div>
      <Button
        onClick={() => {
          setAddPopup({
            isOpen: true
          });
        }}>
        <Add />
        Add New Role
      </Button>

      <Grid container>
        {roles?.map((role, index) => {
          return (
            <Grid key={index} my={2} item lg={2} md={4} xs={6}>
              <Card
                sx={{
                  maxWidth: 200,
                  marginInline: 'auto',
                  backgroundColor: '#64CCC5',
                  color: '#fff'
                }}>
                <CardHeader
                  title={
                    <Typography variant="h6">
                      {role?.name.toUpperCase()}
                    </Typography>
                  }
                />
                {/* <CardMedia style={{ height: 120 }}>
                    {images.map((item, i) => (
                      <ImageBox
                        key={i}
                        component="img"
                        alt="The house from the offer."
                        src={
                          role?.name.toLowerCase() === item.name.toLowerCase()
                            ? item.src
                            : WHITES_CASTLE_IMAGE
                        }
                      />
                    ))}
                  </CardMedia> */}
                <CardContent sx={{ padding: '5px' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <IconButton
                      sx={{ color: '#fff' }}
                      onClick={() => {
                        setAddPopup({
                          isOpen: true,
                          id: role._id,
                          isEdit: true
                        });
                      }}>
                      <Edit />
                    </IconButton>

                    <IconButton
                      sx={{ color: '#fff' }}
                      onClick={() => {
                        setAddPopup({
                          isOpen: true,
                          id: role._id,
                          isDelete: true
                        });
                      }}>
                      <Delete />
                    </IconButton>
                  </Box>
                </CardContent>
                <CardActions sx={{ padding: '0' }}>
                  <ExpandMore
                    sx={{
                      color: '#fff'
                    }}
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded[index]}
                    aria-label="show more">
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <CardContent>
                    <ul>
                      {role?.access?.map((acc, i) => (
                        <li key={i}>{acc}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <AddEditRole
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        access={access}
        callFunc={callFunc}
        roles={roles}
      />
    </div>
  );
}

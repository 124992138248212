/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  AddressTypo,
  ItemBox,
  OrderChip,
  OrdersActionButton
} from './Styled-components';
import { extractTime } from '../../Shared/shared';
import {
  getOrderOperationDisplayApi,
  orderAllReadyApi
} from '../../../api/orders/OrdersApi';
import { theme } from '../../../theme';

import {
  Bottom,
  BtnSection,
  Center,
  ContainerSection,
  EndButton,
  ItemSection,
  LineTag1,
  MidlleSection,
  Shot,
  Tag2,
  TakeHading,
  Time2,
  TimeBox,
  Top
} from './Styled-components';

import notificationSound from '../../../assets/car.mp3';

export default function KitchenDisplay() {
  const params = useParams();
  const { code } = params;
  const { operation } = params;

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );
  const screen = useSelector((state) =>
    state.authorize?.loginResponse?.user?.displays?.find(
      (display) => display.name.toLowerCase() === operation
    )
  );
  const [screenOrder, setScreenOrder] = useState([]);

  // comparing prevState with updated one
  const [isNewOrder, setIsNewOrder] = useState(false);

  // call API

  const prevOrderCountRef = useRef(0);

  const displayAPi = async () => {
    const res = await getOrderOperationDisplayApi(outlet._id, screen?._id);
    const newOrders = res.data.filter(
      (order) => !screenOrder.some((o) => o._id === order._id)
    );

    if (newOrders.length > 0 && prevOrderCountRef.current !== res.data.length) {
      setIsNewOrder(true);
    }

    setScreenOrder(res.data);
    setIsLoading(false);
    prevOrderCountRef.current = res.data.length;
  };

  useEffect(() => {
    if (isNewOrder) {
      const audio = new Audio(notificationSound);
      audio.play();
      setIsNewOrder(false);
    }
  }, [isNewOrder]);

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  // calling API after 30 seconds if outo refresh is on

  useEffect(() => {
    displayAPi();

    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        displayAPi();
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [checked]);

  const filteredOrders = screenOrder.filter(
    (order) =>
      !order.items.every((item) => item.choices.every((choice) => choice.ready))
  );

  const [itemBoxStates, setItemBoxStates] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const filteredOrders = screenOrder.filter(
      (order) =>
        !order.items.every((item) =>
          item.choices.every((choice) => choice.ready)
        )
    );
    setItemBoxStates(Array(filteredOrders.length).fill(false));
  }, [screenOrder]);

  const orderAllReady = async (orderId, screenId, index) => {
    setIsLoading(true);
    const res = await orderAllReadyApi(orderId, screenId);
    if (res.status == 200) {
      const newItemBoxStates = [...itemBoxStates];
      newItemBoxStates[index] = true;
      setItemBoxStates(newItemBoxStates);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const [orderNumber, setOrderNumber] = useState('');
  const [orderDone, setOrderDone] = useState(false);
  const handleKeyDown = (event) => {
    const { key } = event;
    if (key >= '0' && key <= '9') {
      setOrderNumber((prevOrderNumber) => prevOrderNumber + key);
    } else if (key == 'Enter') {
      setOrderDone(true);
    } else if (key == '.') setOrderNumber('');
    else if (key == '/') setChecked((checked) => !checked);
    else if (key == '-') window.scrollBy(0, -30);
    else if (key == '+') window.scrollBy(0, 30);
  };
  useEffect(() => {
    if (filteredOrders.length < orderNumber) setOrderNumber('');
  }, [orderNumber]);
  useEffect(() => {
    if (orderDone) {
      if (!isNaN(orderNumber))
        orderAllReady(
          filteredOrders[orderNumber - 1]?._id,
          screen?._id,
          orderNumber - 1
        );
      setOrderNumber('');
      setOrderDone(false);
    }
  }, [orderDone]);
  useEffect(() => {
    const handleDocumentKeyDown = (event) => {
      handleKeyDown(event);
    };
    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  return (
    <>
      <Box boxShadow="0px 0px 2px 1px #cbd5e0" margin="10px">
        <Typography variant="h5" component="h4" color="green">
          Display Screen : {operation.toUpperCase()}
        </Typography>
        <FormControlLabel
          label="Auto Refresh Orders"
          control={<Switch checked={checked} onChange={handleToggle} />}
        />
      </Box>

      <Grid container spacing={2}>
        {filteredOrders.map((order, index) => {
          return (
            <Grid key={index} item lg={3} md={3} xs={6}>
              <ContainerSection
                sx={{
                  border: orderNumber - 1 == index ? '5px solid black' : ''
                }}
                itemBoxStates={itemBoxStates[index]}>
                <Top
                  sx={{
                    background:
                      orderNumber - 1 == index
                        ? theme.palette.primary.main
                        : 'linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7)',
                    color: theme.palette.white.light
                  }}>
                  <TimeBox>
                    <h5>
                      #{order?.invoiceId || order._id.slice(-5).toUpperCase()}
                    </h5>
                    <h3></h3>
                    <Time2>
                      <span>{extractTime(order.createdAt)}</span>
                      {order.customerName && (
                        <span>
                          <h4 style={{ margin: 0 }}> {order.customerName}</h4>
                        </span>
                      )}
                    </Time2>
                    {order?.orderType == 'dineIn' && (
                      <>
                        <AddressTypo>Table:{order?.tableName}</AddressTypo>
                        <AddressTypo>Waiter:{order?.carrier?.name}</AddressTypo>
                      </>
                    )}
                  </TimeBox>
                  <TakeHading>{order.orderType.toUpperCase()}</TakeHading>
                  <LineTag1 />
                </Top>
                {order.items.map((item, itemIndex) => {
                  return item.choices.map((choice, choiceIndex) => {
                    const modified = item.modified ? true : false;
                    const deleted = item.active ? false : true;
                    const prepared = choice.ready ? true : false;
                    const preparedDeleted = prepared && deleted;
                    const modifiedPrepared = modified && prepared;
                    const ItemModified =
                      item.createdAt.substring(0, 18) !==
                      order.createdAt.substring(0, 18);

                    return (
                      <>
                        <Center
                          key={choiceIndex}
                          sx={{
                            backgroundColor: preparedDeleted
                              ? '#E91E63' // pink
                              : deleted
                              ? '#F44336' // red
                              : modifiedPrepared
                              ? '#8BC34A' // lightgreen
                              : ItemModified
                              ? '#FFC107' // yellow
                              : prepared
                              ? '#8BC34A' // green
                              : ''
                          }}>
                          {!choice.parentChoice ? <Tag2 /> : ''}
                          <MidlleSection>
                            <ItemSection>
                              {!choice.parentChoice ? choice.optionItem : ''}
                              {!choice.parentChoice ? (
                                <b>X {`(${item.quantity})`}</b>
                              ) : (
                                ''
                              )}
                              <Shot>
                                {choice.parentChoice
                                  ? `↳${choice.optionItem}`
                                  : ''}
                              </Shot>

                              {!choice.parentChoice && item.note && (
                                <Tooltip title={item.note}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      border: '1px solid',
                                      borderRadius: '7px',
                                      padding: '0 5px'
                                    }}>
                                    <Typography fontWeight="600">
                                      {' '}
                                      Note:
                                    </Typography>
                                    <Typography sx={{ fontWeight: '400' }}>
                                      {item.note}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              )}
                            </ItemSection>
                          </MidlleSection>
                        </Center>
                      </>
                    );
                  });
                })}
                {order?.deliveryNote && (
                  <>
                    <b>Order Note:</b> {order?.deliveryNote}
                  </>
                )}
                <Bottom>
                  <BtnSection>
                    <p>Total Items : {order?.items.length}</p>
                    <EndButton
                      variant="contained"
                      disabled={itemBoxStates[index]}
                      onClick={() => {
                        orderAllReady(order?._id, screen?._id, index);
                      }}>
                      Ready
                    </EndButton>
                  </BtnSection>
                </Bottom>
              </ContainerSection>
            </Grid>
          );
        })}
        {/* {filteredOrders.map((order, index) => {
          return (
            <>
              <Grid key={index} item lg={3} md={3} xs={6}>
                <ItemBox itemBoxStates={itemBoxStates[index]}>
                  <Box
                    sx={{
                      background:
                        orderNumber - 1 == index
                          ? theme.palette.primary.main
                          : theme.palette.secondary.main,
                      color: theme.palette.white.light
                    }}>
                    <Typography>
                      <b>OrderType:</b> {order.orderType}
                    </Typography>
                    <Typography>
                      <b>Order Id:</b>
                      {order?.invoiceId || order._id.slice(-5).toUpperCase()}
                    </Typography>
                    <Typography>
                      <b>Cust Name</b> : {order.customerName}
                    </Typography>
                    <Typography>
                      <b>Punch Time</b> : {extractTime(order.createdAt)}{' '}
                    </Typography>
                  </Box>

                  <Box sx={{ maxHeight: '60%' }}>
                    {order.items.map((item, itemIndex) => {
                      return item.choices.map((choice, choiceIndex) => {
                        const modified = item.modified ? true : false;
                        const deleted = item.active ? false : true;
                        const prepared = choice.ready ? true : false;
                        const preparedDeleted = prepared && deleted;
                        const modifiedPrepared = modified && prepared;

                        return (
                          <Box
                            key={choiceIndex}
                            sx={{
                              border: '1px solid gray',
                              my: '1px',
                              backgroundColor: preparedDeleted
                                ? '#900D09'
                                : deleted
                                ? '#E3242B'
                                : modifiedPrepared
                                ? 'green'
                                : modified
                                ? 'yellow'
                                : prepared
                                ? 'green'
                                : ''
                            }}>
                            {(choice.parentChoice ? ' ↳   ' : '') +
                              choice.optionItem}
                            <b>X</b> {`(${item.quantity})`} <br />
                            {choice.parentChoice ? '' : <i>{item.note} </i>}
                          </Box>
                        );
                      });
                    })}
                  </Box>
                  {order?.deliveryNote && (
                    <>
                      <b>Order Note:</b> {order?.deliveryNote}
                    </>
                  )}
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      orderAllReady(order?._id, screen?._id, index);
                    }}>
                    Order Ready
                  </OrdersActionButton>
                </ItemBox>
              </Grid>
            </>
          );
        })} */}
      </Grid>
    </>
  );
}

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getInventoryReportApi } from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import { getCurrentDate } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';

export default function InventoryReport() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [inventoryReport, setInventoryReport] = useState([]);

  // handling date
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState();

  // const handleDateChange = (event, isStartDate) => {
  //   const selectedDate = event.target.value;
  //   if (isStartDate) {
  //     setStartDate(selectedDate);
  //   } else {
  //     setEndDate(selectedDate);
  //   }
  // };

  // useEffect(() => {
  //   const currentDate = getCurrentDate();
  //   setStartDate(currentDate);
  //   setEndDate(currentDate);
  //   reportApi();
  // }, []);

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getInventoryReportApi(
        outlet?._id,
        datePopup?.startDate,
        datePopup?.endDate
      );
      setInventoryReport(response?.data);
      setLoading(false);
      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = inventoryReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.ingredient?.name?.toLowerCase().includes(term) ||
          report?.ingredient?.unit?.name?.toLowerCase().includes(term)
        );
      })
  );

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <div>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Box sx={{ textAlign: 'start' }}>
          <Typography>Search Inventory by Item Name or Unit</Typography>
          <TextField
            label="Search Inventory"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Inventory Report
        </Typography>

        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
          <Table size="small">
            <TableHead
              sx={{
                background: '#8CC043',
                color: 'white',
                position: 'sticky',
                top: 0
              }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Opening</TableCell>
                <TableCell>Purchases</TableCell>
                <TableCell>Wastes</TableCell>
                <TableCell>Transfer IN/OUT</TableCell>
                <TableCell>Usage</TableCell>
                <TableCell>Computed Stocks</TableCell>

                <TableCell>Actual Stocks</TableCell>
                <TableCell>Diffrence</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflow: 'auto'
              }}>
              {filteredOrders
                ?.slice(pg * rpg, pg * rpg + rpg)
                .map((singleSet, index) => {
                  const transferInOut =
                    singleSet?.transfersIn - singleSet?.transfersOut;

                  const projected =
                    singleSet?.opening +
                    singleSet?.purchases +
                    transferInOut -
                    singleSet?.wastes -
                    singleSet?.consumption;
                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          '&:hover': {
                            backgroundColor: '#8CC043a5',
                            cursor: 'pointer',
                            transition: '0.4s'
                          }
                        }}>
                        <TableCell>{singleSet.ingredient.name}</TableCell>
                        <TableCell>{singleSet.ingredient.unit.name}</TableCell>
                        <TableCell>{singleSet?.opening}</TableCell>
                        <TableCell>{singleSet?.purchases}</TableCell>
                        <TableCell>{Math.round(singleSet?.wastes)}</TableCell>
                        <TableCell>{transferInOut}</TableCell>
                        <TableCell>
                          {Math.round(singleSet?.consumption)}
                        </TableCell>
                        <TableCell>{Math.round(projected)}</TableCell>
                        <TableCell>{Math.round(singleSet?.closing)}</TableCell>
                        <TableCell>
                          {Math.round(singleSet?.closing - projected)}
                        </TableCell>
                        <TableCell>{Math.round(singleSet?.value)}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

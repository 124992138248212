/* eslint-disable react/prop-types */
import { Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ route, literal, Icon, selected, onClick }) => {
  const link = (
    <Tooltip title={literal} placement="right-end">
      <ListItem
        button
        selected={selected}
        sx={{
          '&.Mui-selected': {
            backgroundColor: process.env.REACT_APP_PRIMARY_COLOR,
            color: 'common.white',
            // borderRight: `2px solid ${process.env.REACT_APP_SECONDARY_COLOR}`,
            borderLeft: `3px solid ${process.env.REACT_APP_SECONDARY_COLOR}`,
            borderRadius: '12px'
          },
          '&:hover': {
            backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
            borderRadius: '10px',
            color: 'common.white'
          }
        }}
        onClick={onClick}>
        <ListItemIcon
          sx={[
            { minWidth: 'auto' },
            (theme) => ({
              paddingRight: theme.spacing(2)
            })
          ]}>
          <Icon sx={{ color: '#fff' }} />
        </ListItemIcon>
        <ListItemText textDecoration="none" primary={literal} />
      </ListItem>
    </Tooltip>
  );
  return route ? <Link to={route}>{link}</Link> : link;
};

export default MenuItem;

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const rolesUrls = {
  roles: `${baseURL}/roles`,
  access: `${baseURL}/roles/access`
};

export default rolesUrls;

// {{API}}/api/roles/:id

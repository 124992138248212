import React from 'react';
import { Box, Typography, Grid, CircularProgress, Rating } from '@mui/material';

const ReviewSection = ({
  totalMonthFeedbacksSum,
  totalYearlyFeedbacksSum,
  loading,
  yearlyAvg,
  monthlyAvg
}) => {
  const isDataLoaded =
    !loading && totalMonthFeedbacksSum !== 0 && totalYearlyFeedbacksSum !== 0;

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'center'
      }}>
      <Grid container spacing={2} sx={{ width: '98%', mt: 5, mb: 2 }}>
        <Grid item xs={12} sm={6}>
          <Box sx={reviewSummaryStyle}>
            {loading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}>
                <CircularProgress />
              </Box>
            ) : !isDataLoaded ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  color: 'gray'
                }}>
                <Typography>Select Date to see Month Reviews</Typography>
              </Box>
            ) : (
              <>
                <Box sx={detailsStyle}>
                  <Typography variant="h5" component="h3">
                    Month Reviews
                  </Typography>
                  <Typography variant="body1">
                    Growth in reviews on this month
                  </Typography>
                </Box>
                <Box sx={ratingStyle}>
                  <Rating
                    size="lg"
                    precision={0.5}
                    value={monthlyAvg || 0}
                    readOnly
                  />
                  <Typography variant="body1" sx={countStyle}>
                    {totalMonthFeedbacksSum || 0}
                    {/* {monthlyAvg.toFixed(2) || 0}) */}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={reviewSummaryStyle}>
            {loading ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}>
                <CircularProgress />
              </Box>
            ) : !isDataLoaded ? (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  color: 'gray'
                }}>
                <Typography>Select Date to see Yearly Reviews</Typography>
              </Box>
            ) : (
              <>
                <Box sx={detailsStyle}>
                  <Typography variant="h5" component="h3">
                    Yearly Reviews
                  </Typography>
                  <Typography variant="body1">
                    Growth in reviews on this year
                  </Typography>
                </Box>
                <Box sx={ratingStyle}>
                  <Rating
                    size="lg"
                    precision={0.5}
                    value={yearlyAvg || 0}
                    // value={3}
                    readOnly
                  />
                  <Typography variant="body1" sx={countStyle}>
                    {/* {yearlyAvg || 0} */}
                    {totalYearlyFeedbacksSum || 0}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

// Define styles using MUI's `sx` prop
const reviewSummaryStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  border: '1px solid #ccc',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};

const detailsStyle = {
  marginRight: '1rem'
};

const ratingStyle = {
  display: 'flex',
  alignItems: 'center'
};

const starStyle = {
  color: '#FFD700', // Gold color for the stars
  marginRight: '0.25rem'
};

const countStyle = {
  marginLeft: '0.5rem',
  fontWeight: 'bold',
  fontSize: '1.2rem'
};

export default ReviewSection;


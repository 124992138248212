/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitButton, TextFields } from './Styled-components';
import swal from 'sweetalert';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { addIngredientApi } from '../../api/ingredients/ingredientsApi';

const AddIngredients = ({
  addPopup,
  setAddPopup,
  callFunc,
  ingredientCategories,
  ingredientUnits
}) => {
  const { register } = useForm();

  const [name, setName] = useState('');
  const [itemValue, setItemValue] = useState('');
  const [alertQuantity, setAlertQuantity] = useState('');

  // drop down Category
  const [category, setCategory] = React.useState('');
  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };
  // drop down Category

  // drop down unit
  const [unit, setUnit] = React.useState('');

  const handleChangeUnit = (event) => {
    setUnit(event.target.value);
  };
  // drop down unit

  const handleClose = () => {
    setAddPopup({ isOpen: false });
    clearForm();
  };

  const clearForm = () => {
    setName('');
    setItemValue('');
    setAlertQuantity('');
    setCategory('');
    setUnit('');
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const formData = {
      name: data.get('item-name'),
      value: data.get('itemValue'),
      alertQuantity: data.get('alert-quantity'),
      category: data.get('select-category'),
      unit: data.get('select-unit')
    };
    try {
      const response = await addIngredientApi(formData);
      if (response.status == 200) {
        swal({
          title: response?.statusText
        });
        callFunc();
      }
    } catch (error) {
      console.error(error);
    }

    handleClose();
    clearForm();
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              <Typography color="#6d1b7b" fontWeight="700">
                Add Ingredient
              </Typography>
            </DialogTitle>
            <DialogContent>
              <form onSubmit={(e) => handleSubmitForm(e)}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                  <TextFields
                    type="text"
                    placeholder="Enter Item Name"
                    {...register('itemName')}
                    name="item-name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    fullWidth
                    required
                  />

                  <FormControl sx={{ m: 1, width: '100%' }} size="large">
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      {...register('select-category')}
                      label="Category"
                      onChange={handleChangeCategory}>
                      {ingredientCategories?.data.map((cat, index) => (
                        <MenuItem key={index} value={cat._id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ m: 1, width: '100%' }} size="large">
                    <InputLabel>Unit</InputLabel>
                    <Select
                      {...register('select-unit')}
                      value={unit}
                      label="Unit"
                      onChange={handleChangeUnit}>
                      {ingredientUnits?.map((unitItem, index) => (
                        <MenuItem key={index} value={unitItem._id}>
                          {unitItem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextFields
                    type="number"
                    placeholder="Enter Value"
                    {...register('itemValue')}
                    onChange={(e) => setItemValue(e.target.value)}
                    value={itemValue}
                    fullWidth
                    required
                  />

                  <TextFields
                    type="number"
                    placeholder="Enter Alert Quantity"
                    {...register('alert-quantity')}
                    onChange={(e) => setAlertQuantity(e.target.value)}
                    value={alertQuantity}
                    fullWidth
                    required
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px',
                    width: '100%'
                  }}>
                  <SubmitButton type="submit">Add Ingredient</SubmitButton>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddIngredients;

import { Snackbar, Alert } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';

const MySnackbar = () => {
  const snackbar = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = (_, reason) =>
    reason !== 'clickaway' && dispatch(clearSnackbar());

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar.timeout}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert variant="filled" onClose={handleClose} severity={snackbar.type}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};
export default MySnackbar;

import React, { useEffect, useState } from 'react';
import { fetchWithAuth } from '../../api/refreshTokenApi';
import { getApi } from '../Shared/shared';
import { CircularProgress } from '@mui/material';

const yesterday = new Date();

yesterday.setDate(yesterday.getDate() - 1);
export default function OutletsDetailedReport() {
  const [date, setData] = useState(yesterday.toISOString().slice(0, 10));
  const [detailedReport, setDetailedReport] = useState('');
  const [loader, setLoader] = useState(false);


  const ChangeDate = (e) => {
    setData(e.target.value);
  };
  const getDate = async (date) => {
    setLoader(true);
    const response = await fetchWithAuth(
      getApi() + '/reports/useless-summary?startDate=' + date,
      { response: 'text' }
    );
    setLoader(false);
    setDetailedReport(response);
  };
  useEffect(() => {
    getDate(date);
  }, [date]);

  return (
    <>
      <div>
        <input type="date" value={date} onChange={ChangeDate} />
        {loader ? (
          <div>
            <CircularProgress size={24}/>
          </div>
        ) : (
          <div>
            <textarea width="1000" value={detailedReport} />
          </div>
        )}
      </div>
    </>
  );
}


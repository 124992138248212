/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getInventoryHistoryIngredientsApi } from '../../../api/stocks/inventoryController/inventoryContollerApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

export default function InventoryHistoryIngredients() {
  const params = useParams();
  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const saleDate = obj.date; // getting date from params

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [inventoryHistoryIngredients, setInventoryHistoryIngredients] =
    useState();

  const getInventoryHistoryIngredients = async () => {
    const res = await getInventoryHistoryIngredientsApi(outlet?._id, saleDate);
    setInventoryHistoryIngredients(res?.data);
  };

  useEffect(() => {
    getInventoryHistoryIngredients();
  }, []);

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = inventoryHistoryIngredients?.ingredients?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) =>
          report?.ingredient?.name?.toLowerCase().includes(term)
        ) || report?.ingredient?.name === 'none'
  );
  return (
    <>
      <Box sx={{ display: 'flex', gap: '10px', margin: '10px 0 0 20px' }}>
        <Box>
          <Typography>Search Inventory by Item name</Typography>
          <TextField
            label="Search Inventory"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Box>
        <Box>
          <Typography>
            <b>From Date:</b> {inventoryHistoryIngredients?.fromDate}
          </Typography>
          <Typography>
            <b> Sale Date:</b> {inventoryHistoryIngredients?.saleDate}
          </Typography>
          <Typography>
            <b>Description: </b> {inventoryHistoryIngredients?.desc}
          </Typography>
        </Box>
      </Box>
      <TableContainer component={Paper} style={{ maxHeight: '700px' }}>
        <Table size="small" aria-label="collapsible table">
          <TableHead
            sx={{
              background: '#8CC043',
              color: 'white',
              position: 'sticky',
              top: 0
            }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Opening</TableCell>
              <TableCell>Purchases</TableCell>
              <TableCell>Wastes</TableCell>
              <TableCell>Transfer IN/OUT</TableCell>
              <TableCell>Usage</TableCell>
              <TableCell>Computed Stocks</TableCell>
              <TableCell>Actual Stocks</TableCell>
              <TableCell>Diffrence</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              overflow: 'auto'
            }}>
            {filteredOrders?.map((singleSet, index) => {
              const transferInOut =
                singleSet?.transfersIn - singleSet?.transfersOut;

              const projected =
                singleSet?.opening +
                singleSet?.purchases +
                transferInOut -
                (singleSet?.wastes || 0) -
                singleSet?.consumption;
              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      '&:hover': {
                        backgroundColor: '#8CC043a2',
                        cursor: 'pointer',
                        transition: '0.4s'
                      }
                    }}>
                    <TableCell>{singleSet?.ingredient?.name}</TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{singleSet?.ingredient?.unit?.name}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{singleSet?.opening}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{singleSet?.purchases}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{singleSet?.wastes}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{transferInOut}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{Math.round(singleSet?.consumption)}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{Math.round(projected)}</p>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{singleSet?.closing}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{Math.round(singleSet?.closing - projected)}</p>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip title={singleSet?.ingredient?.name}>
                        <p>{Math.round(singleSet?.value)}</p>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

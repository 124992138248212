/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  addCustomerAddressApi,
  addCustomerApi,
  addCustomerContactApi,
  deleteCustomerAddressApi,
  deleteCustomerContactApi,
  editCustomerAddressApi,
  getCustomerInfoApi,
  searchCustomerApi,
  updateCustomerNameApi
} from '../../../api/customer/customerApi';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAddressAndArea,
  setCustomer,
  setCustomerContact,
  setCustomerName,
  setAlternativeContact,
  setDeliveryAddress
} from '../../../redux/reducers/ducks/CartDuck';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import { getAreasApi } from '../../../api/areas/areaAPi';
import { CustomerAddButton, CustomerButton } from '../../POS/Styled-components';
import { Delete, DeleteForever } from '@mui/icons-material';
import swal from 'sweetalert';
import { inTimings } from '../../Shared/shared';

const filter = createFilterOptions();

export default function CustomerSearch({ handleChangeOutlet, outlets }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, control } = useForm();
  const cart = useSelector((state) => state.cart);
  const { customerName, customer: customerId } = cart;

  const [trigger, setTrigger] = useState(false);
  const [custData, setCustData] = useState([]);

  const [searchNum, setSearchNum] = useState('');

  const [addressId, setAddressId] = useState('');

  // getting areas API
  const [areas, setAreas] = useState([]);
  const getAreas = async () => {
    const res = await getAreasApi();
    setAreas(res.data);
  };

  // getting customer Information
  const [custInfo, setCustInfo] = useState('');

  const getCustomerInfo = async (autoSelect = true) => {
    const res = await getCustomerInfoApi(customerId);
    setCustInfo(res.data);

    if (res.status == 200) {
    } else {
      swal('User Blocked', `${res.response.data.message}`, 'error');
    }
    dispatch(setCustomerName(res?.data?.name));
    reset();
    if (!autoSelect) return;
    const info = res?.data?.addresses[0];
    if (info)
      dispatch(
        setDeliveryAddress(
          `${info.house}, ${info.street}, ${info.area.name}, ${info.area.city}`
        )
      );
    dispatch(setCustomerContact(res?.data?.contacts[0]?.phone));
    dispatch(setAlternativeContact(res?.data?.contacts[1]?.phone) || '');
  };

  // Search customer Api
  const searchCustomer = async () => {
    // let phoneNumber;
    // if (searchNum.startsWith('+92') || searchNum.startsWith('0092')) {
    //   phoneNumber = '0' + searchNum.slice(3);
    // }
    const res = await searchCustomerApi(searchNum);
    setCustData(res.data);
  };

  //  get address Outlet
  const getAddressOutlet = (area) => {
    const myOutlets = (outlets || [])
      .filter(
        (outlet) =>
          !outlet.closed &&
          (!outlet.timeExclusive || inTimings(outlet.timings || []))
      )
      .map((outlet) => outlet._id);
    const outlet = area?.outlets
      .sort((o1, o2) => o2.priority - o1.priority)
      .map((outlets) => outlets.outlet)
      .filter((outlet) => myOutlets.includes(outlet))[0];

    return outlet;
  };
  //  get address Outlet

  const [value, setFormControlValue] = useState(null);
  const [open, toggleOpen] = useState(false);

  const handleClose = () => {
    setDialogValue({
      phone: '',
      name: ''
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = useState({
    phone: '',
    name: ''
  });

  const handleSubmission = async (event) => {
    event.preventDefault();
    const currentValue = {
      phone: dialogValue.phone,
      name: dialogValue.name
    };
    setFormControlValue(currentValue);

    // Add customer API
    const res = await addCustomerApi(currentValue);
    if (res.status == 200) {
      dispatch(setCustomer(res.data._id));
      handleClose();
    }
  };

  // Add, delete, and Edit customer Address popup, API and textField along with handlechange
  const [addAddressPopUp, setAddAddressPopUp] = useState({
    isOpen: false,
    id: '',
    delete: false
  });

  const handleAddAddressClose = () => {
    setAddAddressPopUp({ isOpen: false });
    reset({ address: '' });
  };

  const onSubmit = async (data) => {
    if (addAddressPopUp.id) {
      const res = await editCustomerAddressApi(customerId, addressId, data);
      if (res.status == 200) {
        const info = res.data;

        reset();
        getCustomerInfo(false);
        handleAddAddressClose();

        setAddressId(info._id);
        // const area = areas.find((area) => area._id == info.area);
        const area = info.area;
        const address = `${info.house}, ${info.street}, ${area?.name} ${area?.city}`;
        dispatch(
          setAddressAndArea({
            address: address,
            area: info?.area?._id
          })
        );

        const outletId = getAddressOutlet(info.area);

        // const outletId = area?.outlets
        //   .sort((a, b) => a < b)
        //   .filter((outlet) => !outlet.outlet.closed)[0]?.outlet?._id;
        if (outletId && handleChangeOutlet) handleChangeOutlet(outletId);
      }
    } else {
      const res = await addCustomerAddressApi(customerId, data);
      if (res.status == 200) {
        const info = res.data;

        reset();
        getCustomerInfo(false);
        handleAddAddressClose();

        setAddressId(info._id);
        const area = info.area;
        const address = `${info.house}, ${info.street}, ${area?.name} ${area?.city}`;
        dispatch(
          setAddressAndArea({
            address: address,
            area: info?.area?._id
          })
        );

        const outletId = getAddressOutlet(info.area);
        if (outletId && handleChangeOutlet) handleChangeOutlet(outletId);
      }
    }
  };
  // deleting Customer Address
  const deleteCustomerData = async () => {
    const res = await deleteCustomerAddressApi(customerId, addressId);
    if (res.status == 200) {
      getCustomerInfo(false);
      handleAddAddressClose();
      dispatch(
        addSnackbar({
          message: 'Customer Address deleted',
          type: 'warning'
        })
      );
    }
  };

  // change name popup , API and textField along with handlechange
  const [changeNamePopUp, setChangeNamePopUp] = useState(false);
  const [changeName, setChangeName] = useState('');
  const handleChangeName = (event) => {
    setChangeName(event.target.value);
  };
  const updateName = async () => {
    const nameData = { name: changeName };
    const res = await updateCustomerNameApi(customerId, nameData);
    if (res.status == 200) {
      handleChangeNameClose();
      getCustomerInfo(false);
      dispatch(
        addSnackbar({
          message: 'Name Updated',
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: res?.response?.data?.message,
          type: 'error'
        })
      );
    }
  };
  const handleChangeNameClose = () => {
    setChangeNamePopUp(false);
    setChangeName('');
  };

  // Add a new COntact popup, API and textField along with handlechange
  const [addContactPopUp, setAddContactPopUp] = useState({
    isOpen: false,
    id: ''
  });

  const handleAddContactClose = () => {
    setAddContact('');
    setAddContactPopUp({ isOpen: false });
  };

  const [addContact, setAddContact] = useState('');
  const [isError, setIsError] = useState(false);

  const handleChangeAddContact = (event) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/\D/g, '');

    // Check if the numeric value has exactly 11 digits
    if (numericValue.length === 11) {
      setAddContact(numericValue);
      setIsError(false); // Clear the error state
    } else {
      setAddContact(inputValue);
      setIsError(true); // Set the error state
    }
  };

  //  getting selected contact Id from second contact
  const contactId = custInfo?.contacts?.find(
    (contact) => contact?.phone === cart?.alternativeContact
  )?._id;

  // deleting contact
  const deleteContact = async () => {
    const res = await deleteCustomerContactApi(customerId, contactId);
    if (res.status == 200) {
      getCustomerInfo(false);
      handleAddContactClose();
      dispatch(
        addSnackbar({
          message: 'Contact Deleted',
          type: 'success'
        })
      );
    }
  };

  const addNewContact = async () => {
    const contactData = {
      contact: { phone: addContact }
    };
    const res = await addCustomerContactApi(customerId, contactData);
    // todo select contact
    if (res.status == 200) {
      getCustomerInfo(false);
      handleAddContactClose();
      dispatch(
        addSnackbar({
          message: 'New Contact Added',
          type: 'success'
        })
      );
    }
  };

  // phone number error validation
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  // edit Address popup , API and textField along with handlechange

  const selectedAddressObject = custInfo?.addresses?.find((address) => {
    return address?._id === addressId;
  });
  const toEditAddress = {
    ...selectedAddressObject,
    area: selectedAddressObject?.area?._id
  };

  useEffect(() => {
    if (searchNum.length <= 5) {
      setTrigger(false);
    } else {
      setTrigger(true);
    }

    reset();
    if (customerId) {
      getCustomerInfo();
    }
    if (trigger == true) {
      searchCustomer();
    }
  }, [trigger, customerId, searchNum]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '10px'
        }}>
        <Box
          sx={{
            display: 'flex',
            width: customerId ? '48%' : '100%'
          }}>
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => {
              if (newValue?._id) {
                dispatch(setCustomer(newValue._id));
              }
              if (typeof newValue === 'string') {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  toggleOpen(true);
                  setDialogValue({
                    phone: newValue.replace(/^\+92/, '0'), // Replace +92 with 0 at the beginning
                    name: ''
                  });
                });
              } else if (newValue && newValue.inputValue) {
                toggleOpen(true);
                setDialogValue({
                  phone: newValue.inputValue.replace(/^\+92/, '0'), // Replace +92 with 0 at the beginning
                  name: ''
                });
              } else {
                setFormControlValue(newValue);
              }
            }}
            // filterOptions={(options, params) => {
            //   const filtered = filter(options, params);

            //   if (params.inputValue !== '') {
            //     const formattedInputValue = params.inputValue.replace(
            //       /^\+92/,
            //       '0'
            //     ); // Replace +92 with 0 at the beginning
            //     if (formattedInputValue.length <= 11) {
            //       // Only add the option if it has 11 digits
            //       filtered.push({
            //         inputValue: formattedInputValue,
            //         phone: `Add "${formattedInputValue}"`
            //       });
            //     }
            //   }

            //   return filtered;
            // }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                const formattedInputValue = params.inputValue.replace(
                  /^\+92/,
                  '0'
                ); // Replace +92 with 0 at the beginning

                if (formattedInputValue.length <= 11) {
                  // Check if the phone number already exists in the list than dont show add option

                  let fullNumber;
                  if (formattedInputValue.length === 11) {
                    fullNumber = formattedInputValue;
                  }
                  const isNumberExists = filtered
                    ?.map((num) => num.phone)
                    .includes(fullNumber);

                  // Only add the option if it has 11 digits and does not already exist in the list
                  if (!isNumberExists) {
                    filtered.push({
                      inputValue: formattedInputValue,
                      phone: `Add "${formattedInputValue}"`
                    });
                  }
                }
              }

              return filtered;
            }}
            id="free-solo-dialog-demo"
            options={custData}
            getOptionLabel={(option) => {
              // e.g value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.phone;
            }}
            selectOnFocus
            // clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option) => (
              <li key={option.phone} {...props}>
                {option.phone}
              </li>
            )}
            sx={{ width: '100%' }}
            freeSolo
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Customer Search"
                onInput={(event) => setSearchNum(event.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '10px'
                  }
                }}
              />
            )}
          />
        </Box>
        {customerId && (
          <Box sx={{ width: '48%', display: 'flex', gap: '5px' }}>
            <TextField
              sx={{
                marginLeft: '2px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px'
                }
              }}
              size="small"
              fullWidth
              label="Customer Name"
              value={customerName}
            />

            <CustomerAddButton
              onClick={() => {
                setChangeNamePopUp(true);
                setChangeName(customerName);
              }}
              variant="contained">
              <EditOutlinedIcon />
            </CustomerAddButton>
          </Box>
        )}
      </Box>

      {customerId && (
        <>
          <Box
            sx={{
              margin: '10px 0',
              display: 'flex',
              width: '100%',
              gap: '10px'
            }}>
            <Box sx={{ width: '48%' }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="contacts-label">Contact</InputLabel>
                <Select
                  inputProps={{
                    style: { padding: 4 }
                  }}
                  style={{
                    borderRadius: '10px'
                  }}
                  label="Contacts"
                  size="small"
                  value={cart.customerContact || ''}
                  onChange={(event) =>
                    dispatch(setCustomerContact(event.target.value))
                  }>
                  {custInfo?.contacts?.map((info, index) => (
                    <MenuItem key={index} value={info.phone || ''}>
                      {info.phone || ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '48%', display: 'flex' }}>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="contacts-label">Second Contact</InputLabel>
                <Select
                  inputProps={{
                    style: { padding: 4 }
                  }}
                  style={{
                    borderRadius: '10px'
                  }}
                  label="Contacts"
                  size="small"
                  value={cart.alternativeContact || ''}
                  onChange={(event) =>
                    dispatch(setAlternativeContact(event.target.value))
                  }>
                  {/* ?.filter(
                       (contact) => !cart.customerContact.includes(contact)
                     ) */}
                  {custInfo?.contacts?.map((info, index) => (
                    <MenuItem key={index} value={info.phone || ''}>
                      {info.phone || ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                  marginLeft: '5px'
                }}>
                <Tooltip title="Add New Contact">
                  <CustomerButton
                    sx={{ marginLeft: '2px' }}
                    variant="contained"
                    onClick={() => {
                      setAddContactPopUp({ isOpen: true, id: '' });
                    }}>
                    <AddIcon sx={{ fontSize: '20px', fontWeight: '800' }} />
                  </CustomerButton>
                </Tooltip>
                <Tooltip title="Delete Second Contact">
                  <CustomerButton
                    sx={{ marginLeft: '2px' }}
                    variant="contained"
                    onClick={() => {
                      setAddContactPopUp({ isOpen: true, id: 'customerId' });
                    }}>
                    <Delete sx={{ fontSize: '17px' }} />
                  </CustomerButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', width: '98%' }}>
            <FormControl sx={{ width: '100%' }} size="small">
              <InputLabel>Address</InputLabel>
              <Select
                size="small"
                defaultValue=""
                value={addressId}
                label="Address"
                style={{
                  borderRadius: '10px'
                }}
                onChange={(event) => {
                  setAddressId(event.target.value);
                  const info = custInfo?.addresses?.find(
                    (address) => address._id == event.target.value
                  );
                  const address = `${info.house}, ${info.street}, ${info.area?.name} ${info.area?.city}`;
                  dispatch(
                    setAddressAndArea({
                      address: address,
                      area: info?.area?._id
                    })
                  );

                  const outletId = getAddressOutlet(info.area);
                  if (outletId && handleChangeOutlet)
                    handleChangeOutlet(outletId);
                }}>
                {custInfo?.addresses?.map((info, index) => {
                  const address = `${info.house}, ${info.street}, ${info.area?.name}, ${info.area?.city}`;

                  return (
                    <MenuItem key={index} value={info._id}>
                      <Typography
                        sx={{
                          color: !getAddressOutlet(info.area) ? 'red' : ''
                        }}>
                        {address}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            {addressId && (
              <Box
                sx={{
                  minWidth: '25px',
                  width: '25px',
                  border: '1px solid gray',
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '3px'
                }}>
                <Typography fontSize="13px">
                  {
                    custInfo?.addresses?.filter((add) => add?._id)[0]?.area
                      ?.outlets[0]?.deliveryTime
                  }
                  m
                </Typography>
              </Box>
            )}

            <CustomerAddButton
              sx={{ marginLeft: '2px' }}
              variant="contained"
              onClick={() => {
                setAddAddressPopUp({ isOpen: true, id: '' });
                getAreas();
              }}>
              <AddIcon />
            </CustomerAddButton>

            {addressId ? (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                <CustomerButton
                  sx={{ marginLeft: '2px' }}
                  variant="contained"
                  onClick={async () => {
                    reset({ address: toEditAddress });
                    await getAreas();
                    setAddAddressPopUp({ isOpen: true, id: addressId });
                  }}>
                  <EditOutlinedIcon sx={{ fontSize: '17px' }} />
                </CustomerButton>
                <CustomerButton
                  sx={{ marginLeft: '2px', width: '30px' }}
                  variant="contained"
                  onClick={() => {
                    setAddAddressPopUp({
                      isOpen: true,
                      id: addressId,
                      delete: true
                    });
                    getAreas();
                  }}>
                  <Delete sx={{ fontSize: '17px' }} />
                </CustomerButton>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </>
      )}

      <Dialog maxWidth="auto" open={open} onClose={handleClose}>
        <form onSubmit={handleSubmission}>
          <DialogTitle>Add Customer Data</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="phone"
              value={dialogValue.phone}
              onChange={(event) => {
                const phoneNumber = event.target.value;
                setDialogValue({
                  ...dialogValue,
                  phone: phoneNumber
                });

                if (phoneNumber.length < 11 || phoneNumber.length > 11) {
                  setPhoneNumberError(true);
                } else {
                  setPhoneNumberError(false);
                }
              }}
              label="Phone Number"
              type="text"
              variant="standard"
              error={phoneNumberError}
              helperText={
                phoneNumberError ? 'Phone number must be 11 digits' : ''
              }
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value
                })
              }
              label="Customer Name"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        maxWidth="auto"
        open={addAddressPopUp.isOpen}
        onClose={handleAddAddressClose}>
        <DialogTitle>
          {addAddressPopUp.id
            ? 'Edit Customer Address'
            : addAddressPopUp.delete
            ? 'Delete Customer Address'
            : 'Add Customer Address'}
        </DialogTitle>
        {!addAddressPopUp.delete ? (
          <Box
            sx={{
              width: '500px',
              height: '500px'
            }}>
            <DialogContent>
              {!custInfo?.oldAddresses?.length == 0 && (
                <>
                  <Typography>Old Addresses</Typography>
                  {custInfo?.oldAddresses?.map((address, index) => (
                    <ul key={index}>
                      <li>{address}</li>
                    </ul>
                  ))}
                </>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px'
                    // width: '100%'
                  }}>
                  <Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      {...register('address.house')}
                      label="House"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      {...register('address.street')}
                      label="Street"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <Controller
                      control={control}
                      name="address.area"
                      render={({ field }) => (
                        <Autocomplete
                          required
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          onChange={(event, value) => field.onChange(value._id)}
                          value={areas.find((area) => area._id === field.value)}
                          options={areas}
                          getOptionLabel={(area) =>
                            `${area.name}, ${area.city}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Area"
                              InputProps={{
                                ...params.InputProps,
                                type: 'search'
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '10px'
                                }
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      margin="dense"
                      {...register('address.tag')}
                      label="Tag"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </Box>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      style={{
                        padding: '12px 50px',
                        borderRadius: '12px'
                        // fontize: '1em'
                      }}
                      variant="contained"
                      type="submit">
                      Submit
                    </Button>
                  </Box>
                </Box>
              </form>
            </DialogContent>
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure to delete this Address?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddAddressClose} autoFocus>
                cancel
              </Button>
              <Button
                onClick={() => {
                  deleteCustomerData();
                }}>
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        maxWidth="auto"
        open={changeNamePopUp}
        onClose={handleChangeNameClose}>
        <DialogTitle>Change Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            type="text"
            id="name"
            value={changeName}
            onChange={handleChangeName}
            label="Change Name"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeNameClose}>Cancel</Button>
          <Button
            onClick={() => {
              updateName();
            }}>
            Change
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="auto"
        open={addContactPopUp.isOpen}
        onClose={handleAddContactClose}>
        {addContactPopUp.id ? (
          <>
            <DialogContent>
              <DialogContentText>
                Are you sure to Delete Second Contact?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddContactClose} autoFocus>
                cancel
              </Button>
              <Button
                onClick={() => {
                  deleteContact();
                }}>
                Delete
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Add Another Contact</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                type="text"
                id="contact"
                value={addContact}
                onChange={handleChangeAddContact}
                label="Add Contact Number"
                variant="standard"
                error={isError}
                helperText={isError ? 'Please enter a 11-digit number' : ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAddContactClose}>Cancel</Button>
              <Button
                varient="contained"
                onClick={() => {
                  addNewContact();
                }}>
                Add
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

import React from 'react';
import {
  Box,
  Typography,
  Button,
  Grid,
  LinearProgress,
  Rating,
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';

const CircularProgressBar = styled('svg')({
  height: '100%',
  width: '70%',
  transform: 'rotate(-90deg)'
});

const Meeting = ({ dashBoardData, isLoading }) => {
  const smallScreen = useMediaQuery('(max-width: 900px)');

  const totalFeedback = dashBoardData?.map((i) => i.totalFeedbacks);
  const totalFeedbacks = totalFeedback?.reduce((acc, curr) => acc + curr, 0);
  const total = totalFeedbacks && totalFeedbacks / totalFeedback?.length;
  const average = Math.min(total, 100);
  const strokeDasharray = `${average}, 100`;

  // Maximum rating value
  const maxValue = 5;
  const calculateAverageRating = (ratings) => {
    const {
      foodQuality,
      foodTaste,
      serviceQuality,
      serviceSpeed,
      valueOfHotness,
      valueOfMoney,
      valueOfPastExperience
    } = ratings;

    const totalRatings = [
      foodQuality,
      foodTaste,
      serviceQuality,
      serviceSpeed,
      valueOfHotness,
      valueOfMoney,
      valueOfPastExperience
    ];

    const sum = totalRatings.reduce((acc, curr) => acc + curr, 0);
    return sum / totalRatings.length;
  };
  const convertToPercentage = (rating, max) => (rating / max) * 100;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '10px'
      }}>
      <Box sx={meetingContainerStyle}>
        {isLoading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : dashBoardData && dashBoardData.length > 0 ? (
          <>
            <Typography variant="h4">Average Ratings</Typography>
            <Box sx={progressCircleStyle}>
              <CircularProgressBar viewBox="0 0 36 36">
                {/* Background circle */}
                <path
                  d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
                  style={circleBgStyle}
                />
                {/* Foreground progress circle */}
                <path
                  d="M18 2.0845
             a 15.9155 15.9155 0 0 1 0 31.831
             a 15.9155 15.9155 0 0 1 0 -31.831"
                  style={{ ...circleStyle, strokeDasharray }}
                />
                {/* Text in the center */}
                <text x={18} y="20.35" style={percentageStyle}>
                  {Math.round(average)}
                </text>
              </CircularProgressBar>
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              // alignItems: 'center',
              marginTop: '2rem',
              height: '100%',
              color: 'gray'
            }}>
            <Typography>Select Date to see Branches Performance</Typography>
          </Box>
        )}
      </Box>
      <Box sx={ratingContainerStyle}>
        <Box sx={headerStyle}>
          <Typography variant="h5">Rating</Typography>
        </Box>
        <Box
          style={{
            maxHeight: 270, // Set max height for the rating box
            overflowY: 'auto' // Enable vertical scrolling
          }}>
          {isLoading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : dashBoardData && dashBoardData.length > 0 ? (
            dashBoardData.map((rating, index) => {
              const feedbackPercentage = convertToPercentage(
                rating.totalFeedbacks,
                maxValue
              );
              const normalizedValue = Math.min(feedbackPercentage, 100);
              const averageRating = calculateAverageRating(rating);

              return (
                <Box sx={ratingBarStyle} key={index}>
                  <Box style={{ width: '20%', textAlign: 'start' }}>
                    <Typography>
                      {rating?.outletTag ? rating?.outletTag : 'Others'}
                    </Typography>
                  </Box>
                  <Box style={{ width: '15%' }}>
                    <Rating
                      size="small"
                      precision={0.5}
                      //   value={rating?.totalFeedbacks || 0}
                      value={averageRating || 0}
                      readOnly
                    />
                  </Box>

                  <Box sx={barStyle}>
                    <LinearProgress
                      sx={filledBarStyle}
                      variant="determinate"
                      value={normalizedValue}
                    />
                  </Box>
                  <Box style={{ width: '5%' }}>
                    <Typography variant="body2" sx={countStyle}>
                      {normalizedValue}%
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                color: 'gray'
              }}>
              <Typography>Select Date to see Branches Performance</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

// Define styles using MUI's `sx` prop
const meetingContainerStyle = {
  width: '38%',
  padding: 2,
  border: '1px solid #ddd',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
  height: 345,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const ratingContainerStyle = {
  width: '58%',
  height: 345,
  padding: 2,
  border: '1px solid #ddd',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column'
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: 2
};

const dropDownButtonStyle = {
  backgroundColor: '#f1f1f1',
  color: '#333',
  padding: '5px 10px',
  cursor: 'pointer',
  borderRadius: 1,
  textTransform: 'none'
};

const ratingBarStyle = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  marginBottom: 2
};

const starsStyle = {
  width: 80
};

const barStyle = {
  //   flex: 1,
  backgroundColor: '#e0e0e0',
  borderRadius: 1,
  overflow: 'hidden',
  margin: '0 10px',
  height: 10,
  width: '60%'
};

const countStyle = {
  //   width: 60,
  //   textAlign: 'right'
};

const filledBarStyle = (width) => ({
  height: '100%',
  //   backgroundColor: '#333',
  width: width
});

const progressCircleStyle = {
  // position: 'relative',
  // width: 100,
  // height: 100,
  // margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const circleBgStyle = {
  fill: 'none',
  stroke: '#eee',
  strokeWidth: 3.8
};

const circleStyle = {
  fill: 'none',
  strokeWidth: 2.8,
  stroke: '#333',
  strokeLinecap: 'round',
  animation: 'progress 1s ease-out forwards'
};

const percentageStyle = {
  fill: '#333',
  fontSize: '0.5em',
  textAnchor: 'middle',
  transform: 'rotate(90deg)',
  transformOrigin: 'center'
};

const detailsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 2
};

const legendStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
};

const legendItemStyle = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 1
};

const completedStyle = {
  width: 10,
  height: 10,
  marginRight: 1,
  backgroundColor: '#333'
};

const pendingStyle = {
  width: 10,
  height: 10,
  marginRight: 1,
  backgroundColor: '#eee'
};

export default Meeting;


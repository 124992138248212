import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  ingredientResponse: null,
  isSuccess: false
};

const ingredientsSlice = createSlice({
  name: 'ingredient',
  initialState: INITIAL_STATE,
  reducers: {
    ingredientResponse(state, { payload }) {
      return {
        ...state,
        ingredientResponse: payload.response
      };
    },
    ingredientRequest: (state) => state,
    updateIngredientSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  ingredientRequest,
  ingredientResponse,
  updateIngredientSuccessStatus
} = ingredientsSlice.actions;

export default ingredientsSlice.reducer;

/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import { BrowserRouter as Router } from 'react-router-dom';

// implementing Redux
import { Provider } from 'react-redux';
import store from '../src/redux/store/GlobalStore';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import MySnackbar from './components/Header/MySnackbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistStore(store)}>
            <MySnackbar />
            <App />
          </PersistGate>
        </Provider>
      </Router>
    </React.StrictMode>
  </ThemeProvider>
);

reportWebVitals();

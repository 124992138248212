import { fetchWithAuth } from '../refreshTokenApi';
import sourcesUrls from './sourcesUrls';

// get All Suppliers API
export const getOutletSourcesApi = async (outletId) => {
  const result = await fetchWithAuth(
    `${sourcesUrls.outletSources}/${outletId}/names`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// get All Suppliers API
export const getCallCenterSourcesApi = async () => {
  const result = await fetchWithAuth(sourcesUrls.callCenterSources);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// http://103.18.20.49:3637/api/sources/callcenter/names

// http://103.18.20.49:3637/api/sources/outlet/:id/names

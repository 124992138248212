import { Box, Chip, styled, TableCell, TableRow } from '@mui/material';

export const TableCellBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid gray',
  borderRight: '1px solid gray'
  //   color: '#fff'
}));

export const HoverTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#008670a5',
    cursor: 'pointer',
    transition: '0.4s'
  }
}));

export const CountTableCell = styled(TableCell)(() => ({
  fontSize: '18px',
  fontWeight: 700
}));

export const HeaderTableCell = styled(TableCell)(() => ({
  color: '#ffffff'
}));

export const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  // backgroundColor: '#1F1817',
  // height: '200px',
  // minHeight: '250px',
  textAlign: 'left',
  justifyContent: 'space-evenly',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  '&:hover': {
    // boxShadow: '0px 0px 10px 4px #cbd5e0'
  }
}));

export const ReportChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.white.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.hover
  }
}));

import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BusinessSummary from './BusinessSummary';
import Sales from './Sales';
import {
  MainGrid,
  SideBar,
  Typo,
  TypoIcon,
  ElementBox,
  SideBarInnerBox,
  DateParentBox
} from './Styled-component';
// import CampaignIcon from '@mui/icons-material/Campaign';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BarChartIcon from '@mui/icons-material/BarChart';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ProgressBar from './ProgressBar/ProgressBar';
import {
  getDashboardItemReportApi,
  getDashboardReportApi
} from '../../api/dashboard/dashboardApi';
import DatePickerComponent from '../Shared/DatePickerComponent';

export default function DashBoard() {
  const pages = [
    { icon: <BarChartIcon />, name: 'Sales' },
    { icon: <AssignmentIcon />, name: 'Business Summary' }
  ];

  const [page, setPage] = useState('Sales');

  const [smallSize, setSmallSize] = useState(true);

  // calling Date component

  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardItemData, setDashboardItemData] = useState([]);

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;

  useEffect(() => {
    if (datePopup.isSelected === true) {
      // call API here
      getDashboardData();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const getDashboardData = async () => {
    try {
      setLoading(true);

      //
      const resp = await getDashboardItemReportApi(
        datePopup?.startDate,
        datePopup?.endDate
      );
      const response = await getDashboardReportApi(
        datePopup?.startDate,
        datePopup?.endDate
      );

      setDashboardData(response?.data);
      setDashboardItemData(resp.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };


  return (
    <>
      {/* <ProgressBar /> */}
      <Grid height="100%" container>
        <Grid
          sx={{ transition: '0.3s' }}
          item
          lg={smallSize ? 0.4 : 2}
          md={2}
          xs={12}>
          <SideBar>
            <SideBarInnerBox>
              {smallSize ? (
                <MenuIcon onClick={() => setSmallSize(false)} />
              ) : (
                <ArrowCircleLeftIcon onClick={() => setSmallSize(true)} />
              )}
            </SideBarInnerBox>

            {pages.map((pg, i) => {
              return (
                <ElementBox
                  page={page}
                  pg={pg}
                  i={i}
                  onClick={() => {
                    setPage(pg.name);
                  }}
                  sx={{}}
                  key={i}>
                  {smallSize ? (
                    <TypoIcon page={page} pg={pg} i={i}>
                      {pg.icon}
                    </TypoIcon>
                  ) : (
                    <>
                      <TypoIcon page={page} pg={pg} i={i}>
                        {pg.icon}
                      </TypoIcon>
                      <Typo page={page} pg={pg} i={i}>
                        {pg.name}
                      </Typo>
                    </>
                  )}
                </ElementBox>
              );
            })}
          </SideBar>
        </Grid>
        <MainGrid item lg={smallSize ? 11.6 : 10} md={2} xs={12}>
          <DateParentBox>
            <DatePickerComponent
              datePopup={datePopup}
              setDatePopup={setDatePopup}
            />
          </DateParentBox>
          {page === 'Sales' ? (
            <>
              <Sales
                isLoading={isLoading}
                dashboardData={dashboardData}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          ) : (
            <>
              <BusinessSummary
                isLoading={isLoading}
                dashboardData={dashboardData}
                dashboardItemData={dashboardItemData}
                startDate={startDate}
                endDate={endDate}
              />
            </>
          )}
        </MainGrid>
      </Grid>
    </>
  );
}


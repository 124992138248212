import axios from 'axios';
import urls from './authUrls';

export const logInApi = async (data) => {
  const { login } = urls.auth;
  const response = await axios.post(login, data, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: false
  });
  return response;
};

export const registerUserApi = (data) =>
  axios.post(`${urls.auth.register}`, data, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
  });

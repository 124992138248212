/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {
  OrdersActionButton,
  OrdersBox,
  SingleOrderBox,
  UserSectionBox,
  OutletSectionBox,
  OrderStatusBox,
  OrdersActionBox,
  OrderTypography,
  UpperBox,
  SearchBox,
  GeneralTypography,
  OrderChip
} from './Styled-components';
import { runningOrdersRequest } from '../../redux/reducers/ducks/OrdersDuck';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleOrderApi } from '../../api/orders/OrdersApi';
import ConfirmDelete from './components/ConfirmDelete';
import OrderDetails from './components/OrderDetails';
import Receipt from './components/Receipt';
import { extractTime } from './Shared';
// import ModifyOrder from './ModifyOrder/ModifyOrder';
import { modifyOrder } from '../../redux/reducers/ducks/CartDuck';
import { acceptCallCenterOrderApi } from '../../api/callCenter/callCenterApi';
import TransferOrder from './components/TransferOrder';
import RejectOrderCC from './components/RejectOrderCC';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import ErrorIcon from '@mui/icons-material/Error';
import useSound from 'use-sound';
import ring from '../../assets/ring.wav';
import { CountUpTimer } from 'react-countup-timer';
import { convertIsoToUnix } from '../Shared/shared';
import RefreshIcon from '@mui/icons-material/Refresh';
import PrintIcon from '@mui/icons-material/Print';
import { Printing } from '../Printing/Printing';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

const RotatingIcon = styled(RefreshIcon)(({ isRotating, checked }) => ({
  color: checked ? 'blue' : 'gray',
  cursor: 'pointer',
  transition: 'transform 0.5s ease-in-out',
  fontSize: '2rem',
  ...(isRotating && {
    animation: 'spin 1s linear infinite'
  }),
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
}));
export default function Orders({
  outletId,
  trigger,
  setTrigger,
  orderTypes,
  triggerPrint,
  singlePrintOrder,
  refr
}) {
  const dispatch = useDispatch();
  // const refr = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => refr.current
  // });
  // const triggerPrint = async (id) => {
  //   const res = await getSingleOrderApi(id);
  //   if (res.data) {
  //     setSingleOrder(res?.data);
  //   }
  //   setTimeout(handlePrint, 0);
  // };

  // const [play] = useSound(ring);
  const [play, { sound }] = useSound(ring);

  const running = true;
  const [runningOrders, setRunningOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState();

  // handling pop Up for delete
  const [deletePopup, setDeletePopup] = useState({
    isOpen: false,
    id: ''
  });

  // handling pop Up for Order details
  const [addPopup, setAddPopup] = useState({ isOpen: false, id: null });

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  // get single Order Api calling
  const getSingleOrder = async (id) => {
    const res = await getSingleOrderApi(id);
    if (res.data) {
      setSingleOrder(res?.data);
    }
  };

  // getting runningOrdersResponse from store
  const runningOrdersResponse = useSelector((state) => state.runningOrders);

  const roating = runningOrdersResponse.isSuccess;

  useEffect(() => {
    if (
      !runningOrdersResponse ||
      !runningOrdersResponse.runningOrdersResponse
    ) {
      dispatch(runningOrdersRequest(outletId));
    }
  }, []);

  useEffect(() => {
    if (runningOrdersResponse && runningOrdersResponse.runningOrdersResponse) {
      //   dispatch(runningOrdersRequest(outletId));
      // } else {
      setRunningOrders(runningOrdersResponse.runningOrdersResponse?.data);
    }
    if (trigger.status) {
      setTrigger({ status: false, order: singleOrder });
      callFunc();
    }

    // calling API after 30 seconds if outo refresh is on
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        dispatch(runningOrdersRequest(outletId));
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [dispatch, runningOrdersResponse, trigger, checked, handleToggle]);

  // calling API over some data modification to update store
  async function callFunc() {
    dispatch(runningOrdersRequest(outletId));
  }

  const runningSale = runningOrders?.reduce(
    (total, order) => total + order?.total,
    0
  );

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders =
    runningOrders
      ?.filter((order) => orderTypes?.includes(order.orderType))
      ?.filter(
        (order) =>
          order?._id?.slice(-5)?.includes(searchQuery) ||
          order?.invoiceId
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ||
          order?.tableName?.includes(searchQuery) ||
          order?.customerName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          order?.customerContact?.includes(searchQuery) ||
          order?.orderType?.toLowerCase()?.includes(searchQuery.toLowerCase())
      ) || [];

  const acceptedOrders = filteredOrders?.filter((order) => order.accepted);
  const unacceptedOrders = filteredOrders?.filter((order) => !order.accepted);

  useEffect(() => {
    let interval;
    if (unacceptedOrders.length >= 1) {
      interval = setInterval(() => {
        play();
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [unacceptedOrders, play]);

  // handling pop Up for print
  const [print, setPrint] = useState({ isOpen: false, id: '' });

  // **************************************** call center Orders ***********************************

  // handling popup for Transfer order
  const [transferPopup, setTransferPopup] = useState({
    isOpen: false,
    orderId: ''
  });

  // handling popup for Transfer order
  const [rejectOrderPopup, setRejectOrderPopup] = useState({
    isOpen: false,
    orderId: ''
  });

  // accept order from callcenter
  const acceptOrderFromCallCenter = async (orderId) => {
    const res = await acceptCallCenterOrderApi(orderId);
    if (res.status == 200) {
      callFunc();
      dispatch(
        addSnackbar({
          message: 'Order accepted from Call Center',
          type: 'success'
        })
      );

      triggerPrint(orderId, running);
      // setPrint({ isOpen: true, id: orderId });
    } else {
      dispatch(
        addSnackbar({
          message: res.response.data.message,
          type: 'error'
        })
      );
    }
  };

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <audio autoPlay loop id="playAudio">
        <source src="audio/source.mp3" />
      </audio>
      {/* <FormControlLabel
        label={<GeneralTypography>Auto Refresh</GeneralTypography>}
        control={<Switch checked={checked} onChange={handleToggle} />}
      /> */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          margin: '4px'
        }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: '6px 10px',
            cursor: 'pointer',
            borderRadius: '10px',
            border: '1px solid gray'
          }}>
          {/* <RotatingIcon onClick={handleToggle} checked={checked} /> */}
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleToggle} />}
            label="Auto Refresh"
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 10px',
            cursor: 'pointer',
            borderRadius: '10px',
            border: '1px solid gray'
          }}>
          <GeneralTypography>
            Running Sale: <b>{Math.round(runningSale || 0)}</b>
          </GeneralTypography>
        </Box>
      </Box>
      <SearchBox>
        <TextField
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px'
            }
          }}
          label="Search Order"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </SearchBox>
      <OrdersBox>
        {unacceptedOrders.map((order, index) => {
          return (
            <Box key={index}>
              <SingleOrderBox
                sx={{
                  backgroundColor: !order?.accepted ? 'goldenrod' : ''
                }}
                key={index}>
                <UpperBox
                  onClick={() => {
                    setAddPopup({ isOpen: true, id: order._id });
                    getSingleOrder(order._id);
                  }}>
                  <UserSectionBox>
                    <OrderTypography>ID:{order?.invoiceId}</OrderTypography>
                    <OrderTypography>Name:{order.customerName}</OrderTypography>
                    <OrderTypography>
                      Contact:{order.customerContact}
                    </OrderTypography>
                    {/* <OrderTypography>Second Contact:{order.customerContact}</OrderTypography> */}
                  </UserSectionBox>
                  <OutletSectionBox>
                    {order.orderType === 'dineIn' && (
                      <>
                        <OrderTypography>
                          Table No: {order?.tableName}
                        </OrderTypography>
                        <OrderTypography>
                          Waiter:
                          {order?.carrier?.name ? order?.carrier?.name : 'N/A'}
                        </OrderTypography>
                      </>
                    )}

                    {order.orderType === 'delivery' && (
                      <>
                        <OrderTypography>
                          Dileviry Address: {order.deliveryAddress}
                        </OrderTypography>
                        <OrderTypography>
                          Rider: {order?.carrier?.name}
                        </OrderTypography>
                      </>
                    )}
                    {order.orderType === 'takeAway' && (
                      <>
                        <OrderTypography>
                          Dispatched:
                          {order?.dispatched
                            ? extractTime(new Date(order?.dispatched))
                            : 'Not dispatched'}
                        </OrderTypography>
                      </>
                    )}
                  </OutletSectionBox>

                  <OrderStatusBox>
                    <OrderTypography>
                      Preparing:
                      {`${order.items.preparing} / ${order.items.count}`}
                    </OrderTypography>
                    <OrderTypography
                      sx={{
                        paddingLeft: '10px',
                        backgroundColor:
                          order.items.ready === order.items.count
                            ? 'green'
                            : '',
                        color:
                          order.items.ready === order.items.count ? '#fff' : '',
                        fontWeight:
                          order.items.ready === order.items.count ? '700' : '',
                        fontSize:
                          order.items.ready === order.items.count ? '15px' : ''
                      }}>
                      Done: {`${order.items.ready} / ${order.items.count}`}
                    </OrderTypography>
                  </OrderStatusBox>
                </UpperBox>
                <Typography>
                  Time:
                  {extractTime(new Date(order.createdAt))}
                </Typography>
                {order?.transferredFrom && (
                  <Typography>
                    Transferred From: <b>{order?.transferredFrom?.name}</b>
                  </Typography>
                )}

                {order?.transferredForced && (
                  <Box color="red">
                    <ErrorIcon />
                  </Box>
                )}

                <OrdersActionBox>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      acceptOrderFromCallCenter(order._id);
                    }}>
                    Accept
                  </OrdersActionButton>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      setTransferPopup({
                        isOpen: true,
                        orderId: order._id
                      });
                    }}>
                    Transfer
                  </OrdersActionButton>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      setRejectOrderPopup({
                        isOpen: true,
                        orderId: order._id
                      });
                    }}>
                    Reject
                  </OrdersActionButton>
                </OrdersActionBox>
              </SingleOrderBox>
            </Box>
          );
        })}

        {acceptedOrders.map((order, index) => {
          return (
            <Box key={index}>
              <SingleOrderBox
                sx={{
                  // backgroundColor: !order?.accepted ? 'goldenrod' : ''
                  backgroundColor: order?.preOrder ? '#945FC9' : '',
                  color: order?.preOrder ? '#fff' : ''
                }}>
                <UpperBox
                  preOrder={order?.preOrder}
                  onClick={() => {
                    setAddPopup({ isOpen: true, id: order._id });
                    getSingleOrder(order._id);
                  }}>
                  <UserSectionBox>
                    <OrderTypography>ID:{order?.invoiceId}</OrderTypography>
                    <OrderTypography>Name:{order.customerName}</OrderTypography>
                    <OrderTypography>
                      Contact:{order.customerContact}
                    </OrderTypography>
                    {/* <OrderTypography>Second Contact:{order.customerContact}</OrderTypography> */}
                  </UserSectionBox>
                  <OutletSectionBox>
                    {order.orderType === 'dineIn' && (
                      <>
                        <OrderTypography>
                          {/* Table No: {order.tableId ? order.tableId : 'N/A'} */}
                          Table No: {order?.tableName}
                        </OrderTypography>
                        <OrderTypography>
                          Waiter:
                          {order?.carrier?.name ? order?.carrier?.name : 'N/A'}
                        </OrderTypography>
                      </>
                    )}

                    {order.orderType === 'delivery' && (
                      <>
                        <OrderTypography>
                          Dileviry Address: {order.deliveryAddress}
                        </OrderTypography>
                        <OrderTypography>
                          Rider: {order?.carrier?.name}
                        </OrderTypography>
                      </>
                    )}
                    {order.orderType === 'takeAway' && (
                      <>
                        <OrderTypography>
                          Dispatched:
                          {order?.dispatched
                            ? order?.dispatched
                            : 'Not dispatched'}
                        </OrderTypography>
                      </>
                    )}
                  </OutletSectionBox>

                  <OrderStatusBox>
                    <OrderTypography>
                      Preparing:
                      {`${order.items.preparing} / ${order.items.count}`}
                    </OrderTypography>
                    <OrderTypography
                      sx={{
                        paddingLeft: '10px',
                        backgroundColor:
                          order.items.ready === order.items.count
                            ? 'green'
                            : '',
                        color:
                          order.items.ready === order.items.count ? '#fff' : '',
                        fontWeight:
                          order.items.ready === order.items.count ? '700' : '',
                        fontSize:
                          order.items.ready === order.items.count ? '15px' : ''
                      }}>
                      Done: {`${order.items.ready} / ${order.items.count}`}
                    </OrderTypography>
                  </OrderStatusBox>
                </UpperBox>
                <Typography>
                  Time:
                  {extractTime(new Date(order.createdAt))}
                </Typography>

                {order.orderType === 'delivery' &&
                order?.carrier?.name &&
                !order.delivered ? (
                  <Typography sx={{ fontWeight: '600' }}>
                    <OrderChip
                      param="way"
                      label="On The Way"
                      variant="outlined"
                    />
                  </Typography>
                ) : null}

                {order.delivered && (
                  <Typography sx={{ fontWeight: '600' }}>
                    <OrderChip
                      param="delivered"
                      label={`Delivered: ${extractTime(
                        new Date(order.delivered)
                      )}`}
                      variant="outlined"
                    />
                  </Typography>
                )}

                {/* <CountUpTimer startTime={convertIsoToUnix(order.createdAt)} /> */}
                {/* for callCenter Orders */}
                <OrdersActionBox>
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      dispatch(modifyOrder(order._id));
                    }}>
                    Modify
                  </OrdersActionButton>
                  {/* <OrdersActionButton variant="contained" disabled>
                    Kitchen
                  </OrdersActionButton> */}
                  {/* <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      setPrint({ isOpen: true, id: order._id });
                    }}>
                    Print
                  </OrdersActionButton> */}

                  {/* <div style={{ display: 'none' }}>
                    <Printing
                      ref={refr}
                      order={singlePrintOrder}
                      running={running}
                    />
                  </div> */}
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => triggerPrint(order._id)}>
                    <PrintIcon />
                  </OrdersActionButton>
                  {/* <div
                  // onClick={() => {
                  //   getSingleOrder(order._id);
                  // }}
                  >
                    <ReactToPrint
                      bodyClass="print-agreement"
                      content={() => refr.current}
                      trigger={async () => {
                        return (
                          <OrdersActionButton variant="contained">
                            <PrintIcon />
                          </OrdersActionButton>
                        );
                      }}
                    />
                  </div> */}
                  <OrdersActionButton
                    variant="contained"
                    onClick={() => {
                      setDeletePopup({ isOpen: true, id: order._id });
                    }}>
                    Cancel
                  </OrdersActionButton>
                </OrdersActionBox>
              </SingleOrderBox>
            </Box>
          );
        })}
      </OrdersBox>
      <OrderDetails
        triggerPrint={triggerPrint}
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
        singleOrder={singleOrder}
      />
      <ConfirmDelete
        deletePopup={deletePopup}
        setDeletePopup={setDeletePopup}
        callFunc={callFunc}
        outletId={outletId}
      />
      <TransferOrder
        transferPopup={transferPopup}
        setTransferPopup={setTransferPopup}
        callFunc={callFunc}
      />
      <RejectOrderCC
        rejectOrderPopup={rejectOrderPopup}
        setRejectOrderPopup={setRejectOrderPopup}
        callFunc={callFunc}
        outletId={outletId}
      />
      <Receipt print={print} setPrint={setPrint} running={running} />
    </Box>
  );
}

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import {
  AddressTypo,
  DoneButton,
  FullGrid,
  Items,
  LowerSection,
  MiddleSection,
  ParentSection,
  ReportChip,
  Timer,
  TimerRing,
  UpperSection,
  UpperTypography
} from './Styled-components';
import { extractTime } from '../../Shared/shared';
import {
  assembleOrderApi,
  getReadyToAssembleApi
} from '../../../api/orders/OrdersApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

export default function CutScreenDisplay() {
  const dispatch = useDispatch();
  const params = useParams();
  const { code } = params;

  // getting outlet from Redux state on the basis of params
  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  // call API to get ReadyToDispatch orders
  const [readyToAssembleOrders, setReadyToAssembleOrders] = useState([]);
  const getReadyToAssemble = async () => {
    const res = await getReadyToAssembleApi(outlet._id);
    setReadyToAssembleOrders(res?.data);
  };

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);
  const handleToggle = () => {
    setChecked(!checked);
  };

  const [dispatchRiders, setDispatchRiders] = useState({});
  // calling API after 30 seconds if outo refresh is on
  useEffect(() => {
    getReadyToAssemble();
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        getReadyToAssemble();
      }, 15000);
    }
    return () => clearInterval(interval);
  }, [checked]);

  const [itemBoxStates, setItemBoxStates] = useState([]);

  const assembleOrder = async (orderId) => {
    if (!orderId) return;
    const res = await assembleOrderApi(orderId);
    if (res && res.status === 200) {
      getReadyToAssemble();
      setItemBoxStates((states) => [...states, orderId]);
    }
  };
  const [orderNumber, setOrderNumber] = useState('');
  const [orderDone, setOrderDone] = useState(false);
  const handleKeyDown = (event) => {
    const { key } = event;
    if (key >= '0' && key <= '9') {
      setOrderNumber((prevOrderNumber) => prevOrderNumber + key);
    } else if (key == 'Enter') {
      setOrderDone(true);
    } else if (key == '.') setOrderNumber('');
    else if (key == '/') setChecked((checked) => !checked);
    else if (key == '-') window.scrollBy(0, -30);
    else if (key == '+') window.scrollBy(0, 30);
  };
  useEffect(() => {
    if (readyToAssembleOrders.length < orderNumber) setOrderNumber('');
  }, [orderNumber]);
  useEffect(() => {
    if (orderDone) {
      if (!isNaN(orderNumber))
        assembleOrder(readyToAssembleOrders[orderNumber - 1]?._id);
      setOrderNumber('');
      setOrderDone(false);
    }
  }, [orderDone]);
  useEffect(() => {
    const handleDocumentKeyDown = (event) => {
      handleKeyDown(event);
    };
    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center'
        }}>
        <Box>
          <Typography variant="h5" component="h4" color="green">
            Cut-Table Orders
          </Typography>
          <FormControlLabel
            label="Auto Refresh Orders"
            control={<Switch checked={checked} onChange={handleToggle} />}
          />
        </Box>
      </Box>

      <Grid position="relative" container spacing={2}>
        {readyToAssembleOrders?.map((order, index) => {
          return (
            <FullGrid
              key={order._id}
              item
              xl={2}
              lg={3}
              md={4}
              xs={12}
              states={itemBoxStates.includes(order._id)}>
              <ParentSection>
                <UpperSection>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <Box>
                      <UpperTypography>
                        {order?.invoiceId || order._id.slice(-5).toUpperCase()}
                      </UpperTypography>

                      <UpperTypography>{order.customerName}</UpperTypography>
                      <UpperTypography>
                        {order.orderType.toUpperCase()}
                      </UpperTypography>
                    </Box>
                    <TimerRing>
                      <Timer>{extractTime(order.createdAt)} </Timer>
                    </TimerRing>
                  </Box>
                  {order?.deliveryAddress && (
                    <AddressTypo>{order?.deliveryAddress}</AddressTypo>
                  )}
                  {order?.orderType == 'dineIn' && (
                    <>
                      <AddressTypo>Table:{order?.tableName}</AddressTypo>
                      <AddressTypo>Waiter:{order?.carrier?.name}</AddressTypo>
                    </>
                  )}
                  {order?.orderType == 'takeAway' && (
                    <>
                      <AddressTypo>
                        Contact: {order?.customerContact}
                      </AddressTypo>
                    </>
                  )}
                </UpperSection>

                <MiddleSection states={itemBoxStates.includes(order._id)}>
                  <Items>
                    {order.items.map((item, itemIndex) => {
                      const modified =
                        item.createdAt.substring(0, 18) !==
                        order.createdAt.substring(0, 18);
                      const deleted = item.active ? false : true;

                      return (
                        <React.Fragment key={itemIndex}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              margin: '5px 0'
                              // backgroundColor: deleted
                              //   ? '#E3242B'
                              //   : modified
                              //   ? 'yellow'
                              //   : ''
                            }}>
                            {deleted ? (
                              <>
                                <del
                                  style={{
                                    fontSize: '16px',
                                    color: '#E3242B'
                                  }}>
                                  {item?.name}
                                </del>
                                <del
                                  style={{
                                    fontSize: '16px',
                                    color: '#E3242B'
                                  }}>
                                  X {`(${item.quantity})`}
                                </del>
                              </>
                            ) : modified ? (
                              <>
                                <p
                                  style={{
                                    color: 'green',
                                    fontSize: '16px'
                                  }}>
                                  {item?.name}
                                </p>
                                X {`(${item.quantity})`}
                              </>
                            ) : (
                              <span>
                                <p
                                  style={{
                                    fontSize: '16px'
                                  }}>
                                  {item?.name}
                                </p>
                                X {`(${item.quantity})`}
                              </span>
                            )}
                          </Box>
                          <span>
                            <Typography
                              style={{
                                fontSize: '14px',
                                color: 'gray'
                              }}>
                              (
                              {item?.choices
                                ?.reduce(
                                  (prev, current) =>
                                    (prev += current.optionName
                                      ? current.optionName +
                                        ` ${
                                          current.price
                                            ? ` Rs. ${current.price}`
                                            : ''
                                        }` +
                                        ', '
                                      : ''),
                                  ''
                                )
                                .slice(0, -2)}
                              )
                            </Typography>
                          </span>
                          {!item.note == '' && (
                            <p
                              style={{
                                fontSize: '14px',
                                padding: 0,
                                margin: '10px 0'
                              }}>
                              Note: <b> {item.note}</b>
                            </p>
                          )}
                          <Divider />
                        </React.Fragment>
                      );
                    })}
                  </Items>
                </MiddleSection>

                <Box
                  backgroundColor={index == orderNumber - 1 ? '#aa88ff' : ''}>
                  <LowerSection>
                    <p>
                      Total Items:
                      {order?.items.length}
                    </p>
                    <DoneButton
                      type="button"
                      onClick={() => {
                        assembleOrder(order._id);
                      }}>
                      Done
                    </DoneButton>
                  </LowerSection>
                </Box>
              </ParentSection>
            </FullGrid>
          );
        })}
      </Grid>
    </>
  );
}

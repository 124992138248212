import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  items: [],
  orderType: '',
  customerName: '',
  customerContact: '',
  deliveryAddress: '',
  tableId: null,
  deliveryNote: ''
};
const modifyOrderSlice = createSlice({
  name: 'modifyOrder',
  initialState,
  reducers: {
    addToModifyOrder: (state, action) => {
      const menuItem = action.payload.menuItem;
      state.items.push({
        menuItem: menuItem._id,
        note: '',
        choices: menuItem.choices.map((choice) => ({
          choice: choice._id,
          option: choice.choice.defaultOption || null
        })),
        quantity: 1
      });
    },

    removeItem: (state, action) => {
      state.items.splice(action.payload, 1);
    },
    setItemNote: (state, action) => {
      const { index, note } = action.payload;
      state.items[index].note = note;
    },
    setItemChoice: (state, action) => {
      const { index, choice, option } = action.payload;
      state.items[index].choices.find(
        (choiceObj) => choiceObj.choice === choice
      ).option = option;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setCustomerContact: (state, action) => {
      state.customerContact = action.payload;
    },
    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    setDeliveryNote: (state, action) => {
      state.deliveryNote = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setTableId: (state, action) => {
      state.tableId = action.payload;
    },
    resetCart(state) {
      Object.assign(state, initialState);
    }
  }
});

export const {
  addToCart,
  removeItem,
  setItemChoice,
  setItemNote,
  setCustomerName,
  setCustomerContact,
  setDeliveryAddress,
  setDeliveryNote,
  setOrderType,
  setTableId,
  resetCart
} = modifyOrderSlice.actions;
export default modifyOrderSlice.reducer;

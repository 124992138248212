/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/DisplayDuck';
import { getDisplayScreensApi } from '../../../api/displayscreens/displayScreenApi';

export function* displayScreen({ payload }) {
  try {
    const displayScreen = yield call(getDisplayScreensApi);
    yield put(actions.updateDisplayScreenSuccessStatus(true));
    yield put(
      actions.displayScreenResponse({
        response: displayScreen
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchDisplayScreenSagas() {
  yield takeLatest(actions.displayScreenRequest.type, displayScreen);
}

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import Receipt from '../POS/components/Receipt';
import { CountTableCell } from './Styled-components';

export default function CanceledOrdersReport({ cancelled }) {
  // printing any order
  const [print, setPrint] = useState({ isOpen: false, id: '' });

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = cancelled.filter(
    (order) =>
      order?._id?.slice(-5)?.includes(searchQuery) ||
      order?.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order?.customerContact?.includes(searchQuery) ||
      order?.orderType?.includes(searchQuery)
  );

  const calculateType = (orders, type) => {
    return orders?.reduce(
      (prev, current) =>
        current.orderType == type
          ? {
              sale:
                prev.sale +
                current.total +
                (current.tax || 0) +
                current.totalCharges +
                (current.chargesTax || 0),
              count: prev.count + 1
            }
          : prev,
      { sale: 0, count: 0 }
    );
  };

  const cancelledTotal = cancelled?.reduce((prev, current) => {
    return (
      prev +
      current.total +
      (current.tax || 0) +
      current.totalCharges +
      (current.chargesTax || 0)
    );
  }, 0);

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <Typography
        sx={{ fontSize: '30px', fontWeight: 700, border: '1px dotted gray' }}>
        Canceled Orders
      </Typography>
      <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  Search Order by Id , Cust. Name, Number, order type
                </Typography>
                <TextField
                  label="Search Order"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table size="small" aria-label="collapsible table">
          <TableHead sx={{ background: '#FFA500', color: 'white' }}>
            <TableRow>
              <TableCell>order ID</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Order Type</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>Charges</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Print</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {filteredOrders
                ?.slice(pg * rpg, pg * rpg + rpg)
                .map((report, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        #{report?.invoiceId || report?._id?.slice(-5)}
                      </TableCell>
                      <TableCell>{report?.customerName}</TableCell>
                      <TableCell>{report?.customerContact} </TableCell>
                      <TableCell>{report?.orderType}</TableCell>
                      <TableCell>{report?.discount}</TableCell>
                      <TableCell>{report?.totalCharges}</TableCell>
                      <TableCell>{report?.total}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            setPrint({ isOpen: true, id: report?._id });
                          }}>
                          <PrintIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </>

            <TableRow>
              <CountTableCell>Total Canceled Orders:</CountTableCell>
              <CountTableCell>{cancelled?.length}</CountTableCell>
              <CountTableCell>Amount:</CountTableCell>
              <CountTableCell>{cancelledTotal}</CountTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Receipt print={print} setPrint={setPrint} cancelled={cancelled} />
    </>
  );
}

/* eslint-disable no-unused-vars */
// calculating price

export const getItemDiscount = (outletItem, choicesPrice) => {
  const menuItem = outletItem?.menuItem;
  const discount =
    (outletItem?.customDiscount ? outletItem.discount : menuItem.discount) || 0;
  const fixedDiscount = outletItem.customDiscount
    ? outletItem.fixedDiscount
    : menuItem.fixedDiscount;
  const price = outletItem.customPrice ? outletItem.price : menuItem.price;
  return fixedDiscount ? discount : (discount * (price + choicesPrice)) / 100;
};

export const getItemDiscountedPrice = (
  outletItem,
  choicesPrice,
  removeTax = false
) => {
  // if (!outletItem) return 0;
  const menuItem = outletItem?.menuItem;
  const price = outletItem?.customPrice ? outletItem?.price : menuItem?.price;
  const inclusiveTax = outletItem?.customTax
    ? outletItem?.inclusiveTax
    : menuItem?.inclusiveTax;
  const tax = (outletItem?.customTax ? outletItem?.tax : menuItem?.tax) || 0;

  const discountedPrice =
    price + choicesPrice - getItemDiscount(outletItem, choicesPrice);

  return discountedPrice / (inclusiveTax && removeTax ? 1 + tax / 100 : 1);
};
export const getPrice = (outletItem, cartItem) => {
  return getItemDiscountedPrice(
    outletItem,
    cartItem?.choices.reduce((prev, current) => {
      return (
        prev +
        (outletItem?.menuItem?.choices
          ?.find((choice) => choice._id === current.choice)
          .choice.options?.find((option) => option.item === current.option)
          ?.price || 0)
      );
    }, 0) || 0
  );
};

export const deleteOrderReason = [
  { title: 'Test Order' },
  { title: 'Duplicate Order' },
  { title: 'Customer not responding on call' },
  { title: 'Customer does not want the order anymore' },
  { title: 'Incomplete customer info' },
  { title: 'Store is closed for technical reasons' },
  { title: 'Requested items are not available' },
  { title: 'Rider not available' }
];

export const deleteItemReason = [
  { title: 'Duplicate Item' },
  { title: 'Customer do not want this Item anymore' },
  { title: 'Requested items are not available' }
];

export const extractTime = (timestamp) => {
  const date = new Date(timestamp);

  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  let meridiem = 'AM';

  // Convert hours to 12-hour format and determine AM/PM
  if (hours >= 12) {
    meridiem = 'PM';
    hours = hours % 12;
  }

  // Handle midnight and noon
  if (hours === 0) {
    hours = 12;
  }

  // Pad hours with leading zeros
  hours = String(hours).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${hours}:${minutes} ${meridiem}`;
  return formattedDate;
};

// Time stamp for recepit component

export const timestamp = (orderTime) => {
  const date = new Date(orderTime);
  // Extract the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Extract the time components
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Combine the components into the desired format
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  return formattedDate;
};

import { Box } from '@mui/material';
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts';

const data = [
  {
    name: 'Millat',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Daewoo',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Main',
    uv: 1000,
    pv: 8800,
    amt: 2290
  },
  {
    name: 'Eden',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'SKP',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Jinah',
    uv: 2390,
    pv: 3800,
    amt: 2500
  }
];

export default function BarChartReport({ salesData }) {
  return (
    <>
      <BarChart
        width={500}
        height={300}
        data={salesData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
        barSize={20}>
        <XAxis dataKey="code" scale="point" padding={{ left: 10, right: 10 }} />
        <YAxis />
        {/* <Tooltip /> */}
        <Tooltip
          content={({ payload }) => {
            if (payload && payload.length) {
              const data = payload[0].payload;
              return (
                <Box
                  sx={{
                    background: '#999',
                    color: '#f1f1f1',
                    padding: '10px 5px',
                    borderRadius: '7px'
                  }}>
                  <strong>Branch:</strong> {data.name}
                  <p>{/* <strong>Item Sales:</strong> {data.uv} */}</p>
                  <strong>Item Count:</strong> {data.pv}
                </Box>
              );
            }
            return null;
          }}
        />
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <Bar
          dataKey="pv"
          name="Item Count"
          fill="#8884d8"
          background={{ fill: '#eee' }}
        />
      </BarChart>
    </>
  );
}


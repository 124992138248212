/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  DialogContent,
  CircularProgress
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DeleteOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ingredientRequest } from '../../../redux/reducers/ducks/IngredientDuck';
import { getsuppliersApi } from '../../../api/suppliers/supplierApi';
import {
  addPurchasesApi,
  editPurchaseApi,
  getSinglePurchaseApi
} from '../../../api/stocks/purchases/purchasesApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

const getById = (collection, id) => {
  for (const item of collection) if (item._id == id) return item;
  return null;
};

export default function PurchaseDialog({
  openDialog,
  setOpenDialog,
  outletId,
  callFunc
}) {
  const dispatch = useDispatch();
  // get single purchase Api
  const [singlePurchase, setSinglePurchase] = useState();
  const getSinglePurchase = async () => {
    if (openDialog.id) {
      const res = await getSinglePurchaseApi(openDialog.id);
      setSinglePurchase(res.data);
      reset(res?.data);
    }
  };

  const { register, handleSubmit, reset, control, errors, formState } = useForm(
    {
      defaultValues: singlePurchase
    }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const [ingredientOptions, setIngredientOptions] = useState([]);
  const ingredientResponse = useSelector((state) => state.ingredient);

  useEffect(() => {
    if (!ingredientResponse || !ingredientResponse.ingredientResponse) {
      dispatch(ingredientRequest());
    } else {
      setIngredientOptions(ingredientResponse?.ingredientResponse);
    }
    getSuppliers();
  }, [dispatch, ingredientResponse]);

  // get suppliers Api
  const [suppliers, setSuppliers] = useState();
  const getSuppliers = async () => {
    const res = await getsuppliersApi();
    setSuppliers(res.data);
  };

  const [formData, setFormData] = useState({});

  const onSubmit = async (data) => {
    setFormData({
      outlet: outletId,
      supplier: data.supplier,
      desc: data.desc || '',
      // saleDate: saleDate,
      ingredients: data.ingredients,
      paidAmount: data.paidAmount
    });
  };

  // confirm dialogue

  const [addPopup, setAddPopup] = useState(false);

  // handling state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const confirmSubmit = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    setErrorMessage('');

    if (openDialog.id) {
      const res = await editPurchaseApi(openDialog.id, formData);
      if (res?.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Purchase Updated',
            type: 'success'
          })
        );
        handleConfirmClose();
        handleClose();
        callFunc();
      }
    } else {
      try {
        const res = await addPurchasesApi(formData);
        if (res?.status == 200) {
          dispatch(
            addSnackbar({
              message: 'Purchase Added',
              type: 'success'
            })
          );
          handleConfirmClose();
          handleClose();
          callFunc();
        } else {
          setErrorMessage(res?.response?.data?.message);
          dispatch(
            addSnackbar({
              message: errorMessage,
              type: 'error'
            })
          );
        }
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleConfirmClose = () => {
    setAddPopup(false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };
  // // confirm dialogue

  useEffect(() => {
    if (openDialog.id) {
      getSinglePurchase();
    }
    reset();
  }, [openDialog.isOpen]);

  const handleClose = () => {
    reset();
    setOpenDialog({ isOpen: false, id: '' });
    setIsLoading(false);
    setIsButtonDisabled(false);
  };

  const isNonNegative = (value) => {
    if (value < 0) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog
        fullScreen
        className="dialog"
        open={openDialog?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {openDialog.id ? (
          <DialogTitle>Edit Purchase</DialogTitle>
        ) : (
          <DialogTitle>Add Purchase</DialogTitle>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <Controller
                  sx={{ width: '100%' }}
                  control={control}
                  name="supplier"
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      fullWidth
                      select
                      defaultValue=""
                      label="Supplier">
                      {suppliers?.map((sup, index) => (
                        <MenuItem key={index} value={sup._id}>
                          {sup.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <TextField
                  size="small"
                  fullWidth
                  label="Description"
                  type="text"
                  {...register(`desc`)}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="Paid Amount"
                  type="number"
                  {...register(`paidAmount`)}
                />
              </Box>

              <Autocomplete
                id="free-solo-2-demo"
                disableClearable
                onChange={(e, value) =>
                  value &&
                  append({
                    ingredient: value._id,
                    quantity: '',
                    value: Math.round(value.value),
                    desc: ''
                  })
                }
                options={ingredientOptions?.filter(
                  (ingredient) =>
                    !fields.map((v) => v.ingredient).includes(ingredient._id)
                )}
                getOptionLabel={(ingredient) => ingredient.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Click to Add Ingredients"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />

              <Box marginBottom="10px">
                {fields.map((ingredient, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      border: '1px solid gray',
                      margin: '10px 0',
                      borderRadius: '7px',
                      padding: '10px 5px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                    key={index}>
                    <Box width="20%">
                      <Typography>
                        {
                          getById(ingredientOptions, ingredient.ingredient)
                            ?.name
                        }
                      </Typography>
                    </Box>
                    <Box>
                      <TextField
                        size="small"
                        {...register(`ingredients.${index}.quantity`, {
                          required: true
                        })}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {getById(ingredientOptions, ingredient.ingredient)
                                ?.unit?.code || ''}
                            </InputAdornment>
                          )
                        }}
                        variant="outlined"
                      />
                    </Box>
                    <Box>
                      <TextField
                        size="small"
                        label="Value"
                        type="text"
                        {...register(`ingredients.${index}.value`, {
                          required: true
                        })}
                      />
                    </Box>
                    <Box>
                      <TextField
                        size="small"
                        label="Description"
                        type="text"
                        {...register(`ingredients.${index}.desc`)}
                      />
                    </Box>
                    <Box>
                      <Tooltip title="Delete Ingredient">
                        <IconButton
                          sx={{
                            color: 'red',
                            width: '10%'
                          }}
                          onClick={() => remove(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setAddPopup(true);
              }}>
              Submit
            </Button>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              close
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={addPopup} onClose={handleConfirmClose}>
        <Box
          sx={{
            textAlign: 'center',
            width: 'min(100% -30px, 700px)',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#FFA500" fontWeight="700">
              Confirm Submit
            </Typography>
          </DialogTitle>
          <>
            <DialogContent>
              <p>Are you sure to Add Purchase?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose} autoFocus>
                cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                onClick={() => {
                  confirmSubmit();
                }}>
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </div>
  );
}

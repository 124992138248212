import React from 'react';
import Ingredients from '../ingredients/Ingredients';

export default function IngredientPage() {
  return (
    <>
      <Ingredients />
    </>
  );
}

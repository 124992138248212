/* eslint-disable no-unused-vars */
import axios from 'axios';
import urls from './auth/authUrls';
import swal from 'sweetalert';

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refreshToken');

  try {
    const res = await axios.post(
      urls.auth.refresh,
      JSON.stringify({ refreshToken }),
      { headers: { 'Content-Type': 'application/json' } }
    );

    localStorage.setItem('accessToken', res.data.accessToken);
    return res.data.accessToken;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      swal(
        'Session Expired',
        'Your session has expired. Please log in again.',
        'warning'
      ).then(() => {
        localStorage.clear();
        window.location.href = '/login';
      });
    } else {
      return error;
    }
  }
}

// ..............................................................

const BASE_URL = '';

export const apiCall = async (method, url, data, config) => {
  let accessToken = localStorage.getItem('accessToken');
  if (!accessToken) {
    return null;
  }
  try {
    const requestConfig = {
      ...config,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...(config && config.headers)
      }
    };

    try {
      const response = await axios[method](
        `${BASE_URL}${url}`,
        data,
        requestConfig
      );

      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        accessToken = await refreshAccessToken();
        // Retry the failed request with the new access token
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        const retryResponse = await axios[method](
          `${BASE_URL}${url}`,
          data,
          requestConfig
        );
        return retryResponse;
      } else {
        return error;
      }
    }
  } catch (error) {
    return error;
  }
};

export const fetchWithAuth = async (
  URL,
  { body, query, method, response = 'json' } = {}
) => {
  const newUrl = query
    ? URL + '?' + new URLSearchParams(query).toString()
    : URL;
  try {
    const result = await fetch(newUrl, {
      headers: {
        'Content-type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`
      },
      body: body && JSON.stringify(body),
      method
    });
    if (result.status == 401) {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        return { message: 'Refresh token not available' };
      }
      // get access token
      const refreshResponse = await fetch(urls.auth.refresh, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json'
          // Authorization: "Bearer " + user.refreshToken,
        },
        // encode refreshToken
        body: JSON.stringify({ refreshToken: refreshToken })
      });
      if (refreshResponse.status === 401) {
        // remove refresh token
        swal(
          'Session Expired',
          'Your session has expired. Please log in again.',
          'warning'
        ).then(() => {
          localStorage.clear();
          window.location.href = '/login';
        });
      }
      if (refreshResponse.status === 200) {
        const newUser = await refreshResponse.json();
        // update access token
        localStorage.setItem('accessToken', newUser.accessToken);

        const latestResponse = await fetch(newUrl, {
          headers: {
            'Content-type': 'application/json',
            authorization: `Bearer ${newUser.accessToken}`
          },
          body: body ? JSON.stringify(body) : undefined,
          method
        });
        return await latestResponse[response]();
      }
    }
    return await result[response]();
  } catch (err) {
    return { error: err, message: 'Some unexpected error occurred' };
  }
};

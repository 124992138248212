import { Box, Button, Chip, styled } from '@mui/material';

export const OutletBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  '&:hover': {}
}));

export const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  textAlign: 'left',
  justifyContent: 'space-evenly',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  borderRadius: '10px',
  border: '1px solid #cbd5e0',
  height: '150px',
  minHeight: '200px',
  // backgroundColor: '#1F1817',
  '&:hover': {
    // boxShadow: '0px 0px 10px 4px #cbd5e0'
  }
}));

export const DisplayButton = styled(Button)(() => ({
  padding: '5px',
  '&:hover': {}
}));

export const ReportChip = styled(Chip)(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.hover
  }
}));

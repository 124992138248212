import { useState } from 'react';

const useSearch = (data, searchFields) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data?.filter((item) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) =>
        searchFields.some((field) => {
          const value = field
            .split('.')
            .reduce((o, i) => (o ? o[i] : ''), item);
          return value?.toString().toLowerCase().includes(term);
        })
      )
  );

  return { searchQuery, handleSearchChange, filteredData };
};

export default useSearch;


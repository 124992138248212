/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  Divider
} from '@mui/material';
import { SingleOrderTypography } from './Styled-components';
import { getSingleOrderApi } from '../../../api/orders/OrdersApi';
import { extractTime } from '../../Shared/shared';

export default function Details({ details, setDetails }) {
  // get single Order Api calling
  const [singleOrder, setSingleOrder] = useState();

  const getSingleOrder = async () => {
    const res = await getSingleOrderApi(details.id);
    setSingleOrder(res?.data);
  };

  const handleClose = () => {
    setDetails({ isOpen: false });
  };

  useEffect(() => {
    if (details.isOpen) {
      getSingleOrder();
    }
  }, [details.isOpen]);

  const totalPayable = Math.round(
    singleOrder?.total +
      singleOrder?.tax +
      singleOrder?.totalCharges +
      (singleOrder?.chargesTax || 0)
  );

  return (
    <div>
      <Dialog open={details.isOpen} onClose={handleClose}>
        <Box
          sx={{
            textAlign: 'center',
            // width: 'auto',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#6d1b7b" fontWeight="700">
              Order Details
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Divider />
            <Box textAlign="left">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: '10px',
                  gap: '10px'
                }}>
                <Box width="50%" borderRight="1px solid gray">
                  <SingleOrderTypography>
                    <b>Invoice: </b>
                    {singleOrder?.invoiceId}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Order Time: </b>
                    {extractTime(singleOrder?.orderTime)}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Branch: </b>
                    {singleOrder?.outlet.name}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Customer: </b>
                    {singleOrder?.customerName}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Contact: </b>
                    {singleOrder?.customerContact}
                  </SingleOrderTypography>
                </Box>
                <Box width="50%">
                  <SingleOrderTypography>
                    <b>Delivery Address: </b>
                    {singleOrder?.deliveryAddress}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Accepted By: </b>
                    {singleOrder?.acceptedBy?.name}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Order Type: </b>
                    {singleOrder?.orderType}
                  </SingleOrderTypography>
                </Box>
              </Box>

              <TableContainer
                component={Paper}
                sx={{ minHeight: '220px', height: '200px', overflowY: 'auto' }}>
                <Table size="small">
                  <TableHead sx={{ background: '#FFA500' }}>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {singleOrder?.items
                      ?.filter((activeItem) => activeItem.active === true)
                      .map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell rowSpan={2}>
                                <p style={{ margin: 0, fontWeight: 700 }}>
                                  {item.name}
                                </p>
                                <span>
                                  <strong>Choices: </strong>
                                  <p
                                    style={{
                                      fontSize: '14px',
                                      margin: 0,
                                      display: 'inline'
                                    }}>
                                    (
                                    {item?.choices
                                      ?.reduce(
                                        (prev, current) =>
                                          (prev += current.optionName
                                            ? current.optionName +
                                              ` ${
                                                current.price
                                                  ? ` Rs. ${current.price}`
                                                  : ''
                                              }` +
                                              ','
                                            : ''),
                                        ''
                                      )
                                      .slice(0, -2)}
                                    )
                                  </p>
                                  {!item.note == '' && <b>Note: {item.note}</b>}
                                </span>
                              </TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>{Math.round(item.discount)}</TableCell>
                              <TableCell>{item.discountedPrice}</TableCell>
                            </TableRow>
                            <TableRow>{/* <TableCell></TableCell> */}</TableRow>
                          </React.Fragment>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {!singleOrder?.deliveryNote == '' && (
                <b>Delivery Note:{singleOrder?.deliveryNote} </b>
              )}

              <Box>
                <SingleOrderTypography>
                  <b>Sub-Total: </b> {Math.round(singleOrder?.subTotal)}
                </SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Discount: </b> {singleOrder?.discount}
                </SingleOrderTypography>
                {singleOrder?.discountReason && (
                  <SingleOrderTypography>
                    <b>Discount Reason: </b> {singleOrder?.discountReason}
                  </SingleOrderTypography>
                )}
                <SingleOrderTypography>
                  <b>Charges: </b> {singleOrder?.totalCharges}
                </SingleOrderTypography>
              </Box>
              <Box>
                {!singleOrder?.tax == 0 && (
                  <>
                    <b>Tax: </b> {Math.round(singleOrder?.tax)}
                  </>
                )}
                <SingleOrderTypography></SingleOrderTypography>
                <SingleOrderTypography>
                  <b>Total Amount: </b>
                  {totalPayable}
                </SingleOrderTypography>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

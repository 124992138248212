/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { deleteIngredientApi } from '../../api/ingredients/ingredientsApi';

export default function DeleteIngredient({ value, callFunc, setValue }) {
  const deleteRow = async (id) => {
    try {
      const response = await deleteIngredientApi(id);
    } catch (e) {}
    callFunc();
    setValue({ isOpen: false });
  };

  const handleClose = () => {
    callFunc();
    setValue({ isOpen: false });
  };

  return (
    <div>
      <Dialog
        open={value.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            cancel
          </Button>
          <Button onClick={() => deleteRow(value.id)}>Delete </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

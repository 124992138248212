import { useState } from 'react';
import Box from '@mui/material/Box';
import { Mask, MaskBox, MaskButton } from './styled-component';

export default function ToggleButton({ states, callFunc }) {
  const [subType, setSubType] = useState(states.first);

  callFunc(subType);
  return (
    <Box sx={{ display: 'flex' }}>
      <MaskBox>
        <Mask
          style={{
            transform: `translateX(${subType === states.first ? 0 : '100px'})`
          }}
        />
        <MaskButton
          disableRipple
          variant="text"
          sx={{ color: subType === states.first ? '#ffffff' : '#B0D9B1' }}
          onClick={() => setSubType(states.first)}>
          {states.first}
        </MaskButton>
        <MaskButton
          disableRipple
          variant="text"
          sx={{ color: subType === states.second ? '#ffffff' : '#B0D9B1' }}
          onClick={() => setSubType(states.second)}>
          {states.second}
        </MaskButton>
      </MaskBox>
    </Box>
  );
}


import axios from 'axios';
import { fetchWithAuth, refreshAccessToken } from '../refreshTokenApi';
import userUrls from './usersUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get all Users with permissions API i.e orderModification, addDiscount,
export const getUsersWithPermissionApi = async (outletId, permission) => {
  const outletParams = `outlet=${outletId}`;
  const permissionParams = `permission=${permission}`;

  const config = { headers: { Authorization: `Bearer ${accessToken}` } };

  try {
    const data = await axios.get(
      `${userUrls.user}?${permissionParams}&${outletParams}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${userUrls.user}?${permissionParams}&${outletParams}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get all Users API
export const getUsersApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(userUrls.getUsers, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(userUrls.getUsers, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// create a new User API
export const createUserApi = async (userData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(userUrls.getUsers, userData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(userUrls.getUsers, userData, newConfig);
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get a single User
export const getSingleUserApi = async (userId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!userId) {
    return null;
  }
  try {
    const data = await axios.get(`${userUrls.getUsers}/${userId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(`${userUrls.getUsers}/${userId}`, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit a User API
export const editUserApi = async (userId, userData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${userUrls.getUsers}/${userId}`,
      userData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${userUrls.getUsers}/${userId}`,
        userData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// create Manager Key API
export const changeUserKeyApi = async (userData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(userUrls.changeKey, userData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(userUrls.changeKey, userData, newConfig);
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// create Password API
export const changePasswordApi = async (userData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(userUrls.changePassword, userData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        userUrls.changePassword,
        userData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// delete user API

export const deleteUserApi = async (userId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(`${userUrls.deleteUser}/${userId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${userUrls.deleteUser}/${userId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return error;
    }
  }
};

// get All Banned Users API
export const getBannedUsersApi = async () => {
  const result = await fetchWithAuth(userUrls.bannedUser);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// ban user API

export const banUserApi = async (userData) => {
  const result = await fetchWithAuth(`${userUrls.banUser}/${userData.phone}`, {
    body: { reason: userData.reason },
    method: 'PUT'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// ban user API
export const unBanUserApi = async (userId) => {
  const result = await fetchWithAuth(`${userUrls.unBanUser}/${userId}`, {
    method: 'PUT'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

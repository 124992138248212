import React from 'react';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import { DRAWER_LIST } from '../constants/menu';
import MenuItem from './MenuItem';
import { useSelector } from 'react-redux';

const MenuItemsList = () => {
  const { pathname } = useLocation();
  const role = useSelector(
    (state) => state?.authorize?.loginResponse?.user?.role
  );
  const filteredList =
    role?.name === 'super'
      ? DRAWER_LIST
      : DRAWER_LIST.filter(
          (drawerItem) =>
            !drawerItem?.access || role?.access?.includes(drawerItem?.access)
        );

  return (
    <Grid>
      <List sx={{ p: 0, color: '#8094AE' }}>
        {filteredList.map(({ literal, route, Icon }, index) => (
          <MenuItem
            Icon={Icon}
            literal={literal}
            route={route}
            key={index}
            selected={pathname === route}
            onClick={undefined}
          />
        ))}
      </List>
    </Grid>
  );
};

export default MenuItemsList;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  Autocomplete,
  DialogContent,
  CircularProgress
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DeleteOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';

import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import {
  addExpenseApi,
  editExpenseApi,
  getExpenseCategoriesApi,
  getSingleExpenseApi
} from '../../../api/stocks/expenses/expensesApi';

const getById = (collection, id) => {
  for (const item of collection) if (item._id == id) return item;
  return null;
};

export default function ExpenseDialog({
  openDialog,
  setOpenDialog,
  outletId,
  callFunc
}) {
  const dispatch = useDispatch();
  // get single purchase Api
  const [singleExpense, setSingleExpense] = useState(null);
  const getSingleExpense = async () => {
    if (openDialog.id) {
      const res = await getSingleExpenseApi(openDialog.id);
      setSingleExpense(res.data);
      reset(res?.data);
    }
  };

  const { register, handleSubmit, reset, control, errors, formState } = useForm(
    {
      defaultValues: singleExpense
    }
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'expenses'
  });

  const [emptyState, setEmptyState] = useState(null);

  const [expenseCategories, setExpenseCategories] = useState([]);

  useEffect(() => {
    getExpenseCategories();
  }, []);

  const getExpenseCategories = async () => {
    const res = await getExpenseCategoriesApi();
    setExpenseCategories(res.data);
  };

  const [formData, setFormData] = useState({});

  const onSubmit = async (data) => {
    setFormData({
      // supplier: data.supplier,
      // saleDate: saleDate,
      // paidAmount: data.paidAmount
      outlet: outletId,
      desc: data.desc || '',
      expenses: data.expenses
    });
  };

  // confirm dialogue

  const [addPopup, setAddPopup] = useState(false);

  // handling state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const confirmSubmit = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    setErrorMessage('');

    if (openDialog.id) {
      const res = await editExpenseApi(openDialog.id, formData);
      if (res?.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Expense Updated',
            type: 'success'
          })
        );
        handleConfirmClose();
        handleClose();
        callFunc();
      }
    } else {
      try {
        const res = await addExpenseApi(formData);
        if (res?.status == 200) {
          dispatch(
            addSnackbar({
              message: 'Expense Added',
              type: 'success'
            })
          );
          handleConfirmClose();
          handleClose();
          callFunc();
        } else {
          setErrorMessage(res?.response?.data?.message);
          dispatch(
            addSnackbar({
              message: errorMessage,
              type: 'error'
            })
          );
        }
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleConfirmClose = () => {
    setAddPopup(false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };
  // confirm dialogue

  useEffect(() => {
    if (openDialog.id) {
      getSingleExpense();
    }
    reset();
  }, [openDialog.isOpen]);

  const handleClose = () => {
    reset();
    setOpenDialog({ isOpen: false, id: '' });
    setIsLoading(false);
    setIsButtonDisabled(false);
  };

  const isNonNegative = (value) => {
    if (value < 0) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog
        fullScreen
        className="dialog"
        open={openDialog?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {openDialog.id ? (
          <DialogTitle>Edit Expense</DialogTitle>
        ) : (
          <DialogTitle>Add Expense</DialogTitle>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <TextField
                  size="small"
                  fullWidth
                  label="Note"
                  type="text"
                  {...register(`desc`)}
                />
              </Box>

              <Box marginBottom="10px">
                {fields.map((expenseCategory, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      border: '1px solid gray',
                      margin: '10px 0',
                      borderRadius: '7px',
                      padding: '10px 5px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                    key={index}>
                    <Box width="20%">
                      <Typography>
                        {
                          getById(
                            expenseCategories,
                            expenseCategory.expenseCategory
                          )?.name
                        }
                      </Typography>
                    </Box>

                    <Box>
                      <TextField
                        size="small"
                        label="Value"
                        type="text"
                        {...register(`expenses.${index}.value`, {
                          required: true
                        })}
                      />
                    </Box>
                    <Box>
                      <TextField
                        size="small"
                        label="Description"
                        type="text"
                        {...register(`expenses.${index}.remarks`)}
                      />
                    </Box>

                    <Box>
                      <TextField
                        label="Employee ID"
                        size="small"
                        {...register(`expenses.${index}.employeeId`)}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {/* {getById(expenseCategoryOptions, expenseCategory.expenseCategory)
                                ?.unit?.code || ''} */}
                            </InputAdornment>
                          )
                        }}
                        variant="outlined"
                      />
                    </Box>
                    <Box>
                      <Tooltip title="Delete expenseCategory">
                        <IconButton
                          sx={{
                            color: 'red',
                            width: '10%'
                          }}
                          onClick={() => remove(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Autocomplete
                id="free-solo-2-demo"
                // disableClearable
                value={emptyState}
                onChange={(e, value) =>
                  value &&
                  append({
                    expenseCategory: value._id,
                    value: '',
                    remarks: '',
                    employeeId: ''
                  }) &&
                  setEmptyState('')
                }
                options={expenseCategories}
                getOptionLabel={(expenseCategory) => expenseCategory.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Click to Add Category"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
            </Box>
          </Box>

          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={() => {
                setAddPopup(true);
              }}>
              Submit
            </Button>
            <Button variant="outlined" onClick={handleClose} autoFocus>
              close
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={addPopup} onClose={handleConfirmClose}>
        <Box
          sx={{
            textAlign: 'center',
            width: 'min(100% -30px, 700px)',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#FFA500" fontWeight="700">
              Confirm Submit
            </Typography>
          </DialogTitle>
          <>
            <DialogContent>
              <p>Are you sure to Add Purchase?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose} autoFocus>
                cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                onClick={() => {
                  confirmSubmit();
                }}>
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </div>
  );
}

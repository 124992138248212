/* eslint-disable no-unused-vars */
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../../reducers/ducks/MenuCategoryDuck';
import { getMenuCategoriesApi } from '../../../api/menuCategory/menuCategoryApi';

export function* menuCategory({ payload }) {
  try {
    const menuCategory = yield call(getMenuCategoriesApi);
    yield put(actions.updateMenuCategorySuccessStatus(true));
    yield put(
      actions.menuCategoryResponse({
        response: menuCategory
      })
    );
  } catch (error) {
    console.error(error);
  }
}

export function* watchMenuCategorySagas() {
  yield takeLatest(actions.menuCategoryRequest.type, menuCategory);
}

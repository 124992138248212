/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getHourlySaleReportApi } from '../../api/report/reportApi';
import { TableCellBorder } from './Styled-components';
import Loader from '../Loader/Loader';
import { getCurrentDate } from '../Shared/shared';
import DatePickerComponent from '../Shared/DatePickerComponent';
import ToggleButton from '../Shared/Toggle/Toggle';

export default function HourlySaleReport() {
  const params = useParams();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [saleReport, setSaleReport] = useState([]);

  // handling date

  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });
  const startDate = datePopup.startDate;
  const endDate = datePopup.endDate;
  useEffect(() => {
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  const millisecondsDiff = new Date(endDate) - new Date(startDate);
  const daysToGetAvg = Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24)) + 1;

  // calling APi
  const [isLoading, setLoading] = useState(false);

  const reportApi = async () => {
    try {
      setLoading(true);
      const response = await getHourlySaleReportApi(
        outlet?._id,
        startDate,
        endDate
      );
      setSaleReport(response?.data);
      setLoading(false);

      if (response) {
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const [checked, setChecked] = useState(true);

  // const handleToggle = () => {
  //   setChecked(!checked);
  // };

  const totals = saleReport.reduce(
    (accumulator, currentObject) => {
      if (currentObject.delivery) {
        accumulator.delivery.count += currentObject.delivery.count;
        accumulator.delivery.sales += currentObject.delivery.sales;
      }
      if (currentObject.dineIn) {
        accumulator.dineIn.count += currentObject.dineIn.count;
        accumulator.dineIn.sales += currentObject.dineIn.sales;
      }
      if (currentObject.takeAway) {
        accumulator.takeAway.count += currentObject.takeAway.count;
        accumulator.takeAway.sales += currentObject.takeAway.sales;
      }
      accumulator.sales += currentObject.sales;
      accumulator.count += currentObject.count;

      return accumulator;
    },
    {
      delivery: { count: 0, sales: 0 },
      dineIn: { count: 0, sales: 0 },
      takeAway: { count: 0, sales: 0 },
      sales: 0,
      count: 0
    }
  );

  const callFunc = (type) => {
    type === 'Total' ? setChecked(true) : setChecked(false);
  };

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Hourly Sales
        </Typography>

        <DatePickerComponent
          datePopup={datePopup}
          setDatePopup={setDatePopup}
        />
        <div>
          {/* <label htmlFor="start-date">Start Date:</label>
          <input
            className="date-input"
            id="start-date"
            type="date"
            value={startDate || ''}
            onChange={(event) => handleDateChange(event, true)}
          />
          <label htmlFor="end-date">End Date:</label>
          <input
            className="date-input"
            id="end-date"
            type="date"
            value={endDate || ''}
            onChange={(event) => handleDateChange(event, false)}
          />
          <Button
            sx={{ marginLeft: '10px' }}
            size="small"
            variant="contained"
            onClick={() => {
              reportApi();
            }}>
            Get Report
          </Button> */}
          {/* Average Sale
          <FormControlLabel
            label="Totale Sale "
            control={<Switch checked={checked} onChange={handleToggle} />}
          /> */}
          <ToggleButton
            states={{ first: 'Total', second: 'Average' }}
            callFunc={callFunc}
          />
        </div>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ background: '#FFA500', color: 'white' }}>
                <TableCell align="center">Hour</TableCell>
                <TableCellBorder align="center" colSpan={3}>
                  DineIn
                </TableCellBorder>
                <TableCellBorder align="center" colSpan={3}>
                  TakeAway
                </TableCellBorder>
                <TableCellBorder align="center" colSpan={3}>
                  Delivery
                </TableCellBorder>
                <TableCellBorder align="center">Total Count</TableCellBorder>

                <TableCellBorder align="center">Total Sales</TableCellBorder>
                <TableCellBorder align="center">
                  Total Average Sales
                </TableCellBorder>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCellBorder align="center">Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                <TableCellBorder align="center">Average sale</TableCellBorder>

                <TableCellBorder align="center"> Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                <TableCellBorder align="center">Average sale</TableCellBorder>

                <TableCellBorder align="center"> Count</TableCellBorder>
                <TableCellBorder align="center">Sales</TableCellBorder>
                <TableCellBorder align="center">Average sale</TableCellBorder>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {saleReport?.map((report, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        '&:hover': {
                          backgroundColor: '#FFA500a5',
                          cursor: 'pointer',
                          transition: '0.4s'
                        }
                      }}>
                      <TableCell align="center">{report.hour}:00 </TableCell>

                      {/* dineIn section */}
                      <TableCell align="center">
                        {!checked
                          ? Math.round(report?.dineIn?.count / daysToGetAvg) ||
                            0
                          : Math.round(report?.dineIn?.count) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(report?.dineIn?.sales / daysToGetAvg) ||
                            0
                          : Math.round(report?.dineIn?.sales) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.dineIn?.sales /
                                daysToGetAvg /
                                (report?.dineIn?.count / daysToGetAvg)
                            ) || 0
                          : Math.round(
                              report?.dineIn?.sales / report?.dineIn?.count
                            ) || 0}
                      </TableCell>

                      {/* takeAway sectio */}
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.takeAway?.count / daysToGetAvg
                            ) || 0
                          : Math.round(report?.takeAway?.count) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.takeAway?.sales / daysToGetAvg
                            ) || 0
                          : Math.round(report?.takeAway?.sales) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.takeAway?.sales /
                                daysToGetAvg /
                                (report?.takeAway?.count / daysToGetAvg)
                            ) || 0
                          : Math.round(
                              report?.takeAway?.sales / report?.takeAway?.count
                            ) || 0}
                      </TableCell>

                      {/* delivery section */}
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.delivery?.count / daysToGetAvg
                            ) || 0
                          : Math.round(report?.delivery?.count) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.delivery?.sales / daysToGetAvg
                            ) || 0
                          : Math.round(report?.delivery?.sales) || 0}
                      </TableCell>

                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report?.delivery?.sales /
                                daysToGetAvg /
                                (report?.delivery?.count / daysToGetAvg)
                            )
                          : Math.round(
                              report?.delivery?.sales / report?.delivery?.count
                            )}
                      </TableCell>

                      {/* total count section */}

                      <TableCell align="center">
                        {!checked
                          ? Math.round(report.count / daysToGetAvg) || 0
                          : report.count || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(report.sales / daysToGetAvg) || 0
                          : Math.round(report.sales) || 0}
                      </TableCell>
                      <TableCell align="center">
                        {!checked
                          ? Math.round(
                              report.sales /
                                daysToGetAvg /
                                (report.count / daysToGetAvg)
                            )
                          : Math.round(report.sales / report.count)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCellBorder>
                    Total Hours: <b> {saleReport.length}</b>
                  </TableCellBorder>
                  {/* dineIn section  */}
                  <TableCellBorder>
                    DineIn Count:
                    <b>
                      {!checked
                        ? Math.round(totals.dineIn.count / daysToGetAvg) || 0
                        : Math.round(totals.dineIn.count) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    DineIn Sales:
                    <b>
                      {!checked
                        ? Math.round(totals.dineIn.sales / daysToGetAvg) || 0
                        : Math.round(totals.dineIn.sales) || 0}
                    </b>
                  </TableCellBorder>

                  <TableCellBorder>
                    DineIn average Sales:
                    <b>
                      {!checked
                        ? Math.round(
                            totals.dineIn.sales /
                              daysToGetAvg /
                              (totals.dineIn.count / daysToGetAvg)
                          ) || 0
                        : Math.round(
                            totals.dineIn.sales / totals.dineIn.count
                          ) || 0}
                    </b>
                  </TableCellBorder>

                  {/* takeAway avg section  */}
                  <TableCellBorder>
                    TakeAway Count:
                    <b>
                      {!checked
                        ? Math.round(totals.takeAway.count / daysToGetAvg) || 0
                        : Math.round(totals.takeAway.count) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    TakeAway Sales:
                    <b>
                      {!checked
                        ? Math.round(totals.takeAway.sales / daysToGetAvg) || 0
                        : Math.round(totals.takeAway.sales) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    TakeAway average Sales:
                    <b>
                      {!checked
                        ? Math.round(
                            totals.takeAway.sales /
                              daysToGetAvg /
                              (totals.takeAway.count / daysToGetAvg)
                          ) || 0
                        : Math.round(
                            totals.takeAway.sales / totals.takeAway.count
                          ) || 0}
                    </b>
                  </TableCellBorder>

                  {/* delivery section  */}
                  <TableCellBorder>
                    Delivery Count:
                    <b>
                      {!checked
                        ? Math.round(totals.delivery.count / daysToGetAvg) || 0
                        : Math.round(totals.delivery.count) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Delivery Sales:
                    <b>
                      {!checked
                        ? Math.round(totals.delivery.sales / daysToGetAvg) || 0
                        : Math.round(totals.delivery.sales) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Delivery average Sales:
                    <b>
                      {!checked
                        ? Math.round(
                            totals.delivery.sales /
                              daysToGetAvg /
                              (totals.delivery.count / daysToGetAvg)
                          ) || 0
                        : Math.round(
                            totals.delivery.sales / totals.delivery.count
                          ) || 0}
                    </b>
                  </TableCellBorder>

                  <TableCellBorder>
                    Total Count:
                    <b>
                      {!checked
                        ? Math.round(totals.count / daysToGetAvg) || 0
                        : Math.round(totals.count) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Total Sales:
                    <b>
                      {!checked
                        ? Math.round(totals.sales / daysToGetAvg) || 0
                        : Math.round(totals.sales) || 0}
                    </b>
                  </TableCellBorder>
                  <TableCellBorder>
                    Total Average Sales:
                    <b>
                      {!checked
                        ? Math.round(
                            totals.sales /
                              daysToGetAvg /
                              totals.count /
                              daysToGetAvg
                          ) || 0
                        : Math.round(totals.sales / totals.count) || 0}
                    </b>
                  </TableCellBorder>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Box,
  IconButton,
  InputAdornment,
  Typography
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordApi } from '../../../api/users/usersApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';
import swal from 'sweetalert';

const ChangePassword = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authorize?.loginResponse?.user);

  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const onSubmit = async (data) => {
    // event.preventDefault();
    // Handle form submission here
    const formData = {
      email: data.email,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword
    };

    const response = await changePasswordApi(formData);
    if (response.status == 200) {
      swal('success', 'Password Changed Successfully', 'success');

      dispatch(
        addSnackbar({
          message: `Password Changed Successfully`,
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: response?.response?.data?.message,
          type: 'error'
        })
      );
    }
  };

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <Typography sx={{ fontSize: 'clamp(1rem, 2vW + 0.5rem, 2rem)' }}>
        Change Password
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            width: '400px',
            borderRadius: '10px',
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.79)',
            p: '20px'
          }}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              defaultValue={user?.email}
              rules={{ required: 'Email is required', pattern: /^\S+@\S+$/i }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="User Email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                />
              )}
            />

            <Controller
              name="oldPassword"
              control={control}
              defaultValue=""
              rules={{ required: 'Old Password is required' }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Old Password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type="password"
                  error={!!errors.oldPassword}
                  helperText={
                    errors.oldPassword ? errors.oldPassword.message : ''
                  }
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'New Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters long'
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="New Password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.newPassword}
                  helperText={
                    errors.newPassword ? errors.newPassword.message : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Confirm Password is required',
                validate: (value) =>
                  value === newPassword ||
                  'Confirm Password does not match with New Password'
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Confirm Password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.confirmPassword}
                  helperText={
                    errors.confirmPassword ? errors.confirmPassword.message : ''
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!newPassword || newPassword !== confirmPassword}
              fullWidth>
              Change Password
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setCharges } from '../../../redux/reducers/ducks/CartDuck';
import { getUsersWithPermissionApi } from '../../../api/users/usersApi';
import { permisssions } from '../../Shared/shared';
import { modifyChargesApi } from '../../../api/orders/OrdersApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

export default function ModifyCharges({
  chargesPopup,
  setChargesPopup,
  singleOrder,
  outletId,
  orderId
}) {
  const dispatch = useDispatch();

  // calling API to get permited users who can apply discount
  const [users, setUsers] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outletId,
      permisssions.modifyOrder
    );
    setUsers(response?.data);
  };

  // handling form of manual discount with useForm hook
  const { register, handleSubmit, setValue, watch, reset, control } = useForm();

  const handleChangeUserName = (event) => {
    setValue('type', event.target.value);
  };

  const onSubmit = async (data) => {
    const charges = [];
    charges.push({ amount: data.amount, type: data.type });

    const payload = {
      ref: data.ref,
      charges: charges
    };

    dispatch(setCharges(charges));

    const res = await modifyChargesApi(orderId, payload);
    if (res.status == 200) {
      // updateTriggerState(true);
      handleClose();
      dispatch(
        addSnackbar({
          message: `Charges Updated`,
          type: 'success'
        })
      );
    } else {
      dispatch(
        addSnackbar({
          message: res.response.data.message,
          type: 'error'
        })
      );
    }
    handleClose();
  };

  const handleClose = () => {
    reset();
    setChargesPopup('isOpen', false);
  };

  const chargesType = [
    { name: 'VAT', value: 'VAT' },
    { name: 'delivery', value: 'delivery' },
    { name: 'services', value: 'services' }
  ];

  // Setting initial values from singleOrder.charges when the component
  useEffect(() => {
    if (singleOrder && singleOrder.charges) {
      dispatch(setCharges(singleOrder?.charges));
    }
    getUsersWithPermission();
    if (singleOrder && singleOrder.charges) {
      reset({
        type: singleOrder?.charges[0]?.type,
        amount: singleOrder?.charges[0]?.amount
      });
    }
  }, [singleOrder, setValue]);

  return (
    <div>
      <Dialog
        className="dialog"
        open={chargesPopup?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Modify Charges</DialogTitle>

        <Box
          sx={{
            width: '500px',
            height: '300px'
          }}>
          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <Controller
                  sx={{ width: '100%' }}
                  control={control}
                  name="ref.user"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      select
                      defaultValue=""
                      label="Name">
                      {users?.map((user, index) => (
                        <MenuItem key={index} value={user._id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                <TextField
                  size="small"
                  label="Manager Key"
                  type="password"
                  {...register('ref.key')}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  gap: '10px',
                  marginBottom: '10px'
                }}>
                <Controller
                  sx={{ width: '100%' }}
                  control={control}
                  name="type"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      select
                      defaultValue=""
                      label="Charges Type">
                      {chargesType?.map((charges, index) => (
                        <MenuItem key={index} value={charges.value}>
                          {charges.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />

                {/* <FormControl sx={{ width: '50%' }} size="small">
                  <InputLabel>Charges Type</InputLabel>
                  <Select
                    defaultValue=""
                    {...register('type')}
                    label="Charges Type"
                    onChange={handleChangeUserName}>
                    {chargesType?.map((charges, index) => (
                      <MenuItem key={index} value={charges.value}>
                        {charges.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <TextField
                  size="small"
                  label="Charges"
                  type="text"
                  {...register('amount')}
                />
              </Box>
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </form>
          </Box>
        </Box>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import {
  Box,
  styled,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';

export const CountTableCell = styled(TableCell)(() => ({
  fontSize: '18px',
  fontWeight: 700
}));

export const DateTextField = styled(TextField)(() => ({}));

export const SingleOrderTypography = styled(Typography)(() => ({
  fontSize: '14px'
}));

export const TableCellBorder = styled(TableCell)(() => ({
  borderLeft: '1px solid gray',
  borderRight: '1px solid gray'
}));

export const StyledTableRow = styled(TableRow)(({ panda }) => ({
  color: panda === 'FoodPanda Rider' ? '#ffffff' : '',
  backgroundColor: panda === 'FoodPanda Rider' ? '#FF2B85' : '',
  '&:hover': {
    backgroundColor: '#008670a5',
    cursor: 'pointer',
    transition: '0.4s'
  }
}));

export const StyledTableCell = styled(TableCell)(({ panda }) => ({
  color: panda === 'FoodPanda Rider' ? '#ffffff' : ''
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  // boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px'
  // border: '1px solid red'
}));

export const HeaderTableCell = styled(TableCell)(() => ({
  color: '#ffffff'
}));

export const HoverTableRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#008670a5',
    cursor: 'pointer',
    transition: '0.4s'
  }
}));

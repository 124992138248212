/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  DialogActions
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  addRoleApi,
  deleteRoleApi,
  editRoleApi
} from '../../api/roles/rolesApi';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { Cancel } from '@mui/icons-material';

const AddEditRole = ({ addPopup, setAddPopup, callFunc, access, roles }) => {
  const dispatch = useDispatch();
  const roleData = roles?.find((role) => role?._id == addPopup?.id);

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: '',
      access: []
    }
  });

  useEffect(() => {
    if (addPopup.id) {
      reset({
        name: roleData?.name,
        access: roleData.access
      });
    }
  }, [addPopup.isOpen]);

  const onSubmit = async (data) => {
    if (!addPopup.isDelete && addPopup.id) {
      const res = await editRoleApi(addPopup.id, data);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Role Credentials Updated',
            type: 'success'
          })
        );
        handleClose();
        callFunc();
      }
    } else if (addPopup.isDelete && addPopup.id) {
    } else if (!addPopup.isDelete && !addPopup.id) {
      const res = await addRoleApi(data);
      if (res.status == 200) {
        dispatch(
          addSnackbar({
            message: 'New Role Created',
            type: 'success'
          })
        );
        handleClose();
        callFunc();
      }
    }
  };

  const handleClose = () => {
    setAddPopup({ isOpen: false });
    reset({
      name: '',
      access: []
    });
  };

  return (
    <Box>
      <Container>
        <Dialog open={addPopup.isOpen} onClose={handleClose}>
          <Box
            sx={{
              textAlign: 'center',
              width: 'min(100% -30px, 700px)',
              marginInline: 'auto'
            }}>
            <DialogTitle id="alert-dialog-title">
              {!addPopup.isDelete ? (
                !addPopup.id ? (
                  <Typography color="#FFA500" fontWeight="700">
                    Create New Role
                  </Typography>
                ) : (
                  <Typography color="#FFA500" fontWeight="700">
                    Edit Role
                  </Typography>
                )
              ) : (
                <Typography color="#FFA500" fontWeight="700">
                  Delete Role
                </Typography>
              )}
            </DialogTitle>
            {!addPopup.isDelete ? (
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    {...register('name', { required: true })}
                    margin="normal"
                    label="Role Name"
                    fullWidth
                  />

                  <Controller
                    control={control}
                    name="access"
                    defaultValue={[]}
                    render={({ field }) => (
                      <FormControl fullWidth sx={{ margin: '10px 0' }}>
                        <InputLabel>Accesses</InputLabel>
                        <Select
                          multiple
                          {...field}
                          input={<OutlinedInput label="outlet" />}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5
                              }}>
                              {selected.map((value, i) => (
                                <Chip
                                  key={i}
                                  label={value}
                                  clickable
                                  deleteIcon={
                                    <Cancel
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  onDelete={() => {
                                    const updatedValue = field.value.filter(
                                      (access) => access !== value
                                    );

                                    field.onChange(updatedValue);
                                  }}
                                />
                              ))}
                            </Box>
                          )}>
                          {access
                            .filter((outlet) => !field.value.includes(outlet))
                            .map((outlet, i) => (
                              <MenuItem key={i} value={outlet}>
                                {outlet}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />

                  <Button variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </DialogContent>
            ) : (
              <>
                <DialogContent>Are you sure to delete this Role?</DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} autoFocus>
                    cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      const res = await deleteRoleApi(addPopup?.id);
                      if (res.status == 200) {
                        dispatch(
                          addSnackbar({
                            message: 'Role Deleted',
                            type: 'warning'
                          })
                        );
                        handleClose();
                        callFunc();
                      }
                    }}>
                    Delete
                  </Button>
                </DialogActions>
              </>
            )}
          </Box>
        </Dialog>
      </Container>
    </Box>
  );
};

export default AddEditRole;

/* eslint-disable react/prop-types */
import React from 'react';
import { Toolbar, Box, useMediaQuery } from '@mui/material';
import Header from './Header';
import Drawer from './Drawer';
import Footer from './footer/Footer';
import { DrawerContextProvider } from '../contexts/drawer-contexts';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const matches = useMediaQuery('(min-width:600px)');

  const toolbarStyle = {
    minHeight: '40px'
  };

  const location = useLocation();
  const paths = location.pathname.split('/');
  const path = paths[paths.length - 1];
  const revokedPath = [
    'pos',
    'callCenter',
    'delivery-monitor',
    'users-managment',
    'outlet',
    'choices',
    'delivery-display',
    'pra-report',
    'outlet-delivery-monitor',
    'dash-board'
  ];
  const showFooter = !revokedPath.includes(path);
  const Foot = showFooter ? Footer : () => {};

  const bodyHeight = showFooter ? 'calc(100vh - 80px)' : 'calc(100vh - 40px)';

  return (
    <DrawerContextProvider>
      <Box
        sx={{
          textAlign: 'center'
        }}>
        <Header />
        <Toolbar style={toolbarStyle} disableGutters />
        <Box
          sx={{
            height: bodyHeight,
            display: matches ? 'flex' : '',
            flex: matches ? 1 : ''
          }}>
          <Box
            sx={{
              display: 'flex',
              width: '100%'
            }}>
            <Drawer />

            <main
              style={{
                overflowY: 'auto',
                height: '100%',
                margin: '0 0 10px 0px',
                boxShadow: '0px 0px 4px 1px rgba(0,0,0,0.6)',
                width: '100%'
              }}>
              {children}
              <Foot />
            </main>
          </Box>
        </Box>
      </Box>
    </DrawerContextProvider>
  );
};

export default Layout;

import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import choicesUrl from './choicesUrl';

// here start
const accessToken = localStorage.getItem('accessToken');

// get all Choices API
export const getChoicesApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(choicesUrl.choices, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(choicesUrl.choices, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get a single Choice
export const getSingleChoiceApi = async (choiceId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!choiceId) {
    return null;
  }
  try {
    const data = await axios.get(`${choicesUrl.choices}/${choiceId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${choicesUrl.choices}/${choiceId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Choice API
export const addChoiceApi = async (choiceData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(choicesUrl.choices, choiceData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(choicesUrl.choices, choiceData, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Choice API
export const editChoiceApi = async (choiceId, choiceData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${choicesUrl.choices}/${choiceId}`,
      choiceData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${choicesUrl.choices}/${choiceId}`,
        choiceData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Ingredient API

export const deleteChoiceApi = async (choiceId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${choicesUrl.choices}/${choiceId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${choicesUrl.choices}/${choiceId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

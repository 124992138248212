/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  CartBox,
  CartCard,
  CartItemBox,
  CustomerInfoBox,
  DineInBox,
  PosButton,
  ExpandMore,
  ButtonsBox,
  IncrDecrBox,
  IncrDecrButton,
  DineInButton,
  TakeAwayButton,
  CatEndButton,
  DiscountParentBox,
  DiscountBox,
  DiscountGrandBox,
  GeneralTypography,
  CartEndButton,
  ModifyCartItemsBox,
  SourceBox,
  DiscountChargeBox
} from './Styled-components';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import { Delete } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlatwareIcon from '@mui/icons-material/Flatware';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeItem,
  setCustomerContact,
  setAlternativeContact,
  setCustomerName,
  setDeliveryAddress,
  setItemChoice,
  setItemNote,
  setOrderType,
  setCarrier,
  resetCart,
  setDeliveryNote,
  setTableName,
  setOrderTime,
  setSource
} from '../../redux/reducers/ducks/CartDuck';
import { addOrdersApi } from '../../api/orders/OrdersApi';
import Receipt from './components/Receipt';
import { currentDate, getPrice } from './Shared';
import Discount from './components/Discount';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import CustomerSearch from '../CallCenter/components/CustomerSearch';
import Charges from './components/Charges';
import { getUsersWithPermissionApi } from '../../api/users/usersApi';
import { permisssions } from '../Shared/shared';
import { getOutletSourcesApi } from '../../api/sources/sourcesApi';
import BusinessIcon from '@mui/icons-material/Business';

export default function Cart({
  outlet,
  outletMenu,
  updateTriggerState,
  orderTypes,
  triggerPrint
}) {
  const dispatch = useDispatch();

  // getting cart from store
  const cart = useSelector((state) => state.cart);
  const {
    items,
    customerName,
    customerContact,
    alternativeContact,
    deliveryAddress,
    deliveryNote,
    orderType,
    tableName,
    carrier,
    source,
    charges,
    preOrder,
    orderTime
  } = cart;
  const myDisplay = useMediaQuery('(max-width: 900px)');

  // handling popup for discount
  const [discountPopup, setDiscountPopup] = useState({
    isOpen: false
  });
  // charges pop up
  const [chargesPopup, setChargesPopup] = useState({
    isOpen: false
  });

  // state to get discounted data from child component
  const [childDiscountData, setChildDiscountData] = useState({});

  // clearing discount popup component state when cart gets empty
  if (cart.items.length == 0) {
    for (let key in childDiscountData) {
      delete childDiscountData[key];
    }
  }
  function checkDealsInCart(cartItems, outletMenu) {
    for (const cartItem of cartItems) {
      const foundmenuItem = outletMenu.find(
        (item) => item.menuItem._id === cartItem.menuItem
      );

      if (
        foundmenuItem &&
        foundmenuItem?.menuItem?.category?.name?.toLowerCase() === 'deals'
      ) {
        return true;
      }
    }

    return null;
  }

  let discountAmount = 0;
  let discountPercentage = 0;
  // get total payable amount
  const getTotal = () => {
    let totalQuantity = 0;
    let totalPrice = 0;

    items.forEach((item) => {
      const menuItem = outletMenu.find(
        (menuItem) => menuItem.menuItem._id === item.menuItem
      );
      let itemPrice = getPrice(menuItem, item);
      totalQuantity += item.quantity;
      totalPrice += itemPrice * item.quantity;
    });

    if (childDiscountData && childDiscountData.fixedDiscount === true) {
      discountAmount = childDiscountData.discount;
      totalPrice -= discountAmount;
    } else if (childDiscountData && childDiscountData.fixedDiscount === false) {
      discountPercentage = (childDiscountData.discount / 100) * totalPrice;
      totalPrice -= discountPercentage;
    }

    return { totalPrice, totalQuantity };
  };
  const total = getTotal();
  // Managing Cart Item Expand
  const [expanded, setExpanded] = useState([]);
  const handleExpandClick = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // handling pop Up for print
  const [print, setPrint] = useState({ isOpen: false, id: '' });
  const running = true;

  // selecting preOrder Time
  const handleTimeChange = (event) => {
    const date = new Date();
    const [hours, minutes] = event.target.value.split(':');
    date.setHours(hours);
    date.setMinutes(minutes);

    if (new Date() > date) date.setDate(date.getDate() + 1);
    if (currentDate() !== currentDate(date))
      return dispatch(
        addSnackbar({
          message: 'At this time order can not be placed',
          type: 'error'
        })
      );
    dispatch(setOrderTime(date));
  };

  // setting up payload to place order

  const handleFormSubmit = async () => {
    const formData = {
      ...cart,
      deliveryAddress: orderType === 'delivery' ? deliveryAddress : '',
      tableName: orderType === 'dineIn' ? tableName : '',
      carrier: orderType === 'dineIn' ? carrier : '',
      source: source,
      outlet: outlet._id,
      discountRef: {
        user: childDiscountData?.userName,
        key: childDiscountData?.key
      },
      // promoCode: applyVoucher && 'defense10',
      careOf: childDiscountData.careOf,
      discount: childDiscountData?.discount,
      fixedDiscount: childDiscountData?.fixedDiscount,
      charges: charges,
      discountReason: childDiscountData?.reason,
      preOrder: preOrder,
      orderTime: orderTime
    };

    const errorMessage =
      cart.orderType === ''
        ? 'Select Order Type'
        : cart.items.length === 0
        ? 'Add Items to place order'
        : (cart.orderType === 'takeAway' || cart.orderType === 'delivery') &&
          cart.customerName === ''
        ? 'Enter Customer Name'
        : cart.orderType === 'dineIn' && cart.tableName === ''
        ? 'Enter Table Number'
        : '';

    try {
      if (errorMessage) {
        dispatch(
          addSnackbar({
            message: errorMessage,
            type: 'error'
          })
        );
      } else {
        setIsDisabled(true);
        const res = await addOrdersApi(formData);

        if (res.status === 200) {
          updateTriggerState(true);
          setChildDiscountData({
            ...childDiscountData,
            discount: 0
          });
          dispatch(resetCart());
          setapplyVoucher(false);
          dispatch(
            addSnackbar({
              message: `Order placed successfully.`,
              type: 'success'
            })
          );
          if (res?.data?.accepted) triggerPrint(res?.data?._id, running);
        } else {
          dispatch(
            addSnackbar({
              message: `${res?.response?.data?.message}`,
              type: 'error'
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          message: 'An error occurred while processing the order.',
          type: 'error'
        })
      );
    } finally {
      setIsDisabled(false);
    }
  };

  // handling switch to show customer search or manual customer data
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  // getting charges
  const totalCharges = charges.reduce(
    (prev, current) => prev + Number(current.amount),
    0
  );

  const tax = 0;
  // const chargesTax = 0;
  const grandTotal = total.totalPrice + (tax || 0) + totalCharges;

  // disable button after order punch till API Recall
  const [isDisabled, setIsDisabled] = useState(false);

  // calling API to get permited users who can apply discount
  const [waiters, setWaiters] = useState([]);
  const [sources, setSources] = useState([]);

  const getUsersWithPermission = async () => {
    const response = await getUsersWithPermissionApi(
      outlet._id,
      permisssions.waiter
    );
    const res = await getOutletSourcesApi(outlet._id);
    setSources(res.data);
    setWaiters(response?.data);
  };
  useEffect(() => {
    getUsersWithPermission();
  }, []);

  const orderTypeButtons = {
    dineIn: {
      button: DineInButton,
      name: 'Dine In',
      icon: FlatwareIcon
    },
    takeAway: {
      button: TakeAwayButton,
      name: 'Take Away',
      icon: TakeoutDiningIcon
    },
    delivery: {
      button: PosButton,
      name: 'Delivery',
      icon: DeliveryDiningIcon
    }
  };
  useEffect(() => {
    if (!orderTypes.includes(orderType)) dispatch(setOrderType(orderTypes[0]));
  }, []);

  // handling switch to show customer search or manual customer data
  const [applyVoucher, setapplyVoucher] = useState(false);

  const handleToggleApplyVoucher = () => {
    let isDeal = checkDealsInCart(cart.items, outletMenu);
    if (isDeal) {
      dispatch(
        addSnackbar({
          message: 'Discount Can not be applied on Deals.',
          type: 'error'
        })
      );
    } else {
      setapplyVoucher(!applyVoucher);
    }
  };

  useEffect(() => {
    if (applyVoucher) {
      setChildDiscountData({
        ...childDiscountData,
        fixedDiscount: false,
        discount: 10,
        reason: ''
      });
    }
  }, [applyVoucher]);

  return (
    <CartBox>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
        <DineInBox>
          {orderTypes.map((type) => {
            const buttonType = orderTypeButtons[type];
            return (
              <buttonType.button
                style={{ borderRadius: 10, margin: 5, minWidth: 100 }}
                ordertype={orderType}
                key={type}
                onClick={() => dispatch(setOrderType(type))}
                variant="outlined"
                startIcon={<buttonType.icon />}>
                {buttonType.name}
              </buttonType.button>
            );
          })}
        </DineInBox>

        {orderType !== 'dineIn' && (
          <SourceBox>
            <FormControl sx={{ width: '50%' }} size="small">
              <InputLabel>Source</InputLabel>
              <Select
                style={{ height: 40, borderRadius: '10px' }}
                defaultValue=""
                value={source || ''}
                inputProps={{
                  style: { padding: 4 }
                }}
                label="Source"
                onChange={(event) => {
                  dispatch(setSource(event.target.value));
                }}>
                {sources?.map((sourc, index) => (
                  <MenuItem key={index} value={sourc._id}>
                    {sourc.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControlLabel
              style={{
                borderRadius: '10px',
                border: '1px solid #ccc',
                padding: '0 5px'
              }}
              label={
                <GeneralTypography>Add Address Manually</GeneralTypography>
              }
              // control={<Switch checked={checked} onChange={handleToggle} />}
              control={<Checkbox checked={checked} onChange={handleToggle} />}
              // label="Add Address Manually"
            /> */}
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '6px 10px',
                cursor: 'pointer',
                borderRadius: '10px',
                border: '1px solid gray',
                gap: '3px'
              }}
              onClick={handleToggle}>
              <BusinessIcon
                style={{ color: checked ? 'blue' : 'gray', cursor: 'pointer' }}
              />
              <GeneralTypography>Add Address Manually</GeneralTypography>
            </Box>
          </SourceBox>
        )}
      </Box>

      <ModifyCartItemsBox>
        <Box
          style={
            {
              //  maxHeight: '150px',
              // height: 'fitcontent'
            }
          }>
          {checked ? (
            <Box style={{ marginTop: '8px' }}>
              <CustomerSearch />
            </Box>
          ) : (
            <CustomerInfoBox>
              {orderType === 'delivery' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px'
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '6px'
                    }}>
                    <TextField
                      inputProps={{
                        style: { padding: 4 }
                      }}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Customer Name"
                      variant="outlined"
                      value={customerName}
                      onChange={(e) =>
                        dispatch(setCustomerName(e.target.value))
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />

                    <TextField
                      inputProps={{
                        style: { padding: 4 }
                      }}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Delivery Address"
                      value={deliveryAddress}
                      onChange={(e) =>
                        dispatch(setDeliveryAddress(e.target.value))
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '6px'
                    }}>
                    <TextField
                      inputProps={{
                        style: { padding: 4 }
                      }}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Customer Contact"
                      value={customerContact}
                      onChange={(e) =>
                        dispatch(setCustomerContact(e.target.value))
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />

                    <TextField
                      inputProps={{
                        style: { padding: 4 }
                      }}
                      InputLabelProps={{ style: { fontSize: 12 } }}
                      label="Second Contact"
                      value={alternativeContact}
                      onChange={(e) =>
                        dispatch(setAlternativeContact(e.target.value))
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </Box>
                </Box>
              ) : null}

              {orderType === 'takeAway' && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '6px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <TextField
                    inputProps={{
                      style: { padding: 4 }
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    label="Customer Name"
                    variant="outlined"
                    value={customerName}
                    onChange={(e) => dispatch(setCustomerName(e.target.value))}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px'
                      }
                    }}
                  />
                  <TextField
                    inputProps={{
                      style: { padding: 4 }
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    label="Customer Contact"
                    value={customerContact}
                    onChange={(e) =>
                      dispatch(setCustomerContact(e.target.value))
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px'
                      }
                    }}
                  />

                  <TextField
                    inputProps={{
                      style: { padding: 4 }
                    }}
                    InputLabelProps={{ style: { fontSize: 12 } }}
                    label="Second Contact"
                    value={alternativeContact}
                    onChange={(e) =>
                      dispatch(setAlternativeContact(e.target.value))
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px'
                      }
                    }}
                  />
                </Box>
              )}

              {orderType === 'dineIn' ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '6px'
                    }}>
                    <Box sx={{ display: 'flex', gap: '5px' }}>
                      <TextField
                        // type="number"
                        inputProps={{
                          style: {
                            padding: 4
                          }
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        label="Table Number"
                        value={tableName}
                        onChange={(e) => {
                          // dispatch(setTableName(e.target.value))
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                          dispatch(setTableName(numericValue));
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px'
                          }
                        }}
                      />
                      <FormControl style={{ width: '50%' }} size="small">
                        <InputLabel>Waiter</InputLabel>
                        <Select
                          style={{
                            height: 30,
                            borderRadius: '10px'
                          }}
                          defaultValue=""
                          inputProps={{
                            style: { padding: 4 }
                          }}
                          label="Waiter"
                          onChange={(event) => {
                            dispatch(setCarrier(event.target.value));
                          }}>
                          {waiters?.map((waiter, index) => (
                            <MenuItem key={index} value={waiter._id}>
                              {waiter.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '5px'
                      }}>
                      <TextField
                        inputProps={{
                          style: { padding: 4 }
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        // label="Customer Contact"
                        // value={customerContact}
                        // onChange={(e) =>
                        //   dispatch(setCustomerContact(e.target.value))
                        // }
                        label="Customer Name"
                        variant="outlined"
                        value={customerName}
                        onChange={(e) =>
                          dispatch(setCustomerName(e.target.value))
                        }
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px'
                          }
                        }}
                      />

                      <TextField
                        style={{
                          width: '50%'
                        }}
                        inputProps={{
                          style: { padding: 4 }
                        }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        // label="Second Contact"
                        // value={alternativeContact}
                        // onChange={(e) =>
                        //   dispatch(setAlternativeContact(e.target.value))
                        // }
                        label="Customer Contact"
                        value={customerContact}
                        onChange={(e) =>
                          dispatch(setCustomerContact(e.target.value))
                        }
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '10px'
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}
            </CustomerInfoBox>
          )}
        </Box>

        <CartItemBox>
          {items?.map((cartItem, index) => {
            const outletItem = outletMenu.find(
              (item) => cartItem.menuItem === item.menuItem._id
            );
            const menuItem = outletItem.menuItem;
            let choices = menuItem.choices;

            return (
              <CartCard key={index}>
                <CardActions sx={{ padding: '0px', height: '35px' }}>
                  <ExpandMore
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={expanded[index]}
                    aria-label="show more">
                    <ExpandMoreIcon />
                  </ExpandMore>
                  <IncrDecrBox>
                    <Box sx={{ width: '190px', textAlign: 'start' }}>
                      <p>
                        {menuItem?.name?.length > 20
                          ? `${menuItem.name.slice(0, 20)}...`
                          : menuItem?.name}
                      </p>
                    </Box>
                    <p>
                      Rs.
                      <b>
                        {Math.round(
                          cartItem?.quantity * getPrice(outletItem, cartItem)
                        )}
                      </b>
                    </p>
                    <ButtonsBox>
                      <IncrDecrButton
                        onClick={() => dispatch(decrementQuantity(index))}>
                        -
                      </IncrDecrButton>
                      <p> {cartItem?.quantity} </p>
                      <IncrDecrButton
                        onClick={() => dispatch(incrementQuantity(index))}>
                        +
                      </IncrDecrButton>
                    </ButtonsBox>
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => dispatch(removeItem(index))}>
                      <Delete />
                    </IconButton>
                  </IncrDecrBox>
                </CardActions>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <CardContent>
                    {choices?.map((choice, choiceIndex) => {
                      const optionId = cartItem.choices.find(
                        (choiceObj) => choice._id === choiceObj.choice
                      ).option;
                      const option = choice.choice.options.find(
                        (option) => option.item === optionId
                      );
                      return choice.choice.optional &&
                        choice.choice.options.length === 1 ? (
                        <FormControlLabel
                          key={choiceIndex}
                          label={`${choice.name}(${choice?.choice.options[0].price}) `}
                          control={
                            <Checkbox
                              checked={
                                cartItem.choices.find(
                                  (choiceObj) => choice._id === choiceObj.choice
                                ).option
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                dispatch(
                                  setItemChoice({
                                    index,
                                    choice: choice._id,
                                    option: event.target.checked
                                      ? choice.choice.options[0].item
                                      : null
                                  })
                                );
                              }}
                            />
                          }
                        />
                      ) : (
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '10px'
                            }
                          }}
                          size="small"
                          key={choiceIndex}
                          defaultValue=""
                          value={option?.item || ''}
                          label={choice?.name}
                          fullWidth
                          margin="normal"
                          select
                          onChange={(event) =>
                            dispatch(
                              setItemChoice({
                                index,
                                choice: choice._id,
                                option: event.target.value
                              })
                            )
                          }>
                          {choice.choice.optional ? (
                            <MenuItem value="">None</MenuItem>
                          ) : (
                            ''
                          )}
                          {choice.choice.options?.map((option, index) => (
                            <MenuItem value={option.item} key={index}>
                              <b style={{ margin: '0 7px 0 0' }}>
                                {option.name}
                              </b>
                              Rs:<b> {option.price}</b>
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    })}

                    <TextField
                      fullWidth
                      type="text"
                      label="Additional Info"
                      variant="outlined"
                      defaultValue={cartItem.note}
                      onBlur={(e) =>
                        dispatch(setItemNote({ index, note: e.target.value }))
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px'
                        }
                      }}
                    />
                  </CardContent>
                </Collapse>
              </CartCard>
            );
          })}
        </CartItemBox>
      </ModifyCartItemsBox>

      <Box sx={{ borderTop: '1px solid gray' }}>
        <Box padding="10px 5px">
          <TextField
            size="small"
            fullWidth
            label="Delivery Note"
            variant="outlined"
            value={deliveryNote} // use value instead of defaultValue
            onChange={(e) => dispatch(setDeliveryNote(e.target.value))}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px'
              }
            }}
          />
        </Box>
        <DiscountParentBox>
          <DiscountChargeBox>
            <DiscountBox>
              <CatEndButton
                variant="contained"
                onClick={() => {
                  setDiscountPopup({
                    isOpen: true
                  });
                }}>
                Discount
              </CatEndButton>
              <Box>
                {childDiscountData.fixedDiscount ? (
                  <strong>{childDiscountData.discount} </strong>
                ) : (
                  <strong>{childDiscountData.discount}%</strong>
                )}
              </Box>
            </DiscountBox>

            {/* {applyVoucher ? (
             <DiscountGrandBox>
                <GeneralTypography>
                  Discount:
                  {total && total.totalPrice && total.totalPrice * 0.1}
                </GeneralTypography>
              </DiscountGrandBox>
            ) : (  */}
            <DiscountGrandBox>
              <GeneralTypography>
                Discount:
                {childDiscountData.fixedDiscount ? (
                  <strong> {discountAmount}</strong>
                ) : (
                  <strong> {discountPercentage}</strong>
                )}
              </GeneralTypography>
            </DiscountGrandBox>
            {/* )} */}
          </DiscountChargeBox>

          {/* <Tooltip title="Apply Defense10">
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyVoucher}
                  onChange={handleToggleApplyVoucher}
                />
              }
              label=""
            />
          </Tooltip> */}

          {/* charges */}
          <DiscountChargeBox>
            <CatEndButton
              variant="contained"
              onClick={() => {
                setChargesPopup({ isOpen: true });
              }}>
              Charges
            </CatEndButton>
            <DiscountGrandBox>
              <GeneralTypography>Charges: {totalCharges}</GeneralTypography>
            </DiscountGrandBox>
          </DiscountChargeBox>
        </DiscountParentBox>

        <Box
          sx={{
            padding: '7px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Box>
            <p style={{ fontSize: '16px' }}>
              Total (<b>{total.totalQuantity}</b> items) :
              <strong>Rs {Math.round(grandTotal)}</strong>
            </p>
          </Box>
          {/* <Box>
            <FormControlLabel
              label={<Typography fontSize="12px">PreOrder</Typography>}
              control={
                <Checkbox
                  size="extra-small"
                  checked={preOrder}
                  // onChange={handlePreOrder}
                  onChange={(e) => {
                    dispatch(setPreOrder(e.target.checked));
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
            />
            {preOrder ? (
              <>
                <input
                  type="time"
                  // value={selectedTime}
                  onChange={handleTimeChange}
                />
              </>
            ) : null}
          </Box> */}

          {/* place order button box */}
          <Box
            style={{
              display: 'flex',
              gap: '10px'
            }}>
            <CartEndButton
              sx={{ backgroundColor: '#19a619', borderRadius: '10px' }}
              disabled={isDisabled}
              variant="contained"
              onClick={() => {
                handleFormSubmit();
              }}>
              {isDisabled ? (
                <CircularProgress size={24} color="secondary" />
              ) : (
                ` Place Order`
              )}
            </CartEndButton>

            <CartEndButton
              sx={{
                backgroundColor: '#e12e2e',
                borderRadius: '10px'
              }}
              variant="contained"
              onClick={() => {
                setChildDiscountData({
                  ...childDiscountData,
                  discount: 0
                });
                setapplyVoucher(false);
                dispatch(resetCart());
              }}>
              Clear
            </CartEndButton>
          </Box>
        </Box>
      </Box>
      <Discount
        discountPopup={discountPopup}
        setDiscountPopup={setDiscountPopup}
        outletId={outlet._id}
        setChildDiscountData={setChildDiscountData}
      />
      <Charges
        chargesPopup={chargesPopup}
        setChargesPopup={setChargesPopup}
        // setchildChargesData={setchildChargesData}
      />
      <Receipt print={print} setPrint={setPrint} running={running} />
    </CartBox>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Tooltip,
  Skeleton,
  TablePagination,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Delete, Edit } from '@mui/icons-material';
import { getDisplayScreensApi } from '../../api/displayscreens/displayScreenApi';
import { StyledTableCell, TableButton } from '../ingredients/Styled-components';
import AddIngredientSet from './AddIngredientSet';
import DeleteIngredientSet from './DeleteIngredientSet';
import EditIngredientSet from './EditIngredientSet';
import { PlaceBox } from './Styled-components';
import { ingredientSetRequest } from '../../redux/reducers/ducks/IngredietSetDuck';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredientSetApi } from '../../api/ingredientSet/ingredientSetApi';
import { theme } from '../../theme';

export default function IngredientSets() {
  const dispatch = useDispatch();
  // state for delete popUp
  const [value, setValue] = useState({ isOpen: false, id: '' });
  // state for Edit popUp
  const [open, setOpen] = useState({ isOpen: false, id: '' });
  // state for Add popUp
  const [addPopup, setAddPopup] = useState({ isOpen: false });

  const [ingredientSet, setIngredientSet] = useState([]);
  const [displayScreen, setDisplayScreen] = useState([]);

  const getDisplayScreens = async () => {
    const res = await getDisplayScreensApi();
    setDisplayScreen(res?.data);
  };

  useEffect(() => {
    getDisplayScreens();
  }, []);

  const [openRows, setOpenRows] = useState(
    Array(ingredientSet.length).fill(false)
  );

  const handleRowClick = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  // fetching data from store and calling API through Saga

  const ingredientSetResponse = useSelector((state) => state.ingredientSet);
  useEffect(() => {
    if (
      !ingredientSetResponse ||
      !ingredientSetResponse.ingredientSetResponse
    ) {
      dispatch(ingredientSetRequest());
    } else {
      setIngredientSet(ingredientSetResponse?.ingredientSetResponse?.data);
    }

    // getDisplayScreens();
  }, [dispatch, ingredientSetResponse, callFunc]);

  // calling API over some data modification to update store
  async function callFunc() {
    dispatch(ingredientSetRequest());
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredIngredients = ingredientSet?.filter(
    (report) =>
      searchQuery
        .toLowerCase()
        .split(' ')
        .every((term) => report?.name?.toLowerCase().includes(term)) ||
      report?.name === 'none' ||
      report?.category?.name
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      report?.category === 'none' ||
      report?.display?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // pagination
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(10);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Ingredient Set"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontSize: '30px',
            fontWeight: 700
          }}>
          Ingredient Sets
        </Typography>
        <div>
          <Tooltip title="Create Ingredient Set">
            <Button
              variant="contained"
              style={{
                padding: ' 10px 30px'
              }}
              onClick={() => {
                setAddPopup({ isOpen: true });
              }}>
              <AddIcon />
              <Typography sx={{ lineHeight: '0', letterSpacing: '0' }}>
                Add Ingredient Set
              </Typography>
            </Button>
          </Tooltip>
        </div>
      </Box>

      {!ingredientSetResponse.isSuccess ? (
        <Box
          sx={{
            height: 'max-content'
          }}>
          {[...Array(10)].map((arr, i) => (
            <Skeleton key={i} variant="rectangular" sx={{ my: 4, mx: 1 }} />
          ))}
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="collapsible table">
            <TableHead sx={{ background: theme.palette.primary.main }}>
              <TableRow>
                <TableCell />
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Display</StyledTableCell>
                <StyledTableCell>User</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredIngredients
                ?.slice(pg * rpg, pg * rpg + rpg)
                .map((singleSet, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            width="0px"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRowClick(index);
                            }}>
                            {openRows[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>{singleSet.name}</TableCell>
                        <TableCell>{singleSet.display?.name}</TableCell>
                        <TableCell>{singleSet.user?.name}</TableCell>
                        <TableCell>
                          {singleSet.active == true ? 'Active' : 'In Active'}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            onClick={() => {
                              setOpen({ isOpen: true, id: singleSet._id });
                            }}>
                            <Edit />
                          </Button>
                          <Button
                            onClick={() => {
                              setValue({ isOpen: true, id: singleSet._id });
                            }}>
                            <Delete />
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}>
                          <Collapse
                            in={openRows[index]}
                            timeout="auto"
                            unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div">
                                Ingredients
                              </Typography>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>Consumed In</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {singleSet.ingredients.map(
                                    (historyRow, index) => {
                                      const resp = [];
                                      for (const [key, value] of Object.entries(
                                        historyRow.consumedIn
                                      )) {
                                        if (value === true) {
                                          resp.push(
                                            <PlaceBox key={key}>{key}</PlaceBox>
                                          );
                                        }
                                      }
                                      return (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {historyRow.ingredient.name}
                                          </TableCell>
                                          <TableCell>
                                            {historyRow.quantity}
                                          </TableCell>
                                          <TableCell></TableCell>
                                          <TableCell
                                            sx={{
                                              display: 'flex',
                                              flexDirection: 'row'
                                            }}>
                                            {resp}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}

              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                count={ingredientSet?.length || 0}
                rowsPerPage={rpg}
                page={pg}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditIngredientSet
        open={open}
        setOpen={setOpen}
        callFunc={callFunc}
        displayScreen={displayScreen}
      />

      <DeleteIngredientSet
        value={value}
        setValue={setValue}
        callFunc={callFunc}
      />

      <AddIngredientSet
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
        displayScreen={displayScreen}
      />
    </>
  );
}

import React, { useEffect } from 'react';
import Slide from '@mui/material/Slide';
import Rating from '@mui/material/Rating';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  Divider,
  TextField
} from '@mui/material';
import { ratingData } from '../../Feedback/keys';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function FeedbackDialog({ details, setDetails }) {
  const handleClose = () => {
    setDetails({ isOpen: false });
  };
  const formatDate = (dateString) => new Date(dateString).toLocaleDateString();

  return (
    <>
      <React.Fragment>
        <Dialog
          open={details.isOpen}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            '& .MuiDialog-paper': {
              width: '80vw',
              height: 'auto',
              maxWidth: '80%',
              maxHeight: '80vh',
              minWidth: '300px',
              minHeight: '200px',
              overflowY: 'auto'
            }
          }}>
          <DialogTitle>
            {details.active ? 'Complaint Details' : 'Feedback Details'}
          </DialogTitle>
          <DialogContent>
            <Box sx={{ mt: 2 }}>
              <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                Customer Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>Name:</strong> {details?.data?.customerName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>Email:</strong> {details?.data?.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>Contact:</strong> {details?.data?.customerContact}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>
                      {details.active ? 'Submission Date:' : 'Visit Date:'}
                    </strong>{' '}
                    {formatDate(
                      details.active
                        ? details?.data?.submissionDate
                        : details?.data?.visitDate
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>Invoice ID:</strong> {details?.data?.invoiceId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                  <Typography variant="body1">
                    <strong>Number of Guests:</strong>{' '}
                    {details?.data?.numberOfGuests}
                  </Typography>
                </Grid>
              </Grid>

              <Typography
                variant="h5"
                style={{ fontWeight: 'bold' }}
                sx={{ mt: 3 }}>
                {details.active ? 'Issue With' : 'Feedback Ratings'}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {details.active ? (
                <Box sx={{ p: 2 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      width: '100%',
                      height: '150px',
                      overflow: 'auto',
                      border: '1px solid',
                      borderRadius: '8px',
                      padding: '8px',
                      boxSizing: 'border-box',
                      backgroundColor: '#fafafa'
                    }}>
                    {details?.data?.issueWith}
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {ratingData?.map(({ key, name }, index) => (
                    <Grid key={index} item xs={6} sm={6} md={4} lg={3} xl={3}>
                      <Typography variant="body1">{name}</Typography>
                      <Rating
                        value={details?.data?.[key] || 1}
                        style={{ color: 'orange' }}
                        readOnly
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
              <Typography
                variant="h5"
                style={{ fontWeight: 'bold' }}
                sx={{ mt: 3 }}>
                {details.active ? 'Comments' : 'Additional Comments'}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                {details?.data?.comments}
              </Typography>

              <Typography variant="caption" sx={{ mt: 3, display: 'block' }}>
                Submitted on: {formatDate(details?.data?.submissionDate)}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </>
  );
}


import React from 'react';
import OutletLineChart from './OutletLineChart';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  BoxGrid,
  BoxIcon,
  ButtonBox,
  ChartBox,
  ChildGrid,
  IconTypography,
  NumberBox,
  ParentBox,
  ParentGrid,
  SaleBox
} from './Styled-component';
import OutletPieChart from './OutletPieChart';
import { useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import CountUp from 'react-countup';
import { calculateTotalSalesAndOrders } from './helper';
import { NavLink } from 'react-router-dom';

export default function Sales({
  dashboardData,
  isLoading,
  startDate,
  endDate
}) {
  const outSale = [
    { name: 'Top', sale: '1499', grow: 3, color: '#8369F1' },
    { name: 'Low n slow', sale: '1499', grow: 3, color: '#FB7269' },
    { name: 'Cold n brew', sale: '1499', grow: 3, color: '#FACC25' }
    // { name: 'Eplus space', sale: '1499', grow: 3, color: '#74C951' },
    // { name: 'Sinenrgy space', sale: '1499', grow: 3, color: '#AFE31F' },
    // { name: 'Very low', sale: '1499', grow: 3, color: '#FF3C00' },
    // { name: 'To graze', sale: '1499', grow: -3, color: 'hotpink' }
  ];

  // Extract the unique outlet names from the API data

  const outletsToTransform = [
    ...new Set(
      dashboardData?.hourly?.flatMap((hourlyData) =>
        hourlyData?.outlets?.map((outlet) => outlet?.name)
      )
    )
  ];
  outletsToTransform?.sort();

  // hourly data for line chart
  function transformOutletData(outletName, dashboardData) {
    const outletData = dashboardData?.hourly
      .sort((a, b) => {
        const x = a.hour <= 6 ? a.hour + 24 : a.hour;
        const y = b.hour <= 6 ? b.hour + 24 : b.hour;
        return x - y;
      })
      ?.map((hourlyData) => {
        const hourData = hourlyData?.outlets?.find(
          (outlet) => outlet?.name === outletName
        );
        return {
          x: `${hourlyData?.hour < 10 ? '0' : ''}${hourlyData?.hour}:00`,
          y: hourData ? hourData?.sales : 0
        };
      });

    return {
      id: outletName,
      // color: "tokens('dark').greenAccent[500]",
      color: getRandomHSLColor(),
      data: outletData
    };
  }

  // Transform the data for each outlet
  const transformedData = outletsToTransform?.map((outletName) =>
    transformOutletData(outletName, dashboardData)
  );

  // *********************data for pie chart************************

  // list of outlets from the API data

  // TODO: This will return only user assigned outlets
  const outlets = useSelector(
    (state) => state.authorize?.loginResponse?.user?.outlets
  ).map((o) => o.tag);
  // const outlets =
  //   dashboardData?.hourly[1]?.outlets?.map((outlet) => outlet?.name) || [];

  // function to generate a random HSL color
  function getRandomHSLColor() {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 50%)`;
  }

  // Create an array to store the data for each outlet
  const outletSalesData =
    outlets?.map((outlet) => {
      const totalSales = dashboardData?.hourly?.reduce((sum, hourlyData) => {
        const outletData = hourlyData?.outlets?.find((o) => o?.name === outlet);
        return sum + (outletData ? outletData?.sales : 0);
      }, 0);

      return {
        id: outlet,
        label: outlet,
        value: totalSales,
        color: getRandomHSLColor()
      };
    }) || [];

  // Calling the function from helper with dashboardData
  const { totalSales, totalOrderCount } =
    calculateTotalSalesAndOrders(dashboardData);

  // Canceled orders count and sale data

  const initialTotal = { canceledSale: 0, totalCanceledOrderCount: 0 };
  let canceledSale = initialTotal.canceledSale;
  let totalCanceledOrderCount = initialTotal.totalCanceledOrderCount;

  if (dashboardData && dashboardData.cancelled) {
    const canceledData = dashboardData.cancelled.reduce((accumulator, item) => {
      accumulator.canceledSale += item.sales;
      accumulator.totalCanceledOrderCount += item.count;
      return accumulator;
    }, initialTotal);

    canceledSale = canceledData.canceledSale;
    totalCanceledOrderCount = canceledData.totalCanceledOrderCount;
  }

  const record = [
    {
      icon: <StickyNote2OutlinedIcon />,
      name: 'Total Orders',
      number: totalOrderCount || 0,
      comparison: -5,
      link: '/reporting/over-all-report'
    },
    {
      icon: <MonetizationOnIcon />,
      name: 'Total Sales',
      number: totalSales || 0,
      comparison: 5,
      link: '/reporting/over-all-report'
    },
    {
      icon: <LineStyleOutlinedIcon />,
      name: 'Canceled Sales',
      number: canceledSale || 0,
      comparison: -4,
      link: '/reporting/canceled-report'
    },
    {
      icon: <HighlightOffOutlinedIcon />,
      name: 'Cancelled Orders',
      number: totalCanceledOrderCount || 0,
      comparison: -6,
      link: '/reporting/canceled-report'
    }
  ];

  return (
    <div>
      <ParentBox>
        {/* *******************Create Box*************************** */}
        <ParentGrid container>
          {record.map((rec, i) => {
            const canceledorders =
              rec.name == 'Cancelled Orders'
                ? rec.name.toLowerCase().replace(' ', '')
                : null;

            const isSale =
              rec.name.toLowerCase() == 'total sales' ||
              rec.name.toLowerCase() == 'net sales'
                ? true
                : false;
            return (
              <ChildGrid key={i} item xl={3} lg={3} md={6} sm={12} xs={12}>
                <SaleBox>
                  {isLoading ? (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}>
                      <CircularProgress size={35} color="secondary" />
                    </Box>
                  ) : (
                    <>
                      <BoxIcon>
                        <IconTypography canceledorders={canceledorders}>
                          {rec.icon}
                        </IconTypography>
                        <Box sx={{ color: 'text.secondary' }}>{rec.name}</Box>
                      </BoxIcon>

                      <NumberBox>
                        <Box
                          sx={{
                            color: 'text.primary',
                            fontSize: 28,
                            fontWeight: 'medium'
                          }}>
                          {isSale ? (
                            <>
                              Rs.
                              <CountUp start={0} end={rec.number} />
                            </>
                          ) : (
                            <CountUp start={0} end={rec.number} />
                          )}
                        </Box>
                      </NumberBox>

                      <NumberBox>
                        {rec.comparison < 0 ? (
                          <>
                            <Box
                              component={TrendingDownIcon}
                              sx={{
                                color: 'error.dark',
                                fontSize: 16,
                                verticalAlign: 'sub'
                              }}
                            />
                            <Box
                              sx={{
                                color: 'error.dark',
                                display: 'inline',
                                fontWeight: 'medium',
                                mx: 0.5
                              }}>
                              {rec.comparison}%
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box
                              component={TrendingUpIcon}
                              sx={{
                                color: 'success.dark',
                                fontSize: 16,
                                verticalAlign: 'sub'
                              }}
                            />
                            <Box
                              sx={{
                                color: 'success.dark',
                                display: 'inline',
                                fontWeight: 'medium',
                                mx: 0.5
                              }}>
                              {rec.comparison}%
                            </Box>
                          </>
                        )}

                        <Box
                          sx={{
                            color: 'text.secondary',
                            display: 'inline',
                            fontSize: 12
                          }}>
                          from last selected time
                        </Box>
                        <NavLink to={rec.link}>
                          <ButtonBox>
                            <Typography>View detail</Typography>
                            <KeyboardArrowRightOutlinedIcon />
                          </ButtonBox>
                        </NavLink>
                      </NumberBox>
                    </>
                  )}
                </SaleBox>
              </ChildGrid>
            );
          })}
        </ParentGrid>
        {/* ******************************Graph Box************************** */}

        <Grid container>
          <BoxGrid item xl={7} lg={7} md={6} sm={12} xs={12}>
            {/* <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}>
              {outSale.map((out, i) => (
                <Box
                  key={i}
                  sx={{
                    boxShadow: 3,
                    bgcolor: (theme) =>
                      theme.palette.mode === 'dark' ? '#101010' : '#fff',
                    color: (theme) =>
                      theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                    p: 1,
                    m: 1,

                    borderRadius: 2,
                    textAlign: 'center',
                    fontSize: '0.875rem',
                    fontWeight: '700',
                    borderTop: '2px solid ' + out.color
                  }}>
                  <Typography>{out.name}</Typography>
                  <Box display="flex" alignItems="center" gap="5px">
                    <Typography>
                      <h3>{out.sale}</h3>
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: out.grow > 0 ? '#73CB50' : 'red'
                      }}>
                      {out.grow > 0 ? `+${out.grow}%` : `${out.grow}%`}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box> */}
            <ChartBox height="60vh" m="20px">
              {startDate && endDate ? (
                isLoading ? (
                  <Loader />
                ) : (
                  <OutletLineChart transformedData={transformedData} />
                )
              ) : (
                <>Select Date to see Branches Hourly Performance</>
              )}
            </ChartBox>
          </BoxGrid>
          <BoxGrid item xl={5} lg={5} md={6} sm={12} xs={12}>
            <ChartBox sx={{ height: '60vh' }} mt="20px">
              {startDate && endDate ? (
                isLoading ? (
                  <Loader />
                ) : (
                  <OutletPieChart outletSalesData={outletSalesData} />
                )
              ) : (
                <>Select Date to see Branches Sales</>
              )}
            </ChartBox>
          </BoxGrid>
        </Grid>
      </ParentBox>
    </div>
  );
}


/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Box,
  InputAdornment,
  IconButton,
  Tooltip,
  Typography,
  CircularProgress,
  DialogContent
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { DeleteOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { ingredientRequest } from '../../../redux/reducers/ducks/IngredientDuck';
import {
  addWasteApi,
  editWasteApi,
  getSingleWasteApi
} from '../../../api/stocks/waste/wasteApi';
import { addSnackbar } from '../../../redux/reducers/ducks/SnackbarDuck';

const getById = (collection, id) => {
  for (const item of collection) if (item._id == id) return item;
  return null;
};

export default function WasteDialog({
  openDialog,
  setOpenDialog,
  outletId,
  callFunc
}) {
  // get single purchase Api
  const [singleWaste, setSingleWaste] = useState();
  const getSingleWaste = async () => {
    if (openDialog.id) {
      const res = await getSingleWasteApi(openDialog.id);
      setSingleWaste(res.data);
      reset(res?.data);
    }
  };

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: singleWaste
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const dispatch = useDispatch();
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const ingredientResponse = useSelector((state) => state.ingredient);

  useEffect(() => {
    if (!ingredientResponse || !ingredientResponse.ingredientResponse) {
      dispatch(ingredientRequest());
    } else {
      setIngredientOptions(ingredientResponse?.ingredientResponse);
    }
  }, [dispatch, ingredientResponse]);

  const [formData, setFormData] = useState({});

  const onSubmit = async (data) => {
    setFormData({
      outlet: outletId,
      ingredients: data.ingredients,
      desc: data.desc
    });
  };

  // confirm dialogue
  const [addPopup, setAddPopup] = useState(false);

  // handling state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const confirmSubmit = async () => {
    setIsButtonDisabled(true);
    setIsLoading(true);
    setErrorMessage('');

    if (openDialog.id) {
      const res = await editWasteApi(openDialog.id, formData);
      if (res?.status == 200) {
        dispatch(
          addSnackbar({
            message: 'Waste Updated',
            type: 'success'
          })
        );
        handleConfirmClose();
        handleClose();
        callFunc();
      }
    } else {
      try {
        const res = await addWasteApi(formData);
        if (res?.status == 200) {
          dispatch(
            addSnackbar({
              message: 'Waste Added',
              type: 'success'
            })
          );
          handleConfirmClose();
          handleClose();
          callFunc();
        } else {
          setErrorMessage(res?.response?.data?.message);
          dispatch(
            addSnackbar({
              message: errorMessage,
              type: 'error'
            })
          );
        }
      } catch (error) {
        setErrorMessage(error);
      } finally {
        setIsLoading(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleConfirmClose = () => {
    setAddPopup(false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };
  // // confirm dialogue

  useEffect(() => {
    if (openDialog.id) {
      getSingleWaste();
    }
    reset();
  }, [openDialog]);

  const handleClose = () => {
    reset();
    setOpenDialog('isOpen', false);
    setIsLoading(false);
    setIsButtonDisabled(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="auto"
        open={openDialog?.isOpen ?? false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {openDialog.id ? (
          <DialogTitle>Edit Waste</DialogTitle>
        ) : (
          <DialogTitle>Add Waste</DialogTitle>
        )}

        <Box
          sx={{
            width: '500px'
            // height: '500px'
          }}>
          <Box sx={{ p: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                marginBottom="10px"
                size="small"
                fullWidth
                label="Description"
                type="text"
                {...register(`desc`)}
              />
              <TextField
                label="Click to Add Ingredients"
                onChange={(e) =>
                  e.target.value &&
                  append({
                    ingredient: e.target.value,
                    quantity: '',
                    value: getById(ingredientOptions, e.target.value)?.value,
                    desc: ''
                  })
                }
                value={''}
                fullWidth
                select
                margin="normal">
                <MenuItem key="" value="">
                  Select Ingredient
                </MenuItem>
                {ingredientOptions
                  ?.filter(
                    (ingredient) =>
                      !fields.map((v) => v.ingredient).includes(ingredient._id)
                  )
                  ?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>

              <Box>
                {fields.map((ingredient, index) => (
                  <Box
                    sx={{
                      border: '1px solid gray',
                      margin: '10px 0',
                      borderRadius: '7px',
                      padding: '0 5px'
                    }}
                    key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}>
                      <Typography>
                        {
                          getById(ingredientOptions, ingredient.ingredient)
                            ?.name
                        }
                      </Typography>
                      <Tooltip title="Delete Ingredient">
                        <IconButton
                          sx={{
                            color: 'red',
                            width: '10%'
                          }}
                          onClick={() => remove(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                      }}>
                      <TextField
                        size="small"
                        {...register(`ingredients.${index}.quantity`, {
                          required: true
                        })}
                        type="number"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {getById(ingredientOptions, ingredient.ingredient)
                                ?.unit?.code || ''}
                            </InputAdornment>
                          )
                        }}
                        variant="outlined"
                      />
                      <TextField
                        size="small"
                        label="Value"
                        type="text"
                        value={ingredient.value}
                        defaultValue={ingredient.value}
                        // {...register(`ingredients.${index}.value`, {
                        //   required: true
                        // })}
                      />

                      <TextField
                        size="small"
                        label="Description"
                        type="text"
                        {...register(`ingredients.${index}.desc`)}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>

              <Button
                type="submit"
                disabled={isButtonDisabled}
                onClick={() => {
                  setAddPopup(true);
                }}
                variant="contained">
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
              <Button variant="outlined" onClick={handleClose} autoFocus>
                close
              </Button>
            </form>
          </Box>
        </Box>
      </Dialog>

      <Dialog open={addPopup} onClose={handleConfirmClose}>
        <Box
          sx={{
            textAlign: 'center',
            width: 'min(100% -30px, 700px)',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#FFA500" fontWeight="700">
              Confirm Submit
            </Typography>
          </DialogTitle>
          <>
            <DialogContent>
              <p>Are you sure to Add Waste?</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClose} autoFocus>
                cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                onClick={() => {
                  confirmSubmit();
                }}>
                {isLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </div>
  );
}

/* eslint-disable no-unused-vars */
import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import ingredientSetUrls from './ingredientSetUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get Ingredient Set API
export const getIngredientSetApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(ingredientSetUrls.ingredientSets, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(ingredientSetUrls.ingredientSets, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get a single Ingredient Set
export const getSingleIngredientSetApi = async (ingredientSetId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!ingredientSetId) {
    return null;
  }
  try {
    const data = await axios.get(
      `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Ingredient Set API
export const addIngredientSetApi = async (ingredientSet) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(
      ingredientSetUrls.ingredientSets,
      ingredientSet,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(
        ingredientSetUrls.ingredientSets,
        ingredientSet,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Ingredient Set API
export const editIngredientSetApi = async (ingredientSetId, ingredientSet) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
      ingredientSet,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
        ingredientSet,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Ingredient API

export const deleteIngredientSetApi = async (ingredientSetId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${ingredientSetUrls.ingredientSets}/${ingredientSetId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

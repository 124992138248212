import { fetchWithAuth } from '../refreshTokenApi';
import walkSheetUrls from './walkSheetUrls';

// get All Walk Sheets Quesions API
export const getwalkSheetsApi = async () => {
  const result = await fetchWithAuth(walkSheetUrls.walksheet);
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// Add Suppliers API

export const addWalkSheetApi = async (formData) => {
  const result = await fetchWithAuth(walkSheetUrls.addWalkSheet, {
    body: formData,
    method: 'POST'
  });
  if (result.message) return { response: { data: result } };
  return { data: result, status: 200 };
};

// get All walkSheetHistory API
export const getWalkSheetHistoryApi = async (outletId) => {
  const result = await fetchWithAuth(
    `${walkSheetUrls.walkSheetHistory}/${outletId}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

// get All walkSheet shiftWise API
export const getWalkSheetShiftWiseApi = async (outletId, date, shift) => {
  const result = await fetchWithAuth(
    `${walkSheetUrls.getWalkSheet}/outlet/${outletId}/date/${date}/shift/${shift}`
  );
  if (result.message) return { error: { data: { message: result.message } } };
  return { data: result };
};

/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getdeletedItemsReportApi } from '../../api/report/reportApi';
import Loader from '../Loader/Loader';
import { timestamp } from '../Shared/shared';
import { getOutletsApi } from '../../api/outlet/outletApi';
import { HeaderTableCell } from './Styled-components';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OrderDetails from '../Shared/OrderDetail/OrderDetails';
import DatePickerComponent from '../Shared/DatePickerComponent';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { useDispatch } from 'react-redux';

export default function DeletedItemReport() {
  const dispatch = useDispatch();
  const [outlets, setOutlets] = useState([]);

  const [outletSaleReport, setOutletSaleReport] = useState([]);

  const [selectOutlet, setSelectOutlet] = useState('');

  const handleChange = (event) => {
    setSelectOutlet(event.target.value);
  };

  // getting all outlets
  const getOutlets = async () => {
    const res = await getOutletsApi();
    setOutlets(res?.data);
  };
  // handlng date
  const [datePopup, setDatePopup] = useState({
    isOpen: false,
    startDate: '',
    endDate: '',
    isSelected: false
  });

  useEffect(() => {
    getOutlets();
    if (datePopup.isSelected === true) {
      reportApi();
    }
  }, [datePopup]);

  // calling APi
  const [isLoading, setLoading] = useState(false);
  const reportApi = async () => {
    if (selectOutlet === '') {
      dispatch(
        addSnackbar({
          message: 'Kindly Select an Outlet...',
          type: 'warning'
        })
      );
    } else {
      try {
        setLoading(true);
        const response = await getdeletedItemsReportApi(
          selectOutlet,
          datePopup?.startDate,
          datePopup?.endDate
        );
        setOutletSaleReport(response?.data);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  // pagination
  const [pg, setpg] = useState(0);
  const [rpg, setrpg] = useState(20);

  function handleChangePage(event, newpage) {
    setpg(newpage);
  }

  function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
  }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = outletSaleReport?.filter((report) =>
    searchQuery
      .toLowerCase()
      .split(' ')
      .every((term) => {
        return (
          report?.invoiceId?.toLowerCase().includes(term) ||
          report?.modifiedBy?.name?.toLowerCase().includes(term) ||
          report?.deletedRef?.name?.toLowerCase().includes(term) ||
          report?.item?.toLowerCase().includes(term) ||
          report?.paymentMethod?.name?.toLowerCase().includes(term)
        );
      })
  );

  // calling order Details Component
  const [details, setDetails] = useState({ isOpen: false, id: '' });

  return (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'space-around',
          boxShadow: '0px 0px 2px 1px #cbd5e0',
          margin: '10px'
        }}>
        <TextField
          label="Search Order by Id, Cust. Name, Contact or Rider"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 'clamp(1rem, 2vW + 0.5rem, 1rem)'
          }}>
          Branch Wise Deleted Items Report
        </Typography>
        <Box display="flex" alignItems="center">
          <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
            <InputLabel id="demo-select-small-label">Select Outlet</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectOutlet}
              label="Select Outlet"
              onChange={handleChange}>
              {outlets.map((outlet, i) => (
                <MenuItem key={i} value={outlet._id}>
                  {outlet.tag}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePickerComponent
            datePopup={datePopup}
            setDatePopup={setDatePopup}
          />
        </Box>
      </Box>

      {isLoading ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} sx={{ overflowY: 'auto' }}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                background: '#008670',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: '1300'
              }}>
              <TableRow>
                <HeaderTableCell>Order Id</HeaderTableCell>
                <HeaderTableCell>Order Time</HeaderTableCell>
                <HeaderTableCell>Deleted Item</HeaderTableCell>
                <HeaderTableCell>Quantity</HeaderTableCell>
                <HeaderTableCell>Price</HeaderTableCell>
                <HeaderTableCell>Delete Time</HeaderTableCell>
                <HeaderTableCell>Deleted By</HeaderTableCell>
                <HeaderTableCell>Refrence</HeaderTableCell>
                <HeaderTableCell>View</HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: 'auto' }}>
              <>
                {filteredOrders
                  ?.slice(pg * rpg, pg * rpg + rpg)
                  .map((report, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{report?.invoiceId}</TableCell>
                        <TableCell>{timestamp(report?.orderTime)}</TableCell>
                        <TableCell>{report?.item}</TableCell>
                        <TableCell>{report?.quantity}</TableCell>
                        <TableCell>
                          {Math.round(report?.discountedPrice + report?.tax)}
                        </TableCell>
                        <TableCell>{timestamp(report?.deleted)}</TableCell>
                        <TableCell>{report?.modifiedBy?.name}</TableCell>
                        <TableCell>{report?.deletedRef?.name}</TableCell>

                        <TableCell>
                          <Button
                            onClick={() => {
                              setDetails({ isOpen: true, id: report?._id });
                            }}>
                            <RemoveRedEyeIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[20, 50, 100]}
                  count={filteredOrders?.length || 0}
                  rowsPerPage={rpg}
                  page={pg}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <OrderDetails details={details} setDetails={setDetails} />
    </>
  );
}

import { getApi } from '../../../components/Shared/shared';
const baseURL = getApi();

export const inventoryContollerUrls = {
  inventoryController: `${baseURL}/inventory-controllers`,
  inventoryControllers: `${baseURL}/inventory-controllers/outlet`,
  outletWastes: `${baseURL}/wastes/outlet`
};

export default inventoryContollerUrls;

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const customerUrls = {
  user: `${baseURL}/users`,
  searchCustomer: `${baseURL}/users/contacts`,
  addCustomer: `${baseURL}/users/customer`
};

export default customerUrls;

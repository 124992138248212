import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

const TableComponent = ({ columns, data }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead style={{ backgroundColor: '#9e9289' }}>
          <TableRow>
            {columns &&
              columns.map((column) => (
                <TableCell key={column.accessor}>{column.Header}</TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => {
                  const value = row[column.accessor];
                  return (
                    <TableCell key={column.accessor}>
                      {typeof value === 'number'
                        ? column.accessor === 'discountRate'
                          ? (value * 100).toFixed(2)
                          : value.toFixed(2)
                        : column.accessor === 'saleDate' ||
                          column.accessor === 'orderTime'
                        ? new Date(value).toLocaleString()
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableComponent;


import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const ordersUrl = {
  orders: `${baseURL}/orders`,
  running: `${baseURL}/orders/running`,
  operation: `${baseURL}/orders/operation`,
  dispatch: `${baseURL}/orders/ready-to-dispatch`,
  serve: `${baseURL}/orders/ready-to-serve`,
  assemble: `${baseURL}/orders/ready-to-assemble`,
  deliver: `${baseURL}/orders/ready-to-deliver`,
  dineInToFeedback: `${baseURL}/orders/no-feedback`
};

export default ordersUrl;

// {{API}}/api/orders/no-feedback?outlet={{JC}}

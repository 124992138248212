/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import swal from 'sweetalert';
import SaveIcon from '@mui/icons-material/Save';
import Loader from '../Loader/Loader';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  AccordionGrid,
  HeaderCell,
  ServicesGrid,
  StyledAccordion,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TopBox
} from './Styled-components';
import {
  addWalkSheetApi,
  getwalkSheetsApi
} from '../../api/walkSheet/walkSheetApi';
import { kitchen, parking, washroom } from './shared';
import { pink } from '@mui/material/colors';
import { getUsersApi } from '../../api/users/usersApi';

export default function AddWalkSheet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const obj = params;
  const outletstr = obj['*'];
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1];

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const [isLoading, setLoading] = useState(false);

  // disable button after order punch till API Recall
  const [isDisabled, setIsDisabled] = useState(false);

  const [section, setSection] = useState();

  // const getQuestions = async () => {
  //   const res = await getwalkSheetsApi();
  //   setSection(res?.data);
  // };

  // useEffect(() => {
  //   getQuestions();
  // }, []);

  const [users, setUsers] = useState([]);

  const OutletStaff = users.filter((user) =>
    user.outlets.some((userOutlet) => userOutlet._id === outlet._id)
  );

  // const FOH = ['serveOrders', 'placeBranchOrders'];
  // const BOH = ['assembleOrders', 'kitchenOrders', 'dispatchOrders'];
  // const riderSec = OutletStaff.filter((staff) =>
  //   staff.role.access.includes('canDeliver')
  // );

  // const frontOfHouseSec = OutletStaff.filter((staf) =>
  //   FOH.some((access) => staf.role.access.includes(access))
  // );
  // const backOfHouseSec = OutletStaff.filter((staf) =>
  //   BOH.some((access) => staf.role.access.includes(access))
  // );

  const initQuestions = async () => {
    try {
      setLoading(true);
      const res = await getwalkSheetsApi();

      setSection(res.data);

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    initQuestions();
  }, []);

  const { register, handleSubmit, setValue, control } = useForm({});

  let formData = null;

  const onSubmit = async (data) => {
    formData = {
      outlet: outlet._id,
      questions: data.questions,
      shift: shift
    };
  };

  // Array to store radio values for each question

  const [shift, setShift] = useState('day');

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };

  // confirm dialogue

  const [addPopup, setAddPopup] = useState(false);

  const confirmSubmit = async () => {
    try {
      setIsDisabled(true);
      const res = await addWalkSheetApi(formData);
      if (res?.status == 200) {
        swal('Success', 'Walk Sheet added Successfully', 'success');

        navigate(`/outlet/${outlet.code.toLowerCase()}/walk-sheet`);
      } else {
        dispatch(
          addSnackbar({
            message: res?.response?.data?.message || 'some error occured',
            type: 'error'
          })
        );
      }
    } catch (error) {
      dispatch(
        addSnackbar({
          message: 'An error occurred while processing the Walk Sheet.',
          type: 'error'
        })
      );
    } finally {
      setIsDisabled(false);
    }
  };

  const handleClose = () => {
    setAddPopup(false);
  };

  return (
    <>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <TopBox>
              <FormControl>
                <FormLabel>SHIFT</FormLabel>
                <RadioGroup
                  name="controlled-radio-buttons-group"
                  value={shift}
                  onChange={handleChangeShift}>
                  <Box sx={{ display: 'flex' }}>
                    <FormControlLabel
                      value="day"
                      control={
                        <Radio
                          sx={{
                            color: pink[800]
                          }}
                        />
                      }
                      label="Day"
                    />
                    <FormControlLabel
                      value="night"
                      control={
                        <Radio
                          sx={{
                            color: pink[800]
                          }}
                        />
                      }
                      label="Night"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </TopBox>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ServicesGrid container spacing={3}>
                <AccordionGrid item xs={12} lg={12} pr="24px">
                  {section?.map((item, index) => (
                    <StyledAccordion checkid={index} key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <img
                            width={50}
                            height={50}
                            src={
                              item.title.toLowerCase() == 'wash room'
                                ? washroom
                                : item.title.toLowerCase() == 'dine in'
                                ? kitchen
                                : item.title.toLowerCase() == 'parking lot'
                                ? parking
                                : 'https://www.iconpacks.net/icons/2/free-click-icon-2384-thumb.png'
                            }
                          />
                          <StyledTypography>{item.title}</StyledTypography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TableContainer component={Paper}>
                          <Table size="small" border={1}>
                            <TableHead>
                              <TableRow>
                                <HeaderCell align="left">Sr.</HeaderCell>
                                <HeaderCell align="left">Question</HeaderCell>
                                <HeaderCell align="left">Answer</HeaderCell>
                                <HeaderCell align="left">
                                  Description
                                </HeaderCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item?.questions?.map((row, index) => (
                                <StyledTableRow key={row._id} checkid={index}>
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.detail}
                                  </TableCell>
                                  <TableCell align="left">
                                    <FormControl>
                                      <Controller
                                        control={control}
                                        name={`questions[${row._id}].radioValue`}
                                        defaultValue=""
                                        render={({ field }) => (
                                          <RadioGroup
                                            {...field}
                                            value={field.value || ''}
                                            onChange={(event) =>
                                              field.onChange(event.target.value)
                                            }>
                                            <FormControlLabel
                                              value="yes"
                                              control={<Radio />}
                                              label="Yes"
                                            />
                                            <FormControlLabel
                                              value="no"
                                              control={<Radio />}
                                              label="No"
                                            />
                                          </RadioGroup>
                                        )}
                                      />
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="left">
                                    <TextField
                                      size="small"
                                      label="Enter Description"
                                      {...register(
                                        `questions[${row._id}].desc`
                                      )}
                                    />
                                  </TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </StyledAccordion>
                  ))}

                  {/* 
                <StyledAccordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        width={50}
                        height={50}
                        src="https://www.iconpacks.net/icons/2/free-click-icon-2384-thumb.png"
                      />
                      <StyledTypography>Staff Managment</StyledTypography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table size="small" border={1}>
                        <TableHead>
                          <TableRow>
                            <HeaderCell>FOH</HeaderCell>
                            <HeaderCell>BOH</HeaderCell>
                            <HeaderCell>Riders</HeaderCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {frontOfHouseSec.map((foh, index) => (
                                <TableRow key={foh._id}>
                                  <TableCell align="left">
                                    {`${index + 1} ${foh.name}`}
                                  </TableCell>
                                  <TableCell align="left">
                                    <TextField
                                      size="small"
                                      label="Enter Time"
                                      {...register(`staff[${foh._id}].time`)}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableCell>

                            <TableCell>
                              {riderSec.map((foh, index) => (
                                <TableRow key={foh._id}>
                                  <TableCell align="left">
                                    {`${index + 1} ${foh.name}`}
                                  </TableCell>
                                  <TableCell align="left">
                                    <TextField
                                      size="small"
                                      label="Enter Time"
                                      {...register(`staff[${foh._id}].time`)}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableCell>
                            <TableCell>
                              {backOfHouseSec.map((foh, index) => (
                                <TableRow key={foh._id}>
                                  <TableCell align="left">
                                    {`${index + 1} ${foh.name}`}
                                  </TableCell>
                                  <TableCell align="left">
                                    <TextField
                                      size="small"
                                      label="Enter Time"
                                      {...register(`staff[${foh._id}].time`)}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableCell>
                            <button type="submit"> submit</button>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </StyledAccordion> */}
                </AccordionGrid>
              </ServicesGrid>

              <Box sx={{ margin: '5px 0' }}>
                <Button
                  variant="contained"
                  disabled={isDisabled}
                  onClick={() => {
                    setAddPopup(true);
                  }}
                  type="submit">
                  {isDisabled ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                      }}>
                      <SaveIcon />
                      Submit
                    </Box>
                  )}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Container>

      <Dialog open={addPopup} onClose={handleClose}>
        <Box
          sx={{
            textAlign: 'center',
            width: 'min(100% -30px, 700px)',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#FFA500" fontWeight="700">
              Confirm Submit
            </Typography>
          </DialogTitle>
          <>
            <DialogContent>
              <p>Are you sure to submit</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                cancel
              </Button>
              <Button
                disabled={isDisabled}
                variant="contained"
                onClick={() => {
                  confirmSubmit();
                }}>
                {isDisabled ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </DialogActions>
          </>
        </Box>
      </Dialog>
    </>
  );
}

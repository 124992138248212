import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  items: [],
  orderType: 'delivery',
  customerName: '',
  customerContact: '',
  alternativeContact: '',
  deliveryAddress: '',
  tableName: '',
  carrier: '',
  source: null || undefined,
  deliveryNote: '',
  modifyOrder: '',
  outlet: '',
  discount: '',
  fixedDiscount: false,
  discountRef: {
    key: '',
    user: ''
  },
  discountReason: '',
  customer: '',
  area: '',
  charges: [],
  preOrder: '',
  orderTime: '00:00'
};
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const menuItem = action.payload.menuItem;
      state.items.push({
        menuItem: menuItem._id,
        note: '',
        choices: menuItem.choices.map((choice) => ({
          choice: choice._id,
          option: choice.choice.defaultOption || null
        })),
        quantity: 1
      });
    },
    incrementQuantity: (state, action) => {
      state.items[action.payload].quantity++;
    },
    decrementQuantity: (state, action) => {
      const item = state.items[action.payload]; // state.items.find((item) => item._id === action.payload);

      if (item.quantity === 1) {
        item.quantity = 1;
      } else {
        item.quantity--;
      }
    },
    removeItem: (state, action) => {
      state.items.splice(action.payload, 1); // removeItem;
    },
    setItems: (state, action) => {
      state.items = action.payload; // set Items from
    },
    setItemNote: (state, action) => {
      const { index, note } = action.payload;
      state.items[index].note = note;
    },
    setItemChoice: (state, action) => {
      const { index, choice, option } = action.payload;
      state.items[index].choices.find(
        (choiceObj) => choiceObj.choice === choice
      ).option = option;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setCustomerContact: (state, action) => {
      state.customerContact = action.payload;
    },
    setAlternativeContact: (state, action) => {
      state.alternativeContact = action.payload;
    },

    setDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    setDeliveryNote: (state, action) => {
      state.deliveryNote = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setSelectedOutlet: (state, action) => {
      state.outlet = action.payload;
    },
    setTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setCarrier: (state, action) => {
      state.carrier = action.payload;
    },
    setSource: (state, action) => {
      state.source = action.payload;
    },
    resetCart(state) {
      Object.assign(state, initialState);
    },
    modifyOrder(state, action) {
      Object.assign(state, initialState);
      state.modifyOrder = action.payload;
    },
    copyOrder: (state, action) => {
      Object.assign(state, action.payload);
    },
    setDiscount: (state, action) => {
      const { discount, fixedDiscount, discountRef, reason } = action.payload;
      state.discount = discount;
      state.fixedDiscount = fixedDiscount;
      state.discountRef = {
        key: discountRef.key,
        user: discountRef.user
      };
      state.discountReason = reason;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    setAddressAndArea: (state, action) => {
      const { address, area } = action.payload;
      state.deliveryAddress = address;
      state.area = area;
    },
    setCharges: (state, action) => {
      state.charges = action.payload;
    },
    setPreOrder: (state, action) => {
      state.preOrder = action.payload;
    },
    setOrderTime: (state, action) => {
      state.orderTime = action.payload;
    }
  }
});

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeItem,
  setItems,
  setItemChoice,
  setItemNote,
  setCustomerName,
  setCustomerContact,
  setAlternativeContact,
  setDeliveryAddress,
  setDeliveryNote,
  setOrderType,
  setTableName,
  setCarrier,
  setSource,
  resetCart,
  modifyOrder,
  copyOrder,
  setSelectedOutlet,
  setDiscount,
  setCustomer,
  setAddressAndArea,
  setCharges,
  setPreOrder,
  setOrderTime
} = cartSlice.actions;
export default cartSlice.reducer;

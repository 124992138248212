/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent
} from '@mui/material';
import { SingleOrderTypography } from '../Styled-components';

export default function OrderDetails({ addPopup, setAddPopup, singleOrder }) {
  const handleClose = () => {
    setAddPopup({ isOpen: false });
  };

  const totalPayable = Math.round(singleOrder?.total + singleOrder?.tax);

  return (
    <div>
      <Dialog open={addPopup.isOpen} onClose={handleClose}>
        <Box
          sx={{
            textAlign: 'center',
            // width: '700px',
            marginInline: 'auto'
          }}>
          <DialogTitle id="alert-dialog-title">
            <Typography color="#6d1b7b" fontWeight="700">
              Order Details
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box textAlign="left">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: '10px'
                }}>
                <Box>
                  <SingleOrderTypography>
                    <b>Branch: </b>
                    {singleOrder?.outlet.name}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Customer: </b>
                    {singleOrder?.customerName}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Contact: </b>
                    {singleOrder?.customerContact}
                  </SingleOrderTypography>
                </Box>
                <Box>
                  <SingleOrderTypography>
                    <b>Delivery Address: </b>
                    {singleOrder?.deliveryAddress}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Accepted By: </b>
                    {singleOrder?.acceptedBy?.name}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Order Type: </b>
                    {singleOrder?.orderType}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>
                      {singleOrder?.orderType.toLowerCase() === 'dinein'
                        ? 'Waiter: '
                        : 'Rider: '}
                    </b>
                    {singleOrder?.carrier?.name}
                  </SingleOrderTypography>

                  {singleOrder?.source && (
                    <SingleOrderTypography>
                      <b>Order Source: </b>
                      {singleOrder?.source?.name}
                    </SingleOrderTypography>
                  )}
                </Box>
              </Box>
              <TableContainer
                component={Paper}
                sx={{ height: '300px', overflowY: 'auto' }}>
                <Table aria-label="collapsible table">
                  <TableHead sx={{ background: '#FFA500', color: 'white' }}>
                    <TableRow>
                      <TableCell color="#fff">Item</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell>Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {singleOrder?.items.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <TableRow>
                            <TableCell color="#fff">{item.name}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{Math.round(item.discount)}</TableCell>
                            <TableCell>
                              {item.discountedPrice + item?.tax}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell colSpan={5}>
                              <span>
                                <strong>Choices: </strong>
                                <Typography>
                                  (
                                  {item?.choices?.reduce(
                                    (prev, current) =>
                                      (prev += current.optionName
                                        ? current.optionName +
                                          ` ${
                                            current.price
                                              ? ` Rs. ${current.price}`
                                              : ''
                                          }` +
                                          ', '
                                        : ''),
                                    ''
                                  )}
                                  )
                                </Typography>
                              </span>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {!singleOrder?.deliveryNote == '' && (
                <b>Delivery Note:{singleOrder?.deliveryNote} </b>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '10px'
                }}>
                <Box>
                  <SingleOrderTypography>
                    <b>Sub-Total: </b>{' '}
                    {Math.round(singleOrder?.subTotal + singleOrder?.subTax)}
                  </SingleOrderTypography>
                  <SingleOrderTypography>
                    <b>Discount: </b> {singleOrder?.discount}
                  </SingleOrderTypography>
                </Box>
                <Box>
                  {/* <SingleOrderTypography>
                    {!singleOrder?.tax == 0 && (
                      <>
                        <b>Tax: </b> {Math.round(singleOrder?.tax)}
                      </>
                    )}
                  </SingleOrderTypography> */}
                  <SingleOrderTypography>
                    <b>Total Payable: </b>
                    {totalPayable}
                  </SingleOrderTypography>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

import {
  Accordion,
  Box,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
  styled
} from '@mui/material';

export const AccordionGrid = styled(Grid)(({ theme }) => ({
  marginTop: '0px',
  [theme.breakpoints.down('lg')]: {
    paddingRight: '0px',
    marginTop: '10px'
  }
}));

export const StyledAccordion = styled(Accordion)(({ checkId, theme }) => ({
  boxShadow: '0 0 7px rgba(0, 0, 0, 0.12)',
  marginTop: checkId === 0 ? '0px' : '15px',
  '&:before': {
    backgroundColor: 'transparent'
  },
  [theme.breakpoints.down('lg')]: {
    marginTop: '15px'
  }
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  fontSize: '18px',
  marginTop: '20px',
  marginLeft: '10px',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

export const ServicesGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '0px'
  }
}));

export const StyledTable = styled(Table)(() => ({
  marginTop: '7px'
}));

export const StyledTableRow = styled(TableRow)(({ checkId, showRows }) => ({
  opacity: checkId === 2 && !showRows ? '0.5' : ''
}));

export const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '19px',
  color: theme.palette.text.primary
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '19px',
  color: theme.palette.text.primary
}));

export const TopBox = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  justifyContent: 'space-around',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px'
}));

import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  displayScreenOrdersResponse: null,
  isSuccess: false
};

const DisplayScreenOrdersSlice = createSlice({
  name: 'displayScreenOrders',
  initialState: INITIAL_STATE,
  reducers: {
    displayScreenOrdersResponse(state, { payload }) {
      return {
        ...state,
        displayScreenOrdersResponse: payload.response
      };
    },
    displayScreenOrdersRequest: (state) => state,
    updateDisplayScreenOrdersSuccessStatus(state, { payload }) {
      return {
        isSuccess: payload
      };
    }
  }
});

export const {
  displayScreenOrdersRequest,
  displayScreenOrdersResponse,
  updateDisplayScreenOrdersSuccessStatus
} = DisplayScreenOrdersSlice.actions;

export default DisplayScreenOrdersSlice.reducer;

import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const callCenterUrl = {
  orders: `${baseURL}/orders`,
  pending: `${baseURL}/orders/pending`,
  unverified: `${baseURL}/orders/unverified`
};

export default callCenterUrl;

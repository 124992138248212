/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  OrdersActionButton,
  OrdersBox,
  SingleOrderBox,
  UserSectionBox,
  OutletSectionBox,
  OrderStatusBox,
  OrdersActionBox,
  OrderTypography,
  UpperBox,
  SearchBox,
  RejectedOrdersActionButton,
  GeneralTypography,
  AcknowledgeCopyBox
} from './Styled-components';
import {
  deleteRejectedOrderApi,
  getPendingOrdersApi,
  getUnverifiedOrdersApi
} from '../../api/callCenter/callCenterApi';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleOrderApi } from '../../api/orders/OrdersApi';
import ConfirmDelete from './components/ConfirmDelete';
import OrderDetails from './components/OrderDetails';
import { extractTime } from './Shared';
import {
  copyOrder,
  modifyOrder,
  resetCart,
  setItems
} from '../../redux/reducers/ducks/CartDuck';
import { addSnackbar } from '../../redux/reducers/ducks/SnackbarDuck';
import { Link } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import Loader from '../Loader/Loader';
import styled from 'styled-components';

const RotatingIcon = styled(RefreshIcon)(({ isRotating, checked }) => ({
  color: checked ? 'blue' : 'gray',
  cursor: 'pointer',
  transition: 'transform 0.5s ease-in-out',
  fontSize: '2rem',
  ...(isRotating && {
    animation: 'spin 1s linear infinite'
  }),
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
}));
export default function Orders({ outletId, trigger, setTrigger, verified }) {
  const dispatch = useDispatch();

  const running = true;
  const [runningOrders, setRunningOrders] = useState([]);
  const [singleOrder, setSingleOrder] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // handling pop Up for Order details
  const [addPopup, setAddPopup] = useState({ isOpen: false, id: null });

  // handling switch to set true or false auto refresh Running orders
  const [checked, setChecked] = useState(true);

  const handleToggle = () => {
    setChecked(!checked);
  };

  // get single Order Api calling
  const getSingleOrder = async (id) => {
    const res = await getSingleOrderApi(id);
    setSingleOrder(res?.data);
  };

  const [isRotating, setIsRotating] = useState(false);

  const pendingOrders = async () => {
    try {
      setIsLoading(true);
      setIsRotating(true);
      const response = await getPendingOrdersApi(outletId);
      const unverifiedRes = verified
        ? (await getUnverifiedOrdersApi()?.data) || []
        : [];

      if (response?.data) {
        setRunningOrders([...unverifiedRes, ...response?.data]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);

      return error;
    } finally {
      setIsLoading(false);
      setIsRotating(false);
    }
  };

  useEffect(() => {
    pendingOrders();

    if (trigger.status) {
      setTrigger({ status: false, order: singleOrder });
      callFunc();
    }

    // calling API after 30 seconds if outo refresh is on
    let interval;
    if (checked) {
      interval = setInterval(() => {
        // Make API call here
        pendingOrders();
      }, 15000);
    }

    return () => clearInterval(interval);
  }, [checked, trigger]);

  async function callFunc() {
    pendingOrders();
  }

  // *******************delete rejected order***************
  const deleteRejectedOrder = async (orderId) => {
    const res = await deleteRejectedOrderApi(orderId);
    if (res.status == 200) {
      dispatch(resetCart());
      pendingOrders();
      dispatch(addSnackbar({ message: `Order Deleted`, type: 'warning' }));
    }
  };

  // **************** get Single Order ***********
  const copySingleOrder = async (orderId) => {
    const res = await getSingleOrderApi(orderId);
    const order = res.data;
    dispatch(
      copyOrder({
        customer: order?.customer?._id,
        area: order.area,
        orderType: order.orderType,
        customerName: order.customerName,
        customerContact: order.customerContact,
        deliveryAddress: order.deliveryAddress,
        tableId: order.tableId,
        deliveryNote: order.deliveryNote,
        modifyOrder: null,
        outlet: order.outlet._id,
        discount: order.fixedDiscount
          ? order.discount
          : order.discountRate * 100,
        fixedDiscount: order.fixedDiscount,
        discountRef: {
          user: order.discountRef,
          key: ''
        },
        items: order.items.map((item) => ({
          menuItem: item.menuItem,
          choices: item.choices.map((choice) => ({
            choice: choice.choice,
            option: choice.option || null
          })),
          note: item.note,
          quantity: item.quantity
        }))
      })
    );
  };

  // // getting runningOrdersResponse from store
  // const runningOrdersResponse = useSelector((state) => state.runningOrders);

  // useEffect(() => {
  //   if (
  //     !runningOrdersResponse ||
  //     !runningOrdersResponse.runningOrdersResponse
  //   ) {
  //     dispatch(runningOrdersRequest(outletId));
  //   } else {
  //     setRunningOrders(runningOrdersResponse.runningOrdersResponse?.data);
  //   }
  //   if (trigger.status) {
  //     setTrigger({ status: false, order: singleOrder });
  //     callFunc();
  //   }

  //   // calling API after 30 seconds if outo refresh is on
  //   let interval;
  //   if (checked) {
  //     interval = setInterval(() => {
  //       // Make API call here
  //       dispatch(runningOrdersRequest(outletId));
  //     }, 15000);
  //   }

  //   return () => clearInterval(interval);
  // }, [dispatch, runningOrdersResponse, trigger, checked]);

  // // calling API over some data modification to update store

  // async function callFunc() {
  // dispatch(runningOrdersRequest(outletId));
  // }

  // Search order Module
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredOrders = runningOrders?.filter(
    (order) =>
      order?._id?.slice(-5)?.includes(searchQuery) ||
      order?.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order?.customerContact?.includes(searchQuery) ||
      order?.orderType?.includes(searchQuery) ||
      order?.carrier?.name?.includes(searchQuery) ||
      order?.outlet.name?.includes(searchQuery)
  );

  return (
    <Box
      style={{
        // border: '1px solid red',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      {/* <FormControlLabel
        label={<GeneralTypography>Auto Refresh Orders</GeneralTypography>}
        control={<Switch checked={checked} onChange={handleToggle} />}
      /> */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '4px'
        }}>
        {/* <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleToggle} />}
          label="Auto Refresh"
        /> */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '6px 10px',
            cursor: 'pointer',
            borderRadius: '10px',
            border: '1px solid gray'
          }}>
          <RotatingIcon
            isRotating={isRotating}
            onClick={handleToggle}
            checked={checked}
          />
        </Box>
        <Button
          style={{
            padding: '8px 30px',
            borderRadius: '10px',
            fontWeight: 100
          }}
          // variant="contained"
          variant="outlined"
          component={Link}
          to="/orders-status">
          All Orders
        </Button>
      </Box>
      <SearchBox>
        <TextField
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px'
            }
          }}
          label="Search Order"
          variant="outlined"
          size="small"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </SearchBox>
      <OrdersBox>
        {filteredOrders?.map((order, index) => {
          return (
            <SingleOrderBox
              sx={{
                // backgroundColor: order.cancelled ? '#A80000' : '',
                backgroundColor: order.cancelled
                  ? '#A80000'
                  : !order.verified
                  ? 'blue'
                  : '',

                color: order.cancelled ? '#fff' : ''
              }}
              key={index}>
              <UpperBox
                onClick={() => {
                  setAddPopup({ isOpen: true, id: order._id });
                  getSingleOrder(order._id);
                }}>
                <UserSectionBox>
                  <OrderTypography>Name:{order.customerName}</OrderTypography>
                  <OrderTypography>
                    Contact:{order.customerContact}
                  </OrderTypography>
                  {/* <OrderTypography>Second Contact:{order.customerContact}</OrderTypography> */}
                </UserSectionBox>

                <OutletSectionBox>
                  <OrderTypography>Branch: {order.outlet.name}</OrderTypography>
                  {order.orderType === 'delivery' && (
                    <>
                      <OrderTypography>
                        Dileviry Address: {order.deliveryAddress}
                      </OrderTypography>
                    </>
                  )}
                </OutletSectionBox>
                {order.cancelled && (
                  <Typography>Reason: {order?.cancelledReason}</Typography>
                )}
              </UpperBox>
              <Typography>
                Time:
                {extractTime(new Date(order.createdAt))}
              </Typography>
              <OrdersActionBox>
                {order.cancelled && (
                  <>
                    <AcknowledgeCopyBox>
                      <Tooltip title="Delete this rejected Order">
                        <RejectedOrdersActionButton
                          variant="contained"
                          onClick={() => {
                            deleteRejectedOrder(order._id);
                          }}>
                          Acknowledge
                        </RejectedOrdersActionButton>
                      </Tooltip>
                      <RejectedOrdersActionButton
                        variant="contained"
                        onClick={() => {
                          copySingleOrder(order._id);
                        }}>
                        Copy
                      </RejectedOrdersActionButton>
                    </AcknowledgeCopyBox>
                  </>
                )}
                {!order.verified && (
                  <>
                    <Tooltip title="Verify">
                      <RejectedOrdersActionButton
                        variant="contained"
                        onClick={() => {
                          // deleteRejectedOrder(order._id); verify api
                        }}>
                        Verify
                      </RejectedOrdersActionButton>
                    </Tooltip>
                    <Tooltip title="Rejected this Order">
                      <RejectedOrdersActionButton
                        variant="contained"
                        onClick={() => {
                          deleteRejectedOrder(order._id);
                        }}>
                        Reject
                      </RejectedOrdersActionButton>
                    </Tooltip>
                    <RejectedOrdersActionButton
                      variant="contained"
                      onClick={() => {
                        copySingleOrder(order._id);
                      }}>
                      Copy
                    </RejectedOrdersActionButton>
                  </>
                )}
              </OrdersActionBox>
            </SingleOrderBox>
          );
        })}
      </OrdersBox>
      <OrderDetails
        addPopup={addPopup}
        setAddPopup={setAddPopup}
        callFunc={callFunc}
        singleOrder={singleOrder}
      />
    </Box>
  );
}

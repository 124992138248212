import axios from 'axios';
import { refreshAccessToken } from '../refreshTokenApi';
import outletUrls from './outletUrls';

// here start
const accessToken = localStorage.getItem('accessToken');

// get Outlet Menu API
export const getOutletMenuApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(
      `${outletUrls.outlets}/${outletId}/menu`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${outletUrls.outlets}/${outletId}/menu`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get all Outlets API
export const getOutletsApi = async () => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.get(outletUrls.outlets, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(outletUrls.outlets, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// get a single Outlet
export const getSingleOutletApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  if (!outletId) {
    return null;
  }
  try {
    const data = await axios.get(`${outletUrls.outlets}/${outletId}`, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.get(
        `${outletUrls.outlets}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Add Outlet API
export const addOutletApi = async (outletData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.post(outletUrls.outlets, outletData, config);
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.post(outletUrls.outlets, outletData, newConfig);
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// Edit Outlet API
export const editOutletApi = async (outletId, outletData) => {
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  try {
    const data = await axios.put(
      `${outletUrls.outlets}/${outletId}`,
      outletData,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.put(
        `${outletUrls.outlets}/${outletId}`,
        outletData,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

// delete Outlet API

export const deleteMenuItemApi = async (outletId) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } };
  try {
    const data = await axios.delete(
      `${outletUrls.outlets}/${outletId}`,
      config
    );
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      const newAccessToken = await refreshAccessToken();
      const newConfig = {
        headers: { Authorization: `Bearer ${newAccessToken}` }
      };
      const data = await axios.delete(
        `${outletUrls.outlets}/${outletId}`,
        newConfig
      );
      return data;
    } else {
      console.error(error);
      return null;
    }
  }
};

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { useDrawerContext } from '../contexts/drawer-contexts';
import { useTheme } from '@mui/material/styles';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, useMediaQuery } from '@mui/material';
import RegisterReport from '../Reports/RegisterReport';
import { logOutUser } from '../../redux/reducers/ducks/AuthorizeDuck';
import ProfileManagment from './components/ProfileManagment';
import WestIcon from '@mui/icons-material/West';

const Header = () => {
  const myDisplay = useMediaQuery('(max-width: 900px)');

  // Register report
  const [value, setValue] = useState({ isOpen: false, id: '' });

  const params = useParams();
  const dispatch = useDispatch();

  const obj = params;
  const outletstr = obj['*']; // gets the string "outlet/skp/pos"
  const str = outletstr;
  const parts = str.split('/');
  const code = parts[1]; // gets the text "skp"

  const outlet = useSelector((state) =>
    state.authorize?.loginResponse?.user?.outlets?.find(
      (outlet) => outlet.code.toLowerCase() === code
    )
  );

  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();

  const navigate = useNavigate();
  const clearStorage = () => {
    localStorage.clear();
    dispatch(logOutUser());
    navigate(0);
  };

  useEffect(() => {
    const roleResitricted = process.env.REACT_APP_ROLE_RESTRICTED || '';
    const userRole = nameFromUser?.role.name;

    if (roleResitricted && userRole && !roleResitricted.includes(userRole)) {
      clearStorage();
    }
  }, []);
  const nameFromUser = useSelector(
    (state) => state.authorize?.loginResponse?.user
  );

  const userName = nameFromUser?.name;

  const toolbarStyle = {
    minHeight: '30px'
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: '#101924',
          height: '40px',
          color: '#fff'
        }}>
        <Toolbar disableGutters style={toolbarStyle}>
          <IconButton
            color="inherit"
            onClick={() => toggleIsOpened(!isOpened)}
            sx={{ padding: theme.spacing(1) }}>
            {isOpened ? <WestIcon /> : <MenuIcon />}
          </IconButton>

          <Typography sx={{ margin: 'auto', fontSize: '14px' }}>
            <NavLink to="/">
              {outlet ? (
                <>{myDisplay ? outlet?.code : outlet?.name}</>
              ) : outletstr == 'callCenter' ? (
                <> {`${process.env.REACT_APP_CLIENT_NAME} Call Center`}</>
              ) : (
                <> {process.env.REACT_APP_CLIENT_NAME}</>
              )}
            </NavLink>
          </Typography>
          {outlet && (
            <Button
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
                color: '#fff',
                height: '20px',
                fontSize: '12px',
                lineHeight: 0,
                letterSpacing: 0,
                // width: !myDisplay ? '121px' : '10px',
                padding: '3px',
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_COLOR_HOVER
                }
              }}
              onClick={() => {
                setValue({ isOpen: true, id: '' });
              }}>
              {!myDisplay ? 'Register Report' : 'RP'}
            </Button>
          )}

          <Typography sx={{ margin: 'auto', fontSize: '14px' }}>
            {!myDisplay
              ? userName
              : userName.charAt(0).toUpperCase() + userName.slice(1, 5) + '...'}
          </Typography>

          <ProfileManagment />
        </Toolbar>
      </AppBar>

      <RegisterReport
        value={value}
        setValue={setValue}
        // callFunc={callFunc}
      />
    </>
  );
};

export default Header;

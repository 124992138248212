import { getApi } from '../../components/Shared/shared';
const baseURL = getApi();

export const userUrls = {
  user: `${baseURL}/users/with-permission`,
  getUsers: `${baseURL}/users`,
  changeKey: `${baseURL}/users/changeKey`,
  changePassword: `${baseURL}/users/changePassword`,
  deleteUser: `${baseURL}/users`,
  bannedUser: `${baseURL}/users/banned`,
  banUser: `${baseURL}/users/ban/phone`,
  unBanUser: `${baseURL}/users/unban`
};

export default userUrls;
